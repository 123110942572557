import {
  RESET_STAFF_PASSWORD_REQUEST,
  RESET_STAFF_PASSWORD_REQUEST_SUCCESS,
  RESET_STAFF_PASSWORD_REQUEST_FAILURE
} from "./actionTypes";

const initialState = {
  isFetching: false,
  errorMessage: "",
  status: null,
  successMessage: "",
  password:""
};

export const Status = {
  RESET_STAFF_PASSWORD_REQUEST: "reset_staff_password_request",
  RESET_STAFF_PASSWORD_REQUEST_SUCCESS: "reset_staff_password_request_success",
  RESET_STAFF_PASSWORD_REQUEST_FAILURE: "reset_staff_password_request_failure",
};

const resetStaffPasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_STAFF_PASSWORD_REQUEST:
      return {
        ...state,
        isFetching: true,
        status: Status.RESET_STAFF_PASSWORD_REQUEST,
      };

    case RESET_STAFF_PASSWORD_REQUEST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        password: action.payload.password,
        successMessage: action.payload.message,
        status: Status.RESET_STAFF_PASSWORD_REQUEST_SUCCESS,
      };

    case RESET_STAFF_PASSWORD_REQUEST_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
        status: Status.RESET_STAFF_PASSWORD_REQUEST_FAILURE,
      };

    default:
      return state;
  }
};

export const resetStaffPasswordIsFetchingState = (state) =>
  state.resetStaffPassword.isFetching;
export const resetStaffPasswordErrorMessageState = (state) =>
  state.resetStaffPassword.errorMessage;
export const resetStaffPasswordStatusState = (state) => state.resetStaffPassword.status;
export const resetStaffPasswordNewPasswordState = (state) => state.resetStaffPassword.password;
export const resetStaffPasswordSuccessMessageState = (state) => state.resetStaffPassword.successMessage;

export default resetStaffPasswordReducer;

import React, { useState } from "react";
import { Layout, Button } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { makeStyles } from "@material-ui/styles";
import { useSelector } from "react-redux";

import { colors, fontWeight, fontSize } from "../Css";
import ProfileDrawer from "./ProfileDrawer";
import { getCurrentUserState } from "../redux/Authentication/loginReducer";

const { Header } = Layout;

const TopHeader = props => {
  const [isVisible, setVisibility] = useState(false);
  const user = useSelector(getCurrentUserState);
  const classes = useStyles();
  const { toggleSideBar } = props;

  const openDrawer = () => {
    setVisibility(true);
  };

  const closeDrawer = () => {
    setVisibility(false);
  };

  return (
    <>
      <Header className={classes.container}>
        <Button type="link" onClick={toggleSideBar}>
          <MenuOutlined style={{ color: colors.white, fontSize: 22 }} />
        </Button>
        <div className="profile-container" onClick={openDrawer}>
          <div className="image-container">
            <img
              src={user && user.profilePictureUri ? user.profilePictureUri : ""}
              alt=""
            />
          </div>
          <span className="user-name">{user && user.fullName}</span>
        </div>
      </Header>
      <ProfileDrawer isVisible={isVisible} closeDrawer={() => closeDrawer()} />
    </>
  );
};

const useStyles = makeStyles({
  container: {
    background: colors.primary,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 56,
    lineHeight: 0,
    padding: "0px 16px",
    "& .profile-container": {
      cursor: "pointer",
      display: "flex",
      alignItems: "center"
    },
    "& .image-container": {
      width: 32,
      height: 32,
      background: colors.imageBackground,
      marginRight: 8,
      borderRadius: "50%"
    },
    "& img": {
      // width: 'inherit',
      // height: 'inherit',
      cursor: "pointer",
      borderRadius: "50%",
      width: 32,
      height: 32,
      objectFit: "cover"
    },
    "& .user-name": {
      fontWeight: fontWeight.medium,
      color: colors.white,
      fontSize: fontSize.medium
    }
  }
});

export default React.memo(TopHeader);

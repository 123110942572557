import React from "react";
import { Layout, Menu } from "antd";
import {
  BarChartOutlined,
  FormOutlined,
  SnippetsOutlined,
  AppstoreAddOutlined,
  UserOutlined,
  LogoutOutlined,
  FileAddOutlined
} from "@ant-design/icons";
import { makeStyles } from "@material-ui/styles";

import { colors, fontWeight } from "../Css";
import { PrivatePaths, PublicPaths } from "../routes";
import Auth from "../utils/Auth";

const { Sider } = Layout;

const SideBar = props => {
  const classes = useStyles();
  const SideBarContents = [
    { name: "Overview", icon: BarChartOutlined, path: PrivatePaths.OVERVIEW },
    { name: "Assessment", icon: FormOutlined, path: PrivatePaths.ASSESSMENT },
    {
      name: "Contact Trace",
      icon: SnippetsOutlined,
      path: PrivatePaths.CONTACT_TRACE
    },
    {
      name: "Case Management",
      icon: FileAddOutlined,
      path: PrivatePaths.CASE_MANAGEMENT
    },
    {
      name: "Partners",
      icon: AppstoreAddOutlined,
      path: PrivatePaths.PARTNERS
    },
    {
      name: "User Administration",
      icon: UserOutlined,
      path: PrivatePaths.STAFF
    }
    // { name: "Settings", icon: SettingOutlined, path: PrivatePaths.SETTINGS },
  ];
  const { history, collapsed, toggleSideBar } = props;
  const currentView = window.location.href
    .replace(new RegExp(`${window.location.origin}/|/$`, "g"), "")
    .trim()
    .split("/")[0];

  function logout() {
    Auth.removeToken();
    Auth.removeCurrentUser();
    history.push(PublicPaths.LOGIN);
  }

  return (
    <Sider width={240} className={classes.container} collapsed={collapsed}>
      <Menu mode="inline" theme="light" selectedKeys={[currentView]}>
        {SideBarContents.map(list => {
          let Icon = list.icon;
          return (
            <Menu.Item
              key={list.name
                .toLowerCase()
                .trim()
                .replace(/\s/, "-")}
              onClick={() => {
                if (list.path === PrivatePaths.OVERVIEW && !collapsed) {
                  toggleSideBar();
                }
                if (list.path === PrivatePaths.OVERVIEW)
                  window.open(list.path, "_self");
                else history.push(list.path);
              }}
            >
              <Icon />
              <span className="sidebar-text">{list.name}</span>
            </Menu.Item>
          );
        })}
      </Menu>
      <Menu mode="inline" theme="light">
        <Menu.Item key="logout" onClick={logout}>
          <LogoutOutlined />
          <span className="sidebar-text">Log Out</span>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

const useStyles = makeStyles({
  container: {
    background: colors.white,
    padding: "32px 0px 50px",
    "& .ant-layout-sider-children": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between"
    },
    "& .ant-menu": {
      background: "transparent"
    },
    "& .ant-menu-item": {
      paddingRight: 0,
      paddingLeft: `25px !important`,
      marginTop: 0,
      height: 52,
      display: "flex",
      alignItems: "center"
    },
    "& .ant-menu-item:not(:last-child)": {
      marginBottom: 10
    },
    "& .ant-menu-item span.sidebar-text": {
      marginLeft: 16,
      fontWeight: fontWeight.medium,
      color: "#83839C"
    },
    "& .ant-menu-item .anticon": {
      fontSize: 24,
      color: "#83839C"
    },
    "& .ant-menu-inline .ant-menu-item::after": {
      left: 0,
      borderRight: 0,
      borderLeft: `5px solid ${colors.primary}`
    },
    "& .ant-menu-item-selected": {
      background: `${colors.primaryWeak} !important`
    },
    "& .ant-menu-item-selected .anticon": {
      color: colors.secondaryBase
    },
    "& .ant-menu-item-selected span.sidebar-text": {
      color: colors.base,
      fontWeight: fontWeight.bold
    },
    "& .ant-menu-item-active:hover": {
      background: colors.primaryWeak
    },
    "& .ant-menu-item-active:hover .anticon": {
      color: colors.secondaryBase
    },
    "& .ant-menu-item-active:hover span.sidebar-text": {
      color: colors.base
    }
  }
});

export default React.memo(SideBar);

import {
  GET_PARTNERS_STATS_REQUEST,
  GET_PARTNERS_STATS_REQUEST_SUCCESS,
  GET_PARTNERS_STATS_REQUEST_FAILURE,
} from "./actionTypes";

const initialState = {
  isFetching: false,
  status: null,
  errorMessage: "",
  stats: {},
};

export const PartnerStatsStatus = {
  GET_PARTNERS_STATS: `get_partners_stats`,
  GET_PARTNERS_STATS_REQUEST_SUCCESS: `get_partners_stats_success`,
  GET_PARTNERS_STATS_REQUEST_FAILURE: `get_partners_stats_failure`,
};

const partnersStatsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PARTNERS_STATS_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GET_PARTNERS_STATS_REQUEST_SUCCESS: {
      return {
        ...state,
        stats: action.payload,
        isFetching: false,
        status: PartnerStatsStatus.GET_PARTNERS_STATS_REQUEST_SUCCESS,
      };
    }
    case GET_PARTNERS_STATS_REQUEST_FAILURE: {
      return {
        ...state,
        errorMessage: action.payload,
        isFetching: false,
        status: PartnerStatsStatus.GET_PARTNERS_STATS_REQUEST_FAILURE,
      };
    }
    default:
      return state;
  }
};

export const getPartnersStatsIsFetchingState = (state) =>
  state.partnersStats.isFetching;
export const getPartnersStatsErrorMessageState = (state) =>
  state.partnersStats.errorMessage;
export const getPartnersStatsStatusState = (state) =>
  state.partnersStats.status;
export const getPartnersStatsState = (state) => state.partnersStats.stats;

export default partnersStatsReducer;

export const GET_INDEX_CASES_REQUEST = 'GET_INDEX_CASES_REQUEST';
export const GET_INDEX_CASES_REQUEST_SUCCESS =
  'GET_INDEX_CASES_REQUEST_SUCCESS';
export const GET_INDEX_CASES_REQUEST_FAILURE =
  'GET_INDEX_CASES_REQUEST_FAILURE';
export const RESET_INDEX_CASES_STORE = 'RESET_INDEX_CASES_STORE';

export const GET_ACTIVITY_LOGS_REQUEST = 'GET_ACTIVITY_LOGS_REQUEST';
export const GET_ACTIVITY_LOGS_REQUEST_SUCCESS =
  'GET_ACTIVITY_LOGS_REQUEST_SUCCESS';
export const GET_ACTIVITY_LOGS_REQUEST_FAILURE =
  'GET_ACTIVITY_LOGS_REQUEST_FAILURE';
export const RESET_ACTIVITY_LOGS_STORE = 'RESET_ACTIVITY_LOGS_STORE';

export const GET_ASSESSMENT_LOGS_REQUEST = 'GET_ASSESSMENT_LOGS_REQUEST';
export const GET_ASSESSMENT_LOGS_REQUEST_SUCCESS =
  'GET_ASSESSMENT_LOGS_REQUEST_SUCCESS';
export const GET_ASSESSMENT_LOGS_REQUEST_FAILURE =
  'GET_ASSESSMENT_LOGS_REQUEST_FAILURE';
export const RESET_ASSESSMENT_LOGS_STORE = 'RESET_ASSESSMENT_LOGS_STORE';

export const GET_PATIENT_INFO_REQUEST = 'GET_PATIENT_INFO_REQUEST';
export const GET_PATIENT_INFO_REQUEST_SUCCESS =
  'GET_PATIENT_INFO_REQUEST_SUCCESS';
export const GET_PATIENT_INFO_REQUEST_FAILURE =
  'GET_PATIENT_INFO_REQUEST_FAILURE';

export const GET_CONTACT_DETAILS_ASSESSMENTS_LOGS =
  'GET_CONTACT_DETAILS_ASSESSMENTS_LOGS';
export const GET_CONTACT_DETAILS_ASSESSMENTS_LOGS_FAILURE =
  'GET_CONTACT_DETAILS_ASSESSMENTS_LOGS_FAILURE';
export const GET_CONTACT_DETAILS_ASSESSMENTS_LOGS_SUCCESS =
  'GET_CONTACT_DETAILS_ASSESSMENTS_LOGS_SUCCESS';
export const RESET_CONTACT_DETAILS_ASSESSMENTS_LOGS =
  'RESET_CONTACT_DETAILS_ASSESSMENTS_LOGS';

export const GET_CONTACT_DETAILS_ASSESSMENTS_LOGS_BY_ID =
  'GET_CONTACT_DETAILS_ASSESSMENTS_LOGS_BY_ID';
export const GET_CONTACT_DETAILS_ASSESSMENTS_LOGS_BY_ID_FAILURE =
  'GET_CONTACT_DETAILS_ASSESSMENTS_LOGS_BY_ID_FAILURE';
export const GET_CONTACT_DETAILS_ASSESSMENTS_LOGS_BY_ID_SUCCESS =
  'GET_CONTACT_DETAILS_ASSESSMENTS_LOGS_BY_ID_SUCCESS';
export const RESET_CONTACT_DETAILS_ASSESSMENTS_LOGS_BY_ID =
  'RESET_CONTACT_DETAILS_ASSESSMENTS_LOGS_BY_ID';

export const GET_EXPORT_CONTACT_DATA_REQUEST =
  'GET_EXPORT_CONTACT_DATA_REQUEST';
export const GET_EXPORT_CONTACT_DATA_REQUEST_SUCCESS =
  'GET_EXPORT_CONTACT_DATA_REQUEST_SUCCESS';
export const GET_EXPORT_CONTACT_DATA_REQUEST_FAILURE =
  'GET_PATIENT_INFO_REQUEST_FAILURE';

export const GET_CONTACT_DETAILS_REQUEST = 'GET_CONTACT_DETAILS_REQUEST';
export const GET_CONTACT_DETAILS_REQUEST_SUCCESS =
  'GET_CONTACT_REQUEST_SUCCESS';
export const GET_CONTACT_DETAILS_REQUEST_FAILURE =
  'GET_CONTACT_DETAILS_REQUEST_FAILURE';
export const RESET_CONTACT_DETAILS_REQUEST = 'RESET_CONTACT_DETAILS_REQUEST';

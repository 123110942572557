export const GET_ASSESSMENTS_REQUEST = "GET_ASSESSMENTS_REQUEST";
export const GET_ASSESSMENTS_REQUEST_SUCCESS =
  "GET_ASSESSMENTS_REQUEST_SUCCESS";
export const GET_ASSESSMENTS_REQUEST_FAILURE =
  "GET_ASSESSMENTS_REQUEST_FAILURE";
export const RESET_ASSESSMENTS_STORE = "RESET_ASSESSMENTS_STORE";

export const GET_ASSESSMENT_SOURCES_REQUEST = "GET_ASSESSMENT_SOURCES_REQUEST";
export const GET_ASSESSMENT_SOURCES_REQUEST_SUCCESS =
  "GET_ASSESSMENT_SOURCES_REQUEST_SUCCESS";
export const GET_ASSESSMENT_SOURCES_REQUEST_FAILURE =
  "GET_ASSESSMENT_SOURCES_REQUEST_FAILURE";

export const GET_STATE_DEMOGRAPHY_DATA_REQUEST =
  "GET_STATE_DEMOGRAPHY_DATA_REQUEST";
export const GET_STATE_DEMOGRAPHY_DATA_REQUEST_SUCCESS =
  "GET_STATE_DEMOGRAPHY_DATA_REQUEST_SUCCESS";
export const GET_STATE_DEMOGRAPHY_DATA_REQUEST_FAILURE =
  "GET_STATE_DEMOGRAPHY_DATA_REQUEST_FAILURE";

export const GET_ASSESSMENTS_REPORT_DETAILS_REQUEST =
  "GET_ASSESSMENTS_REPORT_DETAILS_REQUEST";
export const GET_ASSESSMENTS_REPORT_DETAILS_REQUEST_SUCCESS =
  "GET_ASSESSMENTS_REPORT_DETAILS_REQUEST_SUCCESS";
export const GET_ASSESSMENTS_REPORT_DETAILS_REQUEST_FAILURE =
  "GET_ASSESSMENTS_REPORT_DETAILS_REQUEST_FAILURE";
export const RESET_ASSESSMENTS_REPORT_DETAILS_STORE =
  "RESET_ASSESSMENTS_REPORT_DETAILS_STORE";

export const GET_ASSESSMENTS_BY_PERIOD_REQUEST =
  "GET_ASSESSMENTS_BY_PERIOD_REQUEST";
export const GET_ASSESSMENTS_BY_PERIOD_REQUEST_SUCCESS =
  "GET_ASSESSMENTS_BY_PERIOD_REQUEST_SUCCESS";
export const GET_ASSESSMENTS_BY_PERIOD_REQUEST_FAILURE =
  "GET_ASSESSMENTS_BY_PERIOD_REQUEST_FAILURE";

export const GET_ASSESSMENTS_SUMMARY = "GET_ASSESSMENTS_SUMMARY";
export const GET_ASSESSMENTS_SUMMARY_SUCCESSS =
  "GET_ASSESSMENTS_SUMMARY_SUCCESS";
export const GET_ASSESSMENTS_SUMMARY_FAILURE =
  "GET_ASSESSMENTS_SUMMARY_FAILURE";
export const RESET_ASSESSMENTS_SUMMARY = "RESET_ASSESSMENTS_SUMMARY";

export const GET_ASSESSMENTS_SUMMARY_BY_STATUS =
  "GET_ASSESSMENTS_SUMMARY_BY_STATUS";
export const GET_ASSESSMENTS_SUMMARY_BY_STATUS_SUCCESS =
  "GET_ASSESSMENTS_SUMMARY_BY_STATUS_SUCCESS";
export const GET_ASSESSMENTS_SUMMARY_BY_STATUS_FAILURE =
  "GET_ASSESSMENTS_SUMMARY_BY_STATUS_FAILURE";
export const RESET_ASSESSMENTS_SUMMARY_BY_STATUS =
  "RESET_ASSESSMENTS_SUMMARY_BY_STATUS";

export const GET_PARTNER_SOURCES_REQUEST = "GET_PARTNER_SOURCES_REQUEST";
export const GET_PARTNER_SOURCES_REQUEST_SUCCESS =
  "GET_PARTNER_SOURCES_REQUEST_SUCCESS";
export const GET_PARTNER_SOURCES_REQUEST_FAILURE =
  "GET_PARTNER_SOURCES_REQUEST_FAILURE";
export const RESET_PARTNER_SOURCES_STORE = "RESET_PARTNER_SOURCES_STORE";

export const GET_HEATMAP_DATA_REQUEST = "GET_HEATMAP_DATA_REQUEST";
export const GET_HEATMAP_DATA_REQUEST_SUCCESS =
  "GET_HEATMAP_DATA_REQUEST_SUCCESS";
export const GET_HEATMAP_DATA_REQUEST_FAILURE =
  "GET_HEATMAP_DATA_REQUEST_FAILURE";

export const UPDATE_ACTION_STATUS_SUCCESS = "UPDATE_ACTION_STATUS_SUCCESS";
export const UPDATE_ACTION_STATUS_FAILURE = "UPDATE_ACTION_STATUS_FAILURE";
export const RESET_UPDATE_ACTION_STATUS_STORE = "RESET_UPDATE_ACTION_STATUS_STORE";

export const GET_CSV_URL_REQUEST = "GET_CSV_URL_REQUEST"
export const GET_CSV_URL_REQUEST_SUCCESS = "GET_CSV_URL_REQUEST_SUCCESS"
export const GET_CSV_URL_REQUEST_FAILURE = "GET_CSV_URL_REQUEST_FAILURE"
export const RESET_CSV_URL_STORE = "RESET_CSV_URL_STORE"
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import * as d3 from "d3";
import { useSelector } from "react-redux";
import { notification, message } from "antd";

import SVGImage from "../../assets/images/ngmap.svg";
import { fontSize, fontWeight } from "../../Css";
import { Api } from "../../utils/Api";
import {
  getIsFetchingState as getIsFetchingStateForCasesByRegion,
  getStatusState as getStatusStateForCasesByRegion,
  getErrorMessageState as getErrorMessageStateForCasesByRegion,
  getCasesState,
  Status as StatusForCasesByRegion
} from "../../redux/Overview/casesByRegionReducer";
import OverviewSummaryWidget from "../../components/Overview/OverviewSummaryWidget";

const FULL = 1;
const FADED = "90%";
// const GREY = "grey";

let MAP_THEME = {
  LEVEL_1: "#d5ded9",
  LEVEL_2: "#ffeae7",
  LEVEL_3: "#ffcac1",
  LEVEL_4: "#f6ab9e",
  LEVEL_5: "#df705d",
  LEVEL_6: "#ce351a",
  LEVEL_7: "#821400"
};

const Overview = () => {
  const classes = useStyles();
  const [mapRendered, setMapRendered] = useState(false);
  const [formattedMapData, setFormattedMapData] = useState(null);
  const [filterState, setFilterState] = useState({});

  const statusForCasesByRegion = useSelector(getStatusStateForCasesByRegion);
  const errorMessageForCasesByRegion = useSelector(
    getErrorMessageStateForCasesByRegion
  );
  const isFetchingCasesByRegion = useSelector(
    getIsFetchingStateForCasesByRegion
  );
  const cases = useSelector(getCasesState);

  useEffect(() => {
    message.loading({ content: "Fetching map data", duration: 0 });
  }, []);

  useEffect(() => {
    if (!isFetchingCasesByRegion && mapRendered) {
      message.destroy();
    }
  }, [isFetchingCasesByRegion, mapRendered]);

  useEffect(() => {
    Api.OverviewRepository.getCasesByRegion();
  }, []);

  useEffect(() => {
    if (
      statusForCasesByRegion ===
      StatusForCasesByRegion.GET_CASES_BY_REGION_REQUEST_FAILURE
    ) {
      notification["error"]({
        message: "Failed to load map data",
        description: errorMessageForCasesByRegion
      });
    }
  }, [statusForCasesByRegion, errorMessageForCasesByRegion]);

  useEffect(() => {
    let stateList = cases;
    const STATES_INFO = stateList.reduce((data, state) => {
      let _stateCode = state.stateCode.replace("NG-", "");
      return {
        ...data,
        [_stateCode]: { ...state, code: _stateCode }
      };
    }, []);
    setFormattedMapData(STATES_INFO);
  }, [cases]);

  useEffect(
    function() {
      let _formattedMapData = formattedMapData && formattedMapData.length;

      if (_formattedMapData === undefined) {
        d3.xml(SVGImage)
          .then(data => {
            const map = document.getElementById("map");
            map.remove();
            document.getElementById("legend").after(data.documentElement);
            d3.selectAll(`.${classes.pageContainer} path`)
              .on("mouseover", function() {
                d3.select(this).style("fill-opacity", FULL);
                d3.select(this).style("cursor", "pointer");
              })
              .on("mouseout", function() {
                d3.select(this).style("fill-opacity", FADED);
              })
              .on("click", function() {
                let data = formattedMapData[this.parentElement.dataset.code];
                if (!data) return;
                d3.select(".selected").classed("selected", false);
                d3.select(this).classed("selected", true);
                setFilterState(data);
              });
          })
          .then(function() {
            updateMap();
            if (typeof SVGRect === "undefined") {
              // does not support SVG. Wrap in object tag
              console.log(
                "No native SVG support. Falling back to <object></object> tag"
              );
              const map = document.getElementById("map");
              const svg = new Blob([map.outerHTML], {
                type: "image/svg+xml;charset=utf-8"
              });
              document.getElementById(
                "map-container"
              ).innerHTML += `<object type="image/svg+xml" data="${URL.createObjectURL(
                svg
              )}">`;
            }
          })
          .then(function() {
            d3.selectAll(`${classes.pageContainer} svg`)
              .filter((d, i) => i === 7)
              .remove();
          })
          .then(() => setMapRendered(true))
          .catch(error => console.log(error));
      }
    },
    // eslint-disable-next-line
    [formattedMapData]
  );

  function updateMap() {
    d3.selectAll("g")
      .selectAll("path")
      .each(function() {
        let _xAttr =
          this.parentElement.childNodes[1] &&
          parseInt(this.parentElement.childNodes[1].getAttribute("x"));
        let _yAttr =
          this.parentElement.childNodes[1] &&
          parseInt(this.parentElement.childNodes[1].getAttribute("y"));
        let state = formattedMapData[this.parentElement.dataset.code];
        d3.selectAll("g").each(function(d, i) {
          if (!!state) {
            d3.select(this)
              .append("text")
              .attr("x", _xAttr + 0)
              .attr("y", _yAttr + 8)
              .attr("font-size", "6px")
              .attr("font-weight", "400")
              .attr("fill", "#000")
              .attr("text-anchor", "start")
              .text(formatThousandNumber(state.assessmentCount));
          }
        });
      })
      .transition()
      .style("fill", function() {
        let state = formattedMapData[this.parentElement.dataset.code];

        if (!!state) {
          if (state.assessmentCount > 1000) {
            return MAP_THEME.LEVEL_7;
          } else if (state.assessmentCount > 500) {
            return MAP_THEME.LEVEL_6;
          } else if (state.assessmentCount > 200) {
            return MAP_THEME.LEVEL_5;
          } else if (state.assessmentCount > 50) {
            return MAP_THEME.LEVEL_4;
          } else if (state.assessmentCount > 10) {
            return MAP_THEME.LEVEL_3;
          } else if (state.assessmentCount > 0) {
            return MAP_THEME.LEVEL_2;
          } else {
            return MAP_THEME.LEVEL_1;
          }
        }
      })
      .style("fill-opacity", FADED)
      .style("fill-rule", "evenodd")
      .style("stroke", "#ade2a2")
      .duration(1000)
      .ease(d3.easeQuad);
  }

  const formatThousandNumber = number => {
    return (number && parseInt(number).toLocaleString()) || 0;
  };

  const clearSelectedStateFilter = e => {
    e.preventDefault();
    setFilterState({});
    let selected = document.getElementsByClassName("selected");
    while (selected.length) {
      selected[0].classList.remove("selected");
    }
  };

  return (
    <div className={classes.pageContainer}>
      <div
        id="map-container"
        className={`mapContainer ${isFetchingCasesByRegion && "disableClick"}`}
      >
        <h1 class="map-title">Distribution of Assessment Reports</h1>
        <ul id="legend"></ul>
        <img id="map" src={SVGImage} className={classes.mapContainer} alt="" />
        <div className="indicatorContainer">
          <p className="title">Number of Cases</p>
          <div className="indicator">
            <div className="indicator-segment level_1"></div>
            <div className="indicator-segment level_2"></div>
            <div className="indicator-segment level_3"></div>
            <div className="indicator-segment level_4"></div>
            <div className="indicator-segment level_5"></div>
            <div className="indicator-segment level_6"></div>
          </div>
          <div className="indicator" style={{ margin: 0 }}>
            <div className="indicator-segment">1 - 10</div>
            <div className="indicator-segment">11 - 50</div>
            <div className="indicator-segment">51 - 200</div>
            <div className="indicator-segment">201 - 500</div>
            <div className="indicator-segment">501 - 1,000</div>
            <div className="indicator-segment">Over 1,000</div>
          </div>
        </div>
      </div>
      <OverviewSummaryWidget
        filterState={filterState}
        clearSelectedStateFilter={clearSelectedStateFilter}
      />
    </div>
  );
};

const useStyles = makeStyles({
  pageContainer: {
    display: "flex",
    marginLeft: "0",
    justifyContent: "center",
    height: "100vh",
    overflow: "hidden",
    "& .selected": {
      fill: "#cc0909 !important"
    },

    "& .disableClick": {
      pointerEvents: "none"
    },
    "& .mapContainer": {
      width: "75%",
      position: "relative",
      display: "flex",
      flexDirection: "column",
      height: "95vh",
      justifyContent: "flex-end",
      alignItems: "center",
      paddingTop: "10px"
    },

    "& .map-title": {
      fontWeight: fontWeight.medium,
      fontSize: fontSize.xlarge,
      textAlign: "center"
    },

    "& .indicatorContainer": {
      position: "relative",
      width: "100%",
      padding: "0% 7%",
      bottom: 50,

      "&--hidden": {
        visibility: "hidden"
      },

      "& .title": {
        fontWeight: fontWeight.medium,
        fontSize: fontSize.medium
      },
      "& .indicator": {
        display: "flex",
        width: "100%",
        margin: "7px 0px"
      },
      "& .indicator .indicator-segment": {
        width: "33.3%",
        height: 16,
        fontSize: fontSize.medium,
        fontWeight: fontWeight.medium
      },
      "& .indicator .indicator-segment.level_1": {
        background: MAP_THEME.LEVEL_2
      },
      "& .indicator .indicator-segment.level_2": {
        background: MAP_THEME.LEVEL_3
      },
      "& .indicator .indicator-segment.level_3": {
        background: MAP_THEME.LEVEL_4
      },
      "& .indicator .indicator-segment.level_4": {
        background: MAP_THEME.LEVEL_5
      },
      "& .indicator .indicator-segment.level_5": {
        background: MAP_THEME.LEVEL_6
      },
      "& .indicator .indicator-segment.level_6": {
        background: MAP_THEME.LEVEL_7
      }
    }
  }
});

export default React.memo(Overview);

import React, { useState, useEffect } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { DatePicker, notification } from "antd";
import { makeStyles } from "@material-ui/styles";

import ResourceTable, { Filter, Sorter } from "../../atoms/ResourceTable";
import {
  Status,
  getIsFetchingState,
  getStatusState,
  getErrorMessageState,
  getCurrentLogsState,
  getTotalItemsCountState
} from "../../redux/ContactTrace/activityLogsReducer";
import * as Constants from "../../utils/Constants";
import { Api } from "../../utils/Api";
import { useUpdateEffect } from "../../hooks/useUpdateEffect";
import ReportDetails from "../Assessment/ReportDetails";
import Drawer from "../../atoms/Drawer";

import { colors, fontWeight, fontSize, boxShadow } from "../../Css";
import ContactActivityDetails from "./ContactActivityDetails";

const isValidDateRange = dateRange =>
  !!dateRange.startDate && !!dateRange.endDate;
const formatDate = date => moment(date).format("YYYY/MM/DD");

const ActivityLogs = props => {
  const classes = useStyles();
  const [selectedContact, setSelectedContact] = useState({});
  // const [reportDetails, setReportDetails] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [contactDetails, setContactDetails] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(Constants.DATA_LIMIT);
  const [filteredDate, setFilteredDate] = useState({
    startDate: null,
    endDate: null
  });
  // This query value works for both name and location
  const [queryValue, setQueryValue] = useState("");
  const [isDrawerClose, setDrawerVisibility] = useState(false);
  const [sortOrder, setSortOrder] = useState(Constants.SORT_ORDER.latest.value);
  const currentSortName = !!Constants.SORT_ORDER[sortOrder]
    ? Constants.SORT_ORDER[sortOrder].name
    : "";
  const isFetching = useSelector(getIsFetchingState);
  const status = useSelector(getStatusState);
  const errorMessage = useSelector(getErrorMessageState);
  const currentLogs = useSelector(getCurrentLogsState);
  const totalItems = useSelector(getTotalItemsCountState);
  const { userId } = props;

  useEffect(() => {
    Api.ContactTraceRepository.getActivityLogs(
      currentPage,
      pageSize,
      userId,
      filteredDate,
      sortOrder,
      queryValue
    );
    setCurrentPage(1);
  }, [userId, pageSize, queryValue, filteredDate, sortOrder, currentPage]);

  useUpdateEffect(() => {
    if (status === Status.GET_ACTIVITY_LOGS_REQUEST_FAILURE) {
      notification["error"]({
        message: "Failed to load activity logs",
        description: errorMessage
      });
    }
  }, [status]);

  const handleLoadMore = page => {
    setCurrentPage(page);
  };

  const closeDrawer = () => {
    setIsVisible(false);
  };

  const openContactDetailsDrawer = record => {
    setContactDetails(record);
    setSelectedContact(record);
    // setReportDetails(record);
    setDrawerVisibility(true);
    // setIsVisible(true);
  };

  //codes for the drawer
  const closeContactDetailsDrawer = () => {
    setDrawerVisibility(false);
  };

  const handleChangeQuery = e => {
    setQueryValue(e.target.value);
  };

  const handleClearQuery = () => {
    setQueryValue("");
  };

  const handleFilteredDateChange = dates => {
    if (dates === null || dates === undefined)
      setFilteredDate({
        startDate: null,
        endDate: null
      });
    else {
      const [startDate, endDate] = dates;
      setFilteredDate({
        startDate,
        endDate
      });
    }
  };

  const handleFilteredDateRemove = () => {
    setFilteredDate({
      startDate: null,
      endDate: null
    });
  };

  const handleChangeSortOrder = value => {
    setSortOrder(value);
  };

  const disambiguateLabel = (key, value) => {
    if (key === "location") {
      return `Location: ${value}`;
    } else if (key === "date") {
      return `Date: ${formatDate(value.startDate)}-${formatDate(
        value.endDate
      )}`;
    }
  };

  const columns = () => [
    {
      title: "User ID",
      dataIndex: "fullName"
    },
    {
      title: "Location",
      dataIndex: "location"
    },
    {
      title: "Date/Time",
      dataIndex: "contactTime",
      render: date => moment(date).format("MMM DD, YYYY / hh:mm A")
    }
  ];

  const renderFilterDateControl = () => {
    return (
      <div onClick={e => e.stopPropagation()}>
        <DatePicker.RangePicker
          value={[filteredDate.startDate, filteredDate.endDate]}
          format="YYYY/MM/DD"
          onChange={handleFilteredDateChange}
        />
      </div>
    );
  };

  const appliedFilters = [];
  if (isValidDateRange(filteredDate)) {
    const key = "date";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, filteredDate),
      onRemove: handleFilteredDateRemove
    });
  }

  return (
    <div>
      <ResourceTable
        resourceName={{ singular: "Case", plural: "Cases" }}
        filterControl={
          <Filter
            filters={[
              {
                key: "date",
                label: "Date",
                filter: renderFilterDateControl()
              }
            ]}
            appliedFilters={appliedFilters}
            queryValue={queryValue}
            onQueryChange={handleChangeQuery}
            onQueryClear={handleClearQuery}
            onPressEnter={handleChangeQuery}
          />
        }
        sortControl={
          <Sorter
            currentSort={currentSortName}
            numberOfSortedData={totalItems}
            sorters={[
              {
                key: Constants.SORT_ORDER.latest.value,
                name: Constants.SORT_ORDER.latest.name,
                value: Constants.SORT_ORDER.latest.value
              },
              {
                key: Constants.SORT_ORDER.oldest.value,
                name: Constants.SORT_ORDER.oldest.name,
                value: Constants.SORT_ORDER.oldest.value
              }
            ]}
            onChange={handleChangeSortOrder}
          />
        }
        tableProps={{
          columns: columns(),
          dataSource: currentLogs,
          loading: isFetching,
          onRow: record => ({
            onClick: () => openContactDetailsDrawer(record)
          }),
          pagination: {
            total: totalItems,
            current: currentPage,
            onChange: handleLoadMore,
            onShowSizeChange: (current, pageSize) => setPageSize(pageSize)
          }
        }}
      />

      <ReportDetails
        isVisible={isVisible}
        onClose={closeDrawer}
        reportDetails={contactDetails && contactDetails}
      />

      {/* When a row is clicked the drawer with contact activity details is displayed */}
      <Drawer
        visible={isDrawerClose}
        title="Contact Details"
        onClose={closeContactDetailsDrawer}
        hideFooter={true}
      >
        <div className={classes.contactDrawerContainer}>
          <ContactActivityDetails selectedContact={selectedContact} />
        </div>
      </Drawer>
    </div>
  );
};

const useStyles = makeStyles({
  pageMetaData: {
    marginBottom: 21,
    "& p:first-child": {
      margin: 0,
      color: colors.base,
      fontWeight: fontWeight.medium,
      fontSize: fontSize.title
    }
  },
  actionHeader: {
    marginBottom: 20,
    "& span:nth-child(2)": {
      marginLeft: 18
    },
    "& span.gray-text": {
      color: colors.secondaryBase
    }
  },
  avatarContainer: {
    height: 40,
    width: 40,
    background: "#006FBB",
    marginRight: 9,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: colors.white,
    fontWeight: fontWeight.medium,
    fontSize: fontSize.medium,
    "& img": {
      width: "inherit",
      height: "inherit",
      borderRadius: "50%"
    }
  },
  userNameContainer: {
    "& p": {
      fontSize: fontSize.medium,
      color: colors.base,
      margin: 0
    },
    "& p:first-child": {
      fontWeight: fontWeight.medium
    }
  },
  clutterView: {
    width: "100%",
    height: "700px",
    padding: "0px 20px",
    paddingBottom: 20,
    "&>div": {
      boxShadow: boxShadow.card,
      background: colors.white,
      minHeight: 654
    }
  },
  stateList: {
    "&.ant-menu": {
      border: 0
    },
    "& .ant-menu-item": {
      padding: 0,
      border: 0,
      lineHeight: 2.0,
      height: "fit-content",
      margin: "0 !important"
    },
    "& .ant-menu-item span:last-child": {
      paddingRight: 0
    }
  },
  visualizationContainer: {
    width: "100%",
    height: "100%"
  },
  contactDrawerContainer: {
    paddingBottom: 30,
    "& .address": {
      height: 164,
      backgroundColor: colors.skyLighter,
      boxShadow: boxShadow.card,
      padding: 20,
      "& h6": {
        fontSize: 12,
        fontWeight: 600,
        color: colors.secondaryBase
      },
      "& .seperator": {
        margin: `19px 0`,
        // border: "none",
        border: `1px solid ${colors.seperator}`
      },
      "& p": {
        fontSize: 14,
        color: colors.base
      }
    },
    "& .details": {
      margin: `19px 0`,
      "& .mb-19": {
        marginBottom: 19
      },
      "& .detail": {
        "& h6": {
          color: colors.secondaryBase,
          fontSize: 12,
          margin: 0
        },
        "& p": {
          fontSize: 14,
          color: colors.base,
          margin: 0
        }
      }
    },
    "& .map": {
      marginBottom: 25,
      marginTop: 19,
      height: 268,
      backgroundColor: colors.base,
      borderRadius: 5
    }
  }
});

export default React.memo(ActivityLogs);

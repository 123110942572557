import {
  GET_CASES_BY_REGION_REQUEST_FAILURE,
  GET_CASES_BY_REGION_REQUEST,
  GET_CASES_BY_REGION_REQUEST_SUCCESS,
  GET_OVERVIEW_SUMMARY_REQUEST_FAILURE,
  GET_OVERVIEW_SUMMARY_REQUEST,
  GET_OVERVIEW_SUMMARY_REQUEST_SUCCESS,
  GET_CASES_BY_RISK_FACTOR_REQUEST,
  GET_CASES_BY_RISK_FACTOR_REQUEST_SUCCESS,
  GET_CASES_BY_RISK_FACTOR_REQUEST_FAILURE
} from "../redux/Overview/actionTypes";
import store from "../redux/store";

function OverviewRepository(axiosInstance) {
  let _OverViewRepository = {
    getCasesByRegion: () => {
      store.dispatch({
        type: GET_CASES_BY_REGION_REQUEST
      });
      axiosInstance
        .get("overview/map")
        .then(response => {
          store.dispatch({
            type: GET_CASES_BY_REGION_REQUEST_SUCCESS,
            payload: response.data.data
          });
        })
        .catch(error => {
          store.dispatch({
            type: GET_CASES_BY_REGION_REQUEST_FAILURE,
            payload: error.message
          });
        });
    },

    getOverviewSummary: (stateId = null) => {
      store.dispatch({ type: GET_OVERVIEW_SUMMARY_REQUEST });
      axiosInstance
        .get("overview/stat", {
          params: { stateId }
        })
        .then(response => {
          store.dispatch({
            type: GET_OVERVIEW_SUMMARY_REQUEST_SUCCESS,
            payload: response.data.data
          });
        })
        .catch(error => {
          store.dispatch({
            type: GET_OVERVIEW_SUMMARY_REQUEST_FAILURE,
            payload: error.message
          });
        });
    },

    getCasesByRiskFactor: (stateId = null) => {
      store.dispatch({
        type: GET_CASES_BY_RISK_FACTOR_REQUEST
      });
      axiosInstance
        .get(`assessment/getstateassessmentgroupbyriskfactor`, {
          params: { stateId }
        })
        .then(response => {
          store.dispatch({
            type: GET_CASES_BY_RISK_FACTOR_REQUEST_SUCCESS,
            payload: response.data.data
          });
        })
        .catch(error => {
          store.dispatch({
            type: GET_CASES_BY_RISK_FACTOR_REQUEST_FAILURE,
            payload: error.message
          });
        });
    }
  };

  return _OverViewRepository;
}

export { OverviewRepository };

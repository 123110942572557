import {
  GET_CASES_BY_REGION_REQUEST_FAILURE,
  GET_CASES_BY_REGION_REQUEST,
  GET_CASES_BY_REGION_REQUEST_SUCCESS,
} from "./actionTypes";

const initialState = {
  isFetching: false,
  errorMessage: "",
  status: null,
  cases: [],
};

export const Status = {
  GET_CASES_BY_REGION_REQUEST: `get_cases_by_region_request`,
  GET_CASES_BY_REGION_REQUEST_FAILURE: `get_cases_by_region_request_failure`,
  GET_CASES_BY_REGION_REQUEST_SUCCESS: `get_cases_by_region_request_success`,
};
const casesByRegionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CASES_BY_REGION_REQUEST: {
      return {
        ...state,
        isFetching: true,
        status: Status.GET_CASES_BY_REGION_REQUEST,
      };
    }
    case GET_CASES_BY_REGION_REQUEST_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        status: Status.GET_CASES_BY_REGION_REQUEST_SUCCESS,
        cases: action.payload,
      };
    }
    case GET_CASES_BY_REGION_REQUEST_FAILURE: {
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
        status: Status.GET_CASES_BY_REGION_REQUEST_FAILURE,
      };
    }
    default:
      return state;
  }
};

export const getIsFetchingState = (state) => state.casesByRegion.isFetching;
export const getErrorMessageState = (state) => state.casesByRegion.errorMessage;
export const getStatusState = (state) => state.casesByRegion.status;
export const getCasesState = (state) => state.casesByRegion.cases;

export default casesByRegionReducer;

import {
    GET_CASES_BY_RISK_FACTOR_REQUEST,
    GET_CASES_BY_RISK_FACTOR_REQUEST_SUCCESS,
    GET_CASES_BY_RISK_FACTOR_REQUEST_FAILURE
  } from "./actionTypes";
  
  const initialState = {
    isFetching: false,
    errorMessage: "",
    status: null,
    cases: [],
  };
  
  export const Status = {
    GET_CASES_BY_RISK_FACTOR_REQUEST: `get_cases_by_risk_factor_request`,
    GET_CASES_BY_RISK_FACTOR_REQUEST_SUCCESS: `get_cases_by_risk_factor_request_failure`,
    GET_CASES_BY_RISK_FACTOR_REQUEST_FAILURE: `get_cases_by_risk_factor_request_success`,
  };
  const casesByRiskFactorReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_CASES_BY_RISK_FACTOR_REQUEST: {
        return {
          ...state,
          isFetching: true,
          status: Status.GET_CASES_BY_REGION_REQUEST,
        };
      }
      case GET_CASES_BY_RISK_FACTOR_REQUEST_SUCCESS: {
        return {
          ...state,
          isFetching: false,
          status: Status.GET_CASES_BY_REGION_REQUEST_SUCCESS,
          cases: action.payload,
        };
      }
      case GET_CASES_BY_RISK_FACTOR_REQUEST_FAILURE: {
        return {
          ...state,
          isFetching: false,
          errorMessage: action.payload,
          status: Status.GET_CASES_BY_REGION_REQUEST_FAILURE,
        };
      }
      default:
        return state;
    }
  };
  
  export const getIsFetchingState = (state) => state.casesByRiskFactor.isFetching;
  export const getErrorMessageState = (state) => state.casesByRiskFactor.errorMessage;
  export const getStatusState = (state) => state.casesByRiskFactor.status;
  export const getCasesByRiskFactorState = (state) => state.casesByRiskFactor.cases;
  
  export default casesByRiskFactorReducer;
  
import React, { useState } from "react";
import { Row, Dropdown, Button, Menu } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { colors } from "../../Css";

/**
 * This is the sorter component for the Table.
 * It is an uncontrolled element
 * Its Apis and functionality is quite similar to the shopify Table filter
 * @see https://polaris.shopify.com/components/lists-and-tables/filters#navigation
 */
const Sorter = (props) => {
  const [sortByValue, setSortByValue] = useState(props.currentSort || "");
  const { numberOfSortedData, sorters, onChange, resourceName } = props;
  const classes = useStyles();

  const handleMenuClick = (e) => {
    const { name, value } = e.item.props;
    setSortByValue(name);
    onChange && onChange(value);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      {sorters.map((sorter) => (
        <Menu.Item key={sorter.key} name={sorter.name} value={sorter.value}>
          {sorter.name}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Row displsy="flex" justify="space-between" align="middle">
      <span className={classes.text}>
        Showing {numberOfSortedData} {resourceName.plural}
      </span>
      <Dropdown placement="bottomRight" overlay={menu} trigger={["click"]}>
        <Button className={classes.sortButton}>
          Sort by{" "}
          <span className={classes.text} style={{ marginLeft: 3 }}>
            {sortByValue}
          </span>{" "}
          <CaretDownOutlined />
        </Button>
      </Dropdown>
    </Row>
  );
};

const useStyles = makeStyles({
  text: {
    color: colors.base,
  },
  sortButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "inherit",
  },
});

Sorter.propTypes = {
  numberOfSortedData: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  sorters: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  resourceName: PropTypes.shape({
    singular: PropTypes.string.isRequired,
    plural: PropTypes.string.isRequired,
  }).isRequired,
};

export default React.memo(Sorter);

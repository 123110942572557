import {
  GET_ASSESSMENTS_BY_PERIOD_REQUEST,
  GET_ASSESSMENTS_BY_PERIOD_REQUEST_SUCCESS,
  GET_ASSESSMENTS_BY_PERIOD_REQUEST_FAILURE,
} from "./actionTypes";

const initialState = {
  isFetching: false,
  errorMessage: "",
  status: null,
  periodicAssessments: [],
  stateId: "",
};

export const Status = {
  GET_ASSESSMENTS_BY_PERIOD_REQUEST: `get_assessments_by_period_request`,
  GET_ASSESSMENTS_BY_PERIOD_REQUEST_SUCCESS: `get_assessments_by_period_request_success`,
  GET_ASSESSMENTS_BY_PERIOD_REQUEST_FAILURE: `get_assessments_by_period_request_failure`,
};

const assessmentsByPeriodReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ASSESSMENTS_BY_PERIOD_REQUEST:
      return {
        ...state,
        isFetching: true,
        status: Status.GET_ASSESSMENTS_BY_PERIOD_REQUEST,
      };
    case GET_ASSESSMENTS_BY_PERIOD_REQUEST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        status: Status.GET_ASSESSMENTS_BY_PERIOD_REQUEST_SUCCESS,
        periodicAssessments: action.payload.data,
        stateId: action.payload.stateId,
      };
    case GET_ASSESSMENTS_BY_PERIOD_REQUEST_FAILURE:
      return {
        ...state,
        isFetching: false,
        status: Status.GET_ASSESSMENTS_BY_PERIOD_REQUEST_FAILURE,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export const getIsFetchingState = (state) =>
  state.assessmentsByPeriod.isFetching;
export const getErrorMessageState = (state) =>
  state.assessmentsByPeriod.errorMessage;
export const getStatusState = (state) => state.assessmentsByPeriod.status;
export const getStateIdState = (state) => state.assessmentsByPeriod.stateId;
export const getPeriodicAssessmentsState = (state) =>
  state.assessmentsByPeriod.periodicAssessments;

export default assessmentsByPeriodReducer;

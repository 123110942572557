import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Row, notification, Menu, Checkbox, Button } from "antd";
import { useSelector } from "react-redux";
import { UploadOutlined, UserOutlined } from "@ant-design/icons";
import moment from "moment";

import { colors, fontWeight, fontSize, boxShadow } from "../../Css";
import ResourceTable, { Filter, Sorter } from "../../atoms/ResourceTable";
import Tags from "../../atoms/Tags";
import { Api } from "../../utils/Api";
import * as Constants from "../../utils/Constants";
import {
  Status,
  getIsFetchingState,
  getStatusState,
  getErrorMessageState,
  getCurrentIndexCasesState,
  getTotalItemsCountState
} from "../../redux/ContactTrace/indexCasesReducer";
import { getStates } from "../../utils/States";
import { useCallbackState } from "../../hooks/useCallbackState";
import { PrivatePaths } from "../../routes";
// import ToggleIcon from "../../atoms/ToggleIcon";
import HeadingWithBreadcrumb from "../../atoms/HeadingWithBreadcrumb";
import ExportContactList from "./ExportContactList";
import { useUpdateEffect } from "../../hooks/useUpdateEffect";

const TAB_VIEWS = {
  cluster: "Cluster Map",
  list: "List view"
};

const SORT_ORDER = {
  latest: { name: "Latest data first", value: "-createdAt" },
  oldest: { name: "Oldest data first", value: "+createdAt" }
};

const ContactList = props => {
  const [currentView] = useState(TAB_VIEWS.list);
  const [currentPage, setCurrentPage] = useState(1);
  const [contactListDrawerVisible, setContactListDrawerVisible] = useState(
    false
  );
  const [pageSize, setPageSize] = useState(Constants.DATA_LIMIT);
  const [indexState, setIndexState] = useCallbackState(null);
  const [queryValue, setQueryValue] = useCallbackState("");
  const [sortOrder, setSortOrder] = useCallbackState(SORT_ORDER.latest.value);
  const isFetching = useSelector(getIsFetchingState);
  const status = useSelector(getStatusState);
  const errorMessage = useSelector(getErrorMessageState);
  const currentIndexCases = useSelector(getCurrentIndexCasesState);
  const totalItems = useSelector(getTotalItemsCountState);
  const classes = useStyles();
  const { history } = props;

  useUpdateEffect(() => {
    if (status === Status.GET_INDEX_CASES_REQUEST_FAILURE) {
      notification["error"]({
        message: "Failed to load cases",
        description: errorMessage
      });
    }
  }, [status, errorMessage]);

  useEffect(() => {
    Api.ContactTraceRepository.getIndexCases({
      pageNo: currentPage,
      pageSize,
      keyword: queryValue,
      stateId: indexState,
      sortBy: sortOrder,
      currentData: currentIndexCases
    });
    // eslint-disable-next-line
  }, [indexState, sortOrder, pageSize, currentPage, queryValue]);

  const appliedFilters = [];

  const handleIndexStateRemove = () => {
    setIndexState(null);
  };

  const disambiguateLabel = (key, value) => {
    const state = getStates().find(el => el.id === value);
    if (key === "state" && state.stateName) {
      return `State: ${state.stateName}`;
    }
  };

  if (!!indexState === true) {
    const key = "state";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, indexState),
      onRemove: handleIndexStateRemove
    });
  }

  const columns = () => [
    {
      title: "Case ID",
      dataIndex: "fullName",
      render: renderTableName
    },
    {
      title: "Location",
      dataIndex: "location"
    },
    {
      title: "Status",
      dataIndex: "status",
      render: status => <Tags label="High risk" type="error" />
    },
    {
      title: "Confirm Date",
      dataIndex: "statusAt",
      render: date => {
        return moment(date).format("DD - MMM - YYYY / HH:mm");
      }
    }
  ];

  // const handleChangeTab = value => {
  //   setCurrentView(value);
  //   if (value === TAB_VIEWS.list) {
  //     // handleSearch({});
  //   }
  // };

  // const queryParams = {
  //   pageNo: 1,
  //   pageSize,
  //   keyword: queryValue,
  //   stateId: indexState,
  //   sortBy: sortOrder
  // };

  const onChangeSearchQuery = e => {
    setQueryValue(e.target.value);
    setCurrentPage(1);
    // Api.ContactTraceRepository.getIndexCases({
    //   ...queryParams,
    //   currentData: currentIndexCases,
    //   keyword: e.target.value
    // });
  };

  const handleClearSearchQuery = e => {
    setQueryValue("");
    setCurrentPage(1);
    // Api.ContactTraceRepository.getIndexCases({
    //   ...queryParams,
    //   currentData: currentIndexCases,
    //   keyword: e.target.value
    // });
  };

  const handleLoadMore = page => {
    setCurrentPage(page);
    // Api.ContactTraceRepository.getIndexCases({
    //   ...queryParams,
    //   pageNo: page,
    //   currentData: currentIndexCases,
    // });
  };

  // const handleSearch = ({ ...params }) => {
  //   setCurrentPage(1);
  //   // Api.ContactTraceRepository.getIndexCases({
  //   //   ...queryParams,
  //   //   ...params,
  //   //   currentData: currentIndexCases,
  //   // });
  // };

  // const handleClearQuery = () => {
  //   setQueryValue("");
  //   // handleSearch({ keyword: "" });
  // };

  const handleIndexStateChange = value => e => {
    setIndexState(value);
  };

  const handleChangeSortOrder = value => {
    setSortOrder(value);
  };

  const renderAvatar = record => {
    return (
      <div className={classes.avatarContainer}>
        {!!record.avatar === true ? (
          <img src={record.avatar} alt={record.fullName} />
        ) : (
          <span>
            <UserOutlined />
          </span>
        )}
      </div>
    );
  };

  const renderTableName = (text, record) => {
    return (
      <Row className={classes.userNameContainer}>
        {renderAvatar(record)}
        <div>
          <p>{record.fullName}</p>
          <p>{record.phoneNumber}</p>
        </div>
      </Row>
    );
  };

  const renderCluster = () => {
    return (
      <div className={classes.clutterView}>
        <iframe
          title="virtualization"
          className={classes.visualizationContainer}
          src="https://vizzz.s3.amazonaws.com/index.html"
        />
      </div>
    );
  };

  const renderPageMetaData = () => {
    return (
      <HeadingWithBreadcrumb
        showBreadCrumb={false}
        title="Contact Trace"
        renderHeaderRight={() => (
          <Button
            className={classes.exportContactList}
            icon={<UploadOutlined />}
            size="small"
            onClick={() => setContactListDrawerVisible(true)}
          >
            Export
          </Button>
        )}
      />
    );
  };

  const renderFilterStateControl = () => {
    return (
      <Menu className={classes.stateList}>
        {getStates().map(state => (
          <Menu.Item key={state.id}>
            <Checkbox
              checked={state.id === indexState}
              onChange={handleIndexStateChange(state.id)}
            >
              {state.stateName}
            </Checkbox>
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  const renderCustomTableHeading = () => {
    return (
      <Row className={classes.customTableHeading}>
        <h4>Contact List</h4>
      </Row>
    );
  };

  const renderCasesInformation = () => {
    return (
      <ResourceTable
        renderCustomTableHeading={renderCustomTableHeading}
        resourceName={{ singular: "case", plural: "cases" }}
        filterControl={
          <Filter
            filters={[
              {
                key: "state",
                label: "State",
                filter: renderFilterStateControl(),
                resetFilter: () => setIndexState(null)
              }
            ]}
            appliedFilters={appliedFilters}
            queryValue={queryValue}
            onQueryChange={onChangeSearchQuery}
            onQueryClear={handleClearSearchQuery}
            onPressEnter={onChangeSearchQuery}
          />
        }
        sortControl={
          currentView === TAB_VIEWS.cluster ? null : (
            <Sorter
              numberOfSortedData={totalItems}
              currentSort={SORT_ORDER.latest.name}
              sorters={[
                {
                  name: SORT_ORDER.latest.name,
                  value: SORT_ORDER.latest.value
                },
                {
                  name: SORT_ORDER.oldest.name,
                  value: SORT_ORDER.oldest.value
                }
              ]}
              onChange={handleChangeSortOrder}
            />
          )
        }
        tableProps={{
          columns: columns(),
          dataSource: currentIndexCases,
          loading: isFetching,
          onRow: record => ({
            onClick: () =>
              history.push(`${PrivatePaths.CONTACT_TRACE}/${record.id}`)
          }),
          pagination: {
            total: totalItems,
            current: currentPage,
            onChange: handleLoadMore,
            onShowSizeChange: (current, pageSize) => setPageSize(pageSize)
          }
        }}
        renderCustomElement={
          currentView === TAB_VIEWS.cluster ? renderCluster() : null
        }
      />
    );
  };

  return (
    <div style={{ padding: 32 }}>
      {renderPageMetaData()}
      {renderCasesInformation()}
      <ExportContactList
        isVisible={contactListDrawerVisible}
        onClose={() => setContactListDrawerVisible(false)}
      />
    </div>
  );
};

const useStyles = makeStyles({
  customTableHeading: {
    margin: "20px 20px 0 20px",
    alignItems: "center",
    justifyContent: "space-between",
    "& h4": {
      fontSize: fontSize.large,
      color: colors.base,
      margin: 0,
      padding: 0,
      fontWeight: fontWeight.medium
    }
  },
  actionHeader: {
    marginBottom: 20,
    "& span:nth-child(2)": {
      marginLeft: 18
    },
    "& span.gray-text": {
      color: colors.secondaryBase
    }
  },
  avatarContainer: {
    height: 40,
    width: 40,
    background: "#006FBB",
    marginRight: 9,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: colors.white,
    fontWeight: fontWeight.medium,
    fontSize: fontSize.medium,
    "& img": {
      width: "inherit",
      height: "inherit",
      borderRadius: "50%"
    }
  },
  userNameContainer: {
    "& p": {
      fontSize: fontSize.medium,
      color: colors.base,
      margin: 0
    },
    "& p:first-child": {
      fontWeight: fontWeight.medium
    }
  },
  clutterView: {
    width: "100%",
    height: "700px",
    padding: "0px 20px",
    paddingBottom: 20,
    "&>div": {
      boxShadow: boxShadow.card,
      background: colors.white,
      minHeight: 654
    }
  },
  stateList: {
    "&.ant-menu": {
      border: 0
    },
    "& .ant-menu-item": {
      padding: 0,
      border: 0,
      lineHeight: 2.0,
      height: "fit-content",
      margin: "0 !important"
    },
    "& .ant-menu-item span:last-child": {
      paddingRight: 0
    }
  },
  visualizationContainer: {
    width: "100%",
    height: "100%"
  },
  exportContactList: {
    backgroundColor: "transparent",
    border: "none",
    boxShadow: "none"
  }
});

export default React.memo(ContactList);

export const States = [
  { id: 1, name: "Abia", value: "abia" },
  { id: 2, name: "Adamawa", value: "adamawa" },
  { id: 3, name: "Awka", value: "awka" },
  { id: 4, name: "Anambra", value: "anambra" },
  { id: 5, name: "Bauchi", value: "bauchi" },
  { id: 6, name: "Benue", value: "benue" },
  { id: 7, name: "Borno", value: "borno" },
  { id: 8, name: "Bayelsa", value: "bayelsa" },
  { id: 9, name: "Cross-river", value: "cross-river" },
  { id: 10, name: "Delta", value: "delta" },
  { id: 11, name: "Lagos", value: "lagos" },
  { id: 12, name: "Edo", value: "edo" },
  { id: 13, name: "Ekiti", value: "ekiti" },
  { id: 14, name: "Enugu", value: "enugu" },
  { id: 15, name: "Gombe", value: "gombe" },
  { id: 16, name: "Imo", value: "imo" },
  { id: 17, name: "Jigawa", value: "jigawa" },
  { id: 18, name: "Kaduna", value: "kaduna" },
  { id: 19, name: "Kebbi", value: "kebbi" },
  { id: 1, name: "Ondo", value: "ondo" },
  { id: 1, name: "Osun", value: "osun" },
  { id: 1, name: "Oyo", value: "oyo" },
  { id: 1, name: "Plateau", value: "plateau" },
  { id: 1, name: "Zamfara", value: "zamfara" },
  { id: 1, name: "Kano", value: "kano" },
  { id: 1, name: "Kogi", value: "kogi" },
  { id: 1, name: "Katsina", value: "katsina" },
  { id: 1, name: "Kwara", value: "kwara" },
  { id: 1, name: "Niger", value: "niger" },
  { id: 1, name: "Ogun", value: "ogun" },
  { id: 1, name: "Rivers", value: "rivers" },
  { id: 1, name: "Sokoto", value: "sokoto" },
  { id: 1, name: "Taraba", value: "taraba" },
  { id: 1, name: "Yola", value: "yola" },
  { id: 1, name: "Abuja", value: "abuja" },
];

export const getStates = () => {
  return [
    {
      id: 1,
      stateCode: "NG-FC",
      stateName: "Abuja(FCT)",
    },
    {
      id: 2,
      stateCode: "NG-AB",
      stateName: "Abia",
    },
    {
      id: 3,
      stateCode: "NG-AD",
      stateName: "Adamawa",
    },
    {
      id: 4,
      stateCode: "NG-AK",
      stateName: "Akwa Ibom",
    },
    {
      id: 5,
      stateCode: "NG-AN",
      stateName: "Anambra",
    },
    {
      id: 6,
      stateCode: "NG-BA",
      stateName: "Bauchi",
    },
    {
      id: 7,
      stateCode: "NG-BY",
      stateName: "Bayelsa",
    },
    {
      id: 8,
      stateCode: "NG-BE",
      stateName: "Benue",
    },
    {
      id: 9,
      stateCode: "NG-BO",
      stateName: "Borno",
    },
    {
      id: 10,
      stateCode: "NG-CR",
      stateName: "Cross River",
    },
    {
      id: 11,
      stateCode: "NG-DE",
      stateName: "Delta",
    },
    {
      id: 12,
      stateCode: "NG-EB",
      stateName: "Ebonyi",
    },
    {
      id: 13,
      stateCode: "NG-ED",
      stateName: "Edo",
    },
    {
      id: 14,
      stateCode: "NG-EK",
      stateName: "Ekiti",
    },
    {
      id: 15,
      stateCode: "NG-EN",
      stateName: "Enugu",
    },
    {
      id: 16,
      stateCode: "NG-GO",
      stateName: "Gombe",
    },
    {
      id: 17,
      stateCode: "NG-IM",
      stateName: "Imo",
    },
    {
      id: 18,
      stateCode: "NG-JI",
      stateName: "Jigawa",
    },
    {
      id: 19,
      stateCode: "NG-KD",
      stateName: "Kaduna",
    },
    {
      id: 20,
      stateCode: "NG-KN",
      stateName: "Kano",
    },
    {
      id: 21,
      stateCode: "NG-KT",
      stateName: "Katsina",
    },
    {
      id: 22,
      stateCode: "NG-KE",
      stateName: "Kebbi",
    },
    {
      id: 23,
      stateCode: "NG-KO",
      stateName: "Kogi",
    },
    {
      id: 24,
      stateCode: "NG-KW",
      stateName: "Kwara",
    },
    {
      id: 25,
      stateCode: "NG-LA",
      stateName: "Lagos",
    },
    {
      id: 26,
      stateCode: "NG-NA",
      stateName: "Nasarawa",
    },
    {
      id: 27,
      stateCode: "NG-NI",
      stateName: "Niger",
    },
    {
      id: 28,
      stateCode: "NG-OG",
      stateName: "Ogun",
    },
    {
      id: 29,
      stateCode: "NG-ON",
      stateName: "Ondo",
    },
    {
      id: 30,
      stateCode: "NG-OS",
      stateName: "Osun",
    },
    {
      id: 31,
      stateCode: "NG-OY",
      stateName: "Oyo",
    },
    {
      id: 32,
      stateCode: "NG-PL",
      stateName: "Plateau",
    },
    {
      id: 33,
      stateCode: "NG-RI",
      stateName: "Rivers",
    },
    {
      id: 34,
      stateCode: "NG-SO",
      stateName: "Sokoto",
    },
    {
      id: 35,
      stateCode: "NG-TA",
      stateName: "Taraba",
    },
    {
      id: 36,
      stateCode: "NG-YO",
      stateName: "Yobe",
    },
    {
      id: 37,
      stateCode: "NG-ZA",
      stateName: "Zamfara",
    },
  ];
};

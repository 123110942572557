import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Menu, Dropdown } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import debounce from "lodash.debounce";
import { makeStyles } from "@material-ui/styles";

import { colors, fontSize, fontWeight } from "../../Css";
import ResourceTable, { Filter } from "../../atoms/ResourceTable";
import Tags from "../../atoms/Tags";
import { Api } from "../../utils/Api";
import AddStaffDrawer from "../../components/Staffs/AddStaffDrawer";
import ResetStaffPasswordDrawer from "../../components/Staffs/ResetStaffPasswordDrawer";
import { fromEntries } from "../../utils";
import * as Constants from "../../utils/Constants";
import {
  // getStaffRolesIsFetchingState,
  // getStaffRolesErrorMessageState,
  // getStaffRolesStatusState,
  getStaffRolesState
} from "../../redux/Staffs/rolesReducer";
import {
  // Status,
  getStaffDataState,
  getStaffDataIsFetchingState,
  // getStaffDataStatusState,
  // getStaffDataErrorMessageState,
  getStaffDataToggleStatusLoadingState,
  getStaffDataAddStaffLoadingState,
  getTotalItemsCountState
} from "../../redux/Staffs/staffDataReducer";

const Staff = props => {
  const classes = useStyles();
  const [isVisible, setIsVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentTab, setCurrentTab] = useState("all");
  const [pageSize, setPageSize] = useState(Constants.DATA_LIMIT);
  const staffData = useSelector(getStaffDataState);
  const staffRoles = useSelector(getStaffRolesState);
  const staffDataIsFetching = useSelector(getStaffDataIsFetchingState);
  // const staffDataErrorMessage = useSelector(getStaffDataErrorMessageState);
  // const staffDataStatus = useSelector(getStaffDataStatusState);
  const staffToggleStatusLoading = useSelector(
    getStaffDataToggleStatusLoadingState
  );
  const addStaffLoading = useSelector(getStaffDataAddStaffLoadingState);
  const totalStaff = useSelector(getTotalItemsCountState);
  const [queryValue, setQueryValue] = React.useState("");
  // const [selectionType, setSelectionType] = useState("checkbox");
  const [resourceTableData, setResourceTableData] = React.useState(staffData);
  const [drawerType, setDrawerType] = useState("");
  const [staffId, setStaffId] = useState("");
  const [staffRole, setStaffRole] = useState("");

  useEffect(() => {
    fetchStaffData({});
    Api.StaffRepository.getStaffRoles();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchStaffData({});
    // eslint-disable-next-line
  }, [pageSize, currentTab]);

  useEffect(() => {
    setResourceTableData(staffData);
  }, [staffData]);

  const fetchStaffData = ({ newPageNo, ...params }) => {
    const finalParams = {
      pageNo: newPageNo || 1,
      pageSize: pageSize,
      keyword: queryValue,
      currentData: staffData,
      ...params
    };
    const paramsWithoutNullOrUndefined = fromEntries(
      Object.entries(finalParams).filter(
        ([_, value]) =>
          value !== null &&
          value !== undefined &&
          (Boolean(value) || value === 0)
      )
    );
    Api.StaffRepository.getStaffData(paramsWithoutNullOrUndefined);
  };

  useEffect(() => {
    let selectedData;
    if (!!currentTab) {
      let _currentTab = currentTab === "active" ? true : false;
      selectedData = staffData.filter(value => value.isActive === _currentTab);
    }
    if (currentTab.toLocaleLowerCase() === "all") selectedData = staffData;
    setResourceTableData(selectedData);
  }, [currentTab, staffData]);

  // table
  const TABLE_HEAD = [
    { tab: "All", key: "all" },
    { tab: "Active", key: "active" },
    { tab: "Deactivated", key: "disable" }
  ];

  const handleActionMenuItemClick = staffId => ({ key }) => {
    Api.StaffRepository.toggleStaffStatus(staffId);
  };

  const renderActionMenu = (staffId, isActive = true) => (
    <Menu onClick={handleActionMenuItemClick(staffId)}>
      {!isActive && (
        <Menu.Item key="enable">
          <span>Enable User</span>
        </Menu.Item>
      )}
      {isActive && (
        <Menu.Item key="disable">
          <span style={{ color: colors.error }}>Disable User</span>
        </Menu.Item>
      )}
    </Menu>
  );

  const columns = () => [
    {
      title: "Name",
      dataIndex: "fullName",
      key: "fullName",
      render: (fullName, record) => {
        return (
          <div className={classes.partnerTableNameColumn}>
            <p>{fullName}</p>
            <p>{record.emailAddress}</p>
          </div>
        );
      }
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role"
    },
    {
      title: "Preffered language",
      dataIndex: "preferredLanguage",
      key: "preferredLanguage",
      render: preferredLanguage => {
        return <>{preferredLanguage || "English"}</>;
      }
    },
    {
      title: "User Status",
      dataIndex: "isActive",
      key: "isActive",
      render: isActive => {
        const label = isActive ? "Active" : "Deactivated";
        const type = isActive ? "success" : "error";
        return <Tags label={label} type={type} />;
      }
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "action",
      render: (staffId, record) => (
        <Dropdown
          overlay={renderActionMenu(staffId, record.isActive)}
          trigger={["click"]}
        >
          <Button type="link" color={colors.base} className={classes.menuIcon}>
            <MoreOutlined rotate={90} />
          </Button>
        </Dropdown>
      )
    }
  ];

  const rowSelection = {
    onSelect: (record, selected, selectedRows) => {},
    onSelectAll: (selected, selectedRows, changeRows) => {},
    onChange: (selectedRowKeys, selectedRows) => {}
  };

  // table drawers
  const openDrawer = type => {
    setDrawerType(type);
    setIsVisible(true);
  };

  const closeDrawer = () => {
    setIsVisible(false);
  };

  const onChangeSearchQuery = e => {
    setQueryValue(e.target.value);
    fetchOnChangeSearchQuery(e.target.value);
  };

  const handleClearSearchQuery = () => {
    setQueryValue("");
    fetchOnChangeSearchQuery();
  };

  const fetchOnChangeSearchQuery = React.useRef(
    debounce(searchQuery => {
      fetchStaffData({ keyword: searchQuery });
    }, 500)
  ).current;

  const handleLoadMore = page => {
    setCurrentPage(page);
    fetchStaffData({
      pageNo: page
    });
  };

  return (
    <div className={classes.container}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between"
        }}
      >
        <p className={classes.staff}>User Administration</p>
        <Button onClick={() => openDrawer("add")} className={classes.btn}>
          Add New User
        </Button>
      </div>
      <div className="assessment-table">
        <ResourceTable
          tableProps={{
            columns: columns(),
            loading: staffDataIsFetching || staffToggleStatusLoading,
            dataSource: resourceTableData,
            rowKey: record => record.id,
            rowSelection: { ...rowSelection },
            onRow: record => ({
              onClick: () => {
                setStaffId(record.id);
                setStaffRole(record.role);
                openDrawer("edit");
              }
            }),
            pagination: {
              total: totalStaff,
              current: currentPage,
              pageSize,
              onChange: handleLoadMore,
              onShowSizeChange: (current, pageSize) => setPageSize(pageSize)
            }
          }}
          resourceName={{ singular: "name", plural: "names" }}
          filterControl={
            <Filter
              filters={[]}
              appliedFilters={[]}
              onQueryChange={onChangeSearchQuery}
              onPressEnter={onChangeSearchQuery}
              queryValue={queryValue}
              queryClear={() => {}}
              onQueryClear={handleClearSearchQuery}
              onClearAll={() => {}}
            />
          }
          tabList={TABLE_HEAD}
          onChangeTab={value => setCurrentTab(value)}
          onSelectOption={() => null}
        />
      </div>

      <AddStaffDrawer
        addStaffIsFetching={addStaffLoading}
        roles={staffRoles}
        visible={isVisible && drawerType === "add"}
        onClose={closeDrawer}
      />
      <ResetStaffPasswordDrawer
        staffId={staffId}
        staffRole={staffRole}
        roles={staffRoles}
        visible={isVisible && drawerType === "edit"}
        onClose={closeDrawer}
      />
    </div>
  );
};

const useStyles = makeStyles({
  newInviteContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    alignItems: "center",
    padding: "0 15px",
    borderStyle: "solid",
    borderColor: colors.lightGrey,
    borderWidth: "3px",
    height: "80px",
    borderRadius: "4px",
    marginTop: "40px"
  },

  newInviteFirstItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "310px"
  },

  newInviteRadioItem: {
    color: "red"
  },
  newInviteImageItem: {
    borderRadius: "50%",
    height: "40px",
    width: "40px",
    backgroundColor: "yellow"
  },

  newInviteNameItem: {
    lineHeight: "120%",
    paddingLeft: "15px"
  },

  radioItem: {
    color: "red"
  },
  imageItem: {
    borderRadius: "50%",
    height: "40px",
    width: "40px",
    backgroundColor: "yellow"
  },
  nameItem: {
    lineHeight: "120%",
    paddingLeft: "15px"
  },
  container: {
    padding: 32,
    margin: 20,
    "& .demograph-container": {
      marginTop: 24
    },
    "& .assessment-table": {
      marginTop: 24
    },
    "& .barchart-container": {
      overflowY: "scroll"
    }
  },
  statisticCardContainer: {
    "& .column": {
      cursor: "pointer",
      margin: 0
    }
  },
  partnerTableNameColumn: {
    "& p": {
      margin: 0
    },
    "& p:first-child": {
      fontWeight: fontWeight.medium,
      color: colors.base
    }
  },

  staff: {
    margin: 0,
    fontWeight: fontWeight.medium,
    color: colors.base,
    fontSize: fontSize.title
  },

  btn: {
    backgroundColor: colors.primary,
    color: colors.white
  },
  menuIcon: {
    "& .anticon": {
      fontSize: 24
    }
  }
});

export default React.memo(Staff);

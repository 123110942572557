import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import moment from "moment";

import Drawer from "../../atoms/Drawer";
import { DatePicker, Form, notification } from "antd";
import { fontSize } from "../../Css";

import { Api } from "../../utils/Api";
import {
  Status,
  getStatusState,
  getErrorMessageState,
  getExportContactState,
  getIsFetchingState
} from "../../redux/ContactTrace/exportContactReducer";

const ExportContactList = props => {
  const [dateRange, setDateRange] = useState([
    moment().subtract(1, "day"),
    moment()
  ]);
  const status = useSelector(getStatusState);
  const errorMessage = useSelector(getErrorMessageState);
  const exportContactCsv = useSelector(getExportContactState);
  const isFetching = useSelector(getIsFetchingState);

  const classes = useStyles();
  const { isVisible, onClose, sourceContactIds } = props;
  const { RangePicker } = DatePicker;
  const dateFormat = "YYYY/MM/DD";

  function onOk() {
    Api.ContactTraceRepository.getExportContactData({
      startDate: dateRange[0].toISOString(),
      endDate: dateRange[1].toISOString(),
      sourceContactIds
    });
  }

  useEffect(() => {
    if (status === Status.GET_EXPORT_CONTACT_DATA_REQUEST_FAILURE) {
      notification["error"]({
        message: "Failed to load contact CSV",
        description: errorMessage
      });
    } else if (
      status === Status.GET_EXPORT_CONTACT_DATA_REQUEST_SUCCESS &&
      isVisible
    ) {
      notification["success"]({
        message: "Downloading your file..."
      });
      autoDownloadCsv();
    }
    // eslint-disable-next-line
  }, [status, errorMessage]);

  function disabledDate(current) {
    return current > moment().endOf("day");
  }

  function autoDownloadCsv() {
    var csv = exportContactCsv;
    var downloadLink = document.createElement("a");
    var blob = new Blob(["\ufeff", csv]);
    var url = URL.createObjectURL(blob);
    downloadLink.href = url;
    downloadLink.download = `${
      !!sourceContactIds ? sourceContactIds + "-" : ""
    }Contact_list-${dateRange[0].format(dateFormat)}-${dateRange[1].format(
      dateFormat
    )}-${moment().format("LT")}.csv`;

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  return (
    <Drawer
      title="Contact Download"
      hideFooter={false}
      visible={isVisible}
      onClose={onClose}
      okText="Download CSV"
      onOk={onOk}
      isLoading={isFetching}
    >
      <Form className={classes.exportContactForm}>
        <label>Date Range</label>
        <RangePicker
          defaultValue={[
            moment(new Date(), dateFormat).subtract(1, "day"),
            moment(new Date(), dateFormat)
          ]}
          onChange={dates => {
            setDateRange([dates[0], dates[1]]);
          }}
          format={dateFormat}
          style={{ width: "100%" }}
          autoFocus
          separator="/"
          disabledDate={disabledDate}
          disabled={isFetching}
        />
      </Form>
    </Drawer>
  );
};

const useStyles = makeStyles({
  exportContactForm: {
    "& label": {
      fontSize: fontSize.medium,
      paddingBottom: 3,
      display: "inline-block"
    }
  }
});

export default React.memo(ExportContactList);

import {
  GET_STAFF_ROLE_REQUEST,
  GET_STAFF_ROLE_REQUEST_SUCCESS,
  GET_STAFF_ROLE_REQUEST_FAILURE,
} from "./actionTypes";

const initialState = {
  isFetching: false,
  errorMessage: "",
  status: null,
  roles: [],
};

export const Status = {
  GET_STAFF_ROLE_REQUEST: "get_staff_role_request",
  GET_STAFF_ROLE_REQUEST_SUCCESS: "get_staff_role_request_success",
  GET_STAFF_ROLE_REQUEST_FAILURE: "get_staff_role_request_failure",
};

const staffRolesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STAFF_ROLE_REQUEST:
      return {
        ...state,
        isFetching: true,
        status: Status.GET_STAFF_ROLE_REQUEST,
      };

    case GET_STAFF_ROLE_REQUEST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        roles: action.payload,
        status: Status.GET_STAFF_ROLE_REQUEST_SUCCESS,
      };

    case GET_STAFF_ROLE_REQUEST_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
        status: Status.GET_STAFF_ROLE_REQUEST_FAILURE,
      };

    default:
      return state;
  }
};

export const getStaffRolesIsFetchingState = (state) =>
  state.staffRoles.isFetching;
export const getStaffRolesErrorMessageState = (state) =>
  state.staffRoles.errorMessage;
export const getStaffRolesStatusState = (state) => state.staffRoles.status;
export const getStaffRolesState = (state) => state.staffRoles.roles;

export default staffRolesReducer;

import {
  TOGGLE_TOKEN_STATUS_REQUEST,
  REGENERATE_TOKEN_REQUEST,
  TOGGLE_TOKEN_STATUS_REQUEST_ERROR,
  REGENERATE_TOKEN_REQUEST_SUCCESS,
  REGENERATE_TOKEN_REQUEST_FAILURE,
  TOGGLE_TOKEN_STATUS_REQUEST_SUCCESS, 
} from "./actionTypes";

const initialState = {
  isFetching: false,
  status: null,
  errorMessage: "",
  partnerToken: "",
};

export const FormActionStatus = {
  TOGGLE_TOKEN_STATUS_REQUEST_SUCCESS: `add_partner_request_success`,
  TOGGLE_TOKEN_STATUS_REQUEST_ERROR: `add_partner_request_failure`,
  REGENERATE_TOKEN_REQUEST_SUCCESS: `edit_partner_request_success`,
  REGENERATE_TOKEN_REQUEST_FAILURE: `edit_partner_request_failure`,
};

const partnersFormActionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_TOKEN_STATUS_REQUEST:
    case REGENERATE_TOKEN_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case TOGGLE_TOKEN_STATUS_REQUEST_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        status: FormActionStatus.TOGGLE_TOKEN_STATUS_REQUEST_SUCCESS,
        partnerIsActive: action.payload,
      };
    }
    case TOGGLE_TOKEN_STATUS_REQUEST_ERROR: {
      return {
        ...state,
        isFetching: false,
        status: FormActionStatus.TOGGLE_TOKEN_STATUS_REQUEST_ERROR,
        errorMessage: action.payload,
      };
    }
    case REGENERATE_TOKEN_REQUEST_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        status: FormActionStatus.REGENERATE_TOKEN_REQUEST_SUCCESS,
        partnerToken: action.payload,
      };
    }
    case REGENERATE_TOKEN_REQUEST_FAILURE: {
      return {
        ...state,
        isFetching: false,
        status: FormActionStatus.REGENERATE_TOKEN_REQUEST_FAILURE,
        errorMessage: action.payload,
      };
    }

   
    default:
      return state;
  }
};

export const getPartnersFormActionIsFetchingState = (state) =>
  state.partnersFormActive.isFetching;
export const getPartnersFormActionErrorMessageState = (state) =>
  state.partnersFormActive.errorMessage;
export const getPartnersFormActionStatusState = (state) =>
  state.partnersFormActive.status;
export const getPartnerTokenState = (state) =>
  state.partnersFormActive.partnerToken;

export default partnersFormActionsReducer;

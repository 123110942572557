import {
  GET_HEATMAP_DATA_REQUEST,
  GET_HEATMAP_DATA_REQUEST_SUCCESS,
  GET_HEATMAP_DATA_REQUEST_FAILURE,
} from "./actionTypes";

const initialState = {
  status: null,
  errorMessage: "",
  heatmapData: [],
};

export const Status = {
  GET_HEATMAP_DATA_REQUEST_SUCCESS: "get_heatmap_data_request_success",
  GET_HEATMAP_DATA_REQUEST_FAILURE: "get_heatmap_data_request_failure",
};

const heatmapReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HEATMAP_DATA_REQUEST:
      return {
        ...state,
        errorMessage: "",
        status: Status.GET_HEATMAP_DATA_REQUEST,
      };
    case GET_HEATMAP_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        errorMessage: "",
        status: Status.GET_HEATMAP_DATA_REQUEST_SUCCESS,
        heatmapData: action.payload,
      };
    case GET_HEATMAP_DATA_REQUEST_FAILURE:
      return {
        ...state,
        status: Status.GET_HEATMAP_DATA_REQUEST_FAILURE,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export const getIsFetchingState = (state) =>
  state.heatmapData.status === Status.GET_HEATMAP_DATA_REQUEST;
export const getErrorMessageState = (state) => state.heatmapData.errorMessage;
export const getStatusState = (state) => state.heatmapData.status;
export const getHeatmapDataState = (state) => state.heatmapData.heatmapData;

export default heatmapReducer;

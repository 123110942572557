import React, { useState } from "react";
import AuthenticationLayout from "../components/AuthenticationLayout";
import { Form, Button, Input, Checkbox, Row } from "antd";

import { PublicPaths, PrivatePaths } from "../routes";
import Banner from "../atoms/Banner";

const Register = (props) => {
  const [form] = Form.useForm();
  const [hasTermsBeenAccepted, setHasTermsBeenAccepted] = useState(false);
  const { history } = props;

  const handleNavigation = () => {
    history.push(PublicPaths.FORGOT_PASSWORD);
  };

  const handleCheckboxChange = (e) => {
    setHasTermsBeenAccepted(e.target.checked);
  };

  const handleFinish = (values) => {
    //@todo: this is inappropriate. Will be removed after Api integration
    console.log(values);
    localStorage.setItem("token", "@token");
    history.push(PrivatePaths.OVERVIEW);
  };

  return (
    <AuthenticationLayout
      title="New registration"
      subTitle="Enter your details"
      formTitle="Enter your details"
    >
      <div style={{ marginBottom: 20 }}>
        <Banner>
          Verification link is valid for the next 24 hours. After this, a new
          invite is requested.
        </Banner>
      </div>
      <Form
        form={form}
        onFinish={handleFinish}
        layout="vertical"
        hideRequiredMark={true}
      >
        <div style={{ marginTop: 20 }}>
          <Form.Item
            label="Full Name"
            name="fullName"
            rules={[
              { required: true, message: "Please enter your full name!" },
            ]}
          >
            <Input type="text" placeholder="enter your full name" autoFocus />
          </Form.Item>
        </div>
        <Form.Item
          label="Email Address"
          name="email"
          rules={[{ required: true, message: "Please input your email!" }]}
        >
          <Input type="email" placeholder="enter your email address" />
        </Form.Item>
        <Form.Item
          label="Phone number"
          name="phoneNumber"
          rules={[
            { required: true, message: "Please input your phone number!" },
          ]}
        >
          <Input type="tel" placeholder="enter your phone number" />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password placeholder="enter your password" />
        </Form.Item>
        <Form.Item>
          <Checkbox
            checked={hasTermsBeenAccepted}
            onChange={handleCheckboxChange}
          >
            I accept the terms and conditions
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: "100%", marginBottom: 20 }}
          >
            Sign in
          </Button>
        </Form.Item>
        <Row justify="center">
          <Button type="link" onClick={handleNavigation}>
            Forgot your password?
          </Button>
        </Row>
      </Form>
    </AuthenticationLayout>
  );
};

export default React.memo(Register);

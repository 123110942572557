import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { notification } from "antd";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_material from "@amcharts/amcharts4/themes/material";

import { Api } from "../../../utils/Api";
import {
  // getIsFetchingState,
  getErrorMessageState,
  getHeatmapDataState,
  getStatusState,
  Status
} from "../../../redux/Assessment/heatmapReducer";

am4core.useTheme(am4themes_material);
am4core.useTheme(am4themes_animated);

const HeatMap = ({ selectedStateCode }) => {
  const chartRef = useRef(null);
  const polygonSeriesRef = useRef(null);
  const activeMapObject = useRef(null);

  // const isFetching = useSelector(getIsFetchingState);
  const errorMessage = useSelector(getErrorMessageState);
  const heatmapData = useSelector(getHeatmapDataState);
  const status = useSelector(getStatusState);

  useEffect(() => {
    Api.AssessmentsRepository.getHeatmapData();
  }, []);

  useEffect(() => {
    if (status === Status.GET_HEATMAP_DATA_REQUEST_FAILURE) {
      notification["error"]({
        message: "Failed to load heatmap data",
        description: errorMessage
      });
    }
  }, [status, errorMessage]);

  useEffect(() => {
    // Create map instance
    chartRef.current = am4core.create("heatMapdiv", am4maps.MapChart);
    const chart = chartRef.current;

    // Set map definition
    chart.geodataSource.url =
      "https://www.amcharts.com/lib/4/geodata/json/nigeriaLow.json";
    chart.homeZoomLevel = 1.0;
    chart.homeGeoPoint = {
      latitude: 9.072264,
      longitude: 7.491302
    };
    chart.dragMap = false;
    chart.seriesContainer.draggable = false;
    chart.seriesContainer.resizable = false;

    //chart.lati
    chart.geodataSource.events.on("parseended", ev => {
      let data = [];

      for (let i = 0; i < ev.target.data.features.length; i++) {
        data.push({
          id: ev.target.data.features[i].id, // stateId
          value: heatmapData[i].confirmedCount
        });
      }
      polygonSeries.data = data;
    });

    // Set projection
    chart.projection = new am4maps.projections.Mercator();

    // Create map polygon series
    polygonSeriesRef.current = chart.series.push(
      new am4maps.MapPolygonSeries()
    );
    const polygonSeries = polygonSeriesRef.current;

    //Set min/max fill color for each area
    polygonSeries.heatRules.push({
      property: "fill",
      target: polygonSeries.mapPolygons.template,
      min: am4core.color("#f39889"),
      max: am4core.color("#DE3618")
    });

    // Make map load polygon data (state shapes and names) from GeoJSON
    polygonSeries.useGeodata = true;

    // Calculate and use visual center for fixed tooltips
    polygonSeries.calculateVisualCenter = true;

    // Configure series tooltip
    let polygonTemplate = polygonSeries.mapPolygons.template;
    polygonTemplate.tooltipText = "{name}: {value}";
    polygonTemplate.nonScalingStroke = true;
    polygonTemplate.strokeWidth = 0.5;
    polygonTemplate.tooltipPosition = "fixed";

    // Create hover state and set alternative fill color
    let hs = polygonTemplate.states.create("hover");
    hs.properties.fill = chart.colors.getIndex(0).brighten(-0.4);

    // Create active state and set alternative fill color
    let as = polygonTemplate.states.create("active");
    as.properties.stroke = chart.colors.getIndex(0).brighten(-0.6);
    as.properties.strokeWidth = 3;
    as.properties.tooltipPosition = "fixed";

    chart.events.on("ready", () => {
      chart.maxZoomLevel = 1;
      chart.goHome(300);
    });

    return () => {
      chart.dispose();
      polygonSeriesRef.current = null;
      chartRef.current = null;
    };
  }, [heatmapData]);

  useEffect(() => {
    const chart = chartRef.current;
    const polygonSeries = polygonSeriesRef.current;
    if (chart) {
      if (activeMapObject.current) {
        activeMapObject.current.isActive = false;
        activeMapObject.current.isHover = false;
        activeMapObject.current = null;
      }
      if (selectedStateCode) {
        const mapObject = polygonSeries.getPolygonById(selectedStateCode);

        chart.maxZoomLevel = 2;
        const animation = chart.zoomToMapObject(mapObject, 2, true, 500);
        animation.events.on("animationended", function() {
          mapObject.isActive = true;
          mapObject.isHover = true;
          chart.minZoomLevel = 2;
        });

        activeMapObject.current = mapObject;
      } else {
        chart.maxZoomLevel = 1;
        chart.minZoomLevel = 1;
        chart.goHome(300);
      }
    }
  }, [selectedStateCode]);

  return <div id="heatMapdiv" style={{ width: "100%", height: "100%" }}></div>;
};

export default HeatMap;

import React, { useEffect } from "react";
import Drawer from "../../atoms/Drawer";
import { makeStyles } from "@material-ui/styles";
import {
  Input,
  Form,
  TimePicker,
  DatePicker,
  Button,
  message,
} from "antd";
import { useSelector } from "react-redux";

import { boxShadow, colors } from "../../Css";
import { getFormState } from "../../redux/CaseManagement/newContactFormReducer";
import store from "../../redux/store";
import {
  RESET_FORM_FIELDS,
  SET_FORM_FIELD,
} from "../../redux/CaseManagement/actionTypes";
import { SET_INITIAL_FIELD_VALUES } from "../../redux/CaseManagement/actionTypes";

const { TextArea } = Input;
const NewContact = ({
  name,
  visible,
  onClose,
  onSave,
  onSaveAndAddNew,
  initialValues = null,
}) => {
  const classes = useStyles();
  const formData = useSelector(getFormState);

  useEffect(() => {
    if (initialValues) {
      store.dispatch({
        type: SET_INITIAL_FIELD_VALUES,
        payload: initialValues,
      });
    }
  }, [initialValues]);

  const setFormData = (id, value) => {
    store.dispatch({
      type: SET_FORM_FIELD,
      payload: {
        id,
        value,
      },
    });
  };

  const confirmFormData = () => {
    //Confirm that all form data is truthy.
    return Object.values(formData).every((data) => data);
  };

  const submitFormData = (addNew = false) => {
    const valid = confirmFormData();
    if (valid) {
      addNew ? onSaveAndAddNew(formData) : onSave(formData);
      store.dispatch({
        type: RESET_FORM_FIELDS,
      });
    } else {
      message["error"]("Kindly fill all the inputs", 3);
    }
  };

  return (
    <Drawer
      name={name}
      visible={visible}
      onClose={() => {
        onClose();
        store.dispatch({
          type: RESET_FORM_FIELDS,
        });
      }}
      hideFooter={true}
    >
      <form className={classes.form}>
        <Form.Item>
          <label>Full Name</label>
          <Input
            className={classes.input}
            value={formData.fullName}
            onChange={({ target }) => setFormData("fullName", target.value)}
            placeholder={"Type here"}
            name={"Name"}
          />
        </Form.Item>
        <Form.Item>
          <label>Phone Number</label>
          <Input
            className={classes.input}
            placeholder={"Type here"}
            value={formData.phoneNumber}
            onChange={({ target }) => setFormData("phoneNumber", target.value)}
            type={"tel"}
            name={"Number"}
          />
        </Form.Item>
        <Form.Item>
          <label>Location</label>
          <Input
            className={classes.input}
            placeholder={"Type here"}
            value={formData.contactLocation}
            onChange={({ target }) =>
              setFormData("contactLocation", target.value)
            }
            name={"Location"}
          />
        </Form.Item>
        <div className={classes.formRow}>
          <Form.Item>
            <label>Time</label>
            <br />
            <TimePicker
              value={formData.time}
              className={classes.inputFull}
              onChange={(value) => setFormData("time", value)}
              name={"Contact Time"}
            />
          </Form.Item>
          <Form.Item>
            <label>Date</label>
            <br />
            <DatePicker
              className={classes.inputFull}
              value={formData.date}
              onChange={(value) => setFormData("date", value)}
              name={"Contact Date"}
            />
          </Form.Item>
        </div>
        <Form.Item>
          <label>Note</label>
          <TextArea
            placeholder={"Type Here"}
            className={classes.textArea}
            value={formData.doctorsNote}
            onChange={({ target }) => setFormData("doctorsNote", target.value)}
            name={"Note"}
          />
        </Form.Item>
        <div>
          <Button className={classes.button} onClick={() => submitFormData()}>
            Save
          </Button>
          <Button
            className={classes.button}
            onClick={() => submitFormData(true)}
          >
            Save and Add New
          </Button>
          <Button
            className={classes.button}
            onClick={() => {
              onClose();
              store.dispatch({
                type: RESET_FORM_FIELDS,
              });
            }}
          >
            Cancel
          </Button>
        </div>
      </form>
    </Drawer>
  );
};

const useStyles = makeStyles({
  form: {
    paddingBottom: 50,
  },
  formRow: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridColumnGap: "25px",
  },
  inputFull: {
    width: "100%",
  },
  input: {
    borderColor: "#C4CDD5",
    borderRadius: 3,
    boxShadow: boxShadow.input,
    "& > div": {
      borderColor: "#C4CDD5 !important",
    },
  },
  textArea: {
    border: `1px solid ${colors.infoWeak}`,
    borderRadius: 3,
    borderColor: "#DFE3E8",
    paddingTop: 8,
    resize: "none",
    width: "100%",
    minHeight: "185px !important",
  },
  button: {
    border: "1px solid #C4CDD5",
    borderRadius: "3px",
    background: "linear-gradient(180deg, #FFFFFF 0%, #F9FAFB 100%)",
    boxShadow: "0 1px 0 0 rgba(22,29,37,0.05)",
    marginRight: 10,
  },
});

export default React.memo(NewContact);

import {
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATIONS_REQUEST_SUCCESS,
  GET_NOTIFICATIONS_REQUEST_FAILURE,
  RESET_NOTIFICATIONS_STORE,
} from './actionTypes';

const initialState = {
  isFetching: false,
  numFetches: 0,
  status: null,
  errorMessage: '',
  pageNo: 0,
  total: 0,
  currentNotifications: [],
  notificationsByPageNumber: {},
};

export const Status = {
  GET_NOTIFICATIONS_REQUEST: 'get_notifications_request',
  GET_NOTIFICATIONS_REQUEST_SUCCESS: 'get_notifcations_request_success',
  GET_NOTIFICATIONS_REQUEST_FAILURE: 'get_notifcations_request_failure',
};

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        isFetching: true,
        numFetches: state.numFetches + 1,
        status: Status.GET_NOTIFICATIONS_REQUEST,
        pageNo: action.payload.pageNo,
        currentNotifications:
          state.notificationsByPageNumber[action.payload.pageNo] || [],
      };
    case GET_NOTIFICATIONS_REQUEST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        status: Status.GET_NOTIFICATIONS_REQUEST_SUCCESS,
        notificationsByPageNumber: {
          ...state.notificationsByPageNumber,
          [action.payload.pageNo]: action.payload.data,
        },
        currentNotifications: action.payload.data,
        pageNo: action.payload.pageNo,
        total: action.payload.total,
      };
    case GET_NOTIFICATIONS_REQUEST_FAILURE:
      return {
        ...state,
        isFetching: false,
        status: Status.GET_NOTIFICATIONS_REQUEST_FAILURE,
        errorMessage: action.payload,
      };
    case RESET_NOTIFICATIONS_STORE:
      return { ...initialState };
    default:
      return state;
  }
};

export const getIsFetchingState = (state) => state.notifications.isFetching;
export const getIsFirstFetchState = (state) =>
  state.notifications.numFetches === 1;
export const getStatusState = (state) => state.notifications.status;
export const getErrorMessageState = (state) => state.notifications.errorMessage;
export const getTotalItemsCountState = (state) => state.notifications.total;
export const getCurrentNotificationsState = (state) =>
  state.notifications.currentNotifications;

export default notificationsReducer;

import {
  GET_EXPORT_CONTACT_DATA_REQUEST,
  GET_EXPORT_CONTACT_DATA_REQUEST_SUCCESS,
  GET_EXPORT_CONTACT_DATA_REQUEST_FAILURE,
} from "./actionTypes";

const initialState = {
  isFetching: false,
  errorMessage: "",
  status: null,
  exportContactData: "",
};

export const Status = {
  GET_EXPORT_CONTACT_DATA_REQUEST_SUCCESS: `GET_EXPORT_CONTACT_DATA_REQUEST_success`,
  GET_EXPORT_CONTACT_DATA_REQUEST_FAILURE: `GET_EXPORT_CONTACT_DATA_REQUEST_failure`,
};

const exportContactReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EXPORT_CONTACT_DATA_REQUEST:
      return {
        ...state,
        isFetching: true,
        status: Status.GET_EXPORT_CONTACT_DATA_REQUEST,
        exportContactData: "",
      };
    case GET_EXPORT_CONTACT_DATA_REQUEST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        status: Status.GET_EXPORT_CONTACT_DATA_REQUEST_SUCCESS,
        exportContactData: action.payload,
      };
    case GET_EXPORT_CONTACT_DATA_REQUEST_FAILURE:
      return {
        ...state,
        isFetching: false,
        status: Status.GET_EXPORT_CONTACT_DATA_REQUEST_FAILURE,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export const getIsFetchingState = (state) => state.exportContact.isFetching;
export const getErrorMessageState = (state) => state.exportContact.errorMessage;
export const getStatusState = (state) => state.exportContact.status;
export const getExportContactState = (state) =>
  state.exportContact.exportContactData;

export default exportContactReducer;

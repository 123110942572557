export const getAllRiskStatusWithCode = () => {
  return [
    {
      Key: 1,
      Value: "Not Assessed"
    },
    {
      Key: 2,
      Value: "Low Risk"
    },
    {
      Key: 3,
      Value: "Medium Risk"
    },
    {
      Key: 4,
      Value: "High Risk"
    },
    {
      Key: 5,
      Value: "Confirmed"
    },
    {
      Key: 6,
      Value: "Discharged"
    }
  ];
};

export const getAllCaseStatusWithCode = () => [
  {
    Key: 5,
    Value: "Confirmed"
  },
  {
    Key: 6,
    Value: "Discharged"
  },
  {
    Key: 7,
    Value: "Dead"
  }
];

export const getStatusCode = riskValue => {
  let risk = getAllRiskStatusWithCode().find(
    status => status.Value === riskValue
  );
  return risk ? risk.key : null;
};

export const getCaseStatusCode = caseValue => {
  return getAllCaseStatusWithCode().find(status => status.Value === caseValue)
    .Key;
};

export const getStatusNameByCode = code => {
  return getAllRiskStatusWithCode().find(status => status.Key === code).Value;
};

export const getCaseStatusNameByCode = code => {
  const caseStatus = getAllCaseStatusWithCode().find(
    status => status.Key === code
  );

  if (caseStatus) return caseStatus.Value;
  else {
    throw new Error(`Ecountered unknown case status ${code}`);
  }
};

export const formatNumber = num => {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

const genders = [
  {
    code: 0,
    name: "Unknown"
  },
  {
    code: 1,
    name: "Male"
  },
  {
    code: 2,
    name: "Female"
  }
];

export const getGenderNameFromCode = code => {
  const gender = genders.find(({ code: genderCode }) => genderCode === code);
  if (!gender) {
    throw new Error(`Encountered an unknown gender code: ${code}`);
  } else {
    return gender.name;
  }
};

export const getCodeFromGenderName = name => {
  const gender = genders.find(
    ({ name: gender }) => gender.toLowerCase() === name.toLowerCase()
  );
  if (!gender) {
    throw new Error(`Encountered an unknown gender name: ${name}`);
  } else {
    return gender.code;
  }
};

export const isValidDateRange = dateRange =>
  !!dateRange && !!dateRange.startDate && !!dateRange.endDate;

export const getPartnerNameFromId = (partners, id) => {
  const partner = partners.find(partner => partner.id === id);
  if (!partner) {
    throw new Error(`Encountered an unknow partner id: ${id}`);
  } else {
    return partner.companyName;
  }
};

export const getChannelNameFromId = (channels, id) => {
  const channel = channels.find(channel => channel.Key === id).Value;
  if (!channel) {
    throw new Error(`Encountered an unknow partner channel id: ${id}`);
  } else {
    return channel;
  }
};

export const fromEntries = entries => {
  return entries.reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: value
    }),
    {}
  );
};

import axios from "axios";
import {
  GET_CASE_MANAGEMENT_REQUEST,
  GET_CASE_MANAGEMENT_REQUEST_SUCCESS,
  GET_CASE_MANAGEMENT_REQUEST_FAILURE,
  RESET_CASE_MANAGEMENT_STORE,
  ADD_NEW_CASE_REQUEST_SUCCESS,
  ADD_NEW_CASE_REQUEST_ERROR,
  GET_SINGLE_CASE_REQUEST,
  GET_SINGLE_CASE_REQUEST_SUCCESS,
  GET_SINGLE_CASE_REQUEST_FAILURE,
  RESET_SINGLE_CASE_STORE,
  GET_CREATED_CASE_CONTACT_REQUEST,
  GET_CREATED_CASE_CONTACT_REQUEST_SUCCESS,
  GET_CREATED_CASE_CONTACT_REQUEST_FAILURE,
  RESET_CREATED_CASE_CONTACT_STORE,
  EDIT_CASE_REQUEST_SUCCESS,
  EDIT_CASE_REQUEST_ERROR,
  UPDATE_STATUS_ERROR,
  UPDATE_STATUS_SUCCESS,
  UPLOAD_FILES_ERROR,
  UPLOAD_FILES_SUCCESS
} from "../redux/CaseManagement/actionTypes";
import store from "../redux/store";

function CaseManagementRepository(axiosInstance) {
  const CancelTokenForCaseManagement = axios.CancelToken;
  const CancelTokeForSingleCase = axios.CancelToken;
  const CancelTokenForCreatedCaseContact = axios.CancelToken;
  const CancelTokenForCreatedCase = axios.CancelToken;
  let cancelForCaseManagement = null;
  let cancelForSingleCase = null;
  let cancelForCreatedCaseContact = null;
  let cancelForCreatedCase = null;

  let _CaseManagementRepository = {
    resetCaseManagementStore: function() {
      store.dispatch({
        type: RESET_CASE_MANAGEMENT_STORE
      });
    },

    resetSingleCaseStore: function() {
      store.dispatch({
        type: RESET_SINGLE_CASE_STORE
      });
    },

    resetCreatedCaseContactStore: function() {
      store.dispatch({
        type: RESET_CREATED_CASE_CONTACT_STORE
      });
    },

    getAllCaseManagement: function({ pageNo, currentData, ...filterParams }) {
      // pageSize,
      // pageNo,
      // keyword,
      // state,
      pageNo === 0 && _CaseManagementRepository.resetCaseManagementStore();

      store.dispatch({
        type: GET_CASE_MANAGEMENT_REQUEST,
        payload: {
          pageNo,
          currentData
        }
      });

      cancelForCaseManagement && cancelForCaseManagement();
      axiosInstance
        .get("/CaseManagement/GetAllCreatedCase", {
          cancelToken: new CancelTokenForCaseManagement(function executor(c) {
            cancelForCaseManagement = c;
          }),
          params: { pageNo, ...filterParams }
        })
        .then(response => {
          store.dispatch({
            type: GET_CASE_MANAGEMENT_REQUEST_SUCCESS,
            payload: {
              pageNo,
              total: response.data.total,
              data: response.data.data
            }
          });
        })
        .catch(error => {
          if (axios.isCancel(error)) {
            // this ensures that no error message is shown as a result of a request
            // override by a new request
            return;
          }
          store.dispatch({
            type: GET_CASE_MANAGEMENT_REQUEST_FAILURE,
            payload: error.message
          });
        });
    },

    getSingleCaseDetails: function(caseId) {
      store.dispatch({
        type: GET_SINGLE_CASE_REQUEST
      });
      cancelForSingleCase && cancelForSingleCase();
      axiosInstance
        .get(`/CaseManagement/createdCaseById/${caseId}`, {
          cancelToken: new CancelTokeForSingleCase(function executor(c) {
            cancelForSingleCase = c;
          })
        })
        .then(response => {
          const { success, message, data } = response.data;
          if (success)
            store.dispatch({
              type: GET_SINGLE_CASE_REQUEST_SUCCESS,
              payload: data
            });
          else
            store.dispatch({
              type: GET_SINGLE_CASE_REQUEST_FAILURE,
              payload: message
            });
        })
        .catch(error => {
          if (axios.isCancel(error)) {
            return;
          }
          store.dispatch({
            type: GET_SINGLE_CASE_REQUEST_FAILURE,
            payload: error.message
          });
        });
    },

    getCreatedCaseContact: function({ createdCaseId, ...filterParams }) {
      // pageSize,
      // pageNo,
      // keyword,
      // state,
      const { pageNo } = filterParams;
      pageNo === 0 && _CaseManagementRepository.resetCreatedCaseContactStore();

      store.dispatch({
        type: GET_CREATED_CASE_CONTACT_REQUEST,
        payload: {
          pageNo
        }
      });

      cancelForCreatedCaseContact && cancelForCreatedCaseContact();
      axiosInstance
        .get(`/CaseManagement/GetCreatedCaseContact/${createdCaseId}`, {
          cancelToken: new CancelTokenForCreatedCaseContact(function executor(
            c
          ) {
            cancelForCreatedCaseContact = c;
          }),
          params: filterParams
        })
        .then(response => {
          store.dispatch({
            type: GET_CREATED_CASE_CONTACT_REQUEST_SUCCESS,
            payload: {
              pageNo,
              total: response.data.total,
              data: response.data.data
            }
          });
        })
        .catch(error => {
          if (axios.isCancel(error)) {
            // this ensures that no error message is shown as a result of a request
            // override by a new request
            return;
          }
          store.dispatch({
            type: GET_CREATED_CASE_CONTACT_REQUEST_FAILURE,
            payload: error.message
          });
        });
    },

    addNewCase: function(formData) {
      cancelForCreatedCase && cancelForCreatedCase();
      axiosInstance
        .post("/CaseManagement/CreateCase", formData, {
          cancelToken: new CancelTokenForCreatedCase(function executor(c) {
            cancelForCreatedCase = c;
          })
        })
        .then(response => {
          store.dispatch({
            type: ADD_NEW_CASE_REQUEST_SUCCESS,
            payload: {
              message: "Form submitted successfully"
            }
          });
        })
        .catch(err => {
          if (err.response) {
            return store.dispatch({
              type: ADD_NEW_CASE_REQUEST_ERROR,
              payload: {
                message:
                  err.response.data.message ||
                  "There was an issue submitting the form. Kindly try again"
              }
            });
          }
          return store.dispatch({
            type: ADD_NEW_CASE_REQUEST_ERROR,
            payload: {
              message:
                "There was an issue submitting the form. Kindly try again"
            }
          });
        });
    },

    updateCaseStatus: function(caseId, status) {
      axiosInstance
        .put(`CaseManagement/UpdateCreatedCaseStatus/${caseId}/${status}`)
        .then(res => {
          if (res.data.success) {
            store.dispatch({
              type: UPDATE_STATUS_SUCCESS
            });
          }
          store.dispatch({
            type: UPDATE_STATUS_ERROR,
            payload: res.message
          });
        })
        .catch(err => {
          if (err.response) {
            store.dispatch({
              type: UPDATE_STATUS_ERROR,
              payload: err.response.message
            });
            return;
          }
          store.dispatch({
            type: UPDATE_STATUS_ERROR,
            payload: err.message
          });
        });
    },

    uploadCaseFiles: function(files) {
      if (files.length === 0) {
        store.dispatch({
          type: UPLOAD_FILES_SUCCESS,
          payload: []
        });
      }

      const formData = new FormData();
      files.forEach(file => {
        formData.append("", file);
      });
      axiosInstance
        .post(`CaseManagement/casefiles/upload`, formData)
        .then(({ data: resData }) => {
          if (resData.success === false) {
            return store.dispatch({
              type: UPLOAD_FILES_ERROR,
              payload: resData.message
            });
          }

          store.dispatch({
            type: UPLOAD_FILES_SUCCESS,
            payload: resData.data
          });
        });
    },

    editCase: function(formData, caseId) {
      axiosInstance
        .put(`CaseManagement/UpdateCreatedCase/${caseId}`, formData, {
          cancelToken: new CancelTokenForCreatedCase(function executor(c) {
            cancelForCreatedCase = c;
          })
        })
        .then(response => {
          store.dispatch({
            type: EDIT_CASE_REQUEST_SUCCESS,
            payload: {
              message: "Form submitted successfully"
            }
          });
        })
        .catch(err => {
          if (err.response) {
            return store.dispatch({
              type: EDIT_CASE_REQUEST_ERROR,
              payload: {
                message:
                  err.response.data.message ||
                  "There was an issue submitting the form. Kindly try again"
              }
            });
          }
          return store.dispatch({
            type: EDIT_CASE_REQUEST_ERROR,
            payload: {
              message:
                "There was an issue submitting the form. Kindly try again"
            }
          });
        });
    },

    uploadFileAndCreateCase: function({ files, ...values }) {
      if (files.length === 0) {
        _CaseManagementRepository.addNewCase(values);
        return;
      }
      const formData = new FormData();
      files.forEach(file => {
        formData.append("", file);
      });
      axiosInstance
        .post(`CaseManagement/casefiles/upload`, formData)
        .then(({ data: resData }) => {
          if (resData.success === false) {
            return store.dispatch({
              type: UPLOAD_FILES_ERROR,
              payload: resData.message
            });
          }

          store.dispatch({
            type: UPLOAD_FILES_SUCCESS,
            payload: resData.data
          });
          const newFormValues = { ...values, document: resData.data };
          _CaseManagementRepository.addNewCase(newFormValues);
        });
    }
  };

  return _CaseManagementRepository;
}

export { CaseManagementRepository };

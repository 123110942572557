import {
  UPLOAD_STAFF_AVATAR_REQUEST,
  UPLOAD_STAFF_AVATAR_REQUEST_SUCCESS,
  UPLOAD_STAFF_AVATAR_REQUEST_FAILURE,
} from "./actionTypes";

const initialState = {
  status: null,
  isFetching: false,
  errorMessage: "",
  avatarUrl: null,
};

export const Status = {
  UPLOAD_STAFF_AVATAR_REQUEST: `upload_staff_avatar_request`,
  UPLOAD_STAFF_AVATAR_REQUEST_SUCCESS: `upload_staff_avatar_request_success`,
  UPLOAD_STAFF_AVATAR_REQUEST_FAILURE: `upload_staff_avatar_request_failure`,
};

const staffAvatarReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_STAFF_AVATAR_REQUEST:
      return {
        ...state,
        status: Status.UPLOAD_STAFF_AVATAR_REQUEST,
        isFetching: true,
        errorMessage: "",
      };
    case UPLOAD_STAFF_AVATAR_REQUEST_SUCCESS:
      return {
        ...state,
        status: Status.UPLOAD_STAFF_AVATAR_REQUEST_SUCCESS,
        isFetching: false,
        avatarUrl: action.payload,
      };
    case UPLOAD_STAFF_AVATAR_REQUEST_FAILURE:
      return {
        ...state,
        status: Status.UPLOAD_STAFF_AVATAR_REQUEST_FAILURE,
        isFetching: false,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export const getStatusState = (state) => state.staffAvatar.status;
export const getIsFetchingState = (state) => state.staffAvatar.isFetching;
export const getErrorMessageState = (state) => state.staffAvatar.errorMessage;
export const getAvatarUrlState = (state) => state.staffAvatar.avatarUrl;

export default staffAvatarReducer;

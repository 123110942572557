import React from "react";
import { Row, Col } from "antd";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";

import { colors, fontWeight, fontSize } from "../Css";

const AuthenticationLayout = (props) => {
  const classes = useStyles();
  const { title, subTitle, formTitle, children } = props;

  return (
    <div className={classes.fullHeightAndWidth}>
      <Row className={classes.fullHeight}>
        <Col xs={0} md={9} className={classes.sideBar}>
          <div className={classes.avatarContainer}></div>
          <div className={classes.textContainer}>
            <p className="displayText-title">{title}</p>
            <p className="displayText-subtitle">{subTitle}</p>
          </div>
        </Col>
        <Col xs={24} md={15} className={classes.formSection}>
          <Row align="middle" className={classes.fullHeight}>
            <Col xs={{ span: 22, offset: 1 }} md={{ span: 10, offset: 7 }}>
              <p className="form-title">{formTitle}</p>
              {children}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

AuthenticationLayout.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  formTitle: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const useStyles = makeStyles({
  fullHeightAndWidth: {
    width: "100%",
    height: "100vh",
  },
  fullHeight: {
    height: "100vh",
  },
  sideBar: {
    background: colors.primary,
    padding: "51.5px 69.5px",
    // eslint-disable-next-line
    ["@media (max-width:780px)"]: {
      display: "none",
    },
  },
  avatarContainer: {
    width: 41,
    height: 41,
    background: colors.imageBackground,
    "& img": {
      width: "inherit",
      height: "inherit",
    },
  },
  textContainer: {
    marginTop: 185,
    "& .displayText-title": {
      color: colors.white,
      fontSize: 42,
      fontWeight: fontWeight.medium,
      margin: 0,
    },
    "& .displayText-subtitle": {
      color: "#C4CDD5",
      fontSize: fontSize.xxlarge,
      fontWeight: fontWeight.regular,
      margin: 0,
    },
  },
  formSection: {
    backgroundColor: colors.white,
    "& .form-title": {
      marginBottom: 20,
      fontSize: 26,
      margin: 0,
      fontWeight: fontWeight.medium,
      color: colors.base,
    },
  },
});

export default React.memo(AuthenticationLayout);

import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Drawer from '../../atoms/Drawer';
import { fontSize, colors, fontWeight } from '../../Css';
import moment from 'moment';
import { Card, Tabs } from 'antd';

const formatDate = (date) => moment(date).format('DD - MMM - YYYY / HH:mm');

const TABS = {
  pending: { name: 'Pending', value: 'pending' },
  successful: { name: 'Successful', value: 'successful' },
  failed: { name: 'Failed', value: 'failed' },
};

const ViewNotification = ({ visible, onClose, notification }) => {
  const classes = useStyles();
  const renderRecepientList = (recepients) => {
    return (
      <React.Fragment>
        <div className={classes.row}>
          <h6 className={classes.label}>Total</h6>
          <p className={`${classes.value} large`}>{recepients.length}</p>
        </div>
        <ul className={classes.recepientList}>
          {recepients.map((recepient) => (
            <li key={recepient.id}>{recepient.fullName}</li>
          ))}
        </ul>
      </React.Fragment>
    );
  };

  const pendingRecepients =
    (!!notification && notification.byStatus.pending) || [];
  const successfulRecepients =
    (!!notification && notification.byStatus.successful) || [];
  const failedRecepients =
    (!!notification && notification.byStatus.failed) || [];

  return (
    <Drawer
      title="Message Recepient Status"
      visible={visible}
      onClose={onClose}
      hideFooter={true}
    >
      <div className={classes.row}>
        <h6 className={classes.label}>Date</h6>
        <p className={classes.value}>
          {!!notification && formatDate(notification.date)}
        </p>
      </div>
      <div className={classes.row}>
        <h6 className={`${classes.label} spaced`}>Message</h6>
        <Card bodyStyle={{ padding: 12 }}>
          {!!notification && notification.message}
        </Card>
      </div>
      <Tabs className={classes.tabs}>
        <Tabs.TabPane tab={TABS.pending.name} key={TABS.pending.value}>
          {renderRecepientList(pendingRecepients)}
        </Tabs.TabPane>
        <Tabs.TabPane tab={TABS.successful.name} key={TABS.successful.value}>
          {renderRecepientList(successfulRecepients)}
        </Tabs.TabPane>
        <Tabs.TabPane tab={TABS.failed.name} key={TABS.failed.value}>
          {renderRecepientList(failedRecepients)}
        </Tabs.TabPane>
      </Tabs>
    </Drawer>
  );
};

const useStyles = makeStyles({
  row: {
    marginBottom: 32,
  },
  label: {
    fontSize: fontSize.small,
    color: colors.secondaryBase,
    fontWeight: fontWeight.medium,
    lineHeight: 1.33,
    marginBottom: 10,
    '&.spaced': {
      marginBottom: 22,
    },
  },
  value: {
    fontSize: fontSize.medium,
    lineHeight: 1.42,
    color: colors.base,
    '&.large': {
      fontSize: fontSize.xxlarge,
    },
  },
  recepientList: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    '& li': {
      color: colors.base,
      fontSize: fontSize.medium,
      lineHeight: 1.42,
      '&:not(:last-of-type)': {
        marginBottom: 30,
      },
    },
  },
  tabs: {
    margin: '0 -20px',
    '& .ant-tabs-bar': {
      padding: '0 20px',
    },
    '& .ant-tabs-tabpane': {
      padding: '0 20px',
    },
  },
});

export default ViewNotification;

import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { notification } from "antd";

import {
  getPeriodicAssessmentsState,
  getErrorMessageState,
  getStatusState,
  Status
} from "../../redux/Assessment/assessmentsByPeriodReducer";
import { useSelector } from "react-redux";
import { useUpdateEffect } from "../../hooks/useUpdateEffect";

const LineChart = () => {
  const periodicAssessments = useSelector(getPeriodicAssessmentsState);
  const status = useSelector(getStatusState);
  const errorMessage = useSelector(getErrorMessageState);
  const [days, setDays] = useState([]);
  const [highRiskData, setHighRiskData] = useState([]);
  const [mediumRiskData, setMediumRiskData] = useState([]);
  const [lowRiskData, setLowRiskData] = useState([]);

  useEffect(() => {
    let _days = [];
    let _highRiskData = [];
    let _mediumRiskData = [];
    let _lowRiskData = [];
    if (periodicAssessments) {
      for (let i = 0; i < periodicAssessments.length; i++) {
        _days.push(periodicAssessments[i].title);
        _highRiskData.push(periodicAssessments[i].data["High Risk"]);
        _mediumRiskData.push(periodicAssessments[i].data["Medium Risk"]);
        _lowRiskData.push(periodicAssessments[i].data["Low Risk"]);
      }
      setDays(_days);
      setHighRiskData(_highRiskData);
      setMediumRiskData(_mediumRiskData);
      setLowRiskData(_lowRiskData);
    }
  }, [periodicAssessments]);

  useUpdateEffect(() => {
    if (status === Status.GET_ASSESSMENTS_BY_PERIOD_REQUEST_FAILURE) {
      notification.error({
        message: "Couldn't load line chart data",
        description: errorMessage
      });
    }
  }, [status, errorMessage]);

  const options = {
    chart: {
      id: "risk-levels",
      height: 250,
      toolbar: {
        show: false
      }
    },
    xaxis: {
      categories: days,
      tickPlacement: "on"
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: 2
    },
    legend: {
      show: false
    },
    colors: ["#DE3618", "#F49342", "#50B83C"],
    tooltip: {
      enabled: true,
      enabledOnSeries: undefined,
      shared: true
    }
  };

  const series = [
    {
      name: "High Risk",
      data: highRiskData
    },
    {
      name: "Medium Risk",
      data: mediumRiskData
    },
    {
      name: "Low Risk",
      data: lowRiskData
    }
  ];

  return (
    <div className="BarChart" style={{ width: "100%", marginLeft: "-12px" }}>
      <Chart
        options={options}
        series={series}
        type="line"
        width={"100%"}
        height={200}
      />
    </div>
  );
};

export default LineChart;

import {
    GET_CSV_URL_REQUEST,
    GET_CSV_URL_REQUEST_FAILURE,
    GET_CSV_URL_REQUEST_SUCCESS,
    RESET_CSV_URL_STORE
} from "./actionTypes";

const initialState = {
    isFetching: false,
    errorMessage: "",
    status: null,
    CSVUrl: ""
};

export const Status = {
    GET_CSV_URL_REQUEST: "GET_CSV_URL_REQUEST",
    GET_CSV_URL_REQUEST_SUCCESS: "GET_CSV_URL_REQUEST_SUCCESS",
    GET_CSV_URL_REQUEST_FAILURE: "GET_CSV_URL_REQUEST_FAILURE"
};

export const assessmentsCSVUrlReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CSV_URL_REQUEST: {
            return {
                ...state,
                isFetching: true,
                status: Status.GET_CSV_URL_REQUEST
            };
        }
        case GET_CSV_URL_REQUEST_SUCCESS: {
            return {
                ...state,
                isFetching: false,
                status: Status.GET_CSV_URL_REQUEST_SUCCESS,
                CSVUrl: action.payload
            };
        }
        case GET_CSV_URL_REQUEST_FAILURE: {
            return {
                ...state,
                isFetching: false,
                status: Status.GET_CSV_URL_REQUEST_FAILURE,
                errorMessage: action.payload
            };
        }
        case RESET_CSV_URL_STORE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export const getIsFetchingState = (state) => state.assessmentsCSVUrl.isFetching;
export const getErrorMessageState = (state) => state.assessmentsCSVUrl.errorMessage;
export const getCSVUrlState = (state) => state.assessmentsCSVUrl.CSVUrl;
export const getStatusState = (state) => state.assessmentsCSVUrl.status;
import {
  GET_STATE_DEMOGRAPHY_DATA_REQUEST,
  GET_STATE_DEMOGRAPHY_DATA_REQUEST_SUCCESS,
  GET_STATE_DEMOGRAPHY_DATA_REQUEST_FAILURE,
} from "./actionTypes";

const initialState = {
  errorMessage: "",
  demographyData: [],
  isFetching: false,
  status: null,
};

export const Status = {
  GET_STATE_DEMOGRAPHY_DATA_REQUEST_SUCCESS: `get_state_demography_data_request_success`,
  GET_STATE_DEMOGRAPHY_DATA_REQUEST_FAILURE: `get_state_demography_data_request_failure`,
};

const assessmentsByDemographyReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STATE_DEMOGRAPHY_DATA_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_STATE_DEMOGRAPHY_DATA_REQUEST_SUCCESS: {
      return {
        ...state,
        demographyData: action.payload.data,
        isFetching: false,
        status: GET_STATE_DEMOGRAPHY_DATA_REQUEST_SUCCESS,
      };
    }
    case GET_STATE_DEMOGRAPHY_DATA_REQUEST_FAILURE: {
      return {
        ...state,
        errorMessage: action.payload,
        isFetching: false,
        status: GET_STATE_DEMOGRAPHY_DATA_REQUEST_FAILURE,
      };
    }
    default: {
      return state;
    }
  }
};

export const getDemographyDataState = (state) =>
  state.demographicChart.demographyData;
export const getIsFetchingState = (state) => state.demographicChart.isFetching;
export const getErrorMessageState = (state) =>
  state.demographicChart.errorMessage;
export const getStatusState = (state) => state.demographicChart.status;

export default assessmentsByDemographyReducer;

import {
  LOGIN_REQUEST,
  LOGIN_REQUEST_SUCCESS,
  LOGIN_REQUEST_FAILURE,
  SET_CURRENT_USER,
} from "./actionTypes";

const initialState = {
  isFetching: false,
  errorMessage: "",
  status: null,
  currentUser: null,
};

export const Status = {
  LOGIN_REQUEST_SUCCESS: `login_request_success`,
  LOGIN_REQUEST_FAILURE: `login_request_failure`,
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case LOGIN_REQUEST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        status: Status.LOGIN_REQUEST_SUCCESS,
        currentUser: action.payload.currentUser,
      };
    case LOGIN_REQUEST_FAILURE:
      return {
        ...state,
        isFetching: false,
        status: Status.LOGIN_REQUEST_FAILURE,
        errorMessage: action.payload,
      };
    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload,
      };
    default:
      return state;
  }
};

export const getIsFetchingState = (state) => state.login.isFetching;
export const getErrorMessageState = (state) => state.login.errorMessage;
export const getStatusState = (state) => state.login.status;
export const getCurrentUserState = (state) => state.login.currentUser;

export default loginReducer;

import {
  GET_CASE_MANAGEMENT_REQUEST,
  GET_CASE_MANAGEMENT_REQUEST_SUCCESS,
  GET_CASE_MANAGEMENT_REQUEST_FAILURE,
  RESET_CASE_MANAGEMENT_STORE,
  UPDATE_STATUS_SUCCESS,
  UPDATE_STATUS_ERROR,
  RESET_UPDATE_STATUS_STATE,
} from "./actionTypes";

const initialState = {
  isFetching: false,
  errorMessage: "",
  status: null,
  pageNo: 0,
  total: 0,
  allCaseManagementByPageNumber: {},
  currentCaseManagement: [],
  updateStatusSuccess: null,
  updateStatusError: null,
  updateStatusMessage: null,
};

export const Status = {
  GET_CASE_MANAGEMENT_REQUEST_SUCCESS: `get_case_management_request_success`,
  GET_CASE_MANAGEMENT_REQUEST_FAILURE: `get_case_management_request_failure`,
};

const caseManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CASE_MANAGEMENT_REQUEST:
      return {
        ...state,
        isFetching: true,
        status: Status.GET_CASE_MANAGEMENT_REQUEST,
        currentCaseManagement: action.payload.currentData,
      };
    case GET_CASE_MANAGEMENT_REQUEST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        status: Status.GET_CASE_MANAGEMENT_REQUEST_SUCCESS,
        allCaseManagementByPageNumber: {
          ...state.allCaseManagementByPageNumber,
          [action.payload.pageNo]: action.payload.data,
        },
        currentCaseManagement: action.payload.data,
        pageNo: action.payload.pageNo,
        total: action.payload.total,
      };
    case GET_CASE_MANAGEMENT_REQUEST_FAILURE:
      return {
        ...state,
        isFetching: false,
        status: Status.GET_CASE_MANAGEMENT_REQUEST_FAILURE,
        errorMessage: action.payload,
      };
    case RESET_CASE_MANAGEMENT_STORE:
      return initialState;
    case UPDATE_STATUS_SUCCESS: {
      return {
        ...state,
        updateStatusSuccess: true,
        updateStatusMessage: "Successfully updated status",
      };
    }
    case UPDATE_STATUS_ERROR: {
      return {
        ...state,
        updateStatusError: true,
        updateStatusMessage:
          action.payload ||
          "There was an error updating the status. Kindly try again.",
      };
    }
    case RESET_UPDATE_STATUS_STATE: {
      return {
        ...state,
        updateStatusSuccess: null,
        updateStatusError: null,
        updateStatusMessage: null,
      };
    }
    default:
      return state;
  }
};

export const getIsFetchingState = (state) => state.caseManagement.isFetching;
export const getErrorMessageState = (state) =>
  state.caseManagement.errorMessage;
export const getStatusState = (state) => state.caseManagement.status;
export const getTotalItemsCountState = (state) => state.caseManagement.total;
export const getCurrentCaseManagementState = (state) =>
  state.caseManagement.currentCaseManagement;
export const getUpdateStatusSuccess = (state) =>
  state.caseManagement.updateStatusSuccess;
export const getUpdateStatusError = (state) =>
  state.caseManagement.updateStatusError;
export const getUpdateStatusMessage = (state) =>
  state.caseManagement.updateStatusMessage;

export default caseManagementReducer;

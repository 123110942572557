import React from "react";
import {
  Button,
  Modal,
  Form,
  Input,
} from "antd";
import { makeStyles } from "@material-ui/styles";
import { fontWeight } from "../../Css";

const REVOKE_TEXT = 'revoke account';
const RevokeModal = ({
  revokeForm,
  showRevokeModal,
  handleShowRevokeModal,
  handleRevokeFormFinish,
  handleSubmitRevoke,
}) => {
  const classes = useStyles();

  const compareWithRevokeText = (rule, value, callback) => {
    if (value && value !== REVOKE_TEXT) {
      callback("Revoke text is wrong");
    } else {
      callback();
    }
  };

  return (
    <Modal
      centered
      cancelText={null}
      visible={showRevokeModal}
      onOk={handleShowRevokeModal}
      onCancel={handleShowRevokeModal}
      footer={null}
      className={classes.partnerModal}
    >
      <div className="header">
        <p className="header-title">
          Do you really want to revoke "company's name" token?
        </p>
        <span className="header-subtitle">
          This action cannot be undone so we want to make sure
        </span>
      </div>
      <div className="modal-body">
        <Form form={revokeForm} onFinish={handleRevokeFormFinish}>
          <p className="body-label">Type "{REVOKE_TEXT}"  to confirm</p>
          <Form.Item
            name="revoke"
            rules={[
              {
                required: true,
                message: "Please enter 'revoke account'",
              },
              { validator: compareWithRevokeText },
            ]}
          >
            <Input className="body-input" />
          </Form.Item>

          <Form.Item>
            <div className="button-wrap">
              <Button onClick={handleShowRevokeModal} className="btn-cancel">
                No, don't revoke
              </Button>
              <Button onClick={handleSubmitRevoke} className="btn-submit">
                Yes, revoke account
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

const useStyles = makeStyles({
  partnerModal: {
    width: "50% !important",
    zIndex: 2000,

    "& .ant-modal-body": {
      padding: 0,
    },

    "& .ant-form-item": {
      margin: 0,
    },

    "& .anticon": {
      display: "none",
    },

    "& .header": {
      backgroundColor: "#DE3618",
      padding: "40px 63px",
      display: "flex",
      flexDirection: "column",
    },

    "& .header-title": {
      fontSize: 42,
      color: "#fff",
      fontWeight: fontWeight.medium,
      lineHeight: "44px",
    },

    "& .header-subtitle": {
      fontSize: 20,
      color: "#fff",
      fontWeight: fontWeight.regular,
    },

    "& .modal-body": {
      padding: "40px 63px",
    },

    "& .body-label": {
      margin: "0",
      fontSize: "26px",
      color: "#637381",
      lineHeight: "32px",
    },

    "& .body-input": {
      height: "70px",
      width: "100%",
      borderRadius: "5px",
      border: "1px solid #C4CDD5",
      background: "#F4F6F8",
      marginTop: "5px",
      paddingLeft: "15px",
      fontSize: "20px",
      fontWeight: fontWeight.bold,
    },

    "& .button-wrap": {
      marginTop: "40px",
      display: "flex",
      justifyContent: "flex-end",
    },

    "& .ant-btn": {
      height: "50px",
      borderRadius: "3px",

      "&.btn-cancel": {
        marginRight: "12px",
        background: "#F9FAFB",
      },
      "&.btn-submit": {
        background: "#BF0711",
        color: "#fff",
      },
    },
  },
});

export default RevokeModal;

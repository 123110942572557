/** @format */

import React, { useEffect, useState } from "react";
import { Router, Switch, Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Layout } from "antd";
import { makeStyles } from "@material-ui/styles";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import Login from "../views/Login";
import Assessment from "../views/Assessment/index";
import Register from "../views/Register";
import ResetPassword from "../views/ResetPassword";
import ForgotPassword from "../views/ForgotPassword";
import ContactTrace from "../views/ContactTrace";
import history from "./history";
import SideBar from "../components/SideBar";
import TopHeader from "../components/TopHeader";
import Overview from "../views/Overview/";
import CaseManagement from "../views/CaseManagement";
import Partners from "../views/Partners";
import Staff from "../views/Staffs";
import Auth from "../utils/Auth";
import { SET_CURRENT_USER } from "../redux/Authentication/actionTypes";

export const PublicPaths = {
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forgot-password",
  REGISTER: "/register",
  RESET_PASSWORD: "/reset-password"
};

export const PrivatePaths = {
  OVERVIEW: "/overview",
  ASSESSMENT: "/assessment",
  CONTACT_TRACE: "/contact-trace",
  CASE_MANAGEMENT: "/case-management",
  PARTNERS: "/partners",
  STAFF: "/user-administration",
  SETTINGS: "/settings",
  PROFILE: "/profile"
};

const publicRoutes = [
  { path: PublicPaths.LOGIN, exact: true, component: Login },
  { path: PublicPaths.REGISTER, exact: true, component: Register },
  { path: PublicPaths.RESET_PASSWORD, exact: true, component: ResetPassword },
  { path: PublicPaths.FORGOT_PASSWORD, exact: true, component: ForgotPassword }
];

const privateRoutes = [
  { path: PrivatePaths.OVERVIEW, exact: true, component: Overview },
  { path: PrivatePaths.ASSESSMENT, exact: true, component: Assessment },
  { path: PrivatePaths.CONTACT_TRACE, exact: false, component: ContactTrace },
  {
    path: PrivatePaths.CASE_MANAGEMENT,
    exact: false,
    component: CaseManagement
  },
  {
    path: PrivatePaths.PARTNERS,
    exact: true,
    component: Partners
  },
  {
    path: PrivatePaths.STAFF,
    exact: true,
    component: Staff
  }
];

const Routes = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [sideBarCollapsed, setSideBarCollapsed] = useState(false);

  useEffect(() => {
    /**
     * In the future, we will want to perform a /me request here
     * and save the data in redux and deprecate the localStorage also.
     * This is a workarond using localStorage which is not ideal for now
     */
    const currentUser = Auth.getCurrentUser();
    if (!!currentUser === true) {
      dispatch({
        type: SET_CURRENT_USER,
        payload: currentUser
      });
    }

    if (
      history.location.pathname === PrivatePaths.OVERVIEW &&
      !sideBarCollapsed
    ) {
      toggleSideBar();
    }
    // eslint-disable-next-line
  }, []);

  const toggleSideBar = () => setSideBarCollapsed(!sideBarCollapsed);

  return (
    <Layout className={classes.container}>
      <Router history={history}>
        <Switch>
          <PrivateRoute
            path="/"
            component={props => (
              <TopHeader {...props} toggleSideBar={toggleSideBar} />
            )}
            shouldRedirect={false}
          />
        </Switch>
        <Layout className={classes.contentContainer}>
          <Switch>
            <PrivateRoute
              path="/"
              component={props => (
                <SideBar
                  {...props}
                  collapsed={sideBarCollapsed}
                  toggleSideBar={toggleSideBar}
                />
              )}
              shouldRedirect={false}
            />
          </Switch>
          <Layout style={{}}>
            <Switch>
              {publicRoutes.map((route, index) => (
                <PublicRoute
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  component={route.component}
                />
              ))}
              {privateRoutes.map((route, index) => (
                <PrivateRoute
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  component={route.component}
                />
              ))}
              <Redirect to={PublicPaths.LOGIN} />
            </Switch>
          </Layout>
        </Layout>
      </Router>
    </Layout>
  );
};

const useStyles = makeStyles({
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  contentContainer: {
    background: "#F5F6F8"
  }
});

export default React.memo(Routes);

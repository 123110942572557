import React, { useEffect } from "react";
import { Form, Button, Input, Row, notification } from "antd";
import { PublicPaths } from "../routes";
import AuthenticationLayout from "../components/AuthenticationLayout";
import { Api } from "../utils/Api";
import {
  Status,
  getIsFetchingState,
  getErrorMessageState,
  getStatusState,
} from "../redux/Authentication/loginReducer";
import { useSelector } from "react-redux";

const Login = (props) => {
  const [form] = Form.useForm();
  const { history } = props;

  const isFetching = useSelector(getIsFetchingState);
  const errorMessage = useSelector(getErrorMessageState);
  const status = useSelector(getStatusState);

  const handleNavigation = () => {
    history.push(PublicPaths.FORGOT_PASSWORD);
  };

  const handleFinish = (values) => {
    const { email, password } = values;
    Api.AuthRepository.login(email, password);
  };

  useEffect(() => {
    if (status === Status.LOGIN_REQUEST_FAILURE)
      notification["error"]({
        message: "Failed to login",
        description: errorMessage,
      });
  }, [status, errorMessage]);

  return (
    <AuthenticationLayout
      title="Welcome back"
      subTitle="Login to continue"
      formTitle="Login"
    >
      <Form
        form={form}
        onFinish={handleFinish}
        layout="vertical"
        hideRequiredMark={true}
      >
        <Form.Item
          label="Email Address"
          name="email"
          rules={[{ required: true, message: "Please input your email!" }]}
        >
          <Input
            type="email"
            placeholder="enter your email address"
            autoFocus
          />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input type="password" placeholder="enter your password" />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: "100%", marginBottom: 20 }}
            loading={isFetching}
          >
            Sign in
          </Button>
        </Form.Item>
        <Row justify="center">
          <Button type="link" onClick={handleNavigation}>
            Forgot your password?
          </Button>
        </Row>
      </Form>
    </AuthenticationLayout>
  );
};

export default React.memo(Login);

import React, { useEffect } from "react";
import { Menu, notification, Checkbox } from "antd";
import { makeStyles } from "@material-ui/styles";

import {
  Status,
  getIsFetchingState,
  getErrorMessageState,
  getStatusState,
  getPartnerChannelsState,
} from "../../redux/Partners/partnerChannelsReducer";
import { useSelector } from "react-redux";
import { Api } from "../../utils/Api";
import Loader from "../../atoms/Loader";

const ChannelsFilter= ({ selectedChannel, onSelectChannel }) => {
  const classes = useStyles();

  const isFetching = useSelector(getIsFetchingState);
  const errorMessage = useSelector(getErrorMessageState);
  const status = useSelector(getStatusState);
  const partnerChannels= useSelector(getPartnerChannelsState);

  useEffect(() => {
    Api.PartnersRepository.getPartnerChannels();
    return () => {
      Api.PartnersRepository.resetPartnerChannelsStore();
    };
  }, []);

  useEffect(() => {
    if (status === Status.GET_PARTNER_SOURCES_REQUEST_FAILURE)
      notification["error"]({
        message: "Failed to fetch partners",
        description: errorMessage,
      });
  }, [status, errorMessage]);

  return (
    <Menu className={classes.sourceFilter}>
      {isFetching ? (
        <Loader title="" />
      ) : (
        partnerChannels.map(({ Key, Value}) => (
          <Menu.Item key={Key}>
            <Checkbox
              checked={Key === selectedChannel}
              onChange={() => {console.log("Key............");console.log(Key); onSelectChannel(Key)}}
            >
              {Value}
            </Checkbox>
          </Menu.Item>
        ))
      )}
    </Menu>
  );
};

const useStyles = makeStyles({
  sourceFilter: {
    "&.ant-menu": {
      border: 0,
    },
    "& .ant-menu-item": {
      padding: 0,
      border: 0,
      lineHeight: 2.0,
      height: "fit-content",
      margin: "0 !important",
    },
    "& .ant-menu-item span:last-child": {
      paddingRight: 0,
    },
  },
});

export default ChannelsFilter;

export const GET_CASES_BY_REGION_REQUEST = "GET_CASES_BY_REGION_REQUEST";
export const GET_CASES_BY_REGION_REQUEST_SUCCESS =
  "GET_CASES_BY_REGION_REQUEST_SUCCESS";
export const GET_CASES_BY_REGION_REQUEST_FAILURE =
  "GET_CASES_BY_REGION_REQUEST_FAILURE";

export const GET_OVERVIEW_SUMMARY_REQUEST = "GET_OVERVIEW_SUMMARY_REQUEST";
export const GET_OVERVIEW_SUMMARY_REQUEST_SUCCESS =
  "GET_OVERVIEW_SUMMARY_REQUEST_SUCCESS";
export const GET_OVERVIEW_SUMMARY_REQUEST_FAILURE =
  "GET_OVERVIEW_SUMMARY_REQUEST_FAILURE";

export const GET_CASES_BY_RISK_FACTOR_REQUEST =
  "GET_CASES_BY_RISK_FACTOR_REQUEST";
export const GET_CASES_BY_RISK_FACTOR_REQUEST_SUCCESS =
  "GET_CASES_BY_RISK_FACTOR_REQUEST_SUCCESS";
export const GET_CASES_BY_RISK_FACTOR_REQUEST_FAILURE =
  "GET_CASES_BY_RISK_FACTOR_REQUEST_FAILURE";

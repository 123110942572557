import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import { Api } from "../../utils/Api";
import { DatePicker, notification } from "antd";
import ResourceTable, { Filter, Sorter } from "../../atoms/ResourceTable";
import * as Constants from "../../utils/Constants";
import ReportDetails from "../Assessment/ReportDetails";
import {
  Status,
  getIsFetchingState,
  getStatusState,
  getErrorMessageState,
  getCurrentAssessmentsState,
  getTotalItemsCountState,
} from "../../redux/ContactTrace/assessmentLogsReducer";
import Tags from "../../atoms/Tags";
import { useUpdateEffect } from "../../hooks/useUpdateEffect";
import { isValidDateRange } from "../../utils";

const formatDate = (date) => moment(date).format("YYYY/MM/DD");

const AssessmentsLog = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isVisible, setIsVisible] = useState(false);
  const [pageSize, setPageSize] = useState(Constants.DATA_LIMIT);
  const [contactDetails, setContactDetails] = useState(null);
  const [filteredDate, setFilteredDate] = useState({
    startDate: null,
    endDate: null,
  });
  const [queryValue, setQueryValue] = useState("");
  const [sortOrder, setSortOrder] = useState(
    Constants.SORT_ORDER.latest.value
  );
  const currentSortName = !!Constants.SORT_ORDER[sortOrder]
    ? Constants.SORT_ORDER[sortOrder].name
    : "";
  const isFetching = useSelector(getIsFetchingState);
  const status = useSelector(getStatusState);
  const errorMessage = useSelector(getErrorMessageState);
  const currentAssessments = useSelector(getCurrentAssessmentsState);
  const totalItems = useSelector(getTotalItemsCountState);
  const { userId } = props;

  useEffect(() => {
    Api.ContactTraceRepository.getAssessmentLogs(
      currentPage,
      pageSize,
      userId,
      queryValue,
      filteredDate,
      sortOrder
    );
    setCurrentPage(1);
  }, [userId, pageSize, queryValue, filteredDate, sortOrder, currentPage]);

  useUpdateEffect(() => {
    if (status === Status.GET_ASSESSMENT_LOGS_REQUEST_FAILURE) {
      notification["error"]({
        message: "Failed to load assessment logs",
        description: errorMessage,
      });
    }
  }, [status]);

  const handleLoadMore = (page) => {
    setCurrentPage(page);
  };

  const handleChangeQuery = (e) => {
    setQueryValue(e.target.value);
  }

  const handleClearQuery = () => {
    setQueryValue("");
  };

  const handleFilteredDateChange = (dates) => {
    if (dates === null || dates === undefined)
      setFilteredDate(
        {
          startDate: null,
          endDate: null,
        }
      );
    else {
      const [startDate, endDate] = dates;
      setFilteredDate(
        {
          startDate,
          endDate,
        }
      );
    }
  };

  const handleFilteredDateRemove = () => {
    setFilteredDate(
      {
        startDate: null,
        endDate: null,
      }
    );
  };

  const handleChangeSortOrder = (value) => {
    setSortOrder(value);
  };

  const disambiguateLabel = (key, value) => {
    if (key === "date") {
      return `Date: ${formatDate(value.startDate)}-${formatDate(
        value.endDate
      )}`;
    }
  };

  const getTagMetaData = (status) => {
    switch (status) {
      case 0:
        return { label: "Not Assessed", type: null };
      case 1:
        return { label: "Low risk", type: "success" };
      case 2:
        return { label: "Medium risk", type: "warning" };
      case 3:
        return { label: "High risk", type: "error" };
      case 4:
      default:
        return { label: "Confirmed", type: "error" };
    }
  };

  const getChannelName = (channel) => {
    switch (channel) {
      case 1:
        return "USSD";
      case 2:
        return "Mobile";
      case 3:
      default:
        return "GloEpid Mobile";
    }
  };

  const columns = () => [
    {
      title: "Date",
      dataIndex: "createdAt",
      render: (date) => moment(date).format("MMM DD, YYYY / hh:mm A"),
    },
    {
      title: "Source",
      dataIndex: "assessmentChannel",
      render: (channel) => getChannelName(channel),
    },
    {
      title: "Result",
      dataIndex: "assessmentResult",
      render: (status) => (
        <Tags
          label={getTagMetaData(status).label}
          type={getTagMetaData(status).type}
        />
      ),
    },
  ];

  const renderFilterDateControl = () => {
    return (
      <div onClick={(e) => e.stopPropagation()}>
        <DatePicker.RangePicker
          value={[filteredDate.startDate, filteredDate.endDate]}
          format="YYYY/MM/DD"
          onChange={handleFilteredDateChange}
        />
      </div>
    );
  };

  const appliedFilters = [];
  if (isValidDateRange(filteredDate)) {
    const key = "date";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, filteredDate),
      onRemove: handleFilteredDateRemove,
    });
  }

  const closeDrawer = () => {
    setIsVisible(false);
  };

  const openDrawer = (record) => {
    setContactDetails(record);
    setIsVisible(true);
  };

  return (
    <>
      <ResourceTable
        resourceName={{ singular: "user", plural: "users" }}
        filterControl={
          <Filter
            filters={[
              {
                key: "date",
                label: "Date",
                filter: renderFilterDateControl(),
              },
            ]}
            appliedFilters={appliedFilters}
            queryValue={queryValue}
            onQueryChange={handleChangeQuery}
            onQueryClear={handleClearQuery}
            onPressEnter={handleChangeQuery}
          />
        }
        sortControl={
          <Sorter
            currentSort={currentSortName}
            numberOfSortedData={totalItems}
            sorters={[
              {
                key: Constants.SORT_ORDER.latest.value,
                name: Constants.SORT_ORDER.latest.name,
                value: Constants.SORT_ORDER.latest.value,
              },
              {
                key: Constants.SORT_ORDER.oldest.value,
                name: Constants.SORT_ORDER.oldest.name,
                value: Constants.SORT_ORDER.oldest.value,
              },
            ]}
            onChange={handleChangeSortOrder}
          />
        }
        tableProps={{
          columns: columns(),
          dataSource: currentAssessments,
          loading: isFetching,
          onRow: (record) => ({
            onClick: () => openDrawer(record),
          }),
          pagination: {
            total: totalItems,
            current: currentPage,
            onChange: handleLoadMore,
            onShowSizeChange: (current, pageSize) => setPageSize(pageSize)
          },
        }}
      />
      <ReportDetails
        isVisible={isVisible}
        onClose={closeDrawer}
        reportDetails={contactDetails && contactDetails}
      />
    </>
  );
};

AssessmentsLog.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default React.memo(AssessmentsLog);

import {
  GET_ACTIVITY_LOGS_REQUEST,
  GET_ACTIVITY_LOGS_REQUEST_SUCCESS,
  GET_ACTIVITY_LOGS_REQUEST_FAILURE,
  RESET_ACTIVITY_LOGS_STORE,
} from "./actionTypes";

const initialState = {
  isFetching: false,
  errorMessage: "",
  status: null,
  pageNo: 0,
  total: 0,
  allLogsByPageNumber: {},
  currentLogs: [],
};

export const Status = {
  GET_ACTIVITY_LOGS_REQUEST: `get_activity_logs_request`,
  GET_ACTIVITY_LOGS_REQUEST_SUCCESS: `get_activity_logs_request_success`,
  GET_ACTIVITY_LOGS_REQUEST_FAILURE: `get_activity_logs_request_failure`,
};

const activityLogsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACTIVITY_LOGS_REQUEST:
      return {
        ...state,
        isFetching: true,
        status: Status.GET_ACTIVITY_LOGS_REQUEST,
        currentLogs: state.allLogsByPageNumber[action.payload.pageNo] || [],
      };
    case GET_ACTIVITY_LOGS_REQUEST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        status: Status.GET_ACTIVITY_LOGS_REQUEST_SUCCESS,
        allLogsByPageNumber: {
          ...state.allLogsByPageNumber,
          [action.payload.pageNo]: action.payload.data,
        },
        currentLogs: action.payload.data,
        pageNo: action.payload.pageNo,
        total: action.payload.total,
      };
    case GET_ACTIVITY_LOGS_REQUEST_FAILURE:
      return {
        ...state,
        isFetching: false,
        status: Status.GET_ACTIVITY_LOGS_REQUEST_FAILURE,
        errorMessage: action.payload,
      };
    case RESET_ACTIVITY_LOGS_STORE:
      return initialState;
    default:
      return state;
  }
};

export const getIsFetchingState = (state) => state.activityLogs.isFetching;
export const getErrorMessageState = (state) => state.activityLogs.errorMessage;
export const getStatusState = (state) => state.activityLogs.status;
export const getTotalItemsCountState = (state) => state.activityLogs.total;
export const getCurrentLogsState = (state) => {
  console.log(state.activityLogs.currentLogs);
  return state.activityLogs.currentLogs;
};

export default activityLogsReducer;

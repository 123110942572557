import React from "react";
import { Card, Divider, Row } from "antd";
import { makeStyles } from "@material-ui/styles";

import { colors, fontWeight, fontSize, boxShadow } from "../../Css";
import Tags from "../../atoms/Tags";
import Banner from "../../atoms/Banner";
import Loader from "../../atoms/Loader";
import { RISK_STATUS } from "../../utils/Constants";

const PatientPersonalInfo = ({ patient, isFetching }) => {
  const classes = useStyles();
  const bodyStyle = { padding: 20 };
  const { address, status, phoneNumber, fullName } = patient;

  const getBannerTitle = () => {
    switch (status) {
      case RISK_STATUS.confirmed:
        return "Confirmed";
      case RISK_STATUS.high:
        return "High risk";
      case RISK_STATUS.medium:
        return "Medium risk";
      case RISK_STATUS.low:
        return "Low risk";
      default:
        return "Low risk";
    }
  };

  const getBannerType = () => {
    switch (status) {
      case RISK_STATUS.confirmed:
        return "error";
      case RISK_STATUS.high:
        return "error";
      case RISK_STATUS.medium:
        return "warning";
      case RISK_STATUS.low:
        return "success";
      default:
        return "info";
    }
  };

  const renderDetails = () => (
    <React.Fragment>
      <div className="section-header">
        <h3 className="title">Contact Information</h3>
      </div>
      <div className="info-group">
        <h5 className="label">Full Name</h5>
        <p className="value">{fullName}</p>
      </div>

      <div className="info-group">
        <h5 className="label">Phone number</h5>
        <p className="value">{`+234${phoneNumber}`}</p>
      </div>

      <div className="info-group">
        <h5 className="label">Address</h5>
        <p className="value">{address}</p>
      </div>
      <Divider />
      <div className="section-header">
        <h3 className="title">Symptoms</h3>
      </div>
      <Row>
        {!!symptomsData && symptomsData.length > 0 ? (
          <>
            {symptomsData.map(item =>
              !!item ? (
                <div key={item.name} className="tag-container">
                  <Tags label={item.name} />
                </div>
              ) : null
            )}
          </>
        ) : (
          <p>There are no symptoms</p>
        )}
      </Row>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {(!isFetching || status) && (
        <Banner
          type={getBannerType()}
          closable={false}
          title={getBannerTitle()}
          withoutContent={true}
        />
      )}
      <Card
        bodyStyle={bodyStyle}
        className={classes.PatientPersonalInfoContainer}
      >
        {isFetching ? <Loader /> : renderDetails()}
      </Card>
      {console.log(patient && patient)}
    </React.Fragment>
  );
};

const useStyles = makeStyles({
  PatientPersonalInfoContainer: {
    boxShadow: boxShadow.card,
    "& p": {
      margin: 0,
      padding: 0
    },
    "& .section-header": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: 20,
      "& .title": {
        fontWeight: fontWeight.medium,
        fontSize: fontSize.large,
        marginBottom: 0
      },
      "& .icon": {
        color: colors.grey,
        fontSize: fontSize.large
      }
    },
    "& .info-group": {
      marginBottom: 20,

      "& .label": {
        color: colors.secondaryBase,
        fontSize: fontSize.small,
        fontWeight: fontWeight.bold,
        marginBottom: 4
      },
      "& .value": {
        color: colors.base
      }
    },
    "& .tag-container": {
      marginBottom: 9
    }
  },
  contentContainer: {
    background: "#F5F6F8"
  }
});

const symptomsData = [
  { name: "short breath" },
  { name: "high temperature" },
  { name: "cough" },
  { name: "vomiting" }
];

export default PatientPersonalInfo;

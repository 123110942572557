import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Card, Row, Col, notification, DatePicker } from "antd";
import { makeStyles } from "@material-ui/styles";

import IllustrationCard from "../../components/Assessment/IllustrationCard";
import StatisticCards from "../../atoms/StatisticCards";
import DemographyBarChart from "../../components/Assessment/DemographyBarChart";
import { colors, fontSize, fontWeight } from "../../Css";
import StatisticCardIcon from "../../assets/images/statistic-card-icon.svg";
import AssessmentFilter from "../../components/Assessment/AssessmentFilter";
import { Api } from "../../utils/Api";
import {
  getStatusState,
  getErrorMessageState,
  getCurrentAssessmentState,
  Status
} from "../../redux/Assessment/assessmentsReducer";
import { formatNumber, fromEntries } from "../../utils";
import { getStatusCode } from "../../utils/index";

import {
  getAssessmentSummaryIsFetchingState,
  getAssessmentSummary,
  getAssessmentSummaryErrorMessageState,
  getSingleFetchingState,
  // getSingleErrorMessageState,
  getSummaryByStatus
} from "../../redux/Assessment/assessmentsSummaryReducer";
import AssessmentTable from "../../components/Assessment/AssessmentTable";

const Assessment = () => {
  const classes = useStyles();
  const [isAssessmentFilterVisible, setIsAssessmentFilterVisible] = useState(
    false
  );
  const [activeAssessmentFilter, setActiveAssessmentFilter] = useState(null);

  const status = useSelector(getStatusState);
  const errorMessage = useSelector(getErrorMessageState);
  const currentAssessment = useSelector(getCurrentAssessmentState);
  const { RangePicker } = DatePicker;

  useEffect(() => {
    Api.AssessmentsRepository.getAssessmentSummary();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (status === Status.GET_CASE_MANAGEMENT_REQUEST_FAILURE) {
      notification["error"]({
        message: "Failed to load cases",
        description: errorMessage
      });
    }
  }, [status, errorMessage]);

  useEffect(() => {
    fetchData({});
    // eslint-disable-next-line
  }, []);

  const isAssessmentSummaryFetching = useSelector(
    getAssessmentSummaryIsFetchingState
  );
  const assessmentSummaryErrorMessage = useSelector(
    getAssessmentSummaryErrorMessageState
  );
  const assessmentSummary = useSelector(getAssessmentSummary);
  const isSingleFetching = useSelector(getSingleFetchingState);
  const singleSummary = useSelector(getSummaryByStatus);

  const fetchData = ({ newPageNo, ...params }) => {
    const finalParams = {
      pageNo: newPageNo || 1,
      ...params,
      currentData: currentAssessment
    };
    const paramsWithoutNullOrUndefined = fromEntries(
      Object.entries(finalParams).filter(
        ([_, value]) =>
          value !== null &&
          value !== undefined &&
          (Boolean(value) || value === 0)
      )
    );
    Api.AssessmentsRepository.getAssessmentsCases(paramsWithoutNullOrUndefined);
  };

  const handleDateSelection = (date, dateString) => {
    let filterParams = {
      startDate: dateString[0],
      endDate: dateString[1]
    };
    Api.AssessmentsRepository.getAssessementsByDemography(filterParams);
  };

  const renderPageMetaData = () => {
    return (
      <div className={classes.pageMetaData}>
        <p>Assessment</p>
      </div>
    );
  };

  const showAssessmentFilter = item => {
    const idForStatus = getStatusCode(item.title);
    setIsAssessmentFilterVisible(true);
    setActiveAssessmentFilter(item.title);
    Api.AssessmentsRepository.getAssessmentSummaryByStatus(idForStatus);
  };

  const hideAssessmentFilter = () => {
    setIsAssessmentFilterVisible(false);
    setActiveAssessmentFilter(null);
  };

  const handleChangeAssessmentFilter = title => {
    const idForStatus = getStatusCode(title);
    Api.AssessmentsRepository.getAssessmentSummaryByStatus(idForStatus);
    setActiveAssessmentFilter(title);
  };

  const renderStatisticCards = () => {
    return (
      <Row gutter={23} className={classes.statisticCardContainer}>
        {sortedAndFilteredAssessmentSummary.map(el => (
          <Col
            key={el.title}
            className="gutter-row column"
            span={6}
            onClick={() => showAssessmentFilter(el)}
          >
            <StatisticCards
              title={el.title}
              caption={`${el.percentage}% of total cases`}
              value={el.count && formatNumber(el.count)}
              type={getStatisticCardColor(el.title)}
              icon={StatisticCardIcon}
              isLoading={
                isAssessmentSummaryFetching || assessmentSummary.length === 0
              }
            />
          </Col>
        ))}
      </Row>
    );
  };

  const rearrangeStatisticCards = data => {
    const priority = [
      "Total Assessments",
      "High Risk",
      "Medium Risk",
      "Low Risk"
    ];
    const newArrangement = [];
    if (data.length === 0) return priority.map(el => ({ title: el }));
    priority.forEach(item => {
      const selection = data.filter(assessment => {
        if (assessment.title === item) return assessment;
        return null;
      });
      selection.length > 0 && newArrangement.push(selection[0]);
      return null;
    });
    return newArrangement;
  };

  const sortedAndFilteredAssessmentSummary = rearrangeStatisticCards(
    assessmentSummary
  );

  const getStatisticCardColor = title => {
    switch (title) {
      case "Total Assessments":
        return "info";
      case "Low Risk":
        return "success";
      case "High Risk":
        return "error";
      case "Medium Risk":
        return "warning";
      case "Confirmed":
      case "Not Assessed":
      case "Discharged":
        return "basic";
      default:
        break;
    }
  };

  return (
    <div className={classes.container}>
      {renderPageMetaData()}
      {renderStatisticCards()}
      <IllustrationCard />
      <Card className="demograph-container">
        <div className={classes.barchartHeading}>
          <h4 className={classes.heading}>Age distribution of assessment</h4>
          <RangePicker onChange={handleDateSelection} />
        </div>
        <div className="barchart-container">
          <DemographyBarChart />
        </div>
      </Card>
      <AssessmentTable fetchData={fetchData} />
      <AssessmentFilter
        visible={isAssessmentFilterVisible}
        filters={
          sortedAndFilteredAssessmentSummary &&
          sortedAndFilteredAssessmentSummary
        }
        activeFilter={activeAssessmentFilter}
        onChangeFilter={handleChangeAssessmentFilter}
        data={singleSummary && singleSummary}
        isLoading={isSingleFetching}
        channels={singleSummary && singleSummary}
        onClose={hideAssessmentFilter}
        isFailure={
          assessmentSummaryErrorMessage && assessmentSummaryErrorMessage
        }
      />
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    padding: 32,
    "& .demograph-container": {
      marginTop: 24
    },
    "& .assessment-table": {
      marginTop: 24
    }
  },
  heading: {
    color: colors.base,
    fontSize: fontSize.large,
    fontWeight: fontWeight.medium
  },
  barchartHeading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  pageMetaData: {
    marginBottom: 21,
    "& p:first-child": {
      margin: 0,
      color: colors.base,
      fontWeight: fontWeight.medium,
      fontSize: fontSize.title
    },
    "& p:last-child": {
      margin: 0,
      color: colors.secondaryBase,
      fontSize: fontSize.xxlarge
    }
  },
  statisticCardContainer: {
    flexWrap: "nowrap !important",
    overflowX: "auto",
    "& .column": {
      cursor: "pointer"
    }
  }
});

export default React.memo(Assessment);

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Card, Tabs, Row, Col, Button, notification } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

import HeadingWithBreadcrumb from "../../atoms/HeadingWithBreadcrumb";
import Notification from "../../components/Notification/Notification";
import PatientPersonalInfo from "../../components/ContactTrace/PatientPersonalInfo";
import { colors, boxShadow } from "../../Css";
import { Api } from "../../utils/Api";
import {
  Status,
  getStatusState,
  getErrorMessageState,
  getPatientDataState,
  getIsFetchingState
} from "../../redux/ContactTrace/PatientInfoReducer";
import history from "../../routes/history";
import { PrivatePaths } from "../../routes";

import ExportContactList from "./ExportContactList";
import ActivityLogs from "../../components/ContactTrace/ActivityLogs";
import AssessmentsLog from "../../components/ContactTrace/AssessmentsLog";

const { TabPane } = Tabs;

const TABS = {
  activity: { name: "Activity Logs", value: "activity" },
  assessment: { name: "Assessment Logs", value: "assessment" },
  notification: { name: "Notification", value: "notification" }
};

const ContactDetails = ({ match }) => {
  const {
    params: { caseId }
  } = match;
  const classes = useStyles();
  const [
    patientDetailCsvDrawerVisible,
    setPatientDetailCsvDrawerVisible
  ] = useState(false);
  const status = useSelector(getStatusState);
  const errorMessage = useSelector(getErrorMessageState);
  const patientData = useSelector(getPatientDataState);
  const isFetching = useSelector(getIsFetchingState);
  // const [isConfirmCaseDrawerOpen, setIsConfirmCaseDrawerOpen] = useState(false);
  // const [form] = Form.useForm();

  useEffect(() => {
    Api.ContactTraceRepository.getPatientInfo(caseId);
  }, [caseId]);

  useEffect(() => {
    switch (status) {
      case Status.GET_PATIENT_INFO_REQUEST_FAILURE:
        notification["error"]({
          message: "Failed to load contact information",
          description: errorMessage
        });
        break;

      case Status.CONFIRM_PATIENT_CASE_REQUEST_FAILURE:
        notification["error"]({
          message: "Failed to load contact information",
          description: errorMessage
        });
        break;

      case Status.CONFIRM_PATIENT_CASE_REQUEST_SUCCESS:
        notification["success"]({
          message: "Case confirmed successfully"
        });
        break;
      default:
        break;
      // setIsConfirmCaseDrawerOpen(false);
    }
  }, [status, errorMessage]);

  // const submitConfirmCaseForm = async formValues => {
  //   Api.ContactTraceRepository.confirmContactCase({
  //     userId: patientData.id,
  //     ...formValues
  //   });
  // };

  const renderCreateCaseButton = () => (
    <div style={{ padding: 0, margin: 0 }}>
      <Button
        className={classes.exportContactList}
        icon={<DownloadOutlined />}
        size="small"
        onClick={() => setPatientDetailCsvDrawerVisible(true)}
      >
        Export
      </Button>
      <Button
        disabled={isFetching}
        type="primary"
        onClick={() =>
          history.push({
            pathname: `${PrivatePaths.CASE_MANAGEMENT}/new-case`,
            state: {
              caseDetails: patientData
            }
          })
        }
      >
        Create Case
      </Button>
    </div>
  );

  return (
    <div className={classes.contactDetails} style={{ padding: 32 }}>
      <HeadingWithBreadcrumb
        page="Contact Trace"
        title={patientData?.fullName}
        renderHeaderRight={renderCreateCaseButton}
      />
      <Row gutter={24}>
        <Col span={8}>
          <PatientPersonalInfo patient={patientData} isFetching={isFetching} />
        </Col>
        <Col span={16}>
          <Card bodyStyle={{ padding: 0 }}>
            <Tabs className="tabBar">
              <TabPane
                tab={TABS.activity.name}
                key={TABS.activity.value}
                className="tabContent"
              >
                <ActivityLogs userId={caseId} />
              </TabPane>
              <TabPane
                tab={TABS.assessment.name}
                key={TABS.assessment.value}
                className="tabContent"
              >
                <AssessmentsLog userId={caseId} />
              </TabPane>
              <TabPane
                tab={TABS.notification.name}
                key={TABS.notification.value}
                className="tabContent"
              >
                <Notification caseId={caseId} />
              </TabPane>
            </Tabs>
          </Card>
        </Col>
      </Row>
      <ExportContactList
        isVisible={patientDetailCsvDrawerVisible}
        onClose={() => setPatientDetailCsvDrawerVisible(false)}
        sourceContactIds={caseId}
      />
    </div>
  );
};

const useStyles = makeStyles({
  userDetails: {
    backgroundColor: colors.white,
    boxShadow: boxShadow.card,
    padding: `0 !important`,
    "& .ant-card-body": {
      padding: `0 !important`
    }
  },
  userBody: {
    padding: "20px",
    paddingTop: "24px",
    "& h3": {
      fontSize: "16px",
      color: "#212B36",
      letterSpacing: 0,
      lineHeight: "24px",
      fontWeight: 600
    },
    "& p": {
      fontSize: "14px",
      color: "#212B36",
      letterSpacing: 0,
      lineHeight: "20px"
    },
    "& label": {
      fontSize: "12px",
      color: "#637381",
      letterSpacing: 0,
      lineHeight: "16px",
      fontWeight: 600
    }
  },
  headerPane: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px"
  },
  contactDetails: {
    "& .tabBar": {
      marginBottom: 14,
      "& .ant-tabs-bar": {
        backgroundColor: colors.skyLighter,
        marginBottom: 0,
        padding: "0 17px"
      }
    }
  },
  exportContactList: {
    backgroundColor: "transparent",
    border: "none",
    marginRight: 5,
    boxShadow: "none",
    "&:hover, &:active": {
      backgroundColor: "transparent"
    }
  }
});

export default React.memo(ContactDetails);

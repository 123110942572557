import React from "react";
import PropTypes, { string, node } from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { colors } from "../Css";
import { Button } from "antd";

export const tagsColor = {
  "error-bgColor": colors.errorWeak,
  "success-bgColor": colors.successWeak,
  "warning-bgColor": colors.warningWeak,
  "info-bgColor": colors.infoWeak,

  "error-labelColor": colors.errorWeakText,
  "success-labelColor": colors.successWeakText,
  "warning-labelColor": colors.warningWeakText,
  "info-labelColor": colors.base,
};

const Tags = ({ type, label, rounded, btnAction, icon, width }) => {
  const classes = useStyles();

  if (rounded) {
    return (
      <div
        className={classes.container}
        style={{
          background: tagsColor[`${type}-bgColor`],
          width: width ? width : "auto",
        }}
      >
        <span
          className={classes.label}
          style={{ color: tagsColor[`${type}-labelColor`] }}
        >
          {label}
        </span>
      </div>
    );
  }

  return (
    <span
      className={classes.filterTag}
      style={{
        backgroundColor: tagsColor[`${type}-bgColor`],
        color: tagsColor[`${type}-labelColor`],
        width: width ? width : "auto",
      }}
    >
      <span className={classes.filterTagText}>{label}</span>
      <Button
        className={classes.filterTagButton}
        onClick={btnAction}
        icon={icon}
      />
    </span>
  );
};

Tags.defaultProps = {
  rounded: true,
};

Tags.propTypes = {
  label: PropTypes.oneOfType([string, node]).isRequired,
  type: PropTypes.oneOf(["error", "success", "warning", "info", "default"]),
};

const useStyles = makeStyles({
  container: {
    display: "inline-flex",
    borderRadius: 20,
    border: `2px solid ${colors.white}`,
    alignItems: "center",
    padding: "0 10px",
    justifyContent: "center",
    background: colors.seperator,
  },
  label: {
    fontSize: 13,
    textTransform: "capitalize",
    color: "#454F5B",
  },
  filterTag: {
    marginRight: "0.8rem",
    marginBottom: "0.8rem",
    display: "inline-flex",
    maxWidth: "100%",
    alignItems: "center",
    height: "2.8rem",
    paddingLeft: ".8rem",
    borderRadius: 3,
    background: colors.seperator,
    textTransform: "capitalize",
  },
  filterTagText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontSize: 13,
  },
  filterTagButton: {
    height: "inherit",
    background: "transparent",
    borderLeft: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    "&:hover": {
      background: "transparent",
      border: "none",
      color: "unset",
    },
    "&:focus": {
      background: "transparent",
    },
  },
});

export default React.memo(Tags);

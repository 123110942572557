import {
  GET_STAFF_REQUEST,
  GET_STAFF_REQUEST_FAILURE,
  GET_STAFF_REQUEST_SUCCESS,
  ADD_STAFF_REQUEST,
  ADD_STAFF_REQUEST_SUCCESS,
  ADD_STAFF_REQUEST_FAILURE,
  TOGGLE_STAFF_STATUS_REQUEST,
  TOGGLE_STAFF_STATUS_REQUEST_SUCCESS,
  TOGGLE_STAFF_STATUS_REQUEST_FAILURE
} from "./actionTypes";

const initialState = {
  isFetching: false,
  toggleStatusLoading: false,
  addStaffLoading: false,
  errorMessage: "",
  staff: [],
  status: null,
  pageNo: 0,
  total: 0,
  allStaffByPageNumber: {},
  currentStaff: [], //visible staff data
  clearForm: false
};

export const Status = {
  GET_STAFF_REQUEST: "get_staff_request",
  GET_STAFF_REQUEST_SUCCESS: "get_staff_request_success",
  GET_STAFF_REQUEST_FAILURE: "get_staff_request_failure",
  ADD_STAFF_REQUEST: "add_staff_request",
  ADD_STAFF_REQUEST_SUCCESS: "add_staff_request_success",
  ADD_STAFF_REQUEST_FAILURE: "add_staff_request_failure",
  TOGGLE_STAFF_STATUS_REQUEST: "toggle_staff_status_request",
  TOGGLE_STAFF_STATUS_REQUEST_SUCCESS: "toggle_staff_status_request_success",
  TOGGLE_STAFF_STATUS_REQUEST_FAILURE: "toggle_staff_status_request_failure"
};

const staffDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STAFF_REQUEST:
      return {
        ...state,
        isFetching: true,
        status: Status.GET_STAFF_REQUEST,
        currentStaff: action.payload.currentData
      };

    case GET_STAFF_REQUEST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        currentStaff: action.payload.data,
        allStaffByPageNumber: {
          ...state.allStaffByPageNumber,
          [action.payload.pageNo]: action.payload.data
        },
        status: Status.GET_STAFF_REQUEST_SUCCESS,
        pageNo: action.payload.pageNo,
        total: action.payload.total
      };

    case GET_STAFF_REQUEST_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
        status: Status.GET_STAFF_REQUEST_FAILURE
      };

    case TOGGLE_STAFF_STATUS_REQUEST:
      return {
        ...state,
        toggleStatusLoading: true,
        status: Status.TOGGLE_STAFF_STATUS_REQUEST
      };

    case TOGGLE_STAFF_STATUS_REQUEST_SUCCESS:
      return {
        ...state,
        toggleStatusLoading: false,
        status: Status.TOGGLE_STAFF_STATUS_REQUEST_SUCCESS
      };

    case TOGGLE_STAFF_STATUS_REQUEST_FAILURE:
      return {
        ...state,
        toggleStatusLoading: false,
        errorMessage: action.payload,
        status: Status.TOGGLE_STAFF_STATUS_REQUEST_FAILURE
      };

    case ADD_STAFF_REQUEST:
      return {
        ...state,
        isFetching: true
      };

    case ADD_STAFF_REQUEST_SUCCESS:
      return {
        ...state,
        isFetching: false
      };

    case ADD_STAFF_REQUEST_FAILURE:
      return {
        ...state,
        isFetching: false
      };

    default:
      return state;
  }
};

export const getStaffDataIsFetchingState = state => state.staffData.isFetching;
export const getStaffDataAddStaffLoadingState = state =>
  state.staffData.addStaffLoading;
export const getStaffDataToggleStatusLoadingState = state =>
  state.staffData.toggleStatusLoading;
export const getStaffDataErrorMessageState = state =>
  state.staffData.errorMessage;
export const getCurrentPageNoState = state => state.staffData.pageNo;
export const getTotalItemsCountState = state => state.staffData.total;
export const getStaffDataStatusState = state => state.staffData.status;
export const getStaffDataState = state => state.staffData.currentStaff;
export const getStaffDataFormFieldsState = state => state.staffData.clearForm;

export default staffDataReducer;

import {
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_REQUEST_SUCCESS,
  RESET_PASSWORD_REQUEST_FAILURE,
} from "./actionTypes";

const initialState = {
  isFetching: false,
  errorMessage: "",
  status: null,
};

export const Status = {
  RESET_PASSWORD_REQUEST_SUCCESS: `reset_password_request_success`,
  RESET_PASSWORD_REQUEST_FAILURE: `reset_password_request_failure`,
};

const resetPasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case RESET_PASSWORD_REQUEST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        status: Status.RESET_PASSWORD_REQUEST_SUCCESS,
      };
    case RESET_PASSWORD_REQUEST_FAILURE:
      return {
        ...state,
        isFetching: false,
        status: Status.RESET_PASSWORD_REQUEST_FAILURE,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export const getIsFetchingState = (state) => state.resetPassword.isFetching;
export const getErrorMessageState = (state) => state.resetPassword.errorMessage;
export const getStatusState = (state) => state.resetPassword.status;

export default resetPasswordReducer;

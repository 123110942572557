/** @format */
import axios from "axios";
import {
  UPDATE_STAFF_PROFILE,
  UPDATE_STAFF_PROFILE_SUCCESS,
  UPDATE_STAFF_PROFILE_FAILURE,
  GET_STAFF_ROLE_REQUEST,
  GET_STAFF_ROLE_REQUEST_FAILURE,
  GET_STAFF_ROLE_REQUEST_SUCCESS,
  GET_STAFF_REQUEST,
  GET_STAFF_REQUEST_FAILURE,
  GET_STAFF_REQUEST_SUCCESS,
  ADD_STAFF_REQUEST,
  ADD_STAFF_REQUEST_FAILURE,
  ADD_STAFF_REQUEST_SUCCESS,
  UPLOAD_STAFF_AVATAR_REQUEST,
  UPLOAD_STAFF_AVATAR_REQUEST_SUCCESS,
  UPLOAD_STAFF_AVATAR_REQUEST_FAILURE,
  TOGGLE_STAFF_STATUS_REQUEST,
  TOGGLE_STAFF_STATUS_REQUEST_SUCCESS,
  TOGGLE_STAFF_STATUS_REQUEST_FAILURE,
  RESET_STAFF_PASSWORD_REQUEST,
  RESET_STAFF_PASSWORD_REQUEST_SUCCESS,
  RESET_STAFF_PASSWORD_REQUEST_FAILURE,
  CHANGE_STAFF_ROLE_REQUEST,
  CHANGE_STAFF_ROLE_REQUEST_SUCCESS,
  CHANGE_STAFF_ROLE_REQUEST_FAILURE
} from "../redux/Staffs/actionTypes";
import {
  getStaffDataState,
  getCurrentPageNoState
} from "../redux/Staffs/staffDataReducer";
import store from "../redux/store";
import Auth from "../utils/Auth";
import { notification } from "antd";
import { SET_CURRENT_USER } from "../redux/Authentication/actionTypes";

function StaffRepository(axiosInstance) {
  // const CancelTokenForGetById = axios.CancelToken;
  // let cancelForGetById = null;

  const CancelTokenForUpdateStaff = axios.CancelToken;
  let cancelForUpdateStaff = null;
  const CancelTokenForStaffData = axios.CancelToken;
  const CancelTokenForToggleStaffStatus = axios.CancelToken;

  const CancelTokenForStaffAvatarUpload = axios.CancelToken;
  let cancelForStaffAvatarUpload = null;

  let cancelTokenForStaffData = null;
  // eslint-disable-next-line
  let cancelTokenForToggleStaffStatus = null;

  let CancelTokenForResetStaffPassword = axios.CancelToken;
  let cancelForResetStaffPassword = null;

  let _StaffRepository = {
    getStaffData: ({ pageNo, currentData, ...filterParams }) => {
      store.dispatch({
        type: GET_STAFF_REQUEST,
        payload: {
          pageNo,
          currentData
        }
      });

      cancelTokenForStaffData && cancelTokenForStaffData();

      return axiosInstance
        .get("staff", {
          cancelToken: new CancelTokenForStaffData(function executor(c) {
            cancelTokenForStaffData = c;
          }),
          params: { pageNo, ...filterParams }
        })
        .then(response => {
          store.dispatch({
            type: GET_STAFF_REQUEST_SUCCESS,
            payload: {
              pageNo,
              total: response.data.total,
              data: response.data.data
            }
          });
        })
        .catch(error => {
          store.dispatch({
            type: GET_STAFF_REQUEST_FAILURE,
            payload: error.message
          });
        });
    },

    getStaffRoles: () => {
      store.dispatch({ type: GET_STAFF_ROLE_REQUEST });
      axiosInstance
        .get("staff/roles")
        .then(response => {
          store.dispatch({
            type: GET_STAFF_ROLE_REQUEST_SUCCESS,
            payload: response.data.data
          });
        })
        .catch(err => store.dispatch({ type: GET_STAFF_ROLE_REQUEST_FAILURE }));
    },

    addStaffInviteUser: (request, onClose) => {
      store.dispatch({ type: ADD_STAFF_REQUEST });
      axiosInstance
        .post("staff/CreateNewUser", request)
        .then(response => {
          store.dispatch({ type: ADD_STAFF_REQUEST_SUCCESS });
          notification.success({
            message: "Success",
            description: response.data.message
          });
          const state = store.getState();
          _StaffRepository.getStaffData({
            pageNo: getCurrentPageNoState(state),
            currentData: getStaffDataState(state)
          });
          onClose && onClose();
        })
        .catch(err => store.dispatch({ type: ADD_STAFF_REQUEST_FAILURE }));
    },

    updateStaffProfile: data => {
      store.dispatch({ type: UPDATE_STAFF_PROFILE });
      cancelForUpdateStaff && cancelForUpdateStaff();
      return axiosInstance
        .put(`/Staff`, data, {
          cancelToken: new CancelTokenForUpdateStaff(function executor(c) {
            cancelForUpdateStaff = c;
          })
        })
        .then(res => {
          store.dispatch({
            type: UPDATE_STAFF_PROFILE_SUCCESS,
            payload: res.data.data
          });
          store.dispatch({
            type: SET_CURRENT_USER,
            payload: res.data.data
          });
          Auth.setCurrentUser(res.data.data);
        })
        .catch(err => {
          store.dispatch({
            type: UPDATE_STAFF_PROFILE_FAILURE,
            payload: err.message
          });
        });
    },

    uploadStaffAvatar: data => {
      store.dispatch({ type: UPLOAD_STAFF_AVATAR_REQUEST });
      cancelForStaffAvatarUpload && cancelForStaffAvatarUpload();
      return axiosInstance
        .post(`/Staff/UploadPicture`, data, {
          cancelToken: new CancelTokenForStaffAvatarUpload(function executor(
            c
          ) {
            cancelForStaffAvatarUpload = c;
          })
        })
        .then(res => {
          store.dispatch({
            type: UPLOAD_STAFF_AVATAR_REQUEST_SUCCESS,
            payload: res.data.data
          });
        })
        .catch(error => {
          store.dispatch({
            type: UPLOAD_STAFF_AVATAR_REQUEST_FAILURE
          });
        });
    },

    toggleStaffStatus: staffId => {
      store.dispatch({ type: TOGGLE_STAFF_STATUS_REQUEST });
      return axiosInstance
        .get(`staff/toggleactivate/${staffId}`, {
          cancelToken: new CancelTokenForToggleStaffStatus(function executor(
            c
          ) {
            cancelTokenForToggleStaffStatus = c;
          })
        })
        .then(response => {
          const { success, message } = response.data;
          if (success) {
            const state = store.getState();
            _StaffRepository
              .getStaffData({
                pageNo: getCurrentPageNoState(state),
                currentData: getStaffDataState(state)
              })
              .then(() => {
                store.dispatch({
                  type: TOGGLE_STAFF_STATUS_REQUEST_SUCCESS,
                  payload: staffId
                });
              });
          } else {
            store.dispatch({
              type: TOGGLE_STAFF_STATUS_REQUEST_FAILURE,
              payload: message
            });
          }
        })
        .catch(error => {
          store.dispatch({
            type: TOGGLE_STAFF_STATUS_REQUEST_FAILURE,
            payload: error.message
          });
        });
    },

    resetStaffPassword: id => {
      store.dispatch({ type: RESET_STAFF_PASSWORD_REQUEST, payload: true });
      cancelForResetStaffPassword && cancelForResetStaffPassword();
      axiosInstance
        .post(`auth/user/${id}/resetpassword`, {
          cancelToken: new CancelTokenForResetStaffPassword(function executor(
            c
          ) {
            cancelForResetStaffPassword = c;
          })
        })
        .then(response => {
          store.dispatch({
            type: RESET_STAFF_PASSWORD_REQUEST_SUCCESS,
            payload: response.data.data
          });
        })
        .catch(error => {
          if (!!axios.isCancel(error) === false) {
            store.dispatch({
              type: RESET_STAFF_PASSWORD_REQUEST_FAILURE,
              payload:
                !!error.response === false
                  ? error.message
                  : error.response.data.message
            });
          }
        });
    },
    changeStaffRole: (id, roleKey) => {
      store.dispatch({ type: CHANGE_STAFF_ROLE_REQUEST, payload: true });
      cancelForResetStaffPassword && cancelForResetStaffPassword();
      axiosInstance
        .post(
          `auth/user/changerole`,
          { id: id, roleKey: roleKey },
          {
            cancelToken: new CancelTokenForResetStaffPassword(function executor(
              c
            ) {
              cancelForResetStaffPassword = c;
            })
          }
        )
        .then(response => {
          store.dispatch({
            type: CHANGE_STAFF_ROLE_REQUEST_SUCCESS,
            payload: response.data.data
          });
        })
        .catch(error => {
          if (!!axios.isCancel(error) === false) {
            store.dispatch({
              type: CHANGE_STAFF_ROLE_REQUEST_FAILURE,
              payload:
                !!error.response === false
                  ? error.message
                  : error.response.data.message
            });
          }
        });
    }
  };

  return _StaffRepository;
}

export { StaffRepository };

import {
  GET_SINGLE_CASE_REQUEST,
  GET_SINGLE_CASE_REQUEST_SUCCESS,
  GET_SINGLE_CASE_REQUEST_FAILURE,
  RESET_SINGLE_CASE_STORE,
} from "./actionTypes";

const initialState = {
  isFetching: true,
  errorMessage: "",
  status: null,
  caseDetails: {},
};

export const Status = {
  GET_SINGLE_CASE_REQUEST: `get_single_case_request`,
  GET_SINGLE_CASE_REQUEST_SUCCESS: `get_single_case_request_success`,
  GET_SINGLE_CASE_REQUEST_FAILURE: `get_single_case_request_failure`,
};

const caseDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SINGLE_CASE_REQUEST:
      return {
        ...state,
        isFetching: true,
        status: Status.GET_SINGLE_CASE_REQUEST,
        errorMessage: "",
        caseDetails: {},
      };
    case GET_SINGLE_CASE_REQUEST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        status: Status.GET_SINGLE_CASE_REQUEST_SUCCESS,
        caseDetails: action.payload,
      };
    case GET_SINGLE_CASE_REQUEST_FAILURE:
      return {
        ...state,
        isFetching: false,
        status: Status.GET_SINGLE_CASE_REQUEST_FAILURE,
        errorMessage: action.payload,
      };
    case RESET_SINGLE_CASE_STORE:
      return { ...initialState };
    default:
      return state;
  }
};

export const getIsFetchingState = (state) => state.caseDetails.isFetching;
export const getErrorMessageState = (state) => state.caseDetails.errorMessage;
export const getStatusState = (state) => state.caseDetails.status;
export const getCaseDetailsState = (state) => state.caseDetails.caseDetails;

export default caseDetailsReducer;

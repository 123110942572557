/**
 * Upsert is a create | edit operation, so this reducer should contain create/edit staff
 * if it still follows that convention
 */
import {
    CHANGE_STAFF_ROLE_REQUEST,
    CHANGE_STAFF_ROLE_REQUEST_SUCCESS,
    CHANGE_STAFF_ROLE_REQUEST_FAILURE,
  } from "./actionTypes";
  
  const initialState = {
    status: null,
    isFetching: false,
    errorMessage: "",
  };
  
  export const Status = {
    CHANGE_STAFF_ROLE_REQUEST: `change_staff_role_request`,
    CHANGE_STAFF_ROLE_REQUEST_SUCCESS: `change_staff_role_request_success`,
    CHANGE_STAFF_ROLE_REQUEST_FAILURE: `change_staff_role_request_failure`,
  };
  
  const updateStaffRoleReducer = (state = initialState, action) => {
    switch (action.type) {
      case CHANGE_STAFF_ROLE_REQUEST:
        return {
          ...state,
          status: Status.CHANGE_STAFF_ROLE_REQUEST,
          isFetching: true,
          errorMessage: "",
        };
      case CHANGE_STAFF_ROLE_REQUEST_SUCCESS:
        return {
          ...state,
          isFetching: false,
          errorMessage: "",
          status: Status.CHANGE_STAFF_ROLE_REQUEST_SUCCESS,
        };
      case CHANGE_STAFF_ROLE_REQUEST_FAILURE:
        return {
          ...state,
          isFetching: false,
          status: Status.CHANGE_STAFF_ROLE_REQUEST_FAILURE,
          errorMessage: action.payload,
        };
      default:
        return state;
    }
  };
  
  export const updateStaffRoleStatusState = (state) => state.updateStaffRole.status;
  export const updateStaffRoleErrorMessageState = (state) => state.updateStaffRole.errorMessage;
  export const updateStaffRoleIsFetchingState = (state) => state.updateStaffRole.isFetching;
  
  export default updateStaffRoleReducer;
  
/** @format */
import {
  GET_PARTNER_CHANNELS_REQUEST,
  GET_PARTNER_CHANNELS_REQUEST_SUCCESS,
  GET_PARTNER_CHANNELS_REQUEST_FAILURE,
  RESET_PARTNER_CHANNELS_STORE,
  GET_PARTNERS_REQUEST,
  GET_PARTNERS_REQUEST_SUCCESS,
  GET_PARTNERS_REQUEST_FAILURE,
  GET_PARTNERS_STATS_REQUEST,
  GET_PARTNERS_STATS_REQUEST_SUCCESS,
  GET_PARTNERS_STATS_REQUEST_FAILURE,
  ADD_PARTNER_REQUEST,
  ADD_PARTNER_REQUEST_SUCCESS,
  ADD_PARTNER_REQUEST_FAILURE,
  EDIT_PARTNER_REQUEST,
  EDIT_PARTNER_REQUEST_SUCCESS,
  EDIT_PARTNER_REQUEST_FAILURE,
  REGENERATE_TOKEN_REQUEST,
  REGENERATE_TOKEN_REQUEST_SUCCESS,
  REGENERATE_TOKEN_REQUEST_FAILURE,
  TOGGLE_TOKEN_STATUS_REQUEST,
  TOGGLE_TOKEN_STATUS_REQUEST_SUCCESS,
  TOGGLE_TOKEN_STATUS_REQUEST_ERROR,
} from "../redux/Partners/actionTypes";
import {
  getPartnersDataState,
  getCurrentPageNoState,
} from "../redux/Partners/partnersDataReducer";
import store from "../redux/store";
import axios from "axios";

function PartnersRepository(axiosInstance) {
  let CancelTokenForGetPartnerChannels = axios.CancelToken;
  let CancelTokenForGetPartners = axios.CancelToken;
  let CancelTokenForAddPartners = axios.CancelToken;
  let CancelTokenForUpdatePartners = axios.CancelToken;
  let CancelTokenForGetPartnerStat = axios.CancelToken;
  let CancelTokenToggleActivatePartner = axios.CancelToken;
  let CancelTokenForGeneratePartnerToken = axios.CancelToken;
  let cancelForGetPartnerChannels = null;
  let cancelForGetPartner = null;
  let cancelForAddPartner = null;
  let cancelForUpdatePartner = null;
  let cancelForGetPartnerStat = null;
  let cancelForToggleActivatePartner = null;
  let cancelForGeneratePartnerToken = null;

  let _PartnersRepository = {
    getPartners: ({ pageNo, currentData, ...filterParams }) => {
      store.dispatch({
        type: GET_PARTNERS_REQUEST, payload: {
          pageNo,
          currentData,
        },
      });

      cancelForGetPartner && cancelForGetPartner();
      axiosInstance
        .get("/partners", {
          cancelToken: new CancelTokenForGetPartners(function executor(c) {
            cancelForGetPartner = c;
          }),
          params: { pageNo, ...filterParams },
        })
        .then((response) => {
          const { data } = response;
          store.dispatch({
            type: GET_PARTNERS_REQUEST_SUCCESS,
            payload: data,
            pageNo,
            total: response.data.total,
          });
        })
        .catch((err) => {
          if (!!axios.isCancel(err) === false) {
            store.dispatch({
              type: GET_PARTNERS_REQUEST_FAILURE,
              payload: err.message,
            })
          }
        });
    },
    

    addPartner: ({ addPartner, cb }) => {
      store.dispatch({ type: ADD_PARTNER_REQUEST, payload: true });

      cancelForAddPartner && cancelForAddPartner();
      axiosInstance
        .post("/partners", addPartner, {
          cancelToken: new CancelTokenForAddPartners(function executor(c) {
            cancelForAddPartner = c;
          }),
        })
        .then((response) => {
          const { success, message, data } = response.data;
          if (success) {
            const state = store.getState();
            store.dispatch({
              type: ADD_PARTNER_REQUEST_SUCCESS,
              payload: data,
            });
            _PartnersRepository.getPartners({
              pageNo: getCurrentPageNoState(state),
              currentData: getPartnersDataState(state)
            });
            _PartnersRepository.getPartnerStat();
            cb && cb();
          } else {
            store.dispatch({
              type: ADD_PARTNER_REQUEST_FAILURE,
              payload: message,
            });
          }
        })
        .catch((err) => {
          store.dispatch({
            type: ADD_PARTNER_REQUEST_FAILURE,
            payload: err.message,
          });
        });
    },

    updatePartner: ({ updatePartner, cb }) => {
      store.dispatch({ type: EDIT_PARTNER_REQUEST, payload: true });

      cancelForUpdatePartner && cancelForUpdatePartner();
      axiosInstance
        .put("/partners", updatePartner, {
          cancelToken: new CancelTokenForUpdatePartners(function executor(c) {
            cancelForUpdatePartner = c;
          }),
        })
        .then((response) => {
          const { success, message, data } = response.data;
          if (success) {
            store.dispatch({
              type: EDIT_PARTNER_REQUEST_SUCCESS,
              payload: data,
            });
            const state = store.getState();
            _PartnersRepository.getPartners({
              pageNo: getCurrentPageNoState(state),
              currentData: getPartnersDataState(state)
            });
            cb && cb();
          } else {
            store.dispatch({
              type: EDIT_PARTNER_REQUEST_FAILURE,
              payload: message,
            });
          }
        })
        .catch((err) => {
          store.dispatch({
            type: EDIT_PARTNER_REQUEST_FAILURE,
            payload: err.message,
          });
        });
    },

    generatePartnerToken: (partnerId) => {
      store.dispatch({ type: REGENERATE_TOKEN_REQUEST });

      cancelForGeneratePartnerToken && cancelForGeneratePartnerToken();
      axiosInstance
        .post(`/partners/GenerateNewPartnerToken/${partnerId}`, {}, {
          cancelToken: new CancelTokenForGeneratePartnerToken(function executor(c) {
            cancelForGeneratePartnerToken = c;
          }),
        })
        .then((response) => {
          const { success, message, data } = response.data;
          if (success) {
            store.dispatch({
              type: REGENERATE_TOKEN_REQUEST_SUCCESS,
              payload: data.token,
            });
            const state = store.getState();
            _PartnersRepository.getPartners({
              pageNo: getCurrentPageNoState(state),
              currentData: getPartnersDataState(state)
            });

          } else {
            store.dispatch({
              type: REGENERATE_TOKEN_REQUEST_FAILURE,
              payload: message,
            });
          }

        })
        .catch((err) => {
          store.dispatch({
            type: REGENERATE_TOKEN_REQUEST_FAILURE,
            payload: err.message,
          });
        });
    },

    toggleActivatePartner: (partnerId) => {
      store.dispatch({ type: TOGGLE_TOKEN_STATUS_REQUEST });

      cancelForToggleActivatePartner && cancelForToggleActivatePartner();
      axiosInstance
        .put(`/partners/ToggleActivatePartner/${partnerId}`, {}, {
          cancelToken: new CancelTokenToggleActivatePartner(function executor(c) {
            cancelForToggleActivatePartner = c;
          }),
        })
        .then((response) => {
          const state = store.getState();
          store.dispatch({
            type: TOGGLE_TOKEN_STATUS_REQUEST_SUCCESS,
            payload: response.data.data.isActive,
          });
          _PartnersRepository.getPartners({
            pageNo: getCurrentPageNoState(state),
            currentData: getPartnersDataState(state)
          });
          _PartnersRepository.getPartnerStat();
        })
        .catch((err) => {
          store.dispatch({
            type: TOGGLE_TOKEN_STATUS_REQUEST_ERROR,
            payload: err.message,
          });
        });
    },

    getPartnerStat: () => {
      store.dispatch({ type: GET_PARTNERS_STATS_REQUEST });

      cancelForGetPartnerStat && cancelForGetPartnerStat();
      axiosInstance
        .get("partners/Stat", {
          cancelToken: new CancelTokenForGetPartnerStat(function executor(c) {
            cancelForGetPartnerStat = c;
          })
        })
        .then((response) => {
          store.dispatch({
            type: GET_PARTNERS_STATS_REQUEST_SUCCESS,
            payload: response.data.data,
          });
        })
        .catch((err) => {
          if (!!axios.isCancel(err) === false) {
            store.dispatch({
              type: GET_PARTNERS_STATS_REQUEST_FAILURE,
              payload: err.message,
            })
          }
        });
    },

    getPartnerChannels: () => {
      store.dispatch({
        type: GET_PARTNER_CHANNELS_REQUEST,
      });
      cancelForGetPartnerChannels && cancelForGetPartnerChannels();
      axiosInstance
        .get("/partners/channels", {
          cancelToken: new CancelTokenForGetPartnerChannels(function executor(
            c
          ) {
            cancelForGetPartnerChannels = c;
          }),
        })
        .then((response) => {
          const { success, message, data } = response.data;
          if (success) {
            store.dispatch({
              type: GET_PARTNER_CHANNELS_REQUEST_SUCCESS,
              payload: data,
            });
          } else {
            store.dispatch({
              type: GET_PARTNER_CHANNELS_REQUEST_FAILURE,
              payload: message,
            });
          }
        })
        .catch((error) => {
          if (axios.isCancel(error)) return;
          store.dispatch({
            type: GET_PARTNER_CHANNELS_REQUEST_FAILURE,
            payload: error.message,
          });
        });
    },

    resetPartnerChannelsStore: function () {
      store.dispatch({
        type: RESET_PARTNER_CHANNELS_STORE,
      });
    },
  };

  return _PartnersRepository;
}

export { PartnersRepository };

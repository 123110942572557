 import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Tabs,
  Button,
  Input,
  Modal,
  Form,
  Checkbox,
  Card,
  notification,
  Select,
} from 'antd';
import Drawer from '../../atoms/Drawer';
import { Prompt } from 'react-router-dom';
import { colors, fontSize, fontWeight } from '../../Css';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import {
  Status,
  getIsFetchingState,
  getIsSendingState,
  getStatusState,
  getErrorMessageState,
  getAllContactsSelectedState,
  getContactsState,
  getSelectedContactsState,
  getSomeContactsSelectedState,
} from '../../redux/Notification/sendNotificationReducer';
import {
  SELECT_CONTACT,
  DESELECT_CONTACT,
  SELECT_ALL_CONTACTS,
  DESELECT_ALL_CONTACTS,
} from '../../redux/Notification/actionTypes';
import store from '../../redux/store';
import Loader from '../../atoms/Loader';
import { Api } from '../../utils/Api';
import {
  getNotificationTypeId,
  DEFAULT_NOTIFICATION_TYPE,
  getAllContactTraceNotificationTypes,
  getAllCaseManagementNotificationTypes,
  getNotificationTypeName,
  NOTIFICATION_VARIANTS,
  NOTIFICATION_CATEGORIES,
} from '../../utils/Notifications';

const TABS = {
  message: { name: 'Message', value: 'message' },
  contacts: { name: 'Contacts', value: 'contacts' },
};

const getInitialValues = () => ({
  message: '',
  notificationType: getNotificationTypeId(DEFAULT_NOTIFICATION_TYPE),
});

const SendNotification = ({
  caseId,
  caseGloEpidUserId,
  variant,
  visible,
  onClose,
}) => {
  const classes = useStyles();
  const [form] = Form.useForm();

  const [activeTab, setActiveTab] = useState(TABS.message.value);
  const [hasMessage, setHasMessage] = useState('');
  const lastFetchedCaseId = useRef(null);

  const isFetching = useSelector(getIsFetchingState);
  const isSending = useSelector(getIsSendingState);
  const status = useSelector(getStatusState);
  const errorMessage = useSelector(getErrorMessageState);
  const allContactsSelected = useSelector(getAllContactsSelectedState);
  const someContactsSelected = useSelector(getSomeContactsSelectedState);
  const contacts = useSelector(getContactsState);
  const selectedContacts = useSelector(getSelectedContactsState);

  const shouldPromptBeforeDismissal = hasMessage;
  const shouldDisallowDismissal = isSending;

  const resetState = () => {
    // Instead of resetting here, which causes us to lose fetched contact/=
    // Simply re-select all contacts, which is the default state
    store.dispatch({
      type: SELECT_ALL_CONTACTS,
    });
    setActiveTab(TABS.message.value);
    form.resetFields();
    setHasMessage(false);
  };

  const closeAndResetState = (reloadTable = false) => {
    onClose(reloadTable);
    resetState();
  };

  const handleClose = () => {
    if (shouldDisallowDismissal) return;
    if (shouldPromptBeforeDismissal) {
      Modal.confirm({
        title: `You have unprocessed changes`,
        icon: <ExclamationCircleOutlined />,
        content: `If you leave now, you may lose your progress`,
        onOk() {
          closeAndResetState();
        },
      });
    } else {
      closeAndResetState();
    }
  };

  const hideFooter = activeTab !== TABS.contacts.value;

  const switchToMessageTab = () => {
    setActiveTab(TABS.message.value);
  };

  const switchToContactsTab = () => {
    setActiveTab(TABS.contacts.value);
  };

  const handleFinish = ({ message, notificationType }) => {
    if (selectedContacts.length === 0) {
      notification['info']({
        message: 'Cannot send to 0 contacts',
        description:
          'You need to select at least one contact to send a notifcatiion to.',
      });
      return;
    }
    // for contact tracing, everybody is a user of the app
    let contactIds = selectedContacts;
    let caseContactIds = [];
    let notificationCategory = NOTIFICATION_CATEGORIES.contact;
    if (variant === NOTIFICATION_VARIANTS.CaseManagement) {
      notificationCategory = NOTIFICATION_CATEGORIES.case;
      /*
       * The contacts of a case may be either be manually created, or, uploaded
       * from the app. So here, we seperate the contacts into those uploaded
       * from the app and those that are manually created.
       */
      contactIds = selectedContacts
        .map((id) => contacts.find((contact) => contact.id === id))
        .filter((contact) => !!contact.gloEpidUserId)
        .map((contact) => contact.gloEpidUserId);
      caseContactIds = selectedContacts
        .map((id) => contacts.find((contact) => contact.id === id))
        .filter((contact) => !contact.gloEpidUserId)
        .map((contact) => contact.id);
    }
    let referenceUserId = caseId;
    let createdCaseId = null;
    if (variant === NOTIFICATION_VARIANTS.CaseManagement) {
      createdCaseId = caseId;
      // If they're a gloepid user, then reference them by their gloEpidUserId
      // otherwise, reference them by their caseId
      referenceUserId = caseGloEpidUserId || caseId;
    }
    Api.NotificationRepository.sendNotification({
      referenceUserId,
      createdCaseId,
      message,
      contactIds,
      caseContactIds,
      notificationType,
      notificationCategory,
    }).then(
      () => {
        notification['success']({
          message: 'Sent successfully',
          description: 'Notification sent to selected contacts',
        });
        // Close the drawer, informing the parent to reload the table
        closeAndResetState(true);
      },
      () => {
        // A no-op so we don't get an unhandled promise rejection
        // The error has already been handled
      }
    );
  };

  const onMessageChange = (event) => {
    const { value } = event.target;
    setHasMessage(!!value);
  };

  const toggleSelectAll = (event) => {
    const { checked } = event.target;
    if (checked) {
      store.dispatch({
        type: SELECT_ALL_CONTACTS,
      });
    } else {
      store.dispatch({
        type: DESELECT_ALL_CONTACTS,
      });
    }
  };

  const toggleContact = (id) => ({ target: { checked } }) => {
    if (checked) {
      store.dispatch({
        type: SELECT_CONTACT,
        payload: id,
      });
    } else {
      store.dispatch({
        type: DESELECT_CONTACT,
        payload: id,
      });
    }
  };

  const numberOfSelectedContacts = selectedContacts.length;
  const totalNumberOfContacts = contacts.length;

  useEffect(() => {
    if (status === Status.GET_CONTACTS_REQUEST_FAILURE)
      notification['error']({
        message: 'Failed to fetch contacts',
        description: errorMessage,
      });
    if (status === Status.SEND_NOTIFICATION_REQUEST_FAILURE)
      notification['error']({
        message: 'Failed to send notification',
        description: errorMessage,
      });
  }, [status, errorMessage]);

  useEffect(() => {
    if (visible && lastFetchedCaseId.current !== caseId) {
      Api.NotificationRepository.getAllContacts(caseId, variant);
      lastFetchedCaseId.current = caseId;
    }
  }, [caseId, visible, variant]);

  const renderContactsTab = () => {
    return (
      <React.Fragment>
        <div style={{ marginBottom: 20 }}>
          <Checkbox
            indeterminate={someContactsSelected}
            checked={allContactsSelected}
            onChange={toggleSelectAll}
          >
            Select all
          </Checkbox>
        </div>
        {contacts.map((contact) => (
          <Card
            key={contact.id}
            className={[
              classes.checkGroupItem,
              selectedContacts.includes(contact.id) ? 'selected' : '',
            ].join(' ')}
            bodyStyle={{ padding: '12px 15px' }}
          >
            <Checkbox
              checked={selectedContacts.includes(contact.id)}
              onChange={toggleContact(contact.id)}
              value={contact.id}
            >
              {contact.fullName}
            </Checkbox>
          </Card>
        ))}
      </React.Fragment>
    );
  };

  return (
    <Drawer
      visible={visible}
      title="Contact Details"
      hideFooter={hideFooter}
      hideCancel={true}
      onClose={handleClose}
      okText="Done"
      onOk={switchToMessageTab}
    >
      <Prompt
        when={shouldPromptBeforeDismissal || shouldDisallowDismissal}
        message={
          !isSending
            ? 'You will lose your selections and progress if you navigate away'
            : 'A notification is currently beiing sent out, navigating away could cause it to fail.'
        }
      />
      {isFetching ? (
        <Loader title="" />
      ) : (
        <div className={classes.tabContainer}>
          <Tabs activeKey={activeTab} onChange={setActiveTab}>
            <Tabs.TabPane tab={TABS.message.name} key={TABS.message.value}>
              <Form
                initialValues={getInitialValues()}
                layout="vertical"
                form={form}
                onFinish={handleFinish}
              >
                <div className={classes.contactsSummaryBox}>
                  <h5 className="title">
                    Sending to {numberOfSelectedContacts} of{' '}
                    {totalNumberOfContacts} contacts
                  </h5>
                  <Button type="link" onClick={switchToContactsTab}>
                    Edit
                  </Button>
                </div>
                <Form.Item
                  name="message"
                  rules={[
                    {
                      required: true,
                      message: 'Please provide a message to be sent',
                    },
                  ]}
                  label="Message"
                >
                  <Input.TextArea
                    className={classes.textArea}
                    rows={4}
                    onChange={onMessageChange}
                  />
                </Form.Item>
                <Form.Item name="notificationType" label="Notification Type">
                  <Select
                    className={classes.select}
                    placeholder="Select the type of notification to send"
                  >   
                  {variant === NOTIFICATION_VARIANTS.CaseManagement ?
                   (getAllCaseManagementNotificationTypes().map((notificationType) => (                    
                    <Select.Option
                      key={getNotificationTypeId(notificationType)}
                      value={getNotificationTypeId(notificationType)}
                    >
                      {getNotificationTypeName(notificationType)}
                    </Select.Option>
                  ))):

                    (getAllContactTraceNotificationTypes().map((notificationType) => (
                      <Select.Option
                        key={getNotificationTypeId(notificationType)}
                        value={getNotificationTypeId(notificationType)}
                      >
                        {getNotificationTypeName(notificationType)}
                      </Select.Option>
                    )))
                  }
                  </Select>
                </Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className={classes.button}
                  loading={isSending}
                  disabled={isFetching}
                >
                  Send
                </Button>
              </Form>
            </Tabs.TabPane>
            <Tabs.TabPane tab={TABS.contacts.name} key={TABS.contacts.value}>
              {renderContactsTab()}
            </Tabs.TabPane>
          </Tabs>
        </div>
      )}
    </Drawer>
  );
};

const useStyles = makeStyles({
  tabContainer: {
    margin: '0 -20px',
    '& .ant-tabs-bar': {
      padding: '0 20px',
    },
    '& .ant-tabs-tabpane': {
      padding: '0 20px',
    },
    '& .ant-form-item-label label': {
      fontSize: fontSize.small,
      color: colors.secondaryBase,
      fontWeight: fontWeight.medium,
      lineHeight: 1.33,
    },
  },
  contactsSummaryBox: {
    backgroundColor: colors.skyLighter,
    padding: '15px 20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 30,
    '& .title': {
      color: colors.base,
      fontSize: fontSize.large,
      fontWeight: fontWeight.medium,
      marginBottom: 0,
      lineHeight: 1.5,
    },
  },
  textArea: {
    padding: 11,
  },
  select: {
    marginBottom: 25,
  },
  button: {
    display: 'block',
    marginLeft: 'auto',
  },
  checkGroupItem: {
    display: 'block',
    backgroundColor: colors.skyLighter,
    width: '100%',
    marginBottom: 9,
    '&.selected': {
      border: `1px solid ${colors.primary}`,
    },
  },
});

export default SendNotification;

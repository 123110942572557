import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { CloseOutlined } from "@ant-design/icons";
import { Input, Row, Radio, Button, notification, Spin } from "antd";

import Drawer from "../../atoms/Drawer";
import { colors, fontSize } from "../../Css";
import { Api } from "../../utils/Api";
// import { getStaffDataFormFieldsState } from "../../redux/Staffs/staffDataReducer";

/**
 * @see https://html.spec.whatwg.org/multipage/input.html#e-mail-state-(type=email)
 */
// eslint-disable-next-line
const W3C_EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

const UsersInput = ({ value, onChange }) => {
  const classes = useStyles();
  // Split the value into items by comma, trim any spacing before and after each item
  // and remove any empty strings
  const tags = value
    .split(",")
    .map(x => x.trim())
    .filter(x => Boolean(x));

  const removeTag = tag => {
    const newValue = tags.filter(x => x !== tag).join(", ");
    onChange({
      target: {
        value: newValue
      }
    });
  };

  return (
    <div className={classes.inputContainer}>
      <Input className={classes.input} onChange={onChange} value={value} />
      <small className={classes.instructionText}>
        Use comma to seperate emails.
      </small>
      <div className={classes.tagList}>
        {tags.map(tag => (
          <span className={classes.tag} key={tag}>
            <span className={classes.tagText}>{tag}</span>
            <Button
              className={`${classes.tagButton} ${classes.tagClearButton}`}
              onClick={() => removeTag(tag)}
              icon={<CloseOutlined style={{ fontSize: fontSize.small }} />}
            />
          </span>
        ))}
      </div>
    </div>
  );
};

const AddStaffDrawer = ({ visible, onClose, roles, addStaffIsFetching }) => {
  const classes = useStyles();
  // const clearFormFields = useSelector(getStaffDataFormFieldsState);
  const [role, setRole] = useState(null);
  const [emails, setEmails] = useState("");
  const emailItems = emails
    .split(",")
    .map(x => x.trim())
    .filter(x => Boolean(x));

  useEffect(() => {
    if (visible === false) {
      setRole(null);
      setEmails("");
    }
  }, [visible]);

  const getRoleColor = role => {
    switch (role) {
      case 0:
        return colors.info;
      case 1:
        return colors.success;
      case 2:
        return colors.warning;
      case 3:
      default:
        return colors.teal;
    }
  };

  const handleClose = () => {
    onClose();

    setRole(null);
  };

  const validateEmails = () =>
    emailItems.reduce(
      (acc, emailItem) => W3C_EMAIL_REGEX.test(emailItem) && acc,
      true
    );

  const handleOk = () => {
    let request = {};
    if (role === null || role === undefined) {
      notification["info"]({
        message: "No role selected",
        description: "You must select a role for the users to be invited"
      });
      return;
    } else if (!emails) {
      notification["info"]({
        message: "No email provided",
        description:
          "You must provide the email of at least one user to be invited"
      });
      return;
    } else if (!validateEmails()) {
      notification["info"]({
        message: "Invalid email",
        description: "One or more of the provided emails are not valid."
      });
      return;
    } else {
      request.roleId = role;
      request.emails = emails.split(",");
      Api.StaffRepository.addStaffInviteUser(request, onClose);
    }
  };

  return (
    <Drawer
      visible={visible}
      onClose={handleClose}
      title="Add New User"
      okText={emailItems.length > 1 ? "Add Users" : "Add User"}
      onOk={handleOk}
    >
      <Spin spinning={addStaffIsFetching}>
        <UsersInput value={emails} onChange={e => setEmails(e.target.value)} />
        {roles.map(({ Key, Value }) => (
          <Row
            className={`${classes.newInviteContainer} ${
              role === Key ? "active" : ""
            }`}
            key={Key}
            onClick={() => setRole(Key)}
          >
            <div className={classes.newInviteFirstItem}>
              <Radio
                name="new-user-role"
                value={Key}
                checked={role === Key}
                onChange={() => setRole(Key)}
              />
              <div
                className={classes.imageItem}
                style={{ background: getRoleColor(Key) }}
              />

              <div className={classes.newInviteNameItem}>
                <div className={classes.name}>{Value}</div>
              </div>
            </div>
          </Row>
        ))}
      </Spin>
    </Drawer>
  );
};

const useStyles = makeStyles({
  newInviteContainer: {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    padding: "0 15px",
    borderStyle: "solid",
    borderColor: colors.lightGrey,
    borderWidth: "3px",
    height: "80px",
    borderRadius: "4px",
    marginBottom: "10px",
    cursor: "pointer",
    "&.active": {
      borderColor: colors.primary
    }
  },

  newInviteFirstItem: {
    display: "flex",
    alignItems: "center"
  },

  newInviteNameItem: {
    lineHeight: "120%",
    paddingLeft: "15px"
  },

  pendingInviteContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    alignItems: "center",
    padding: "0 15px",
    backgroundColor: colors.lightGrey,
    height: "80px",
    borderRadius: "4px",
    marginBottom: "10px",
    "&.invite-active": {
      borderColor: colors.primary,
      borderStyle: "solid",
      borderWidth: "3px"
    }
  },

  pendingInviteFirstItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  imageItem: {
    borderRadius: "50%",
    height: "40px",
    width: "40px",
    background: "yellow"
  },
  nameItem: {
    lineHeight: "120%",
    paddingLeft: "15px"
  },
  tag: {
    marginRight: "0.8rem",
    marginBottom: "0.8rem",
    display: "inline-flex",
    maxWidth: "100%",
    alignItems: "center",
    height: "2.8rem",
    paddingLeft: ".8rem",
    backgroundColor: colors.seperator,
    borderRadius: 3,
    color: colors.base
  },
  tagText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontSize: 13
  },
  tagButton: {
    height: "inherit",
    background: "transparent",
    borderLeft: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      background: "transparent"
    },
    "&:focus": {
      background: "transparent"
    }
  },
  tagClearButton: {
    padding: 0,
    border: 0
  },
  inputContainer: {
    marginBottom: 52
  },
  input: {
    marginBottom: 5
  },
  instructionText: {
    color: colors.darkGrey,
    fontSize: fontSize.small,
    marginBottom: 16,
    display: "block"
  },
  tagList: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap"
  }
});

export default AddStaffDrawer;

import React from "react";
import { Row, Input, Dropdown, Button, Card } from "antd";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  SearchOutlined,
  CaretDownOutlined,
  CloseOutlined,
  CloseCircleFilled,
} from "@ant-design/icons";
import { colors, fontSize, fontWeight } from "../../Css";

/**
 * This is the filter component for the Table.
 * It is a uncontrolled element
 * Its Apis and functionality is quite similar to the shopify Table filter
 * @see https://polaris.shopify.com/components/lists-and-tables/filters#navigation
 */
const Filter = (props) => {
  const {
    filters,
    appliedFilters,
    resourceName,
    queryValue,
    onQueryChange,
    onQueryClear,
    onPressEnter,
    renderCustomFilter,
  } = props;
  const classes = useStyles();

  const renderMenu = (node, resetFilter) => (
    <Card style={{ padding: 8 }}>
      <div className="dropdown-filter-container">{node}</div>
      <span
        className={classes.clearButton}
        onClick={!!resetFilter && resetFilter}
      >
        Clear
      </span>
    </Card>
  );

  const renderFilterTags = () => {
    return (
      <Row display="flex" style={{ paddingTop: 8 }}>
        {appliedFilters.map((appliedFilter) => (
          <span className={classes.filterTag} key={appliedFilter.key}>
            <span className={classes.filterTagText}>{appliedFilter.label}</span>
            <Button
              className={`${classes.filterTagButton} ${classes.filterTagClearButton}`}
              onClick={appliedFilter.onRemove}
              icon={<CloseOutlined style={{ fontSize: fontSize.small }} />}
            />
          </span>
        ))}
      </Row>
    );
  };

  return (
    <>
      <Row className={classes.filter} style={{ flexWrap: "nowrap" }}>
        <Input
          onChange={onQueryChange}
          onPressEnter={onPressEnter}
          value={queryValue}
          size="large"
          placeholder={`Filter ${resourceName.plural}`}
          prefix={<SearchOutlined />}
          suffix={
            <CloseCircleFilled
              style={{ cursor: "pointer" }}
              onClick={onQueryClear}
            />
          }
        />
        {filters.map((filter) => (
          <Dropdown
            placement="bottomRight"
            overlay={renderMenu(filter.filter, filter.resetFilter)}
            key={filter.key}
            trigger={["click"]}
            overlayClassName={classes.filterDropDown}
          >
            <Button className={classes.filterTagButton}>
              {filter.label} <CaretDownOutlined />
            </Button>
          </Dropdown>
        ))}

        {!!renderCustomFilter && renderCustomFilter()}
      </Row>
      {renderFilterTags()}
    </>
  );
};

const useStyles = makeStyles({
  filter: {
    "& .ant-input-prefix": {
      display: "block",
      marginTop: 0,
    },
    "& .ant-input-suffix": {
      display: "block",
      marginTop: 0,
    },
    "& .anticon": {
      color: colors.secondaryBase,
    },
  },
  dropdownButton: {
    borderLeft: 0,
    height: "inherit",
    borderRadius: 0,
  },
  clearButton: {
    color: colors.primary,
    fontWeight: fontWeight.medium,
    fontSize: 13,
    margin: 0,
    marginTop: 10,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  filterTag: {
    marginRight: "0.8rem",
    marginBottom: "0.8rem",
    display: "inline-flex",
    maxWidth: "100%",
    alignItems: "center",
    height: "2.8rem",
    paddingLeft: ".8rem",
    backgroundColor: colors.seperator,
    borderRadius: 3,
    color: colors.base,
  },
  filterTagText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontSize: 13,
  },
  filterTagButton: {
    height: "inherit",
    background: "transparent",
    borderLeft: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      background: "transparent",
    },
    "&:focus": {
      background: "transparent",
    },
  },
  filterTagClearButton: {
    padding: 0,
    border: 0,
  },
  filterDropDown: {
    minWidth: "122px !important",
    "& .ant-dropdown-menu": {
      padding: "16px !important",
    },
    "& .dropdown-filter-container": {
      maxHeight: 200,
      overflow: "scroll",
    },
  },
});

Filter.propTypes = {
  queryValue: PropTypes.string.isRequired,
  onQueryChange: PropTypes.func.isRequired,
  onQueryClear: PropTypes.func.isRequired,
  onPressEnter: PropTypes.func,
  renderCustomFilter: PropTypes.func,
  onClearAll: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      resetFilter: PropTypes.func.isRequired,
      filter: PropTypes.node,
    })
  ).isRequired,
  appliedFilters: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      onRemove: PropTypes.func,
    })
  ),
  resourceName: PropTypes.shape({
    singular: PropTypes.string.isRequired,
    plural: PropTypes.string.isRequired,
  }),
};

export default React.memo(Filter);

import {
  GET_CONTACTS_REQUEST,
  GET_CONTACTS_REQUEST_SUCCESS,
  GET_CONTACTS_REQUEST_FAILURE,
  SEND_NOTIFICATION_REQUEST,
  SEND_NOTIFICATION_REQUEST_SUCCESS,
  SEND_NOTIFICATION_REQUEST_FAILURE,
  SELECT_CONTACT,
  DESELECT_CONTACT,
  SELECT_ALL_CONTACTS,
  DESELECT_ALL_CONTACTS,
  RESET_SEND_NOTIFICATION_STORE,
} from './actionTypes';

export const Status = {
  GET_CONTACTS_REQUEST: `get_contacts_request`,
  GET_CONTACTS_REQUEST_SUCCESS: `get_contacts_request_success`,
  GET_CONTACTS_REQUEST_FAILURE: `get_contacts_request_failure`,
  SEND_NOTIFICATION_REQUEST: `send_notification_request`,
  SEND_NOTIFICATION_REQUEST_SUCCESS: `send_notification_request_success`,
  SEND_NOTIFICATION_REQUEST_FAILURE: `send_notification_request_failure`,
};

const initialState = {
  status: null,
  errorMessage: '',
  contacts: [],
  selectedContacts: [],
};

const sendNotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONTACTS_REQUEST:
      return {
        ...state,
        status: Status.GET_CONTACTS_REQUEST,
        errorMessage: '',
        contacts: [],
        selectedContacts: [],
      };
    case GET_CONTACTS_REQUEST_SUCCESS:
      return {
        ...state,
        status: Status.GET_CONTACTS_REQUEST_SUCCESS,
        contacts: action.payload,
        selectedContacts: action.payload.map((contact) => contact.id),
      };
    case GET_CONTACTS_REQUEST_FAILURE:
      return {
        ...state,
        status: Status.GET_CONTACTS_REQUEST_FAILURE,
        errorMessage: action.payload,
      };
    case SEND_NOTIFICATION_REQUEST:
      return {
        ...state,
        status: Status.SEND_NOTIFICATION_REQUEST,
        errorMessage: '',
      };
    case SEND_NOTIFICATION_REQUEST_SUCCESS:
      return {
        ...state,
        status: Status.SEND_NOTIFICATION_REQUEST_SUCCESS,
      };
    case SEND_NOTIFICATION_REQUEST_FAILURE:
      return {
        ...state,
        status: Status.SEND_NOTIFICATION_REQUEST_FAILURE,
        errorMessage: action.payload,
      };
    case SELECT_CONTACT:
      return {
        ...state,
        selectedContacts: [...state.selectedContacts, action.payload],
      };
    case DESELECT_CONTACT:
      return {
        ...state,
        selectedContacts: state.selectedContacts.filter(
          (contactId) => contactId !== action.payload
        ),
      };
    case SELECT_ALL_CONTACTS:
      return {
        ...state,
        selectedContacts: state.contacts.map((contact) => contact.id),
      };
    case DESELECT_ALL_CONTACTS:
      return {
        ...state,
        selectedContacts: [],
      };
    case RESET_SEND_NOTIFICATION_STORE:
      return { ...initialState };
    default:
      return state;
  }
};

export const getIsFetchingState = (state) =>
  state.sendNotification.status === Status.GET_CONTACTS_REQUEST;
export const getIsSendingState = (state) =>
  state.sendNotification.status === Status.SEND_NOTIFICATION_REQUEST;
export const getStatusState = (state) => state.sendNotification.status;
export const getErrorMessageState = (state) =>
  state.sendNotification.errorMessage;
export const getContactsState = (state) => state.sendNotification.contacts;
export const getSelectedContactsState = (state) =>
  state.sendNotification.selectedContacts;
export const getAllContactsSelectedState = (state) =>
  state.sendNotification.selectedContacts.length ===
    state.sendNotification.contacts.length &&
  state.sendNotification.contacts.length > 0;
export const getSomeContactsSelectedState = (state) =>
  state.sendNotification.selectedContacts.length > 0 &&
  state.sendNotification.selectedContacts.length <
    state.sendNotification.contacts.length;

export default sendNotificationReducer;

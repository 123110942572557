import React, { useEffect, useState } from "react";
import { Api } from "../../utils/Api";
import {
  getErrorMessageState,
  getIsFetchingState,
  getDemographyDataState,
  getStatusState,
  Status
} from "../../redux/Assessment/assessmentsByDemographyReducer";
import { notification, Spin, Empty } from "antd";
import { useSelector } from "react-redux";
import BarChart from "../../atoms/BarChart";

const DemographyBarChart = () => {
  const demographyData = useSelector(getDemographyDataState);
  const isFetching = useSelector(getIsFetchingState);
  const status = useSelector(getStatusState);
  const errorMessage = useSelector(getErrorMessageState);
  const [age_0_19_Data, setAge_0_19_Data] = useState([]);
  const [age_20_39_Data, setAge_20_39_Data] = useState([]);
  const [age_40_59_Data, setAge_40_59_Data] = useState([]);
  const [age_60_above_Data, setAge_60_above_Data] = useState([]);
  const [states, setStates] = useState([]);

  useEffect(() => {
    Api.AssessmentsRepository.getAssessementsByDemography();
  }, []);

  useEffect(() => {
    if (status === Status.GET_STATE_DEMOGRAPHY_DATA_REQUEST_FAILURE) {
      notification.error({
        message: "Something went wrong",
        description: errorMessage
      });
    }
  }, [status, errorMessage]);

  useEffect(() => {
    let _age_0_19 = [];
    let _age_20_39 = [];
    let _age_40_59 = [];
    let _age_60_above = [];
    let _states = [];

    if (demographyData) {
      for (let i = 0; i < demographyData.length; i++) {
        const ageGroupObject = demographyData[i].data;
        _age_0_19.push(parseInt(ageGroupObject["0 - 19"] || 0));
        _age_20_39.push(parseInt(ageGroupObject["20 - 39"] || 0));
        _age_40_59.push(parseInt(ageGroupObject["40 - 59"] || 0));
        _age_60_above.push(parseInt(ageGroupObject["60+"] || 0));
        _states.push(demographyData[i].state);
      }

      for (let i = 0; i < _states.length; i++) {
        if (_states[i] === "Abuja Federal Capital Territory") {
          _states[i] = "Abuja FCT";
        }
      }
      setAge_0_19_Data(_age_0_19);
      setAge_20_39_Data(_age_20_39);
      setAge_40_59_Data(_age_40_59);
      setAge_60_above_Data(_age_60_above);
      setStates(_states);
    }
  }, [demographyData]);

  const series = [
    {
      name: "0 - 19",
      data: age_0_19_Data
    },
    {
      name: "20 - 39",
      data: age_20_39_Data
    },
    {
      name: "40 - 59",
      data: age_40_59_Data
    },
    {
      name: "60+",
      data: age_60_above_Data
    }
  ];

  const options = {
    chart: {
      id: "assessments",
      height: 350,
      stacked: true,
      toolbar: {
        show: false
      }
    },
    xaxis: {
      categories: states.sort(),

      labels: {
        show: true,
        rotate: -45,
        rotateAlways: true
      },
      tickPlacement: "on"
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: 1
    },
    plotOptions: {
      bar: {
        horizontal: false
      }
    },
    legend: {
      position: "bottom",
      offsetX: "90%"
    },
    colors: ["#E3F1DF", "#BBE5B3", "#50B83C", "#108043"],
    tooltip: {
      enabled: true,
      enabledOnSeries: undefined,
      shared: true
    }
  };

  return (
    <div className="BarChart">
      <Spin spinning={isFetching}>
        {demographyData.length ? (
          <BarChart options={options} series={series} />
        ) : (
          <Empty />
        )}
      </Spin>
    </div>
  );
};

export default DemographyBarChart;

import {
  GET_PARTNER_CHANNELS_REQUEST,
  GET_PARTNER_CHANNELS_REQUEST_SUCCESS,
  GET_PARTNER_CHANNELS_REQUEST_FAILURE,
  RESET_PARTNER_CHANNELS_STORE
} from "./actionTypes";

const initialState = {
  status: null,
  errorMessage: "",
  channels: [],
};

export const Status = {
  GET_PARTNER_CHANNELS_REQUEST: `get_partner_channels_request`,
  GET_PARTNER_CHANNELS_REQUEST_SUCCESS: `get_partner_channels_request_success`,
  GET_PARTNER_CHANNELS_REQUEST_FAILURE: `get_partner_channels_request_failure`,
  RESET_PARTNER_CHANNELS_STORE : `get_partner_channels_store`
};

const partnerChannelsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PARTNER_CHANNELS_REQUEST:
      return {
        ...state,
        status: Status.GET_PARTNER_CHANNELS_REQUEST,
        errorMessage: "",
        channels: [],
      };
    case GET_PARTNER_CHANNELS_REQUEST_FAILURE:
      return {
        ...state,
        status: Status.GET_PARTNER_CHANNELS_REQUEST_FAILURE,
        errorMessage: action.payload,
      };
    case GET_PARTNER_CHANNELS_REQUEST_SUCCESS:
      return {
        ...state,
        status: Status.GET_PARTNER_CHANNELS_REQUEST_SUCCESS,
        channels: action.payload,
      };
    case RESET_PARTNER_CHANNELS_STORE:
      return { ...initialState };
    default:
      return state;
  }
};

export const getIsFetchingState = (state) =>
  state.partnerChannels.status === Status.GET_PARTNER_CHANNELS_REQUEST;
export const getStatusState = (state) => state.partnerChannels.status;
export const getErrorMessageState = (state) =>
  state.partnerChannels.errorMessage;
export const getPartnerChannelsState = (state) =>
  state.partnerChannels.channels;

export default partnerChannelsReducer;

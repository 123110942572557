/* This file contains general style configurations e.g color sets, typography sets, etc*/
export const colors = {
  primary: "#138750",
  primaryWeak: "#ECF5F0",
  secondary: "#011429",
  imageBackground: "#FAFAFA",
  white: "#fff",
  base: "#212B36",
  secondaryBase: "#637381",
  success: "#50B83C",
  successWeak: "#BBE5B3",
  successWeakText: "#414F3E",
  error: "#DE3618",
  errorWeak: "#FFC48B",
  errorWeakText: "#594430",
  info: "#0070E0",
  infoWeak: "#B5E0FB",
  warning: "#F49342",
  warningWeak: "#FFEA8A",
  warningWeakText: "#595130",
  darkGrey: "#919EAB",
  lightGrey: "#F5F6F8",
  seperator: "#DFE3E8",
  teal: "#47C1BF",
  tealLighter: "#E0F5F5",
  tealLight: "#B7ECEC",
  grey: "#979797",
  grayishBlue: "#454F5B",
  skyLighter: "#F9FAFB",
  ashGrey: "#D8D8D8",
};

export const fontSize = {
  xxsmall: 8,
  xsmall: 10,
  small: 12,
  medium: 14,
  large: 16,
  xlarge: 18,
  xxlarge: 20,
  title: 28,
};

export const fontWeight = {
  bold: 700,
  medium: 600,
  regular: 400,
  light: 300,
};

export const boxShadow = {
  card: "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,.15)",
};

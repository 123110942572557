import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Form, Button, Input, notification } from "antd";

import AuthenticationLayout from "../components/AuthenticationLayout";
import {
  getIsFetchingState,
  getErrorMessageState,
  getStatusState,
  Status,
} from "../redux/Authentication/resetPasswordReducer";
import { Api } from "../utils/Api";
import { validateEmail } from "../utils/FormUtils";

const ResetPassword = (props) => {
  const [form] = Form.useForm();
  const isfetching = useSelector(getIsFetchingState);
  const errorMessage = useSelector(getErrorMessageState);
  const status = useSelector(getStatusState);

  useEffect(() => {
    if (status === Status.RESET_PASSWORD_REQUEST_FAILURE) {
      notification["error"]({
        message: "An error occurred resseting your password",
        description: errorMessage,
      });
    } else if (status === Status.RESET_PASSWORD_REQUEST_SUCCESS) {
      notification["success"]({
        message: "Password reset was successful, Proceed to login",
      });
    }
  }, [errorMessage, status]);

  const getSearchQueries = () => {
    let params = props.history.location.search;
    let queryObj = {};

    if (params) {
      let newParamsArr = params.split("?");
      params = newParamsArr[newParamsArr.length - 1];

      let queries = params.split("&");
      queries.forEach((searchQuery) => {
        let values = searchQuery.split("=");
        queryObj[values[0]] = values[1];
      });
      return queryObj;
    }

    return queryObj;
  };

  const compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== form.getFieldValue("password")) {
      callback("Confirm password should match Password value");
    } else {
      callback();
    }
  };

  const handleFinish = (values) => {
    const { email, token } = getSearchQueries();
    const { password } = values;
    if (!email || !token) {
      notification["error"]({
        message: "Ensure you click the link in the mail sent to you",
      });
      return;
    }
    Api.AuthRepository.resetPassword({ email, password, token });
  };

  return (
    <AuthenticationLayout
      title="Forgot password"
      subTitle="We advice you use alphanumeric password to increase the security"
      formTitle="Enter new password"
    >
      <Form
        form={form}
        onFinish={handleFinish}
        layout="vertical"
        hideRequiredMark={true}
      >
        <Form.Item
          label="New Password"
          name="password"
          rules={[
            {
              required: true,
              message:
                "Password should contain a number, a capital letter, and minimum of 8 characters.",
            },
            () => ({
              validator(rule, value) {
                if (!value || validateEmail(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  "Password should contain a number, a capital letter, and minimum of 8 characters."
                );
              },
            }),
          ]}
        >
          <Input
            type="password"
            placeholder="enter your new password"
            autoFocus
          />
        </Form.Item>
        <Form.Item
          label="Confirm New Password"
          name="confirmPassword"
          rules={[
            {
              required: true,
              message: "Please input your confirm password!",
            },
            { validator: compareToFirstPassword },
          ]}
        >
          <Input type="password" placeholder="enter your confirm password" />
        </Form.Item>
        <Form.Item>
          <Button
            loading={isfetching}
            type="primary"
            htmlType="submit"
            style={{ width: "100%" }}
          >
            Reset Password
          </Button>
        </Form.Item>
      </Form>
    </AuthenticationLayout>
  );
};

export default React.memo(ResetPassword);

import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Button, Input, Form, Spin, Select, notification } from "antd";
import { makeStyles } from "@material-ui/styles";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSelector } from "react-redux";

import Drawer from "../../atoms/Drawer";
import {
  Status,
  getIsFetchingState,
  getStatusState,
  getErrorMessageState,
  getPartnerChannelsState,
} from "../../redux/Partners/partnerChannelsReducer";
import { colors, fontSize, fontWeight } from "../../Css";
import { Api } from "../../utils/Api";
import { fromEntries } from "../../utils";

const { Option } = Select;

const AddOrEditPartnerModal = (props) => {
  const {
    mode,
    visible,
    onClose,
    isFetchingToken,
    onRequestTokenGeneration,
    token,
    handleSubmit,
    partnerDetails,
    formSubmitting,
    handleShowRevokeModal,
    onTogglePartnerActiveState
  } = props;
  const classes = useStyles();
  const [copyStatus, setCopyStatus] = useState(false);
  const [form] = Form.useForm();

  const isPartnerChannelFetching = useSelector(getIsFetchingState);
  const status = useSelector(getStatusState);
  const errorMessage = useSelector(getErrorMessageState);
  const partnerChannels = useSelector(getPartnerChannelsState);

  const isFetching = isPartnerChannelFetching || isFetchingToken;

  const partnerStatus = partnerDetails && partnerDetails.status;

  const handleOk = () => {
    form.submit();
  };

  const handleGenerateTokenClick = () => {
    setCopyStatus(false);
    onRequestTokenGeneration();
  };

  const getChannelId = useCallback(
    (name) => partnerChannels.find(({ Value }) => Value === name).Key,
    [partnerChannels]
  );

  const handleFinish = (values) => {
    handleSubmit(values, mode);
  };

  useEffect(() => {
    if (status === Status.GET_PARTNER_CHANNELS_REQUEST_FAILURE)
      notification["error"]({
        message: "Failed to fetch partner channels",
        description: errorMessage,
      });
  }, [status, errorMessage]);

  useEffect(() => {
    if (partnerDetails === null) {
      const fields = Object.entries(form.getFieldsValue());
      const emptyState = fromEntries(fields.map(([key, _]) => [key, ""]));
      form.setFieldsValue(emptyState);
    } else
      form.setFieldsValue({
        ...partnerDetails,
        channel: !!partnerDetails.channel
          ? getChannelId(partnerDetails.channel)
          : null,
      });
  }, [partnerDetails, form, getChannelId]);

  useEffect(() => {
    Api.PartnersRepository.getPartnerChannels();
  }, []);

  const onShowRevokeModal = (e) => {
    e.preventDefault();
    handleShowRevokeModal();
  };

  return (
    <Drawer
      visible={visible}
      onClose={onClose}
      title={mode === "edit" ? "Edit Partner" : "Add New Partner"}
      okText="Submit"
      onOk={handleOk}
      isLoading={formSubmitting}
    >
      <Spin spinning={isFetching}>
        <div className={classes.drawerContainer}>
          <p className="p">Partner Details</p>
         
          <Form
            hideRequiredMark
            form={form}
            layout="vertical"
            name="partnerFrom"
            onFinish={handleFinish}
          >
            <Form.Item
              className={classes.formItem}
              name="companyName"
              label="Company Name"
              rules={[
                {
                  required: true,
                  message: "Please specify a company name",
                },
              ]}
            >
              <Input placeholder="Value" />
            </Form.Item>
            <Form.Item
              label="Email Address"
              className={classes.formItem}
              name="emailAddress"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid email",
                },
                { required: true, message: "Please specify an email" },
              ]}
            >
              <Input placeholder="Value" />
            </Form.Item>
            <Form.Item
              label="Phone Number"
              className={classes.formItem}
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Please specify a phone number",
                },
                {
                  pattern: /^\d{11}$/,
                  message: "Please specify a valid phone number",
                },
              ]}
            >
              <Input placeholder="Value" />
            </Form.Item>
            <Form.Item
              name="channel"
              label="Channel"
              className={classes.formItem}
              rules={[{ required: true, message: "Please specify a channel" }]}
            >
              <Select>
                {partnerChannels.map(({ Key, Value }) => (
                  <Option key={Key} value={Key}>
                    {Value}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            {mode === "edit" && (
              <div className={classes.generateContainer}>
                <p className="p">REGENERATE TOKEN</p>
                <hr />
                <Button
                  onClick={handleGenerateTokenClick}
                  style={{ marginTop: "10px" }}
                >
                  Regenerate Token
                </Button>
                <div className="generate-items">
                  <Form.Item name="token">
                    <Input
                      disabled
                      className="p"
                      placeholder={token}
                      suffix={
                        <CopyToClipboard
                          text={token}
                          onCopy={() => setCopyStatus(true)}
                        >
                          <Button>
                            {" "}
                            {copyStatus ? "Copied!" : "Copy Token"}{" "}
                          </Button>
                        </CopyToClipboard>
                      }
                    />
                  </Form.Item>
                  <button
                    onClick={
                      partnerStatus
                        ? onShowRevokeModal
                        : onTogglePartnerActiveState
                    }
                    className="revoke-token"
                  >
                    {partnerStatus ? "Revoke token" : "Reactivate Token"}
                  </button>
                </div>
              </div>
            )}
          </Form>
        </div>
      </Spin>
    </Drawer>
  );
};

AddOrEditPartnerModal.defaultProps = {
  mode: "edit",
};

AddOrEditPartnerModal.propTypes = {
  mode: PropTypes.oneOf(["add", "edit"]).isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isFetchingToken: PropTypes.bool.isRequired,
  onRequestTokenGeneration: PropTypes.func.isRequired,
  onTogglePartnerActiveState: PropTypes.func.isRequired,
  token: PropTypes.string,
  partnerDetails: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  handleShowRevokeModal: PropTypes.func.isRequired,
};

const useStyles = makeStyles({
  drawerContainer: {
    color: colors.base,
    marginTop: 20,
    "& .p": {
      color: colors.base,
      fontWeight: fontWeight.medium,
      fontSize: fontSize.large,
    },
  },

  generateContainer: {
    padding: 20,
    backgroundColor: colors.lightGrey,
    borderRadius: "10px",
    "& p": {
      color: colors.base,
      fontWeight: fontWeight.medium,
      fontSize: fontSize.medium,
    },
    "& .generate-items": {
      marginTop: 20,
      marginBottom: 10,
      padding: 5,
      backgroundColor: colors.lightGrey,
      borderWidth: "2px",
      borderSyle: "solid",
      borderColor: colors.grayishBlue,
      display: "flex",
      justifyContent: "space-between",
      borderRadius: "4px",
      alignItems: "flex-end",
      flexDirection: "column",
    },

    "& .ant-form-item": {
      width: "100%",
    },

    "& .revoke-token": {
      border: "none",
      background: "transparent",
      color: "#BF0711",
      textTransform: "capitalize",
      fontSize: "14px",
      outline: "none",
      cursor: "pointer",
    },
  },

  formItem: {
    "& .ant-form-item-label": {
      paddingBottom: 0,
    },
  },
});

export default React.memo(AddOrEditPartnerModal);

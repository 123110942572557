import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Menu, Checkbox, DatePicker, Button } from "antd";
import { makeStyles } from "@material-ui/styles";

import { colors, fontWeight } from "../../Css";
import ResourceTable, { Filter } from "../../atoms/ResourceTable";
import Tags from "../../atoms/Tags";
import ReportDetails from "../../components/Assessment/ReportDetails";
import { Api } from "../../utils/Api";
import * as Constants from "../../utils/Constants";
import {
  getIsFetchingState,
  getTotalItemsCountState,
  getCurrentAssessmentState
} from "../../redux/Assessment/assessmentsReducer";
import { useCallbackState } from "../../hooks/useCallbackState";
import { getStates } from "../../utils/States";
import {
  getPartnerNameFromId,
  getChannelNameFromId,
  fromEntries
} from "../../utils";
import SourceFilter from "../../components/Assessment/SourceFilter";
import ChannelsFilter from "../../components/Assessment/ChannelsFilter";
import { getPartnerSourcesState } from "../../redux/Assessment/partnerSourcesReducer";
import { getPartnerChannelsState } from "../../redux/Partners/partnerChannelsReducer";
import { getActionStatusByName } from "../../utils/Assessment";
import { UploadOutlined } from "@ant-design/icons";
import ExportAssessments from "./ExportAssessments";
import moment from "moment";

const TAB_VIEWS = [
  { tab: "All", key: "0" },
  { tab: "Low Risk", key: "1" },
  { tab: "Medium Risk", key: "2" },
  { tab: "High Risk", key: "3" }
];

const States = getStates();

const getStateNameFromId = id =>
  States.find(state => state.id === id).stateName;

const riskStatusKey = {
  low: "success",
  medium: "warning",
  high: "error",
  confirmed: "error",
  not: "info",
  healed: "success"
};

const initDateRange = {
  startDate: null,
  endDate: null
};

const timeRanges = {
  "1": "12AM-6AM",
  "2": "6AM-12PM",
  "3": "12PM-6PM",
  "4": "6PM-11:59PM"
};

const AssessmentTable = () => {
  const classes = useStyles();
  const [reportDetails, setReportDetails] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentTab, setCurrentTab] = useState("0");
  const [pageSize, setPageSize] = useState(Constants.DATA_LIMIT);
  const [isReportDetailsVisible, setReportDetailsVisible] = useState(false);

  //this is the title passed as status in the getAssessmentSummaryByStatus()
  const [assessmentState, setAssessmentState] = useCallbackState(null);
  const [assessmentDateRange, setAssessmentDateRange] = useCallbackState(
    initDateRange
  );
  const [assessmentChannel, setAssessmentChannel] = useCallbackState(null);
  const [assessmentSource, setAssessmentSource] = useCallbackState(null);
  const [timeRange, setTimeRange] = useState(null);
  const [specificDate, setSpecificDate] = useState(null);
  const [queryValue, setQueryValue] = useCallbackState("");

  const [exportDrawerActive, setExportDrawerActive] = useState(false);

  const isFetching = useSelector(getIsFetchingState);
  const currentAssessment = useSelector(getCurrentAssessmentState);
  const totalItems = useSelector(getTotalItemsCountState);
  const partnerSources = useSelector(getPartnerSourcesState);
  const partnerChannels = useSelector(getPartnerChannelsState);

  const queryParams = useMemo(() => {
    const dateRange = assessmentDateRange;
    const dateRangeParams = {};
    if (dateRange.startDate && dateRange.endDate) {
      dateRangeParams.startDate = dateRange.startDate.format("YYYY-MM-DD");
      dateRangeParams.endDate = dateRange.endDate.format("YYYY-MM-DD");
    }

    if (specificDate) {
      const date = moment(specificDate); //Clone object
      dateRangeParams.startDate = date.startOf("day").format();
      dateRangeParams.endDate = date.endOf("day").format();
    }

    const params = {
      pageNo: currentPage,
      pageSize: pageSize,
      keyword: queryValue,
      stateId: assessmentState,
      channel: assessmentChannel,
      sourcePartnerId: assessmentSource,
      riskStatus: currentTab !== "0" ? String(+currentTab + 1) : null,
      timeRange,
      ...dateRangeParams
    };

    return fromEntries(
      Object.entries(params).filter(
        ([_, value]) =>
          value !== null &&
          value !== undefined &&
          (Boolean(value) || value === 0)
      )
    );
  }, [
    assessmentDateRange,
    specificDate,
    currentPage,
    pageSize,
    queryValue,
    assessmentState,
    assessmentChannel,
    assessmentSource,
    currentTab,
    timeRange
  ]);

  const fetchData = () => {
    const params = { ...queryParams };

    if (currentAssessment) {
      params.currentData = currentAssessment;
    }

    Api.AssessmentsRepository.getAssessmentsCases(params);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [queryParams]);

  const handleLoadMore = page => {
    setCurrentPage(page);
  };

  const handleQueryChange = e => {
    setQueryValue(e.target.value);
  };

  const handleClearQuery = () => {
    setQueryValue("");
  };

  const handleAssessmentDateRangeChange = dates => {
    if (!dates) {
      setAssessmentDateRange({
        startDate: null,
        endDate: null
      });
    } else {
      const [startDate, endDate] = dates;
      setAssessmentDateRange({
        startDate,
        endDate
      });
      setSpecificDate(null);
    }
  };

  const handleSpecificDateChange = date => {
    setSpecificDate(date);
    setAssessmentDateRange(initDateRange);
  };

  const appliedFilters = useMemo(() => {
    const filters = [];
    if (!!assessmentChannel) {
      filters.push({
        key: "Channel",
        label: `Channel: ${getChannelNameFromId(
          partnerChannels,
          assessmentChannel
        )}`,
        onRemove: _ => setAssessmentChannel(null)
      });
    }

    if (!!assessmentState) {
      filters.push({
        key: "State",
        label: `State: ${getStateNameFromId(assessmentState)}`,
        onRemove: _ => setAssessmentState(null)
      });
    }

    if (!!assessmentDateRange.startDate && !!assessmentDateRange.endDate) {
      filters.push({
        key: "Date",
        label: `Date: From ${assessmentDateRange.startDate.format(
          "YYYY-MM-DD"
        )} to ${assessmentDateRange.endDate.format("YYYY-MM-DD")}`,
        onRemove: () => handleAssessmentDateRangeChange([null, null])
      });
    }

    if (!!assessmentSource) {
      filters.push({
        key: "Source",
        label: `Source: ${getPartnerNameFromId(
          partnerSources,
          assessmentSource
        )}`,
        onRemove: () => setAssessmentSource(null)
      });
    }

    if (timeRange) {
      filters.push({
        key: "Time Range",
        label: `Time Range: ${timeRanges[timeRange]}`,
        onRemove: () => setTimeRange(null)
      });
    }

    if (specificDate) {
      filters.push({
        key: "Specific Date",
        label: `Specific Date: ${specificDate.format("YYYY-MM-DD")}`,
        onRemove: () => handleSpecificDateChange(null)
      });
    }

    return filters;
    // eslint-disable-next-line
  }, [
    assessmentChannel,
    assessmentDateRange.endDate,
    assessmentDateRange.startDate,
    assessmentSource,
    assessmentState,
    partnerChannels,
    partnerSources,
    specificDate,
    timeRange
  ]);

  const columns = () =>
    [
      {
        title: "Name",
        dataIndex: "fullName",
        key: "name",
        render: renderTableName
      },
      {
        title: "Report Date",
        dataIndex: "reportDate",
        key: "reportDate",
        render: Date => {
          return <>{!!Date ? Date : "No date"}</>;
        }
      },
      {
        title: "Risk Status",
        dataIndex: "riskStatus",
        key: "riskStatus",
        render: status => {
          const label = status.split(" ")[0].toLowerCase();
          return (
            <Tags
              label={label}
              width={"80px"}
              type={riskStatusKey[label] || "default"}
            />
          );
        }
      },
      // Only show this column for high risk assessments
      // 3 === High Risk
      currentTab === "3" && {
        title: "Action Status",
        dataIndex: "status",
        key: "actionStatus",
        render: status => {
          const tag = getActionStatusByName(status);
          return <Tags label={tag.label} width={"100px"} type={tag.type} />;
        }
      },
      {
        title: "Report Source",
        dataIndex: "source",
        key: "reportSource",
        render: source => {
          return <>{!!source ? source : "Unknown source"}</>;
        }
      },
      {
        title: "Channel",
        dataIndex: "channel",
        key: "channel"
      },
      {
        title: "Language",
        dataIndex: "language",
        key: "language"
      }
    ].filter(column => !!column); // only show enabled columns

  const renderTableName = (name, data) => {
    return (
      <div className={classes.assessmentTableNameColumn}>
        <p>{!!name ? name : `User ${data.id}`}</p>
        <p>{!!data.phoneNumber ? data.phoneNumber : "No phone number"}</p>
      </div>
    );
  };

  const renderFilterStateControl = () => {
    return (
      <Menu className={classes.stateList}>
        {States.map(state => (
          <Menu.Item key={state.id}>
            <Checkbox
              checked={state.id === assessmentState}
              onChange={_ => setAssessmentState(state.id)}
            >
              {state.stateName}
            </Checkbox>
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  const renderFilterChannelControl = () => (
    <ChannelsFilter
      selectedChannel={assessmentChannel}
      onSelectChannel={setAssessmentChannel}
    />
  );

  const renderFilterSourceControl = () => (
    <SourceFilter
      selectedSource={assessmentSource}
      onSelectSource={setAssessmentSource}
    />
  );

  const renderFilterDateControl = () => {
    return (
      // Prevent clicks in this filter from dismissing the dropdown
      <div onClick={e => e.stopPropagation()}>
        <DatePicker.RangePicker
          value={[assessmentDateRange.startDate, assessmentDateRange.endDate]}
          onChange={handleAssessmentDateRangeChange}
          format={"DD/MM/YYYY"}
        />
      </div>
    );
  };

  const renderTimeRangeControl = () => {
    return (
      <Menu className={classes.stateList}>
        {Object.entries(timeRanges).map(([key, value]) => (
          <Menu.Item key={key}>
            <Checkbox
              checked={key === timeRange}
              onChange={_ => setTimeRange(key)}
            >
              {value}
            </Checkbox>
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  const renderSpecificDateControl = () => {
    return (
      <div onClick={e => e.stopPropagation()}>
        <DatePicker
          onChange={handleSpecificDateChange}
          defaultValue={specificDate}
        />
      </div>
    );
  };

  const handleReportDetailsCode = () => {
    setReportDetailsVisible(false);
  };

  const onRowClick = record => {
    setReportDetails(record);
    setReportDetailsVisible(true);
    Api.AssessmentsRepository.resetAssessmentReportDetailStore();
  };

  const renderExportCSV = () => {
    return (
      <Button
        className={classes.exportAssessments}
        icon={<UploadOutlined />}
        size="small"
        onClick={_ => setExportDrawerActive(true)}
      >
        Export
      </Button>
    );
  };

  return (
    <>
      <div className="assessment-table">
        <ResourceTable
          tabBarExtraContent={renderExportCSV()}
          tableProps={{
            columns: columns(),
            loading: isFetching,
            dataSource: currentAssessment,
            onRow: record => ({
              onClick: () => onRowClick(record)
            }),
            pagination: {
              total: totalItems,
              current: currentPage,
              onChange: handleLoadMore,
              onShowSizeChange: (current, pageSize) => setPageSize(pageSize)
            }
          }}
          resourceName={{ singular: "name", plural: "names" }}
          filterControl={
            <Filter
              filters={[
                {
                  key: "Channel",
                  label: "Channel",
                  resetFilter: _ => setAssessmentChannel(null),
                  filter: renderFilterChannelControl()
                },
                {
                  key: "State",
                  label: "State",
                  resetFilter: _ => setAssessmentState(null),
                  filter: renderFilterStateControl()
                },
                {
                  key: "Source",
                  label: "Source",
                  resetFilter: () => setAssessmentSource(null),
                  filter: renderFilterSourceControl()
                },
                {
                  key: "Date",
                  label: "Date",
                  resetFilter: () =>
                    handleAssessmentDateRangeChange([
                      initDateRange.startDate,
                      initDateRange.endDate
                    ]),
                  filter: renderFilterDateControl()
                },
                {
                  key: "Time Range",
                  label: "Time Range",
                  resetFilter: () => setTimeRange(null),
                  filter: renderTimeRangeControl()
                },
                {
                  key: "Specific Date",
                  label: "Specific Date",
                  resetFilter: _ => handleSpecificDateChange(null),
                  filter: renderSpecificDateControl()
                }
              ]}
              appliedFilters={appliedFilters}
              queryValue={queryValue}
              onQueryChange={handleQueryChange}
              onQueryClear={handleClearQuery}
              onPressEnter={handleQueryChange}
            />
          }
          tabList={TAB_VIEWS}
          onChangeTab={value => setCurrentTab(value)}
          onSelectOption={() => null}
        />
      </div>

      <ReportDetails
        isVisible={isReportDetailsVisible}
        onClose={handleReportDetailsCode}
        reportDetails={reportDetails}
      />
      <ExportAssessments
        totalItems={totalItems}
        onClose={_ => setExportDrawerActive(false)}
        visible={exportDrawerActive}
        params={queryParams}
      />
    </>
  );
};

const useStyles = makeStyles({
  assessmentTableNameColumn: {
    "& p": {
      margin: 0
    },
    "& p:first-child": {
      fontWeight: fontWeight.medium,
      color: colors.base
    }
  },
  stateList: {
    "&.ant-menu": {
      border: 0
    },
    "& .ant-menu-item": {
      padding: 0,
      border: 0,
      lineHeight: 2.0,
      height: "fit-content",
      margin: "0 !important"
    },
    "& .ant-menu-item span:last-child": {
      paddingRight: 0
    }
  },
  exportAssessments: {
    backgroundColor: "transparent",
    border: "none",
    boxShadow: "none"
  }
});

export default React.memo(AssessmentTable);

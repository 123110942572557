import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { CaretDownOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Button, Typography, Card, Row } from "antd";
import moment from "moment";

import Drawer from "../../atoms/Drawer";
import { colors, fontSize, fontWeight, boxShadow } from "../../Css";
import BarChart from "../../atoms/BarChart";
import Loader from "../../atoms/Loader";
import { formatNumber } from "../../utils";

const AssessmentFilter = props => {
  const classes = useStyles();
  const {
    visible,
    onClose,
    onChangeFilter,
    activeFilter,
    filters,
    data
    // channels
  } = props;

  const parsedDatawithoutNull = data.filter(item => item.Key !== null);

  const categories = parsedDatawithoutNull.map(datum => datum.Key);

  const options = {
    chart: {
      id: "assessments",
      height: 209,
      stacked: true,
      toolbar: {
        show: false
      }
    },
    xaxis: {
      categories: categories,
      labels: {
        show: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: 1
    },
    plotOptions: {
      bar: {
        horizontal: false
      }
    },
    legend: {
      position: "bottom",
      offsetX: "95%",
      show: false
    },
    colors: ["#138750", "#1AAF68", "#1DC173", "#27ED8F", "#BBE5B3"],
    tooltip: {
      enabled: true,
      enabledOnSeries: undefined,
      shared: true
    }
  };

  const series = parsedDatawithoutNull.map((datum, index) => {
    let seriesItem = {
      name: datum.Key,
      data: new Array(categories.length).fill(null)
    };
    seriesItem.data[index] = datum.Value;
    return seriesItem;
  });

  const renderBulletPoint = style => {
    return <div className={classes.bulletPoint} style={style} />;
  };

  // const handleMenuClick = e => {
  //   message.info("Click on menu item.");
  // };

  const filterMenu = (
    <Menu onClick={event => onChangeFilter(event.key)}>
      {filters.map(filter => (
        <Menu.Item key={filter.title}>{filter.title}</Menu.Item>
      ))}
    </Menu>
  );

  // const menu = (
  //   <Menu onClick={handleMenuClick}>
  //     <Menu.Item key="1">1st menu item</Menu.Item>
  //     <Menu.Item key="2">2nd menu item</Menu.Item>
  //     <Menu.Item key="3">3rd item</Menu.Item>
  //   </Menu>
  // );

  const renderFilterOptions = () => {
    return (
      <Row justify="space-between">
        <Dropdown overlay={filterMenu}>
          <Button>
            {activeFilter}
            <CaretDownOutlined style={{ marginLeft: 20 }} />
          </Button>
        </Dropdown>
      </Row>
    );
  };

  const applyClassName = title => {
    switch (title) {
      case "Low Risk":
        return `${classes.assessmentCard} ${classes.success}`;
      case "High Risk":
        return `${classes.assessmentCard} ${classes.error}`;
      case "Medium Risk":
        return `${classes.assessmentCard} ${classes.warning}`;
      case "Total Assessments":
        return `${classes.assessmentCard} ${classes.info}`;
      default:
        return `${classes.assessmentCard} ${classes.basic}`;
    }
  };

  const applyTitleClassName = title => {
    switch (title) {
      case "Low Risk":
        return `title success`;
      case "High Risk":
        return `title error`;
      case "Medium Risk":
        return `title warning`;
      case "Total Assessments":
        return `title info`;
      default:
        return `title basic`;
    }
  };

  const applyParagraphClassName = title => {
    switch (title) {
      case "Low Risk":
        return `${classes.assessmentDate} success`;
      case "High Risk":
        return `${classes.assessmentDate} error`;
      case "Medium Risk":
        return `${classes.assessmentDate} warning`;
      case "Total Assessments":
        return `${classes.assessmentDate} info`;
      default:
        return `${classes.assessmentDate} basic`;
    }
  };
  const renderAssessmentSource = data => (
    <div>
      {data.map((item, index) => [
        <div className={classes.assessmentContainer}>
          {renderBulletPoint({
            backgroundColor: options.colors[index],
            marginRight: 10
          })}
          <div className={classes.assessmentSource}>
            <p>{item.Key} </p>
            <p>{item.Value}</p>
          </div>
        </div>
      ])}
    </div>
  );

  return (
    <Drawer
      visible={visible}
      title="Assessment Filter"
      okText="Save"
      onClose={onClose}
      hideFooter={true}
    >
      {renderFilterOptions()}
      {filters.map(filter => {
        if (filter.title === activeFilter) {
          return (
            <>
              <Card className={applyClassName(filter.title)}>
                <div className={classes.totalAssessmentWrapper}>
                  <Typography.Title
                    level={4}
                    className={applyTitleClassName(filter.title)}
                  >
                    {activeFilter}
                  </Typography.Title>
                </div>
                <Typography.Paragraph
                  className={applyParagraphClassName(filter.title)}
                >
                  {moment().format("ll")}
                </Typography.Paragraph>
              </Card>
              <Typography.Paragraph className={classes.assessmentNum}>
                {formatNumber(filter.count)}
              </Typography.Paragraph>
              <Typography.Title level={4} className={classes.assesmentText}>
                {activeFilter}
              </Typography.Title>
            </>
          );
        }
        return null;
      })}

      {props.isLoading ? (
        <Loader title="Fetching Summary data" />
      ) : (
        <React.Fragment>
          {!props.isLoading && props.data !== 0 ? (
            <>
              <div>
                <BarChart options={options} series={series} />
              </div>
              {renderAssessmentSource(parsedDatawithoutNull)}
            </>
          ) : (
            <div className={classes.errorContainer}>
              <h1>There was an error</h1>
              <p>{props.errorMessage}</p>
            </div>
          )}
        </React.Fragment>
      )}
    </Drawer>
  );
};

AssessmentFilter.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  channels: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    cases: PropTypes.oneOf([PropTypes.string, PropTypes.number]).isRequired,
    channels: PropTypes.object.isRequired
  }).isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeFilter: PropTypes.string
};

const typeColorStyles = {
  "&.info": {
    color: colors.info
  },
  "&.error": {
    color: colors.error
  },
  "&.warning": {
    color: colors.warning
  },
  "&.success": {
    color: colors.success
  }
};

const useStyles = makeStyles({
  heading: {
    color: colors.base,
    fontSize: fontSize.large,
    fontWeight: fontWeight.medium
  },
  assessmentContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: 20
  },
  totalAssessmentWrapper: {
    "& .title": {
      padding: 0,
      margin: 0,
      fontSize: fontSize.xxlarge,
      fontWeight: fontWeight.medium,
      ...typeColorStyles
    }
  },
  assessmentDate: {
    padding: 0,
    margin: 0,
    fontWeight: 600,
    fontSize: fontSize.small,
    ...typeColorStyles
  },
  assessmentNum: {
    padding: 0,
    margin: 0,
    fontWeight: 600,
    color: colors.base,
    fontSize: 42,
    marginTop: 34
  },
  assesmentText: {
    padding: 0,
    margin: 0,
    fontWeight: 600,
    color: colors.base,
    fontSize: fontSize.large,
    marginTop: 63
  },
  assessmentCard: {
    marginTop: 24,
    boxShadow: boxShadow.card,
    borderRadius: 3,
    "& p": {
      margin: 0,
      padding: 0
    }
  },
  info: {
    backgroundColor: "rgba(0,112,224,0.15)",
    border: `1px solid ${colors.info}`
  },
  error: {
    backgroundColor: "rgba(222, 54, 24, 0.15)",
    border: `1px solid ${colors.error}`
  },
  warning: {
    backgroundColor: "rgba(244, 147, 67, 0.15)",
    border: `1px solid ${colors.warning}`
  },
  success: {
    backgroundColor: "rgba(80, 184, 60, 0.15)",
    border: `1px solid ${colors.success}`
  },
  basic: {
    backgroundColor: "rgba(59.2, 59.2, 59.2, 0.15)",
    border: `1px solid ${colors.grey}`
  },
  bulletPoint: {
    height: 10,
    width: 10,
    marginRight: 10
  },
  assessmentSource: {
    width: "100%",
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
    "& > p": {
      margin: 0,
      color: colors.grayishBlue,
      fontSize: fontSize.medium
    }
  },
  barchartHeading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  errorContainer: {
    width: "100%",
    height: 200,
    display: "flex",
    alignItems: "center",
    flexDirection: "column"
  }
});

export default React.memo(AssessmentFilter);

import React from "react";
import PropTypes from "prop-types";
import { Breadcrumb, Typography } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { makeStyles } from "@material-ui/styles";
import { Link } from "react-router-dom";

import { colors, fontSize } from "../Css";

const breadcrumbNameMap = (id) => {
  return {
    "/case-management": "Case management",
    [`/case-management/${id}`]: `${id}`,
    "/contact-trace": "Contact trace",
    [`/contact-trace/${id}`]: `${id}`,
  };
};

const HeadingWithBreadcrumb = ({
  showBreadCrumb,
  title,
  renderHeaderRight,
}) => {
  const pathSnippets = window.location.pathname.split("/").filter((i) => i);
  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
    let id = url.split("/");
    id = id[id.length - 1];

    const editCaseBackUrl = `/${pathSnippets[0]}/${
      pathSnippets[pathSnippets.length - 1]
    }`;
    return url.includes("edit-case") ? (
      url.endsWith("edit-case") && (
        <Breadcrumb.Item key={editCaseBackUrl}>
          <Link to={editCaseBackUrl}>
            {pathSnippets[pathSnippets.length - 1]}
          </Link>
        </Breadcrumb.Item>
      )
    ) : (
      <Breadcrumb.Item key={url}>
        <Link to={url}>{breadcrumbNameMap(id)[url]}</Link>
      </Breadcrumb.Item>
    );
  });

  const classes = useStyles();
  const backUrl = `/${pathSnippets[0]}`;
  return (
    <div className={classes.headingContainer}>
      <div>
        {showBreadCrumb && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Link to={backUrl}>
              <LeftOutlined
                className={classes.LeftOutlined}
                style={{ paddingRight: 4 }}
              />
            </Link>
            <Breadcrumb>{extraBreadcrumbItems}</Breadcrumb>
          </div>
        )}
        <Typography.Title style={{ marginBottom: 0 }} level={2}>
          {title}
        </Typography.Title>
      </div>
      {!!renderHeaderRight && <div>{renderHeaderRight()}</div>}
    </div>
  );
};

const useStyles = makeStyles({
  headingContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: 24,
    justifyContent: "space-between",
    "& p": {
      margin: 0,
      padding: 0,
    },
  },
  breadcrumb: { color: colors.secondaryBase, fontSize: fontSize.medium },
  filterContainer: {
    padding: 20,
    borderBottom: "1px solid #DFE3E8",
  },
  LeftOutlined: {
    color: "rgba(0, 0, 0, 0.45)",
    "&:hover": {
      color: "#128750",
    },
  },
});

HeadingWithBreadcrumb.defaultProps = {
  showBreadCrumb: true,
};

HeadingWithBreadcrumb.propTypes = {
  title: PropTypes.string.isRequired,
  renderHeaderRight: PropTypes.node,
  page: PropTypes.string,
  showBreadCrumb: PropTypes.bool,
};

export default React.memo(HeadingWithBreadcrumb);

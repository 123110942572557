import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { useSelector } from "react-redux";
import { Skeleton, notification, Tag } from "antd";

import { Api } from "../../utils/Api";
import { colors, fontSize, fontWeight } from "../../Css";
import {
  getIsFetchingState as getIsFetchingStateForOverviewSummary,
  getStatusState as getStatusStateForOverviewSummary,
  getErrorMessageState as getErrorMessageStateForOverviewSummary,
  getSummaryState,
  Status as StatusForOverviewSummary
} from "../../redux/Overview/overviewSummaryReducer";
import {
  getIsFetchingState as getIsFetchingStateForCasesByRiskFactor,
  getStatusState as getStatusStateForCasesByRiskFactor,
  getErrorMessageState as getErrorMessageStateForCasesByRiskFactor,
  getCasesByRiskFactorState,
  Status as StatusForCasesByRiskFactor
} from "../../redux/Overview/casesByRiskFactorReducer";

const formatThousandNumber = number => {
  return (number && parseInt(number).toLocaleString()) || 0;
};

const OverviewSummaryWidget = ({ filterState, clearSelectedStateFilter }) => {
  const classes = useStyles();
  const isFetchingCasesByRiskFactor = useSelector(
    getIsFetchingStateForCasesByRiskFactor
  );
  const casesByRiskFactor = useSelector(getCasesByRiskFactorState);
  const casesByRiskFactorErrMsg = useSelector(
    getErrorMessageStateForCasesByRiskFactor
  );
  const statusForCasesByRiskFactor = useSelector(
    getStatusStateForCasesByRiskFactor
  );

  const summary = useSelector(getSummaryState);
  const isFetchingSummary = useSelector(getIsFetchingStateForOverviewSummary);
  const summaryErrMsg = useSelector(getErrorMessageStateForOverviewSummary);
  const summaryStatus = useSelector(getStatusStateForOverviewSummary);
  const { statAssessment } = summary;

  useEffect(() => {
    Api.OverviewRepository.getOverviewSummary(filterState.stateId);
    Api.OverviewRepository.getCasesByRiskFactor(filterState.stateId);
  }, [filterState.stateId]);

  useEffect(() => {
    if (
      statusForCasesByRiskFactor ===
      StatusForCasesByRiskFactor.GET_CASES_BY_RISK_FACTOR_REQUEST_FAILURE
    ) {
      notification["error"]({
        message: casesByRiskFactorErrMsg
      });
    }
  }, [statusForCasesByRiskFactor, casesByRiskFactorErrMsg]);

  useEffect(() => {
    if (
      summaryStatus ===
      StatusForOverviewSummary.GET_OVERVIEW_SUMMARY_REQUEST_FAILURE
    ) {
      notification["error"]({
        message: summaryErrMsg
      });
    }
  }, [summaryStatus, summaryErrMsg]);

  return (
    <div className={classes.container}>
      <h3 className="heading" style={{ marginTop: 0 }}>
        Assessment Action Status
      </h3>
      <div className={classes.confirmedCasesItems}>
        {!!filterState.stateName && (
          <Tag closable onClose={clearSelectedStateFilter}>
            {filterState.stateName}
          </Tag>
        )}
      </div>
      <Skeleton
        loading={isFetchingSummary || isFetchingCasesByRiskFactor}
        active
      >
        <div className="totalNumbers confirmedNumber">
          {formatThousandNumber(statAssessment?.totalCount)}
        </div>

        <BulletComponent
          dotColor="orange"
          label="Pending"
          value={statAssessment?.pending}
        />
        <BulletComponent
          dotColor="yellow"
          label="Escalated"
          value={statAssessment?.escalated}
        />
        <BulletComponent
          dotColor="grey"
          label="Closed"
          value={statAssessment?.closed}
        />

        <h3 className="heading assessment">Assessment Reports</h3>

        <div className="totalNumbers">
          <div className="count">
            {formatThousandNumber(casesByRiskFactor?.cummulative?.total)}
          </div>
        </div>
        <BulletComponent
          dotColor="orange"
          label="High"
          value={casesByRiskFactor?.cummulative?.high}
        />
        <BulletComponent
          dotColor="yellow"
          label="Medium"
          value={casesByRiskFactor?.cummulative?.medium}
        />
        <BulletComponent
          dotColor="green"
          label="Low"
          value={casesByRiskFactor?.cummulative?.low}
        />
      </Skeleton>
    </div>
  );
};

const BulletComponent = ({ dotColor, label, value }) => {
  const classes = useStyles();
  return (
    <div className={classes.confirmedCasesItems}>
      <div className="cases-items-content">
        <div className={`circle circle-${dotColor}`}></div>
        <div className="activities">{label}:</div>
      </div>
      <div className="count">{formatThousandNumber(value)}</div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    backgroundColor: colors.white,
    width: "20%",
    height: "fit-content",
    margin: "0",
    padding: 20,
    borderRadius: 4,
    marginTop: "20px",
    minHeight: "415px",

    "& .lastUpdated": {
      color: colors.darkGrey,
      fontStyle: "italic",
      fontSize: fontSize.xsmall
    },
    "& .heading": {
      margin: "14px 0px",
      fontSize: fontSize.large,
      fontWeight: fontWeight.medium,
      color: colors.base,

      "&.assessment": {
        marginTop: "25px"
      }
    },
    "& .headingTwo": {
      marginTop: 40,
      fontSize: fontSize.xlarge,
      fontWeight: fontWeight.bold
    },
    "& .totalNumbers": {
      fontSize: fontSize.title,
      fontWeight: fontWeight.bold,
      color: colors.base
    },
    "& .confirmedNumber": {
      fontSize: fontSize.title,
      fontWeight: fontWeight.bold,
      color: colors.error
    }
  },

  confirmedCasesItems: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 5,
    "& .cases-items-content": {
      display: "flex",
      width: "60%",
      fontWeight: fontWeight.medium,
      alignItems: "center",

      "& .circle": {
        borderRadius: "50%",
        width: "12px",
        height: "12px",
        backgroundColor: "red",
        marginRight: "10px"
      },
      "& .circle-red": {
        backgroundColor: colors.error
      },
      "& .circle-orange": { backgroundColor: colors.warning },
      "& .circle-green": { backgroundColor: colors.success },
      "& .circle-grey": { backgroundColor: colors.darkGrey },
      "& .circle-yellow": { backgroundColor: "#EEC200" },
      "& .activities": {
        fontSize: fontSize.medium,
        fontWeight: fontWeight.regular,
        color: colors.base
      }
    },
    "& .count": {},
    "& .recovered": {
      display: "flex",
      justifyContent: "space-between",
      width: "100px"
    },
    "& .medium": {
      display: "flex",
      justifyContent: "space-between",
      width: "95px"
    }
  }
});

export default React.memo(OverviewSummaryWidget);

import React from "react";
import moment from "moment";
import GoogleAPIMap from "./GoogleAPIMap";

const ContactActivityDetails = ({ selectedContact }) => {
  let date = moment(selectedContact && selectedContact.contactTime).format(
    "MMMM DD YYYY"
  );

  return (
    <div>
      <div className="details">
        <div className="detail mb-19">
          <h6>Name</h6>
          <p>{selectedContact && selectedContact.fullName}</p>
        </div>
        <div className="detail mb-19">
          <h6>Activity Date</h6>
          <p>{date}</p>
        </div>
        {selectedContact && selectedContact.channel ? (
          <div className="detail mb-19">
            <h6>Channel</h6>
            <p>USSD</p>
          </div>
        ) : null}

        {selectedContact && selectedContact.source ? (
          <div className="detail mb-19">
            <h6>Source</h6>
            <p>Gloepid mobile</p>
          </div>
        ) : null}
        <div className="detail mb-19">
          <h6>Contact</h6>
          <p>+234{selectedContact && selectedContact.phoneNumber}</p>
        </div>
      </div>
      <GoogleAPIMap selectedContact={selectedContact} />

      <div className="address">
        <h6>Location</h6>
        <hr className="seperator" />
        <p>{selectedContact.location}</p>
      </div>
    </div>
  );
};

export default ContactActivityDetails;

import {
  GET_OVERVIEW_SUMMARY_REQUEST_FAILURE,
  GET_OVERVIEW_SUMMARY_REQUEST,
  GET_OVERVIEW_SUMMARY_REQUEST_SUCCESS,
} from "./actionTypes";

const initialState = {
  isFetching: false,
  errorMessage: "",
  status: null,
  summary: [],
};

export const Status = {
  GET_OVERVIEW_SUMMARY_REQUEST: `get_overview_summary_request`,
  GET_OVERVIEW_SUMMARY_REQUEST_FAILURE: `get_overview_summary_request_failure`,
  GET_OVERVIEW_SUMMARY_REQUEST_SUCCESS: `get_overview_summary_request_success`,
};

const overviewSummaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_OVERVIEW_SUMMARY_REQUEST: {
      return {
        ...state,
        isFetching: true,
        status: Status.GET_OVERVIEW_SUMMARY_REQUEST,
      };
    }
    case GET_OVERVIEW_SUMMARY_REQUEST_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        status: Status.GET_OVERVIEW_SUMMARY_REQUEST_SUCCESS,
        summary: action.payload,
      };
    }
    case GET_OVERVIEW_SUMMARY_REQUEST_FAILURE: {
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
        status: Status.GET_OVERVIEW_SUMMARY_REQUEST_FAILURE,
      };
    }
    default:
      return state;
  }
};

export const getIsFetchingState = (state) => state.overviewSummary.isFetching;
export const getErrorMessageState = (state) =>
  state.overviewSummary.errorMessage;
export const getStatusState = (state) => state.overviewSummary.status;
export const getSummaryState = (state) => state.overviewSummary.summary;

export default overviewSummaryReducer;

import React, { useState, useEffect } from "react";
import { Input, notification, Upload, Form, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { makeStyles } from "@material-ui/styles";
import { useSelector } from "react-redux";

import Drawer from "../atoms/Drawer";
import { colors } from "../Css";
import { Api } from "../utils/Api";

import {
  Status as EditProfileStatus,
  getStatusState as getEditProfileStatusState,
  getIsFetchingState as getEditProfileIsFetchingState,
  getErrorMessageState as getEditProfileErrorMessageState
} from "../redux/Staffs/upsertStaffReducer";
import {
  Status as StaffAvatarStatus,
  getStatusState as getStaffAvatarStatusState,
  getIsFetchingState as getStaffAvatarIsFetchingState,
  getErrorMessageState as getStaffAvatarErrorMessageState,
  getAvatarUrlState
} from "../redux/Staffs/staffAvatarReducer";
import { getCurrentUserState } from "../redux/Authentication/loginReducer";
import Auth from "../utils/Auth";
import history from "../routes/history";
import { PublicPaths } from "../routes";
import { useUpdateEffect } from "../hooks/useUpdateEffect";

const allowedProfilePictureFileType = process.env.REACT_APP_ALLOWED_PROFILE_PICTURE_EXTENSION.split(
  ","
);
const maxFileSize = Number(process.env.REACT_APP_ALLOWED_PROFILE_PICTURE_SIZE);

const ProfileDrawer = props => {
  const classes = useStyles();
  const { isVisible, closeDrawer } = props;
  const [form] = Form.useForm();

  const [avatarUrl, setAvatarUrl] = useState(null);
  const editProfileIsFetching = useSelector(getEditProfileIsFetchingState);
  const editProfileErrorMessage = useSelector(getEditProfileErrorMessageState);
  const editProfileStatus = useSelector(getEditProfileStatusState);
  const currentUser = useSelector(getCurrentUserState);
  const avatarStatus = useSelector(getStaffAvatarStatusState);
  const avatarIsFetching = useSelector(getStaffAvatarIsFetchingState);
  const avatarErrorMessage = useSelector(getStaffAvatarErrorMessageState);
  const avatarUrlFromProps = useSelector(getAvatarUrlState);

  useEffect(() => {
    if (!!currentUser === true) {
      form.setFieldsValue({
        fullName: currentUser.fullName,
        phoneNumber: currentUser.phoneNumber
      });
      setAvatarUrl(currentUser.profilePictureUri);
    }
    // eslint-disable-next-line
  }, [currentUser]);

  useEffect(() => {
    if (!!avatarUrlFromProps === true) {
      setAvatarUrl(avatarUrlFromProps);
    }
  }, [avatarUrlFromProps]);

  useUpdateEffect(() => {
    if (
      avatarStatus === StaffAvatarStatus.UPLOAD_STAFF_AVATAR_REQUEST_FAILURE
    ) {
      notification["error"]({
        message: "Failed to upload avatar",
        description: avatarErrorMessage
      });
    }
  }, [avatarStatus, avatarErrorMessage]);

  useUpdateEffect(() => {
    if (editProfileStatus === EditProfileStatus.UPDATE_STAFF_PROFILE_FAILURE) {
      notification["error"]({
        message: "Failed to update Profile",
        description: editProfileErrorMessage
      });
    } else if (
      editProfileStatus === EditProfileStatus.UPDATE_STAFF_PROFILE_SUCCESS
    ) {
      notification["success"]({
        message: "Profile updated successfully",
        description: "Your profile has been updated successfully"
      });
      logoutAfterPasswordChange();
    }
  }, [editProfileStatus, editProfileErrorMessage]);

  const validateToNextPassword = (rule, value, callback) => {
    if (value) {
      form.validateFields(["confirmPassword"], { force: true });
    }
    callback();
  };

  const compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== form.getFieldValue("newPassword")) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  };

  const checkAvatarBeforeUpload = file => {
    const isJpgOrPng = allowedProfilePictureFileType.includes(file.type);
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size < maxFileSize;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const logoutAfterPasswordChange = () => {
    const {
      currentPassword,
      newPassword,
      confirmPassword
    } = form.getFieldsValue();
    if (currentPassword && newPassword && confirmPassword) {
      Auth.removeToken();
      Auth.removeCurrentUser();
      notification["info"]({
        message: "Please sign in again",
        description: "You will need to sign in again into your account"
      });
      history.push(PublicPaths.LOGIN);
    }
  };

  const uploadAvatarProps = () => ({
    accept: "image/*",
    listType: "picture-card",
    name: "picture",
    beforeUpload: checkAvatarBeforeUpload,
    showUploadList: false,
    className: "imgContainer",
    customRequest: handleAvatarUpload
  });

  const handleAvatarUpload = data => {
    let { file, filename } = data;
    let formData = new FormData();
    formData.append(filename, file);
    Api.StaffRepository.uploadStaffAvatar(formData);
  };

  const handleSubmit = values => {
    const {
      currentPassword,
      newPassword,
      confirmPassword,
      phoneNumber,
      fullName
    } = values;
    let data = {};

    if (!currentPassword && newPassword && confirmPassword) {
      notification.error({
        message: "You must enter the current password"
      });
    } else if (newPassword && !confirmPassword) {
      notification.error({
        message: "You must enter your confirm password"
      });
    } else if (!currentPassword && !newPassword && !confirmPassword) {
      data = {
        fullName,
        phoneNumber,
        profilePictureUri: avatarUrl
      };

      Api.StaffRepository.updateStaffProfile(data);
    } else {
      data = {
        fullName,
        phoneNumber,
        currentPassword,
        newPassword,
        confirmPassword,
        profilePictureUri: avatarUrl
      };

      Api.StaffRepository.updateStaffProfile(data);
    }
  };

  const renderBodyForm = () => {
    return (
      <Form form={form} onFinish={handleSubmit} className={classes.body}>
        <div className="formGroup">
          <div className="mb">
            <label htmlFor="fullName">
              Full Name
              <Form.Item name="fullName" rules={[{ required: true }]}>
                <Input placeholder="Type here" />
              </Form.Item>
            </label>
          </div>
          <div>
            <label htmlFor="phone">
              Phone Number
              <Form.Item name="phoneNumber" rules={[{ required: true }]}>
                <Input placeholder="Type here" type="tel" />
              </Form.Item>
            </label>
          </div>
        </div>
        <hr className="seperator" />
        <div className="formGroup">
          <div className="mb">
            <label htmlFor="current-password">
              Current Password
              <Form.Item name="currentPassword">
                <Input placeholder="Type here" type="password" />
              </Form.Item>
            </label>
          </div>
          <div className="mb">
            <label htmlFor="new-password">
              New Password
              <Form.Item
                name="newPassword"
                rules={[{ validator: validateToNextPassword }]}
              >
                <Input placeholder="Type here" type="password" />
              </Form.Item>
            </label>
          </div>
          <label htmlFor="new-password">
            Confirm New Password
            <Form.Item
              name="confirmPassword"
              rules={[{ validator: compareToFirstPassword }]}
            >
              <Input placeholder="Type here" type="password" />
            </Form.Item>
          </label>
        </div>
      </Form>
    );
  };

  const renderHeader = () => {
    const uploadingComponent = avatarIsFetching ? <LoadingOutlined /> : null;

    return (
      <div className="header">
        <Upload {...uploadAvatarProps()}>
          {avatarUrl ? (
            <>
              <img src={avatarUrl} alt="staff" />
            </>
          ) : (
            uploadingComponent
          )}
          <div className="editProfileImage">
            <label for="upload-photo" style={{ cursor: "pointer" }}>
              {avatarIsFetching ? "Uploading..." : "Edit"}
            </label>
          </div>
        </Upload>
        <div>
          <h1>{currentUser && currentUser.fullName}</h1>
          <h5>{currentUser && currentUser.emailAddress}</h5>
          <h6>{currentUser && currentUser.role}</h6>
        </div>
      </div>
    );
  };

  return (
    <Drawer
      visible={isVisible}
      onClose={closeDrawer}
      title="Profile"
      hideFooter={false}
      isLoading={editProfileIsFetching}
      okText="Save"
      onOk={() => form.submit()}
      overrideAntBodyPadding={true}
    >
      <div className={classes.drawerContainer}>
        {renderHeader()}
        {renderBodyForm()}
      </div>
    </Drawer>
  );
};

const useStyles = makeStyles({
  drawerContainer: {
    "& .header": {
      paddingTop: 40,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      "& h1": {
        fontSize: 16,
        fontWeight: 600,
        letterSpacing: 0,
        textAlign: "center",
        margin: 0
      },
      "& h5": {
        color: colors.base,
        fontSize: 14,
        letterSpacing: 0,
        textAlign: "center",
        marginTop: 5,
        marginBottom: 5
      },
      "& h6": {
        color: colors.secondaryBase,
        fontSize: 12,
        textAlign: "center",
        margin: 0
      }
    },
    "& .imgContainer": {
      position: "relative",
      width: 88,
      height: 88,
      borderRadius: 100,
      marginBottom: 10,
      "& .ant-upload.ant-upload-select-picture-card": {
        width: 88,
        height: 88,
        display: "block",
        borderRadius: "50%",
        background: "#d8d8d8"
      },
      "& .ant-upload.ant-upload-select-picture-card span": {
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        padding: 0
      }
    },
    "& img": {
      width: "inherit",
      height: "inherit",
      borderRadius: "50%",
      objectFit: "cover"
    },
    "& .editProfileImage": {
      width: 86,
      height: 42,
      position: "absolute",
      cursor: "pointer",
      bottom: 1,
      left: 1,
      borderBottomLeftRadius: 90,
      borderBottomRightRadius: 90,
      backgroundColor: `rgba(0,0,0,0.3)`,
      color: colors.white,
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      textAlign: "center",
      fontSize: 12,
      "& .uploadPhoto": {
        opacity: 0,
        cursor: "pointer",
        position: "absolute",
        zIndex: -1
      }
    }
  },
  body: {
    backgroundColor: colors.skyLighter,
    padding: `30.5px 20px`,
    marginTop: 45.5,
    paddingBottom: 183.5,
    "& .seperator": {
      border: `1px solid #E0E0E0`,
      margin: `29.5px 0`
    },
    "& .formGroup": {
      "& label": {
        color: colors.base,
        fontSize: 14,
        marginBottom: 4
      },
      "& .mb": {
        marginBottom: 20
      },
      "& input": {
        height: 36,
        color: colors.base,
        fontSize: 14
      }
    }
  }
});

export default React.memo(ProfileDrawer);

import axios from "axios";
import Auth from "../utils/Auth";
import history from "../routes/history";
import {
  LOGIN_REQUEST,
  LOGIN_REQUEST_SUCCESS,
  LOGIN_REQUEST_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_REQUEST_SUCCESS,
  RESET_PASSWORD_REQUEST_FAILURE,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_REQUEST_SUCCESS,
  FORGOT_PASSWORD_REQUEST_FAILURE
} from "../redux/Authentication/actionTypes";
import store from "../redux/store";
import PrivatePaths, { PublicPaths } from "../routes";

const AuthRepository = function(axiosInstance) {
  const CancelTokenForLogin = axios.CancelToken;
  const CancelTokenForResetPassword = axios.CancelToken;
  const CancelTokenForForgotPassword = axios.CancelToken;
  let cancelForLogin = null;
  let cancelForResetPassword = null;
  let cancelForForgotPassword = null;

  let _AuthRepository = {
    login: function(email, password) {
      store.dispatch({
        type: LOGIN_REQUEST
      });
      cancelForLogin && cancelForLogin();
      return axiosInstance
        .post(
          "/auth/login",
          {
            email: email,
            password: password
          },
          {
            cancelToken: new CancelTokenForLogin(function executor(c) {
              cancelForLogin = c;
            })
          }
        )
        .then(function(response) {
          const { success, message, data } = response.data;
          if (success) {
            store.dispatch({
              type: LOGIN_REQUEST_SUCCESS,
              payload: { currentUser: data.staff }
            });
            axiosInstance.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${data.token}`;
            Auth.setToken(data.token, data.expiresIn);
            Auth.setCurrentUser(data.staff);
            history.replace(PrivatePaths.ASSESMENT);
          } else {
            store.dispatch({
              type: LOGIN_REQUEST_FAILURE,
              payload: message
            });
          }
        })
        .catch(function(error) {
          store.dispatch({
            type: LOGIN_REQUEST_FAILURE,
            payload:
              !!error.response === false
                ? error.message
                : error.response.data.message
          });
        });
    },
    resetPassword: function({ email, password, token }) {
      store.dispatch({
        type: RESET_PASSWORD_REQUEST
      });
      cancelForResetPassword && cancelForResetPassword();
      return axiosInstance
        .post(
          "/auth/resetpassword",
          {
            userEmail: email,
            token: token,
            newPassword: password
          },
          {
            cancelToken: new CancelTokenForResetPassword(function executor(c) {
              cancelForResetPassword = c;
            })
          }
        )
        .then(function(response) {
          const { success, message } = response.data;

          if (success) {
            store.dispatch({
              type: RESET_PASSWORD_REQUEST_SUCCESS
            });
            history.replace(PublicPaths.LOGIN);
          } else {
            store.dispatch({
              type: RESET_PASSWORD_REQUEST_FAILURE,
              payload: message
            });
          }
        })
        .catch(function(error) {
          store.dispatch({
            type: RESET_PASSWORD_REQUEST_FAILURE,
            payload:
              !!error.response === false
                ? error.message
                : error.response.data.message
          });
        });
    },
    forgotPassword: function(email) {
      store.dispatch({
        type: FORGOT_PASSWORD_REQUEST
      });
      cancelForForgotPassword && cancelForForgotPassword();
      return axiosInstance
        .post(`/auth/forgotpassword?mail=${email}`, {
          cancelToken: new CancelTokenForForgotPassword(function executor(c) {
            cancelForForgotPassword = c;
          })
        })
        .then(function(response) {
          store.dispatch({
            type: FORGOT_PASSWORD_REQUEST_SUCCESS,
            payload: response.data.message
          });
        })
        .catch(function(error) {
          store.dispatch({
            type: FORGOT_PASSWORD_REQUEST_FAILURE,
            payload:
              !!error.response === false
                ? error.message
                : error.response.data.message
          });
        });
    }
  };

  return _AuthRepository;
};

export { AuthRepository };

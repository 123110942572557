import axios from 'axios';
import {
  GET_INDEX_CASES_REQUEST,
  GET_INDEX_CASES_REQUEST_SUCCESS,
  GET_INDEX_CASES_REQUEST_FAILURE,
  RESET_INDEX_CASES_STORE,
  GET_ACTIVITY_LOGS_REQUEST,
  GET_ACTIVITY_LOGS_REQUEST_SUCCESS,
  GET_ACTIVITY_LOGS_REQUEST_FAILURE,
  RESET_ACTIVITY_LOGS_STORE,
  GET_ASSESSMENT_LOGS_REQUEST,
  GET_ASSESSMENT_LOGS_REQUEST_SUCCESS,
  GET_ASSESSMENT_LOGS_REQUEST_FAILURE,
  RESET_ASSESSMENT_LOGS_STORE,
  GET_PATIENT_INFO_REQUEST,
  GET_PATIENT_INFO_REQUEST_SUCCESS,
  GET_PATIENT_INFO_REQUEST_FAILURE,
  GET_EXPORT_CONTACT_DATA_REQUEST_SUCCESS,
  GET_EXPORT_CONTACT_DATA_REQUEST_FAILURE,
  GET_EXPORT_CONTACT_DATA_REQUEST,
} from '../redux/ContactTrace/actionTypes';
import store from '../redux/store';
import * as Constants from '../utils/Constants';
import moment from 'moment';

function ContactTraceRepository(axiosInstance) {
  const CancelTokenForGetPatientInfo = axios.CancelToken;
  const CancelTokenForGetIndexCases = axios.CancelToken;
  const CancelTokenForGetActivityLogs = axios.CancelToken;
  const CancelTokenForGetAssessmentLogs = axios.CancelToken;
  const CancelTokenForExportContactData = axios.CancelToken;
  let cancelForGetIndexCases = null;
  let cancelForGetActivityLogs = null;
  let cancelForGetAssessmentLogs = null;
  let cancelForGetPatientInfo = null;
  let cancelForExportContactData = null;

  let _ContactTraceRepository = {
    resetIndexCasesStore: function () {
      store.dispatch({
        type: RESET_INDEX_CASES_STORE,
      });
    },

    getIndexCases: function ({ pageNo, currentData, ...params }) {
      if (!params.keyword) {
        delete params.keyword;
      }
      pageNo === 0 && _ContactTraceRepository.resetIndexCasesStore();

      store.dispatch({
        type: GET_INDEX_CASES_REQUEST,
        payload: {
          pageNo,
          currentData,
        },
      });
      cancelForGetIndexCases && cancelForGetIndexCases();
      axiosInstance
        .get('/ContactTracing/GetAllHighRiskUsers', {
          cancelToken: new CancelTokenForGetIndexCases(function executor(c) {
            cancelForGetIndexCases = c;
          }),
          params: {
            pageNo,
            ...params,
          },
        })
        .then((response) => {
          store.dispatch({
            type: GET_INDEX_CASES_REQUEST_SUCCESS,
            payload: {
              pageNo,
              total: response.data.total,
              data: response.data.data,
            },
          });
        })
        .catch((error) => {
          store.dispatch({
            type: GET_INDEX_CASES_REQUEST_FAILURE,
            payload: error.message,
          });
        });
    },

    resetActivityLogsStore: function () {
      store.dispatch({
        type: RESET_ACTIVITY_LOGS_STORE,
      });
    },

    getActivityLogs: function (
      pageNo,
      pageSize,
      userId,
      date,
      sortBy,
      keyword
    ) {
      pageNo === 0 && _ContactTraceRepository.resetActivityLogsStore();

      store.dispatch({
        type: GET_ACTIVITY_LOGS_REQUEST,
        payload: {
          pageNo,
        },
      });
      const dateParams =
        !!date && !!date.startDate && !!date.endDate
          ? {
              startDate: moment(date.startDate).format('YYYY-MM-DD'),
              endDate: moment(date.endDate).format('YYYY-MM-DD'),
            }
          : {};
      const sortByParam = !!sortBy
        ? sortBy === Constants.SORT_ORDER.latest.value
          ? '-contactTime'
          : '+contactTime'
        : null;
      const params = {
        pageSize,
        pageNo,
        gloEpidUserId: userId,
        ...dateParams,
        ...(!!sortBy && { sortBy: sortByParam }),
        ...(!!keyword && { keyword }),
      };
      cancelForGetActivityLogs && cancelForGetActivityLogs();
      axiosInstance
        .get(`/ContactTracing/GetContactTraceByGloEpidUserId/${userId}`, {
          cancelToken: new CancelTokenForGetActivityLogs(function executor(c) {
            cancelForGetActivityLogs = c;
          }),
          params,
        })
        .then((response) => {
          store.dispatch({
            type: GET_ACTIVITY_LOGS_REQUEST_SUCCESS,
            payload: {
              pageNo,
              total: response.data.total,
              data: response.data.data,
            },
          });
        })
        .catch((error) => {
          if (axios.isCancel(error)) return;
          store.dispatch({
            type: GET_ACTIVITY_LOGS_REQUEST_FAILURE,
            payload: error.message,
          });
        });
    },

    resetAssessmentLogsStore: function () {
      store.dispatch({
        type: RESET_ASSESSMENT_LOGS_STORE,
      });
    },

    getAssessmentLogs: function (
      pageNo,
      pageSize,
      userId,
      keyword,
      date,
      sortBy
    ) {
      pageNo === 0 && _ContactTraceRepository.resetAssessmentLogsStore();

      store.dispatch({
        type: GET_ASSESSMENT_LOGS_REQUEST,
        payload: {
          pageNo,
        },
      });

      cancelForGetAssessmentLogs && cancelForGetAssessmentLogs();
      const dateParams =
        !!date && !!date.startDate && !!date.endDate
          ? {
              startDate: moment(date.startDate).format('YYYY-MM-DD'),
              endDate: moment(date.endDate).format('YYYY-MM-DD'),
            }
          : {};
      const sortByParam = !!sortBy
        ? sortBy === Constants.SORT_ORDER.latest.value
          ? '-createdAt'
          : '+createdAt'
        : null;
      const params = {
        pageSize,
        pageNo,
        gloEpidUserId: userId,
        ...dateParams,
        ...(!!sortBy && { sortBy: sortByParam }),
        ...(!!keyword && { keyword }),
      };
      axiosInstance
        .get(`/ContactTracing/GetAllAssessmentByGloEpidUserId/${userId}`, {
          cancelToken: new CancelTokenForGetAssessmentLogs(function executor(
            c
          ) {
            cancelForGetAssessmentLogs = c;
          }),
          params,
        })
        .then((response) => {
          store.dispatch({
            type: GET_ASSESSMENT_LOGS_REQUEST_SUCCESS,
            payload: {
              pageNo,
              total: response.data.total,
              data: response.data.data,
            },
          });
        })
        .catch((error) => {
          if (axios.isCancel(error)) return;
          store.dispatch({
            type: GET_ASSESSMENT_LOGS_REQUEST_FAILURE,
            payload: error.message,
          });
        });
    },

    getPatientInfo: function (userId) {
      store.dispatch({
        type: GET_PATIENT_INFO_REQUEST,
      });
      cancelForGetPatientInfo && cancelForGetPatientInfo();
      axiosInstance
        .get(`/ContactTracing/GetGloEpidUserById/${userId}`, {
          cancelToken: new CancelTokenForGetPatientInfo(function executor(c) {
            cancelForGetPatientInfo = c;
          }),
        })
        .then((response) => {
          store.dispatch({
            type: GET_PATIENT_INFO_REQUEST_SUCCESS,
            payload: response.data.data,
          });
        })
        .catch((error) => {
          store.dispatch({
            type: GET_PATIENT_INFO_REQUEST_FAILURE,
            payload: error.message,
          });
        });
    },

    getExportContactData: function ({ startDate, endDate, sourceContactIds }) {
      store.dispatch({
        type: GET_EXPORT_CONTACT_DATA_REQUEST,
      });
      cancelForExportContactData && cancelForExportContactData();
      axiosInstance
        .get(`/ContactTracing/exportData`, {
          cancelToken: new CancelTokenForExportContactData(function executor(
            c
          ) {
            cancelForExportContactData = c;
          }),
          params: { startDate, endDate, sourceContactIds },
        })
        .then((response) => {
          store.dispatch({
            type: GET_EXPORT_CONTACT_DATA_REQUEST_SUCCESS,
            payload: response.data,
          });
        })
        .catch((error) => {
          store.dispatch({
            type: GET_EXPORT_CONTACT_DATA_REQUEST_FAILURE,
            payload: error.message,
          });
        });
    },
  };

  return _ContactTraceRepository;
}

export { ContactTraceRepository };

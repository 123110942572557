import React from "react";
import { Drawer as AntdDrawer, Button } from "antd";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

const Drawer = (props) => {
  const classes = useStyles();
  const {
    title,
    visible,
    onClose,
    okText,
    onOk,
    isLoading,
    hideFooter,
    children,
    hideCancel,
    overrideAntBodyPadding,
  } = props;
  const _style = overrideAntBodyPadding
    ? { padding: "0px" }
    : { padding: "20px" };
  return (
    <AntdDrawer
      title={title}
      className={classes.drawer}
      width={475}
      onClose={onClose}
      visible={visible}
      bodyStyle={_style}
      headerStyle={{ padding: 20 }}
      footerStyle={{ padding: 20, textAlign: "right" }}
      footer={
        hideFooter ? null : (
          <>
            {!hideCancel ? (
              <Button onClick={onClose} style={{ marginRight: 20 }}>
                Cancel
              </Button>
            ) : null}
            <Button loading={isLoading} onClick={onOk} type="primary">
              {okText}
            </Button>
          </>
        )
      }
    >
      {children}
    </AntdDrawer>
  );
};

const useStyles = makeStyles({
  drawer: {
    "& .ant-drawer-content": {
      borderTopLeftRadius: 6,
      borderBottomLeftRadius: 6,
    },
  },
});

AntdDrawer.propTypes = {
  ...AntdDrawer.propTypes,
  okText: PropTypes.string,
  onOk: PropTypes.func,
  children: PropTypes.node,
  hideFooter: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default React.memo(Drawer);

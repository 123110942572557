import React from "react";
import Chart from "react-apexcharts";
import PropTypes from "prop-types";

const BarChart = (props) => {
  const { options, series } = props;

  return (
    <div className="BarChart">
      <Chart
        options={options}
        series={series}
        type="bar"
        width={"100%"}
        height={320}
      />
    </div>
  );
};

BarChart.propTypes = {
  options: PropTypes.object,
  series: PropTypes.array,
};

export default BarChart;

import {
  ADD_PARTNER_REQUEST,
  EDIT_PARTNER_REQUEST,
  ADD_PARTNER_REQUEST_SUCCESS,
  ADD_PARTNER_REQUEST_FAILURE,
  EDIT_PARTNER_REQUEST_FAILURE,
  EDIT_PARTNER_REQUEST_SUCCESS,
} from "./actionTypes";

const initialState = {
  isFetching: false,
  status: null,
  errorMessage: "",
};

export const PartnersFormStatus = {
  ADD_PARTNER_REQUEST_SUCCESS: `add_partner_request_success`,
  ADD_PARTNER_REQUEST_FAILURE: `add_partner_request_failure`,
  EDIT_PARTNER_REQUEST_SUCCESS: `edit_partner_request_success`,
  EDIT_PARTNER_REQUEST_FAILURE: `edit_partner_request_failure`,
};

const partnersFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PARTNER_REQUEST:
    case EDIT_PARTNER_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case ADD_PARTNER_REQUEST_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        status: PartnersFormStatus.ADD_PARTNER_REQUEST_SUCCESS,
      };
    }
    case EDIT_PARTNER_REQUEST_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        status: PartnersFormStatus.EDIT_PARTNER_REQUEST_SUCCESS,
      };
    }
    case ADD_PARTNER_REQUEST_FAILURE: {
      return {
        ...state,
        isFetching: false,
        status: PartnersFormStatus.ADD_PARTNER_REQUEST_FAILURE,
        errorMessage: action.payload,
      };
    }
    case EDIT_PARTNER_REQUEST_FAILURE: {
      return {
        ...state,
        isFetching: false,
        status: PartnersFormStatus.EDIT_PARTNER_REQUEST_FAILURE,
        errorMessage: action.payload,
      };
    }    
    default:
      return state;
  }
};

export const getPartnersFormIsFetchingState = (state) =>
  state.partnersForm.isFetching;
export const getPartnersFormErrorMessageState = (state) =>
  state.partnersForm.errorMessage;
export const getPartnersFormStatusState = (state) => state.partnersForm.status;

export default partnersFormReducer;

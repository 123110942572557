import axios from "axios";
import {
  GET_ASSESSMENTS_REQUEST,
  GET_ASSESSMENTS_REQUEST_SUCCESS,
  GET_ASSESSMENTS_REQUEST_FAILURE,
  RESET_ASSESSMENTS_STORE,
  GET_ASSESSMENT_SOURCES_REQUEST,
  GET_ASSESSMENT_SOURCES_REQUEST_SUCCESS,
  GET_ASSESSMENT_SOURCES_REQUEST_FAILURE,
  GET_ASSESSMENTS_REPORT_DETAILS_REQUEST,
  GET_ASSESSMENTS_REPORT_DETAILS_REQUEST_SUCCESS,
  GET_ASSESSMENTS_REPORT_DETAILS_REQUEST_FAILURE,
  RESET_ASSESSMENTS_REPORT_DETAILS_STORE,
  GET_STATE_DEMOGRAPHY_DATA_REQUEST,
  GET_STATE_DEMOGRAPHY_DATA_REQUEST_SUCCESS,
  GET_STATE_DEMOGRAPHY_DATA_REQUEST_FAILURE,
  GET_ASSESSMENTS_BY_PERIOD_REQUEST,
  GET_ASSESSMENTS_BY_PERIOD_REQUEST_SUCCESS,
  GET_ASSESSMENTS_BY_PERIOD_REQUEST_FAILURE,
  GET_ASSESSMENTS_SUMMARY,
  GET_ASSESSMENTS_SUMMARY_FAILURE,
  GET_ASSESSMENTS_SUMMARY_SUCCESSS,
  RESET_ASSESSMENTS_SUMMARY,
  GET_ASSESSMENTS_SUMMARY_BY_STATUS,
  GET_ASSESSMENTS_SUMMARY_BY_STATUS_SUCCESS,
  GET_ASSESSMENTS_SUMMARY_BY_STATUS_FAILURE,
  // RESET_ASSESSMENTS_SUMMARY_BY_STATUS,
  GET_PARTNER_SOURCES_REQUEST,
  GET_PARTNER_SOURCES_REQUEST_SUCCESS,
  GET_PARTNER_SOURCES_REQUEST_FAILURE,
  RESET_PARTNER_SOURCES_STORE,
  GET_HEATMAP_DATA_REQUEST,
  GET_HEATMAP_DATA_REQUEST_SUCCESS,
  GET_HEATMAP_DATA_REQUEST_FAILURE,
  UPDATE_ACTION_STATUS_SUCCESS,
  UPDATE_ACTION_STATUS_FAILURE,
  GET_CSV_URL_REQUEST,
  GET_CSV_URL_REQUEST_SUCCESS,
  GET_CSV_URL_REQUEST_FAILURE
} from "../redux/Assessment/actionTypes";
import store from "../redux/store";
// import { getStates } from "../utils/States";

function AssessmentsRepository(axiosInstance) {
  const CancelTokenForGetAssessmentDemographyData = axios.CancelToken;
  let cancelForGetAssessmentDemographyData = null;
  const CancelTokenForAssessmentsCases = axios.CancelToken;
  const CancelTokenForGetAssessmentSources = axios.CancelToken;
  const cancelTokenForGetAssessmentReportDetails = axios.CancelToken;
  const CancelTokenForGetAssessmentsByPeriod = axios.CancelToken;
  const CancelTokenForGetPartnerSources = axios.CancelToken;
  const CancelTokenForGetHeatmapData = axios.CancelToken;
  const CancelTokenForGetCSVUrl = axios.CancelToken;

  let cancelForAssessmentsCases = null;
  let cancelForGetAssessmentSources = null;
  let cancelForGetAssessmentReportDetails = null;
  let cancelForGetAssessmentsByPeriod = null;
  let cancelForGetPartnerSources = null;
  let cancelForGetHeatmapData = null;
  let cancelForGetCSVUrl = null;

  let _AssessmentsRepository = {
    resetAssessmentsStore: function() {
      store.dispatch({
        type: RESET_ASSESSMENTS_STORE
      });
    },

    getAssessmentsCases: function({ pageNo, currentData, ...filterParams }) {
      // pageSize,
      // pageNo,
      // keyword,
      // state,
      // channel,
      // riskStatus,
      pageNo === 0 && _AssessmentsRepository.resetAssessmentsStore();

      store.dispatch({
        type: GET_ASSESSMENTS_REQUEST,
        payload: {
          pageNo,
          currentData
        }
      });
      cancelForAssessmentsCases && cancelForAssessmentsCases();
      axiosInstance
        .get("/Assessment/dashboard-GetAllAssessment", {
          cancelToken: new CancelTokenForAssessmentsCases(function executor(c) {
            cancelForAssessmentsCases = c;
          }),
          params: { pageNo, ...filterParams }
        })
        .then(response => {
          store.dispatch({
            type: GET_ASSESSMENTS_REQUEST_SUCCESS,
            payload: {
              pageNo,
              total: response.data.total,
              data: response.data.data
            }
          });
        })
        .catch(error => {
          store.dispatch({
            type: GET_ASSESSMENTS_REQUEST_FAILURE,
            payload: error.message
          });
        });
    },

    getAssessementsByDemography: function(filterParams) {
      store.dispatch({
        type: GET_STATE_DEMOGRAPHY_DATA_REQUEST
      });
      cancelForGetAssessmentDemographyData &&
        cancelForGetAssessmentDemographyData();
      axiosInstance
        .get(`Assessment/dashboard-GetAssessmentGroupByState-AgeGroup`, {
          cancelToken: new CancelTokenForGetAssessmentDemographyData(
            function executor(c) {
              cancelForGetAssessmentDemographyData = c;
            }
          ),
          params: filterParams
        })
        .then(response => {
          store.dispatch({
            type: GET_STATE_DEMOGRAPHY_DATA_REQUEST_SUCCESS,
            payload: {
              data: response.data.data
            }
          });
        })
        .catch(error => {
          store.dispatch({
            type: GET_STATE_DEMOGRAPHY_DATA_REQUEST_FAILURE,
            payload: error.message
          });
        });
    },

    getAssessmentSources: function(stateId) {
      store.dispatch({
        type: GET_ASSESSMENT_SOURCES_REQUEST
      });
      cancelForGetAssessmentSources && cancelForGetAssessmentSources();
      axiosInstance
        .get(
          `/Assessment/dashboard-GetAssessmentsGroupByChannel?stateId=${stateId}`,
          {
            cancelToken: new CancelTokenForGetAssessmentSources(
              function executor(c) {
                cancelForGetAssessmentSources = c;
              }
            )
          }
        )
        .then(response => {
          store.dispatch({
            type: GET_ASSESSMENT_SOURCES_REQUEST_SUCCESS,
            payload: {
              data: response.data.data
            }
          });
        })
        .catch(error => {
          store.dispatch({
            type: GET_ASSESSMENT_SOURCES_REQUEST_FAILURE,
            payload: error.message
          });
        });
    },

    resetAssessmentReportDetailStore: function() {
      store.dispatch({
        type: RESET_ASSESSMENTS_REPORT_DETAILS_STORE
      });
    },

    getAssessmentReportDetail: function(id) {
      store.dispatch({
        type: GET_ASSESSMENTS_REPORT_DETAILS_REQUEST
      });
      cancelForGetAssessmentReportDetails &&
        cancelForGetAssessmentReportDetails();
      axiosInstance
        .get(`/Assessment/GetAssessment/${id}`, {
          cancelToken: new cancelTokenForGetAssessmentReportDetails(
            function executor(c) {
              cancelForGetAssessmentReportDetails = c;
            }
          )
        })
        .then(response => {
          store.dispatch({
            type: GET_ASSESSMENTS_REPORT_DETAILS_REQUEST_SUCCESS,
            payload: {
              data: response.data
            }
          });
        })
        .catch(error => {
          if (axios.isCancel(error)) {
            // this ensures that no error message is shown as a result of a request
            // override by a new request
            return;
          }
          store.dispatch({
            type: GET_ASSESSMENTS_REPORT_DETAILS_REQUEST_FAILURE,
            payload: error.message
          });
        });
    },
    getAssessementsByPeriod: function(
      stateId = "",
      totalRecords = 12,
      periodId = 1
    ) {
      store.dispatch({
        type: GET_ASSESSMENTS_BY_PERIOD_REQUEST
      });
      cancelForGetAssessmentsByPeriod && cancelForGetAssessmentsByPeriod();
      axiosInstance
        .get(
          `/Assessment/dashboard-GetAssessmentsGroupByPeriod/?stateId=${stateId}&count=${totalRecords}&period=${periodId}`,
          {
            cancelToken: new CancelTokenForGetAssessmentsByPeriod(
              function executor(c) {
                cancelForGetAssessmentsByPeriod = c;
              }
            )
          }
        )
        .then(response => {
          console.log(
            "dashboard-GetAssessmentsGroupByPeriod",
            response.data.data
          );
          store.dispatch({
            type: GET_ASSESSMENTS_BY_PERIOD_REQUEST_SUCCESS,
            payload: {
              data: response.data.data,
              stateId: stateId
            }
          });
        })
        .catch(error => {
          store.dispatch({
            type: GET_ASSESSMENTS_BY_PERIOD_REQUEST_FAILURE,
            payload: error.message
          });
        });
    },
    resetAssessmentSummary: function() {
      store.dispatch({
        type: RESET_ASSESSMENTS_SUMMARY
      });
    },

    getAssessmentSummary: function(stateId = "") {
      store.dispatch({
        type: GET_ASSESSMENTS_SUMMARY
      });

      axiosInstance
        .get(`Assessment/dashboard-assessmentCount?stateId=${stateId}`)
        .then(res => {
          store.dispatch({
            type: GET_ASSESSMENTS_SUMMARY_SUCCESSS,
            payload: {
              data: res.data.data
            }
          });
        })
        .catch(error => {
          store.dispatch({
            type: GET_ASSESSMENTS_SUMMARY_FAILURE,
            payload: error.message
          });
        });
    },

    getAssessmentSummaryByStatus: function(status) {
      store.dispatch({
        type: GET_ASSESSMENTS_SUMMARY_BY_STATUS
      });

      axiosInstance
        .get(`Assessment/dashboard-GetAssessmentsGroupByChannel`, {
          params: { status }
        })
        .then(res => {
          store.dispatch({
            type: GET_ASSESSMENTS_SUMMARY_BY_STATUS_SUCCESS,
            payload: {
              data: res.data.data
            }
          });
        })
        .catch(error => {
          store.dispatch({
            type: GET_ASSESSMENTS_SUMMARY_BY_STATUS_FAILURE,
            payload: error.message
          });
        });
    },

    resetPartnerSourcesStore: function() {
      store.dispatch({
        type: RESET_PARTNER_SOURCES_STORE
      });
    },

    getPartnerSources: function() {
      store.dispatch({
        type: GET_PARTNER_SOURCES_REQUEST
      });
      cancelForGetPartnerSources && cancelForGetPartnerSources();
      axiosInstance
        .get("/Assessment/sources", {
          cancelToken: new CancelTokenForGetPartnerSources(function executor(
            c
          ) {
            cancelForGetPartnerSources = c;
          })
        })
        .then(response => {
          const { success, message, data } = response.data;
          if (success)
            store.dispatch({
              type: GET_PARTNER_SOURCES_REQUEST_SUCCESS,
              payload: data
            });
          else
            store.dispatch({
              type: GET_PARTNER_SOURCES_REQUEST_FAILURE,
              payload: message
            });
        })
        .catch(error => {
          if (axios.isCancel(error)) return;
          store.dispatch({
            type: GET_PARTNER_SOURCES_REQUEST_FAILURE,
            payload: error.message
          });
        });
    },

    getHeatmapData: function() {
      store.dispatch({
        type: GET_HEATMAP_DATA_REQUEST
      });
      cancelForGetHeatmapData && cancelForGetHeatmapData();
      axiosInstance
        .get("/CaseManagement/GetCaseVisualizationByState/NG", {
          cancelToken: new CancelTokenForGetHeatmapData(function executor(c) {
            cancelForGetHeatmapData = c;
          })
        })
        .then(response => {
          const { success, message, data } = response.data;
          if (success)
            store.dispatch({
              type: GET_HEATMAP_DATA_REQUEST_SUCCESS,
              payload: data
            });
          else
            store.dispatch({
              type: GET_HEATMAP_DATA_REQUEST_FAILURE,
              payload: message
            });
        })
        .catch(error => {
          if (axios.isCancel(error)) return;
          store.dispatch({
            type: GET_HEATMAP_DATA_REQUEST_FAILURE,
            payload: error.message
          });
        });
    },

    updateActionStatus(assessmentId, statusId) {
      axiosInstance
        .patch(`/Assessment/${assessmentId}/assessment-status`, {
          status: statusId
        })
        .then(() => {
          store.dispatch({
            type: UPDATE_ACTION_STATUS_SUCCESS,
            payload: {
              assessmentId,
              statusId
            }
          });
        })
        .catch(error => {
          if (error.response) {
            const { message } = error.response;
            store.dispatch({
              type: UPDATE_ACTION_STATUS_FAILURE,
              payload: message
            });
          } else {
            store.dispatch({
              type: UPDATE_ACTION_STATUS_FAILURE,
              payload: error.message
            });
          }
        });
    },

    getCSVUrl(params) {
      store.dispatch({
        type: GET_CSV_URL_REQUEST
      });
      cancelForGetCSVUrl && cancelForGetCSVUrl();
      axiosInstance
        .get(`/Assessment/dashboard-CreateCSV`, {
          params,
          cancelToken: new CancelTokenForGetCSVUrl(function executor(c) {
            cancelForGetCSVUrl = c;
          })
        })
        .then(response => {
          store.dispatch({
            type: GET_CSV_URL_REQUEST_SUCCESS,
            payload: response.data.data
          });
        })
        .catch(error => {
          let errorMessage = error.message;
          if (error.response) {
            errorMessage = error.response?.data?.message || errorMessage;
          }

          store.dispatch({
            type: GET_CSV_URL_REQUEST_FAILURE,
            payload: errorMessage
          });
        });
    }
  };

  return _AssessmentsRepository;
}

export { AssessmentsRepository };

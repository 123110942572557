import {
  SET_FORM_FIELD,
  SET_INITIAL_FIELD_VALUES,
  RESET_FORM_FIELDS,
} from "./actionTypes";

const initialState = {
  form: {
    fullName: "",
    phoneNumber: "",
    contactLocation: "",
    time: "",
    date: "",
    doctorsNote: "",
  },
};

const newContactFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FORM_FIELD: {
      const { id, value } = action.payload;
      return {
        ...state,
        form: {
          ...state.form,
          [id]: value,
        },
      };
    }
    case SET_INITIAL_FIELD_VALUES: {
      return {
        ...state,
        form: action.payload,
      };
    }
    case RESET_FORM_FIELDS: {
      return {
        ...state,
        form: initialState.form,
      };
    }
    default: {
      return state;
    }
  }
};

export const getFormState = (state) => state.newContactForm.form;

export default newContactFormReducer;

/** @format */

export const GET_PARTNER_CHANNELS_REQUEST = "GET_PARTNER_CHANNELS_REQUEST";
export const GET_PARTNER_CHANNELS_REQUEST_SUCCESS =
  "GET_PARTNER_CHANNELS_REQUEST_SUCCESS";
export const GET_PARTNER_CHANNELS_REQUEST_FAILURE =
  "GET_PARTNER_CHANNELS_REQUEST_FAILURE";
export const RESET_PARTNER_CHANNELS_STORE = "RESET_PARTNER_CHANNELS_STORE";
export const GET_PARTNER = "GET_PARTNER";
export const ADD_PARTNER = "ADD_PARTNER";
export const UPDATE_PARTNER = "UPDATE_PARTNER";
export const GET_PARTNER_TOKEN = "GET_PARTNER_TOKEN";
export const REVOKE_PARTNER_TOKEN = "REVOKE_PARTNER_TOKEN";
export const GET_PARTNER_STAT = "GET_PARTNER_STAT";
export const GET_PARTNER_STAT_LOADING = "GET_PARTNER_STAT_LOADING";
export const GET_PARTNER_LOADING = "GET_PARTNER_LOADING";
export const GENERATE_PARTNER_TOKEN = "GENERATE_PARTNER_TOKEN";
export const GENERATE_PARTNER_TOKEN_LOADING = "GENERATE_PARTNER_TOKEN_LOADING";
export const TOGGLE_ACTIVATE_PARTNER = "TOGGLE_ACTIVATE_PARTNER";

export const GET_PARTNERS_REQUEST = "GET_PARTNERS_REQUEST";
export const GET_PARTNERS_REQUEST_SUCCESS = "GET_PARTNERS_REQUEST_SUCCESS";
export const GET_PARTNERS_REQUEST_FAILURE = "GET_PARTNERS_REQUEST_FAILURE";

export const GET_PARTNERS_STATS_REQUEST = "GET_PARTNERS_STATS_REQUEST";
export const GET_PARTNERS_STATS_REQUEST_SUCCESS =
  "GET_PARTNERS_STATS_REQUEST_SUCCESS";
export const GET_PARTNERS_STATS_REQUEST_FAILURE =
  "GET_PARTNERS_STATS_REQUEST_FAILURE";

export const ADD_PARTNER_REQUEST = "ADD_PARTNER_REQUEST";
export const ADD_PARTNER_REQUEST_SUCCESS = "ADD_PARTNER_REQUEST_SUCCESS";
export const ADD_PARTNER_REQUEST_FAILURE = "ADD_PARTNER_REQUEST_FAILURE";

export const EDIT_PARTNER_REQUEST = "EDIT_PARTNER_REQUEST";
export const EDIT_PARTNER_REQUEST_SUCCESS = "EDIT_PARTNER_REQUEST_SUCCESS";
export const EDIT_PARTNER_REQUEST_FAILURE = "EDIT_PARTNER_REQUEST_FAILURE";

export const TOGGLE_TOKEN_STATUS_REQUEST = "TOGGLE_TOKEN_STATUS_REQUEST";
export const TOGGLE_TOKEN_STATUS_REQUEST_SUCCESS =
  "TOGGLE_TOKEN_STATUS_REQUEST_SUCCESS";
export const TOGGLE_TOKEN_STATUS_REQUEST_ERROR =
  "TOGGLE_TOKEN_STATUS_REQUEST_ERROR";

export const REGENERATE_TOKEN_REQUEST = "REGENERATE_TOKEN_REQUEST";
export const REGENERATE_TOKEN_REQUEST_SUCCESS =
  "REGENERATE_TOKEN_REQUEST_SUCCESS";
export const REGENERATE_TOKEN_REQUEST_FAILURE =
  "REGENERATE_TOKEN_REQUEST_FAILURE";



import React, { useEffect } from "react";
import { InputNumber, message } from "antd";
import { makeStyles } from "@material-ui/styles";
import Form from "antd/es/form";
import Drawer from "../../atoms/Drawer";
import { Api } from "../../utils/Api";
import {
  getIsFetchingState,
  getStatusState,
  getCSVUrlState,
  getErrorMessageState,
  Status
} from "../../redux/Assessment/assessmentsCSVUrlReducer";
import { useSelector } from "react-redux";
import { RESET_CSV_URL_STORE } from "../../redux/Assessment/actionTypes";
import store from "../../redux/store";

const ExportAssessments = ({ totalItems, visible, onClose, params }) => {
  const classes = useStyles();

  const [form] = Form.useForm();

  const _onClose = () => {
    onClose();
    form.resetFields();
    store.dispatch({
      type: RESET_CSV_URL_STORE
    });
  };

  const isFetching = useSelector(getIsFetchingState);
  const errorMessage = useSelector(getErrorMessageState);
  const CSVUrl = useSelector(getCSVUrlState);
  const status = useSelector(getStatusState);

  useEffect(() => {
    if (status === Status.GET_CSV_URL_REQUEST_SUCCESS) {
      fetchCSV();
    }

    if (status === Status.GET_CSV_URL_REQUEST_FAILURE) {
      message.error(errorMessage);
      store.dispatch({
        type: RESET_CSV_URL_STORE
      });
    }
    // eslint-disable-next-line
  }, [errorMessage, status]);

  const fetchCSV = () => {
    const link = document.createElement("a");
    link.href = CSVUrl;
    link.download = "Assessment";
    document.body.appendChild(link);
    link.click();
    link.remove();
    message.success("Successfully downloaded CSV");
    _onClose();
  };

  const onSubmit = async values => {
    form.validateFields().then(values => {
      const { numberOfReports } = values;

      const newParams = {
        ...params,
        limit: numberOfReports,
        offset: 0
      };

      Api.AssessmentsRepository.getCSVUrl(newParams);
    });
  };

  const renderExtra = () => (
    <span className={"extra"}>
      The larger the number the longer it will take to generate the CSV file
    </span>
  );

  return (
    <Drawer
      title={"Assessments Download"}
      hideFooter={false}
      visible={visible}
      onClose={_onClose}
      okText={"Download CSV"}
      onOk={onSubmit}
      isLoading={isFetching}
      bodyStyle={{
        width: "400px",
        maxWidth: "100%"
      }}
      width={"unset"}
    >
      <Form
        initialValues={{
          numberOfReports: 1
        }}
        layout={"vertical"}
        className={classes.form}
        form={form}
      >
        <Form.Item
          label={"Number of Reports"}
          name={"numberOfReports"}
          rules={[
            {
              required: true,
              message: "Page Size is required"
            }
          ]}
          extra={renderExtra()}
        >
          <InputNumber
            min={1}
            max={totalItems}
            className={"input"}
            disabled={isFetching}
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

const useStyles = makeStyles({
  form: {
    "& .input": {
      width: "100%"
    },
    "& .extra": {
      fontSize: 12,
      marginTop: 5
    }
  }
});

export default ExportAssessments;

import React from "react";
import { Switch } from "react-router-dom";

import PrivateRoute from "../../routes/PrivateRoute";
import ContactList from "./ContactList";
import ContactDetails from "./ContactDetails";

const ContactTrace = (props) => {
  let {
    match: { path },
  } = props;

  return (
    <Switch>
      <PrivateRoute path={path} exact={true} component={ContactList} />
      <PrivateRoute
        path={`${path}/:caseId`}
        exact={true}
        component={ContactDetails}
      />
    </Switch>
  );
};

export default React.memo(ContactTrace);

import React, { useEffect, useState } from "react";
import { Card, Row, Col, Select, InputNumber, Spin, Button } from "antd";
import { makeStyles } from "@material-ui/styles";

import { notification } from "antd";
import LineChart from "./LineChart";
import DonutChart from "./DonutChart";
import States from "./States";
import { colors, fontSize, fontWeight } from "../../Css";
import HeatMap from "./HeatMap";

import { Api } from "../../utils/Api";
import {
  getAssessmentSourcesState,
  getErrorMessageState,
  getStatusState,
  Status,
  getIsFetchingState as getIsFetchingStateForAssessmentChannels
} from "../../redux/Assessment/assessmentSourcesReducer";
import {
  getStateIdState,
  getIsFetchingState as getIsFetchingStateForPeriodicAssessments
} from "../../redux/Assessment/assessmentsByPeriodReducer";
import { useSelector } from "react-redux";
import { formatNumber } from "../../utils";

const IllustrationCard = () => {
  const classes = useStyles();

  const data = useSelector(getAssessmentSourcesState);
  const status = useSelector(getStatusState);
  const errorMessage = useSelector(getErrorMessageState);
  const stateId = useSelector(getStateIdState);
  const isFetchingPeriodicAssessments = useSelector(
    getIsFetchingStateForPeriodicAssessments
  );
  const isFetchingAssessmentChannels = useSelector(
    getIsFetchingStateForAssessmentChannels
  );
  const lineChartFilterList = [
    { value: "Days", id: 1 },
    { value: "Weeks", id: 2 },
    { value: "Months", id: 3 }
  ];
  const [totalRecords, setTotalRecords] = useState(12);
  const [periodId, setPeriodId] = useState(1);
  const [selectedState, setSelectedState] = useState({
    id: "",
    stateName: "",
    stateCode: ""
  });

  const handleChangeSelectedState = stateItem => {
    if (!stateItem)
      setSelectedState({
        id: "",
        stateName: "",
        stateCode: ""
      });
    else setSelectedState(stateItem);
  };

  const { Option } = Select;

  useEffect(() => {
    Api.AssessmentsRepository.getAssessementsByPeriod(
      selectedState.id,
      totalRecords,
      periodId
    );
    Api.AssessmentsRepository.getAssessmentSources(selectedState.id);
    Api.AssessmentsRepository.getAssessmentSummary(selectedState.id);
    // eslint-disable-next-line
  }, [selectedState]);

  useEffect(() => {
    if (status === Status.GET_ASSESSMENT_SOURCES_REQUEST_FAILURE) {
      notification.error({
        message: "Something went wrong",
        description: errorMessage
      });
    }
  }, [status, errorMessage]);

  const handlePeriodSelection = value => {
    setPeriodId(value);
  };

  const handleFilterByPeriod = () => {
    Api.AssessmentsRepository.getAssessementsByPeriod(
      stateId,
      totalRecords,
      periodId
    );
  };

  const keys =
    data &&
    data.map((item, i) => {
      return item.Key || "Unknown";
    });
  const values =
    data &&
    data.map((item, i) => {
      return parseInt(item.Value);
    });

  const assessmentChannel =
    (data &&
      data.map((item, i) => {
        return { source: item.Key || "Unknown", value: item.Value };
      })) ||
    [];

  function renderBulletPoint(style) {
    return <div className={classes.bulletPoint} style={style}></div>;
  }

  function renderAssessmentChannel() {
    const bulletColors = [
      "#53A8E2",
      "#76DDFB",
      "#DBECF8",
      "#2C82BE",
      "#53A8E2"
    ];
    return (
      <>
        {assessmentChannel.map((el, index) => {
          if (index <= 4) {
            return (
              <div className={classes.assessmentContainer} key={index}>
                {renderBulletPoint({
                  backgroundColor: bulletColors[index] || el.bgColor,
                  marginRight: 10
                })}
                <div className={classes.assessmentChannel}>
                  <p>{el.source}</p>
                  <p>{formatNumber(el.value)}</p>
                </div>
              </div>
            );
          }
          return null;
        })}
      </>
    );
  }

  function renderHeatMap() {
    return (
      <Card style={{ marginBottom: 20 }}>
        <div className={classes.mapHeading}>
          <h4
            className={classes.heading}
            style={{
              margin: 0
            }}
          >
            Risk Concentration
          </h4>
          <div className={classes.affectedArea}>
            <div style={{ marginRight: 20 }}>
              {renderBulletPoint({
                backgroundColor: colors.error,
                marginRight: 7
              })}
              <p>Most affected</p>
            </div>
            <div>
              {renderBulletPoint({
                backgroundColor: "#FEAD9A",
                marginRight: 7
              })}
              <p>Less affected</p>
            </div>
          </div>
        </div>
        <div style={{ borderRadius: 4, height: 300 }}>
          <HeatMap selectedStateCode={selectedState.stateCode} />
        </div>
      </Card>
    );
  }

  return (
    <Card style={{ marginTop: 24 }}>
      <Row gutter={16}>
        <Col className="gutter-row" span={6}>
          <States
            selectedStateId={selectedState.id}
            onChangeSelectedState={handleChangeSelectedState}
          />
        </Col>
        <Col className="gutter-row" span={18}>
          <Row gutter={16}>
            <Col className="gutter-row" span={16}>
              {renderHeatMap()}
            </Col>
            <Col
              className={`gutter-row ${classes.assessmentSection}`}
              push={1}
              span={5}
            >
              <h4
                className={classes.heading}
                style={{
                  marginBottom: 20,
                  textAlign: "center"
                }}
              >
                Assessment Channel
              </h4>
              <Spin spinning={isFetchingAssessmentChannels}>
                {data && <DonutChart series={values} labels={keys} />}
                <div style={{ marginTop: 18, width: "100%" }}>
                  {renderAssessmentChannel()}
                </div>
              </Spin>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <div>
                <Spin spinning={isFetchingPeriodicAssessments}>
                  <Row>
                    <Col span={12}>
                      <h4
                        className={classes.heading}
                        style={{
                          marginBottom: 10
                        }}
                      >
                        Comparison by risk
                      </h4>
                    </Col>
                    <Col span={12} className={classes.filterInputContainer}>
                      <InputNumber
                        min={1}
                        max={50}
                        defaultValue={12}
                        onChange={value => {
                          setTotalRecords(value);
                        }}
                        className="numberInput"
                      />
                      <Select
                        defaultValue="Days"
                        onChange={handlePeriodSelection}
                        className="periodSelectInput"
                      >
                        {lineChartFilterList.map((item, i) => (
                          <Option key={item.id} value={item.id}>
                            {item.value}
                          </Option>
                        ))}
                      </Select>
                      <Button
                        className="applyFilterButton"
                        onClick={handleFilterByPeriod}
                      >
                        Filter
                      </Button>
                    </Col>
                  </Row>

                  <LineChart />
                </Spin>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

const useStyles = makeStyles({
  assessmentContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: 10
  },
  mapHeading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 10
  },
  affectedArea: {
    display: "flex",
    alignItems: "center",
    "& > div": {
      display: "flex",
      margin: 0,
      alignItems: "center",
      "& > p": {
        margin: 0,
        fontSize: fontSize.xsmall,
        color: colors.secondaryBase
      }
    }
  },
  heading: {
    color: colors.base,
    fontSize: fontSize.large,
    fontWeight: fontWeight.medium
  },
  bulletPoint: {
    height: 10,
    width: 10,
    marginRight: 10
  },
  assessmentChannel: {
    width: "100%",
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
    "& > p": {
      margin: 0,
      color: colors.grayishBlue,
      fontSize: fontSize.medium
    }
  },
  liveAssessmentSource: {
    display: "flex",
    alignItems: "center",

    "&> p": {
      margin: 0,
      color: colors.base,
      fontSize: fontSize.small * 2,
      fontWeight: 600,
      marginRight: 10,
      display: "inline-block"
    },
    "& > div": {
      display: "inline-flex",
      alignItems: "center",
      color: "#f4f6f8",
      backgroundColor: colors.primary,
      fontSize: 11,
      paddingRight: 8.4,
      fontWeight: "bold",
      borderRadius: 3.36,
      "& > span": {
        margin: 0,
        marginRight: 5,
        marginLeft: 5,
        padding: 0,
        fontSize: fontSize.medium * 2,
        lineHeight: "20px"
      }
    }
  },
  fillingAssessment: {
    color: colors.grayishBlue,
    fontSize: fontSize.small
  },
  assessmentSection: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column"
  },
  filterInputContainer: {
    textAlign: "right",
    paddingRight: 5,
    "& .numberInput, .periodSelectInput": {
      marginRight: 5
    },
    "& .applyFilterButton": {
      backgroundColor: "#f5f5f5a3"
    }
  }
});

export default IllustrationCard;

import {
  GET_ASSESSMENTS_SUMMARY,
  GET_ASSESSMENTS_SUMMARY_FAILURE,
  GET_ASSESSMENTS_SUMMARY_SUCCESSS,
  RESET_ASSESSMENTS_SUMMARY,
  GET_ASSESSMENTS_SUMMARY_BY_STATUS,
  GET_ASSESSMENTS_SUMMARY_BY_STATUS_SUCCESS,
  GET_ASSESSMENTS_SUMMARY_BY_STATUS_FAILURE,
} from "../Assessment/actionTypes";

const initialState = {
  isFetching: false,

  errorMessage: "",
  error: false,
  assessmentSummary: [],
  isFetchingSingle: false,
  isSingleErrorMessage: "",
  summary: [],
  singleStatus: null,
  status: null,
};

export const Status = {
  GET_ASSESSMENTS_SUMMARY: `get_assessments_summary`,
  GET_ASSESSMENTS_SUMMARY_FAILURE: `get_assessments_summary_failure`,
  GET_ASSESSMENTS_SUMMARY_SUCCESSS: `get_assesments_summary_success`,

  GET_ASSESSMENTS_SUMMARY_BY_STATUS: `get_assessments_summary_by_status`,
  GET_ASSESSMENTS_SUMMARY_BY_STATUS_SUCCESS: `get_assessments_summary_by_status_success`,
  GET_ASSESSMENTS_SUMMARY_BY_STATUS_FAILURE: `get_assessments_summary_by_status_failure`,
};

const assessmentsSummaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ASSESSMENTS_SUMMARY:
      return {
        ...state,
        isFetching: true,
        status: Status.GET_ASSESSMENTS_SUMMARY,
        // assessmentSummary: getAssessmentSummary()
      };
    case GET_ASSESSMENTS_SUMMARY_SUCCESSS:
      console.log("from the reducer", action.payload);
      return {
        ...state,
        isFetching: false,
        status: Status.GET_ASSESSMENTS_SUMMARY_SUCCESSS,
        assessmentSummary: action.payload.data,
      };
    case GET_ASSESSMENTS_SUMMARY_FAILURE:
      return {
        ...state,
        isFetching: false,
        status: Status.GET_ASSESSMENTS_SUMMARY_FAILURE,
        errorMessage: action.payload,
      };

    case GET_ASSESSMENTS_SUMMARY_BY_STATUS:
      return {
        ...state,
        isFetchingSingle: true,
        singleStatus: Status.GET_ASSESSMENTS_SUMMARY_BY_STATUS,
      };
    case GET_ASSESSMENTS_SUMMARY_BY_STATUS_SUCCESS:
      console.log("from the reducer", action.payload);
      return {
        ...state,
        isFetchingSingle: false,
        singleStatus: Status.GET_ASSESSMENTS_SUMMARY_BY_STATUS_SUCCESS,
        summary: action.payload.data,
      };
    case GET_ASSESSMENTS_SUMMARY_BY_STATUS_FAILURE:
      return {
        ...state,
        isFetchingSingle: false,
        singleStatus: Status.GET_ASSESSMENTS_SUMMARY_BY_STATUS_FAILURE,
        isSingleErrorMessage: action.payload,
      };

    case RESET_ASSESSMENTS_SUMMARY:
      return initialState;
    default:
      return state;
  }
};

export const getAssessmentSummaryIsFetchingState = (state) =>
  state.assessmentsSummary.isFetching;
export const getAssessmentSummaryErrorMessageState = (state) =>
  state.assessmentsSummary.errorMessage;
export const getAssessmentSummaryStatusState = (state) =>
  state.assessmentsSummary.status;
export const getAssessmentSummary = (state) =>
  state.assessmentsSummary.assessmentSummary;

export const getSingleFetchingState = (state) =>
  state.assessmentsSummary.isFetchingSingle;
export const getSingleErrorMessageState = (state) =>
  state.assessmentsSummary.isSingleErrorMessage;
export const getSingleStatusState = (state) =>
  state.assessmentsSummary.singleStatus;
export const getSummaryByStatus = (state) => state.assessmentsSummary.summary;

export default assessmentsSummaryReducer;

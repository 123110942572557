import {
  GET_PARTNER_SOURCES_REQUEST,
  GET_PARTNER_SOURCES_REQUEST_SUCCESS,
  GET_PARTNER_SOURCES_REQUEST_FAILURE,
  RESET_PARTNER_SOURCES_STORE,
} from "./actionTypes";

const initialState = {
  status: null,
  errorMessage: "",
  partnerSources: [],
};

export const Status = {
  GET_PARTNER_SOURCES_REQUEST: "get_partner_sources_request",
  GET_PARTNER_SOURCES_REQUEST_SUCCESS: "get_partner_sources_request_success",
  GET_PARTNER_SOURCES_REQUEST_FAILURE: "get_partner_sources_request_failure",
};

const partnerSourcesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PARTNER_SOURCES_REQUEST:
      return {
        ...state,
        status: Status.GET_PARTNER_SOURCES_REQUEST,
        errorMessage: "",
        partnerSources: [],
      };
    case GET_PARTNER_SOURCES_REQUEST_SUCCESS:
      return {
        ...state,
        status: Status.GET_PARTNER_SOURCES_REQUEST_SUCCESS,
        partnerSources: action.payload,
      };
    case GET_PARTNER_SOURCES_REQUEST_FAILURE:
      return {
        ...state,
        status: Status.GET_PARTNER_SOURCES_REQUEST_FAILURE,
        errorMessage: action.payload,
      };
    case RESET_PARTNER_SOURCES_STORE:
      return { ...initialState };
    default:
      return state;
  }
};

export const getIsFetchingState = (state) =>
  state.partnerSources.status === Status.GET_PARTNER_SOURCES_REQUEST;
export const getErrorMessageState = (state) =>
  state.partnerSources.errorMessage;
export const getStatusState = (state) => state.partnerSources.status;
export const getPartnerSourcesState = (state) =>
  state.partnerSources.partnerSources;

export default partnerSourcesReducer;

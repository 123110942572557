import React from "react";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";

const GoogleAPIMap = ({ selectedContact }) => {
  const MyMapComponent = compose(
    withProps({
      googleMapURL:
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyAq1BoPCBWv6rtHeeoeRPsTtSCYVeyJTpA&v=3.exp&libraries=geometry,drawing,places",
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: <div style={{ height: `268px` }} />,
      mapElement: <div style={{ height: `100%` }} />
    }),
    withScriptjs,
    withGoogleMap
  )(props => (
    <GoogleMap
      defaultZoom={8}
      defaultCenter={{
        lat: selectedContact && selectedContact.latitude,
        lng: selectedContact && selectedContact.longitude
      }}
    >
      {props.isMarkerShown && (
        <Marker
          position={{
            lat: selectedContact && selectedContact.latitude,
            lng: selectedContact && selectedContact.longitude
          }}
        />
      )}
    </GoogleMap>
  ));

  return <div className="map">{<MyMapComponent isMarkerShown />}</div>;
};

export default GoogleAPIMap;

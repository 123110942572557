import React, {useEffect} from "react";
import { Form, Button, Input, Row, notification, Spin  } from "antd";
import AuthenticationLayout from "../components/AuthenticationLayout";
import { PublicPaths } from "../routes";
import { Api } from "../utils/Api";
import {
  Status,
  getIsFetchingState,
  getErrorMessageState,
  getStatusState,
} from "../redux/Authentication/forgotPasswordReducer";
import { useSelector } from "react-redux";

const ForgotPassword = (props) => {
  const [form] = Form.useForm();
  const { history } = props;

  const isFetching = useSelector(getIsFetchingState);
  const errorMessage = useSelector(getErrorMessageState);
  const status = useSelector(getStatusState);

  const handleNavigation = () => {
    history.push(PublicPaths.LOGIN);
  };

  const handleFinish = (values) => {
    const { email} = values;    
    Api.AuthRepository.forgotPassword(email);
  };

  useEffect(() => {
    if (status === Status.FORGOT_PASSWORD_REQUEST_FAILURE)
      notification["error"]({
        message: "Failed to login",
        description: errorMessage,
      });
      if (status === Status.FORGOT_PASSWORD_REQUEST_SUCCESS)
      notification["success"]({
        message: "Action Successful",
        description: "The link has been sent to your email",
      });
  }, [status, errorMessage]);

  return (
    <AuthenticationLayout
      title="Forgot Password"
      subTitle="Provide the email you used in registering your account"
      formTitle="Enter your email"
    >
    <Spin spinning={isFetching}>
      <Form
        form={form}
        onFinish={handleFinish}
        layout="vertical"
        hideRequiredMark={true}
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: "Please input your email!" }]}
        >
          <Input
            type="email"
            placeholder="enter your email address"
            name="email"
            autoFocus
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
            Send link
          </Button>
        </Form.Item>
        <Row justify="center">
          <Button type="link" onClick={handleNavigation}>
            Remember password?
          </Button>
        </Row>
      </Form>
      </Spin>
    </AuthenticationLayout>
  );
};

export default React.memo(ForgotPassword);

import { useState, useEffect, useRef } from "react";

export function useCallbackState(initialState) {
  const [state, setState] = useState(initialState);
  const callbackRef = useRef(null);

  useEffect(() => {
    const callback = callbackRef.current;
    callback && callback(state);
  }, [state]);

  function setCallbackState(updater, callback) {
    callbackRef.current = callback;
    setState(updater);
  }

  return [state, setCallbackState];
}

import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Row, notification, Menu, Checkbox } from "antd";
import { useSelector } from "react-redux";
import moment from "moment";

import { colors, fontWeight, fontSize, boxShadow } from "../../Css";
import ResourceTable, { Filter, Sorter } from "../../atoms/ResourceTable";
import { Api } from "../../utils/Api";
import * as Constants from "../../utils/Constants";
import Notification from "../../components/Notification/Notification";

import {
  Status,
  getIsFetchingState,
  getStatusState,
  getErrorMessageState,
  getCurrentCreatedCaseContactState,
  getTotalItemsCountState
} from "../../redux/CaseManagement/createdCaseContactReducer";
import { getStates } from "../../utils/States";
import { useCallbackState } from "../../hooks/useCallbackState";
import { NOTIFICATION_VARIANTS } from "../../utils/Notifications";
import Drawer from "../../atoms/Drawer";

const TAB_VIEWS = [
  { tab: "Contact Log", key: "0" },
  { tab: "Notification", key: "1" }
];

const TABLE_VIEWS = {
  cluster: "Cluster Map",
  list: "List view"
};

const CaseManagementProfileDetailsTable = props => {
  const [currentView] = useState(TABLE_VIEWS.list);
  const [currentTab, setCurrentTab] = useState("0");
  const [pageSize, setPageSize] = useState(Constants.DATA_LIMIT);
  const [currentPage, setCurrentPage] = useState(1);
  const [indexState, setIndexState] = useCallbackState(null);
  const [queryValue, setQueryValue] = useCallbackState("");
  const [sortOrder, setSortOrder] = useCallbackState(
    Constants.SORT_ORDER.latest.value
  );
  const [isContactDrawerOpen, setContactDrawerOpen] = useState(false);
  const [selectedContact, setSelectedContact] = useState();
  const isFetching = useSelector(getIsFetchingState);
  const status = useSelector(getStatusState);
  const errorMessage = useSelector(getErrorMessageState);
  const currentCaseManagement = useSelector(getCurrentCreatedCaseContactState);
  const totalItems = useSelector(getTotalItemsCountState);
  const classes = useStyles();
  const { createdCaseId } = props;

  useEffect(() => {
    if (status === Status.GET_CASE_MANAGEMENT_REQUEST_FAILURE) {
      if (errorMessage) {
        notification["error"]({
          message: "Failed to load cases",
          description: errorMessage
        });
      }
    }
  }, [status, errorMessage]);

  useEffect(() => {
    if (currentView === TABLE_VIEWS.list) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [currentView, pageSize, sortOrder, indexState, queryValue, currentPage]);

  const columns = () => [
    {
      title: "Name",
      dataIndex: "fullName",
      key: "fullName",
      render: renderTableName
    },
    {
      title: "State",
      key: "contactLocation",
      dataIndex: "contactLocation"
    },
    {
      title: "Date",
      dataIndex: "contacTime",
      key: "contacTime",
      render: date => {
        return moment(date).format("DD - MMM - YYYY / HH:mm");
      }
    }
  ];

  const disambiguateLabel = (key, value) => {
    const state = getStates().find(el => el.id === value);
    if (key === "state") {
      return `State: ${state.stateName}`;
    }
  };

  const showDrawer = record => {
    if (currentTab === "0") {
      setContactDrawerOpen(true);
      setSelectedContact(record);
    }
  };

  const fetchData = () => {
    Api.CaseManagementRepository.getCreatedCaseContact({
      createdCaseId,
      page: currentPage,
      pageSize,
      state: indexState,
      sortOrder,
      keyword: queryValue
    });
  };

  const onChangeSearchQuery = e => {
    setQueryValue(e.target.value);
    setCurrentPage(1);
  };

  const handleClearSearchQuery = () => {
    setQueryValue("");
    setCurrentPage(1);
  };

  const handleLoadMore = page => {
    setCurrentPage(page);
  };

  const handleIndexStateChange = value => e => {
    setIndexState(value);
  };

  const handleIndexStateRemove = () => {
    setIndexState(null);
  };

  const handleChangeSortOrder = value => {
    setSortOrder(value);
  };

  const renderTableName = (text, record) => {
    return (
      <Row className={classes.userNameContainer}>
        <div>
          <p>{record.fullName}</p>
          <p>{record.phoneNumber}</p>
        </div>
      </Row>
    );
  };

  const renderCluster = () => {
    return <div className={classes.clutterView} />;
  };

  const renderFilterStateControl = () => {
    return (
      <Menu className={classes.stateList}>
        {getStates().map(state => (
          <Menu.Item key={state.id}>
            <Checkbox
              checked={state.id === indexState}
              onChange={handleIndexStateChange(state.id)}
            >
              {state.stateName}
            </Checkbox>
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  // const renderChangeTabView = () => null;

  const renderNotification = () => (
    <div style={{ height: "700px" }}>
      <Notification
        caseId={props.createdCaseId}
        gloEpidUserId={props.gloEpidUserId}
        isFetchingCase={props.isFetchingCase}
        variant={NOTIFICATION_VARIANTS.CaseManagement}
      />
    </div>
  );

  const appliedFilters = [];
  if (!!indexState === true) {
    const key = "state";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, indexState),
      onRemove: handleIndexStateRemove
    });
  }

  const renderBody = () => {
    return (
      <div>
        <div className="details">
          <h5>Name</h5>
          <p>{selectedContact && selectedContact.fullName}</p>
        </div>
        <div className="details">
          <h5>Phone Number </h5>
          <p>{selectedContact && selectedContact.phoneNumber}</p>
        </div>
        <div className="details">
          <h5>Location</h5>
          <p>{selectedContact && selectedContact.contactLocation}</p>
        </div>
        <div className="details">
          <h5>Date</h5>
          <p>
            {moment(selectedContact && selectedContact.contactTime).format(
              "DD - MMM - YYYY"
            )}
          </p>
        </div>
        <div className="details">
          <h5>Note</h5>
          <p>{selectedContact && selectedContact.doctorsNote}</p>
        </div>
      </div>
    );
  };

  return (
    <>
      <ResourceTable
        resourceName={{ singular: "case", plural: "cases" }}
        filterControl={
          TAB_VIEWS[currentTab].key === "1" ? null : (
            <Filter
              filters={[
                {
                  key: "state",
                  label: "State",
                  filter: renderFilterStateControl(),
                  resetFilter: () => setIndexState(null)
                }
              ]}
              appliedFilters={appliedFilters}
              queryValue={queryValue}
              onQueryChange={onChangeSearchQuery}
              onQueryClear={handleClearSearchQuery}
              onPressEnter={onChangeSearchQuery}
            />
          )
        }
        sortControl={
          TAB_VIEWS[currentTab].key === "1" ? null : currentView ===
            TABLE_VIEWS.cluster ? null : (
            <Sorter
              numberOfSortedData={totalItems}
              currentSort={Constants.SORT_ORDER.latest.name}
              sorters={[
                {
                  name: Constants.SORT_ORDER.latest.name,
                  value: Constants.SORT_ORDER.latest.value
                },
                {
                  name: Constants.SORT_ORDER.oldest.name,
                  value: Constants.SORT_ORDER.oldest.value
                }
              ]}
              onChange={handleChangeSortOrder}
            />
          )
        }
        tableProps={{
          columns: columns(),
          dataSource: currentCaseManagement,
          loading: isFetching,
          rowKey: "id",
          onRow: record => ({
            onClick: () => showDrawer(record)
          }),
          pagination: {
            total: totalItems,
            current: currentPage,
            onChange: handleLoadMore,
            onShowSizeChange: (current, pageSize) => setPageSize(pageSize)
          }
        }}
        renderCustomElement={
          TAB_VIEWS[currentTab].key === "0" &&
          currentView === TABLE_VIEWS.cluster
            ? renderCluster()
            : TAB_VIEWS[currentTab].key === "1"
            ? renderNotification()
            : null
        }
        tabList={TAB_VIEWS}
        onChangeTab={value => setCurrentTab(value)}
      />
      <Drawer
        title="Contact Details"
        visible={isContactDrawerOpen}
        hideFooter={true}
        onClose={() => setContactDrawerOpen(false)}
      >
        <div className={classes.contactDrawer}>{renderBody()}</div>
      </Drawer>
    </>
  );
};

const useStyles = makeStyles({
  pageMetaData: {
    marginBottom: 21,
    "& p:first-child": {
      margin: 0,
      color: colors.base,
      fontWeight: fontWeight.medium,
      fontSize: fontSize.title
    }
  },
  actionHeader: {
    marginBottom: 20,
    "& span:nth-child(2)": {
      marginLeft: 18
    },
    "& span.gray-text": {
      color: colors.secondaryBase
    }
  },
  avatarContainer: {
    height: 40,
    width: 40,
    background: "#006FBB",
    marginRight: 9,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: colors.white,
    fontWeight: fontWeight.medium,
    fontSize: fontSize.medium,
    "& img": {
      width: "inherit",
      height: "inherit",
      borderRadius: "50%"
    }
  },
  userNameContainer: {
    "& p": {
      fontSize: fontSize.medium,
      color: colors.base,
      margin: 0
    },
    "& p:first-child": {
      fontWeight: fontWeight.medium
    }
  },
  clutterView: {
    width: "100%",
    height: "700px",
    padding: "0px 20px",
    paddingBottom: 20,
    "&>div": {
      boxShadow: boxShadow.card,
      background: colors.white,
      minHeight: 654
    }
  },
  stateList: {
    "&.ant-menu": {
      border: 0
    },
    "& .ant-menu-item": {
      padding: 0,
      border: 0,
      lineHeight: 2.0,
      height: "fit-content",
      margin: "0 !important"
    },
    "& .ant-menu-item span:last-child": {
      paddingRight: 0
    }
  },
  visualizationContainer: {
    width: "100%",
    height: "100%"
  },
  contactDrawer: {
    "& .details": {
      marginBottom: 18,
      "& h5": {
        fontSize: 12,
        color: colors.secondaryBase,
        margin: 0,
        fontWeight: "bold"
      },
      "& p": {
        color: colors.base,
        fontSize: 14,
        margin: 0
      }
    }
  }
});

export default React.memo(CaseManagementProfileDetailsTable);

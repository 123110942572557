import {
  GET_ASSESSMENTS_REPORT_DETAILS_REQUEST,
  GET_ASSESSMENTS_REPORT_DETAILS_REQUEST_SUCCESS,
  GET_ASSESSMENTS_REPORT_DETAILS_REQUEST_FAILURE,
  RESET_ASSESSMENTS_REPORT_DETAILS_STORE,
  UPDATE_ACTION_STATUS_SUCCESS,
  UPDATE_ACTION_STATUS_FAILURE,
  RESET_UPDATE_ACTION_STATUS_STORE,
} from "./actionTypes";
import { getActionStatusNameForId } from "../../utils/Assessment";

const initialState = {
  isFetching: false,
  errorMessage: "",
  status: null,
  reportDetails: {},
  updateStatusSuccess: null,
  updateStatusError: null,
  updateStatusMessage: null,
};

export const Status = {
  GET_ASSESSMENTS_REPORT_DETAILS_REQUEST_SUCCESS: `get_assessments_report_details_request_success`,
  GET_ASSESSMENTS_REPORT_DETAILS_REQUEST_FAILURE: `get_assessments_report_details_request_failure`,
};

const assessmentsReportDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ASSESSMENTS_REPORT_DETAILS_REQUEST:
      return {
        ...state,
        isFetching: true,
        status: Status.GET_ASSESSMENTS_REPORT_DETAILS_REQUEST,
      };
    case GET_ASSESSMENTS_REPORT_DETAILS_REQUEST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        status: Status.GET_ASSESSMENTS_REPORT_DETAILS_REQUEST_SUCCESS,
        reportDetails: action.payload.data,
      };
    case GET_ASSESSMENTS_REPORT_DETAILS_REQUEST_FAILURE:
      return {
        ...state,
        isFetching: false,
        status: Status.GET_ASSESSMENTS_REPORT_DETAILS_REQUEST_FAILURE,
        errorMessage: action.payload,
      };
    case RESET_ASSESSMENTS_REPORT_DETAILS_STORE:
      return initialState;
    case UPDATE_ACTION_STATUS_SUCCESS:
      return {
        ...state,
        reportDetails: {
          ...state.reportDetails,
          status: getActionStatusNameForId(action.payload.statusId)
        },
        updateStatusSuccess: true,
        updateStatusError: false
      }
    case UPDATE_ACTION_STATUS_FAILURE:
      return {
        ...state,
        updateStatusSuccess: false,
        updateStatusError: true,
        updateStatusMessage:
          action.payload ||
          "There was an error updating the status. Kindly try again.",
      }
    case RESET_UPDATE_ACTION_STATUS_STORE: {
      return {
        ...state,
        updateStatusSuccess: null,
        updateStatusError: null,
        updateStatusMessage: null,
      }
    }
    default:
      return state;
  }
};

export const getIsFetchingState = (state) =>
  state.assessmentReportDetails.isFetching;
export const getErrorMessageState = (state) =>
  state.assessmentReportDetails.errorMessage;
export const getStatusState = (state) => state.assessmentReportDetails.status;
export const getAssessmentReportDetailState = (state) =>
  state.assessmentReportDetails.reportDetails;
export const getUpdateActionStatusSuccessState = (state) =>
  state.assessmentReportDetails.updateStatusSuccess
export const getUpdateActionStatusErrorState = (state) =>
  state.assessmentReportDetails.updateStatusError
export const getUpdateActionStatusMessageState = (state) =>
  state.assessmentReportDetails.updateStatusMessage

export default assessmentsReportDetailsReducer;

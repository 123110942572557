import {
  ADD_CONTACT,
  ADD_SYMPTOM,
  DELETE_SYMPTOM,
  EDIT_CONTACT_DETAILS,
  ADD_NEW_CASE_REQUEST_ERROR,
  ADD_NEW_CASE_REQUEST_SUCCESS,
  EDIT_CASE_REQUEST_SUCCESS,
  EDIT_CASE_REQUEST_ERROR,
  SET_INITIAL_CASE_VALUES,
  RESET_NEW_CASE_FORM,
  UPLOAD_FILES_SUCCESS,
  UPLOAD_FILES_ERROR,
} from "./actionTypes";

const initialState = {
  contacts: [],
  symptoms: [],
  success: null,
  error: null,
  statusMessage: null,
  uploadSuccess: null,
  files: [],
};

const newCaseReducer = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_CONTACT_DETAILS: {
      const newContacts = [...state.contacts];
      newContacts[action.payload.index] = action.payload.contact;
      return {
        ...state,
        contacts: newContacts,
      };
    }
    case ADD_CONTACT: {
      return {
        ...state,
        contacts: Array.isArray(action.payload)
          ? [...state.contacts, ...action.payload]
          : [...state.contacts, action.payload],
      };
    }
    case ADD_SYMPTOM: {
      return {
        ...state,
        symptoms: Array.isArray(action.payload)
          ? [...state.symptoms, ...action.payload]
          : [...state.symptoms, action.payload],
      };
    }
    case DELETE_SYMPTOM: {
      return {
        ...state,
        symptoms: state.symptoms.filter(
          (symptom) => symptom !== action.payload
        ),
      };
    }
    case ADD_NEW_CASE_REQUEST_SUCCESS:
    case EDIT_CASE_REQUEST_SUCCESS: {
      return {
        ...state,
        success: true,
        statusMessage: action.payload.message,
      };
    }
    case ADD_NEW_CASE_REQUEST_ERROR:
    case EDIT_CASE_REQUEST_ERROR: {
      return {
        ...state,
        error: true,
        statusMessage: action.payload.message,
      };
    }
    case RESET_NEW_CASE_FORM:
      return initialState;
    case SET_INITIAL_CASE_VALUES: {
      return {
        ...state,
        contacts: action.payload.contacts,
        symptoms: action.payload.symptoms,
      };
    }
    case UPLOAD_FILES_SUCCESS: {
      return {
        ...state,
        uploadSuccess: true,
        files: action.payload,
      };
    }
    case UPLOAD_FILES_ERROR: {
      return {
        ...state,
        uploadSuccess: false,
        statusMessage:
          action.payload ||
          "Something went wrong with uploading the files. Kindly try again",
      };
    }
    default: {
      return state;
    }
  }
};

export const getContactsState = (state) => state.newCase.contacts;
export const getSymptomsState = (state) => state.newCase.symptoms;
export const getErrorState = (state) => state.newCase.error;
export const getSuccessState = (state) => state.newCase.success;
export const getUploadSuccessState = (state) => state.newCase.uploadSuccess;
export const getFilesState = (state) => state.newCase.files;
export const getStatusMessageState = (state) => state.newCase.statusMessage;

export default newCaseReducer;

/* This file helps to manage variables that take the same value in different components. Therefore changing the value here affects everywhere it is used */
export const DATA_LIMIT = 10;

export const SORT_ORDER = {
  latest: { name: "Latest data first", value: "latest" },
  oldest: { name: "Oldest data first", value: "oldest" },
};

export const RISK_STATUS = {
  low: "Low Risk",
  medium: "Medium Risk",
  high: "High Risk",
  confirmed: "Confirmed",
};

import {
  GET_PARTNERS_REQUEST,
  GET_PARTNERS_REQUEST_SUCCESS,
  GET_PARTNERS_REQUEST_FAILURE,
} from "./actionTypes";

const initialState = {
  isFetching: false,
  status: null,
  errorMessage: "",
  partners: [],
  pageNo: 0,
  total: 0,
  allPartnersByPageNumber: {},
  currentPartners: [], 
};

export const PartnersDataStatus = {
  GET_PARTNERS_REQUEST: `get_partners_request`,
  GET_PARTNER_REQUEST_SUCCESS: `get_partners_request_success`,
  GET_PARTNER_REQUEST_FAILURE: `get_partners_request_failure`,
};

const partnersDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PARTNERS_REQUEST: {
      return {
        ...state,
        status: PartnersDataStatus.GET_PARTNERS_REQUEST,
        currentPartners: action.payload.currentData,
        isFetching: true,
      };
    }
    case GET_PARTNERS_REQUEST_SUCCESS:
      return {
        ...state,
        status: PartnersDataStatus.GET_PARTNER_REQUEST_SUCCESS,
        partners: action.payload.data,
        currentPartners: action.payload.data,
        allPartnersByPageNumber: {
          ...state.allPartnersByPageNumber,
          [action.payload.pageNo]: action.payload.data,
        },
        pageNo: action.payload.pageNo,
        total: action.payload.total,
        isFetching: false,
      };

    case GET_PARTNERS_REQUEST_FAILURE: {
      return {
        ...state,
        status: PartnersDataStatus.GET_PARTNER_REQUEST_FAILURE,
        errorMessage: action.payload,
        isFetching: false,
      };
    }
    default:
      return state;
  }
};

export const getPartnersDataIsFetchingState = (state) =>
  state.partnersData.isFetching;
export const getPartnersDataErrorMessageState = (state) =>
  state.partnersData.errorMessage;
export const getPartnersDataStatusState = (state) => state.partnersData.status;
export const getPartnersDataState = (state) =>
  state.partnersData.currentPartners;
  export const getCurrentPageNoState = (state) => state.partnersData.pageNo;
  export const getTotalItemsCountState = (state) => state.partnersData.total;

export default partnersDataReducer;

import React from "react";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import { Skeleton } from "antd";
import { colors, fontSize, fontWeight, boxShadow } from "../Css";

export const STATUS_TYPE = {
  success: colors.success,
  error: colors.error,
  warning: colors.warning,
  info: colors.info,
  basic: colors.grey,
};

const StatisticCards = (props) => {
  const classes = useStyles();
  const { isLoading, title, value, icon, caption, type, hideCaption } = props;

  return (
    <div
      style={{
        borderBottom: `5px solid ${STATUS_TYPE[type] || "transparent"}`,
      }}
      className={classes.container}
    >
      <p className={classes.cardTitle}>
        <span>{title}</span>
        {icon && <img src={icon} alt="Statistics icon" />}
      </p>
      <Skeleton loading={isLoading} active title={false}>
        <p className={classes.cardValue}>{value}</p>
        {hideCaption === false && (
          <p className={classes.cardCaption}>{caption}</p>
        )}
      </Skeleton>
    </div>
  );
};

StatisticCards.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  caption: PropTypes.string,
  hideCaption: PropTypes.bool,
  isLoading: PropTypes.bool,
  icon: PropTypes.any,
  type: PropTypes.oneOf([Object.values(STATUS_TYPE)]),
};

StatisticCards.defaultProps = {
  hideCaption: false,
};

const useStyles = makeStyles({
  container: {
    boxShadow: boxShadow.card,
    outline: "1px solid transparent",
    padding: 20,
    display: "inline-block",
    width: "100%",
    color: colors.base,
    borderRadius: "5px",
    backgroundColor: colors.white,
    marginBottom: "10px",
  },
  cardTitle: {
    fontSize: fontSize.large,
    fontWeight: fontWeight.medium,
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 8,
    "& > span": {
      margin: 0,
      padding: 0,
    },
  },
  cardValue: {
    fontSize: fontSize.title,
    fontWeight: fontWeight.medium,
    lineHeight: "32px",
    margin: 0,
  },
  cardCaption: {
    paddingTop: "21px",
    fontSize: fontSize.medium,
    margin: 0,
    lineHeight: "20px",
  },
});

export default React.memo(StatisticCards);

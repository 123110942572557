import React, { useState, useCallback, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import Drawer from "../../atoms/Drawer";
import { Input, Select, Button, notification, Spin, Form } from "antd";
import { colors, fontSize, fontWeight } from "../../Css";
import { Api } from "../../utils/Api";
import { useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  Status,
  resetStaffPasswordIsFetchingState,
  resetStaffPasswordErrorMessageState,
  resetStaffPasswordStatusState,
  resetStaffPasswordNewPasswordState,
  resetStaffPasswordSuccessMessageState
} from "../../redux/Staffs/resetStaffPasswordReducer";
import {
  Status as StatusForRoleUpdate,
  updateStaffRoleStatusState,
  updateStaffRoleErrorMessageState,
  updateStaffRoleIsFetchingState
} from "../../redux/Staffs/updateStaffRoleReducer";
import { useUpdateEffect } from "../../hooks/useUpdateEffect";

const ResetStaffPasswordDrawer = ({
  staffId,
  staffRole,
  roles,
  visible,
  onClose
}) => {
  const classes = useStyles();
  const { Option } = Select;
  const [form] = Form.useForm();
  const status = useSelector(resetStaffPasswordStatusState);
  const newPassword = useSelector(resetStaffPasswordNewPasswordState);
  const isFetching = useSelector(resetStaffPasswordIsFetchingState);
  const successMessage = useSelector(resetStaffPasswordSuccessMessageState);
  const errorMessage = useSelector(resetStaffPasswordErrorMessageState);

  const isFetchingForRoleUpdate = useSelector(updateStaffRoleIsFetchingState);
  const statusForRoleUpdate = useSelector(updateStaffRoleStatusState);
  const errorMessageForRoleUpdate = useSelector(
    updateStaffRoleErrorMessageState
  );
  const [placeholder, setPlaceholder] = useState("");
  const getRolelId = useCallback(
    name => roles.find(({ Value }) => Value === name).Key,
    // eslint-disable-next-line
    [roles, staffId]
  );

  useEffect(() => {
    if (!!staffRole) {
      form.setFieldsValue({
        role: getRolelId(staffRole)
      });
    }
  }, [staffRole, form, getRolelId]);

  useUpdateEffect(() => {
    if (status === Status.RESET_STAFF_PASSWORD_REQUEST_FAILURE) {
      notification.error({
        message: "Something went wrong",
        description: errorMessage
      });
    }

    if (status === Status.RESET_STAFF_PASSWORD_REQUEST_SUCCESS) {
      setPlaceholder(newPassword);
      notification.success({
        message: "Password Successfully Reset",
        description: successMessage
      });
    }
  }, [status, errorMessage, successMessage]);

  useUpdateEffect(() => {
    if (
      statusForRoleUpdate ===
      StatusForRoleUpdate.CHANGE_STAFF_ROLE_REQUEST_FAILURE
    ) {
      notification.error({
        message: "Something went wrong",
        description: errorMessageForRoleUpdate
      });
    }
    if (
      statusForRoleUpdate ===
      StatusForRoleUpdate.CHANGE_STAFF_ROLE_REQUEST_SUCCESS
    ) {
      setPlaceholder(newPassword);
      notification.success({
        message: "Role changed Successfully",
        description: ""
      });
    }
  }, [statusForRoleUpdate, errorMessageForRoleUpdate]);

  const handleClose = () => {
    onClose();
    setPlaceholder("");
  };

  const handleResetPassword = () => {
    Api.StaffRepository.resetStaffPassword(staffId);
  };

  const handleSelection = option => {
    Api.StaffRepository.changeStaffRole(staffId, option);
  };

  return (
    <Drawer
      visible={visible}
      onClose={handleClose}
      title="Edit User"
      hideFooter="true"
    >
      <Spin spinning={isFetching || isFetchingForRoleUpdate}>
        <Form
          hideRequiredMark
          form={form}
          layout="vertical"
          name="editUserFrom"
        >
          <Form.Item
            name="role"
            label="Role"
            className={classes.formItem}
            rules={[{ required: true, message: "Please specify a role" }]}
          >
            <Select onChange={handleSelection}>
              {roles.map(({ Key, Value }) => (
                <Option key={Key} value={Key}>
                  {Value}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <div className={classes.resetPasswordContainer}>
            <p className="title"> PASSWORD RESET</p>
            <hr />

            <div className="generate-items">
              <Form.Item name="password">
                <Input
                  readOnly
                  placeholder={placeholder}
                  suffix={
                    <CopyToClipboard
                      text={newPassword}
                      onCopy={() => handleResetPassword()}
                    >
                      <Button>Generate</Button>
                    </CopyToClipboard>
                  }
                />
              </Form.Item>
            </div>
          </div>
        </Form>
      </Spin>
    </Drawer>
  );
};

const useStyles = makeStyles({
  resetPasswordContainer: {
    padding: 20,
    backgroundColor: colors.lightGrey,
    borderRadius: "10px",
    "& .title": {
      color: colors.base,
      fontWeight: fontWeight.medium,
      fontSize: fontSize.medium
    },
    "& .generate-items": {
      marginTop: 20,
      marginBottom: 10,
      padding: 5,
      backgroundColor: colors.lightGrey,
      borderWidth: "2px",
      borderSyle: "solid",
      borderColor: colors.grayishBlue,
      display: "flex",
      justifyContent: "space-between",
      borderRadius: "4px",
      alignItems: "flex-end",
      flexDirection: "column"
    },

    "& .ant-form-item": {
      width: "100%"
    },
    "& .ant-input-affix-wrapper, .ant-input": {
      background: "transparent"
    }
  },
  formItem: {
    "& .ant-form-item-label": {
      paddingBottom: 0
    }
  }
});

export default ResetStaffPasswordDrawer;

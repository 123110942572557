import React from "react";
import Chart from "react-apexcharts";

const DonutChart = ({ series, labels }) => {
  const options = {
    chart: {
      id: "assessment-source",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      donut: {
        customScale: 3,
        offsetX: 10,
        offsetY: 30,
      },
    },
    labels: labels,
    dataLabels: {
      enabled: true,
      style: {
        fontSize: 10,
        fontWeight: 400,
      },
    },
    stroke: {
      width: 0,
    },
    legend: {
      show: false,
    },

    colors: ["#53A8E2", "#76DDFB", "#DBECF8", "#2C82BE"],
    tooltip: {
      enabled: true,
      enabledOnSeries: undefined,
    },
  };

  return <Chart options={options} series={series} type="donut" />;
};

export default React.memo(DonutChart);

import React from "react";
import PropTypes from "prop-types";
import { Card, Table, Menu, Dropdown } from "antd";
import { makeStyles } from "@material-ui/styles";
import { MoreOutlined } from "@ant-design/icons";

import { colors, fontSize, boxShadow } from "../../Css";

/**
 * It is the resuable table component of the entire app.
 * It is a controlled element
 * Its' props are quite similar to the Card props of Antd design , tableProps are quire similar to that of Antd Design Table
 * @see https://ant.design/components/table/
 * @see https://ant.design/components/card/#components-card-demo-tabs
 */
const ResourceTable = (props) => {
  const {
    tabList,
    onChangeTab,
    filterControl,
    sortControl,
    resourceName,
    tableProps,
    onSelectOption,
    options,
    renderCustomElement,
    renderCustomTableHeading,
    tabBarExtraContent
  } = props;
  const classes = useStyles(props);

  function handleSelectOption(event, option, rowData) {
    event.stopPropagation();
    onSelectOption(option, rowData);
  }

  const menu = (rowData) => {
    let _options = typeof options === "function" ? options(rowData) : options;
    return (
      <Menu className={classes.options}>
        {_options.map((option, index) => (
          <Menu.Item
            key={index}
            onClick={({ domEvent }) =>
              handleSelectOption(domEvent, option, rowData)
            }
          >
            <span>{option}</span>
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  const optionsRow = {
    title: "Action",
    dataIndex: "options",
    key: "options",
    render: (text, data) => (
      <div onClick={(e) => e.stopPropagation()}>
        <Dropdown
          overlay={menu(data)}
          trigger={["click"]}
          onClick={(event) => event.stopPropagation()}
        >
          <MoreOutlined style={{ color: colors.secondaryBase }} />
        </Dropdown>
      </div>
    ),
  };

  return (
    <Card
      tabList={tabList}
      onTabChange={onChangeTab}
      className={classes.cardContainer}
      tabBarExtraContent={tabBarExtraContent}
    >
      {!!renderCustomTableHeading && renderCustomTableHeading()}
      {!!filterControl === true && (
        <div className={classes.filterContainer}>
          {React.cloneElement(filterControl, { resourceName })}
          {!!sortControl === true &&
            React.cloneElement(sortControl, { resourceName })}
        </div>
      )}
      {!!renderCustomElement === true ? (
        renderCustomElement
      ) : (
          <Table
            {...tableProps}
            pagination={tableProps.pagination}
            columns={
              !!options === false
                ? tableProps.columns
                : [...tableProps.columns, optionsRow]
            }
          />
        )}
    </Card>
  );
};

const useStyles = makeStyles({
  cardContainer: {
    borderRadius: 3,
    "& .ant-tabs-tab": {
      fontSize: fontSize.medium,
    },
    boxShadow: boxShadow.card,
    "& .ant-card-head": {
      height: 54,
      padding: "0px 18px",
      borderBottom: `1px solid ${colors.seperator}`,
    },
    "& .ant-tabs-bar": {
      borderBottom: 0,
      height: 54,
    },
    "& .ant-card-body": {
      padding: 0,
    },
    "& .ant-table-thead > tr > th": {
      borderBottom: "1px solid #C4CDD4",
      background: colors.white,
      padding: "0px 20px",
      height: 53,
      fontWeight: "600",
    },
    "& .ant-table-tbody > tr > td": {
      borderBottom: `1px solid ${colors.seperator}`,
      padding: "0px 20px",
      height: 53,
      cursor: "pointer",
    },
    "& .ant-pagination": {
      paddingRight: 20,
    },
  },
  filterContainer: {
    padding: 20,
    borderBottom: (props) =>
      !!props.renderCustomElement === true
        ? 0
        : `1px solid ${colors.seperator}`,
  },
});

ResourceTable.propTypes = {
  tabList: PropTypes.arrayOf(
    PropTypes.shape({
      tab: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChangeTab: PropTypes.func.isRequired,
  resourceName: PropTypes.shape({
    singular: PropTypes.string.isRequired,
    plural: PropTypes.string.isRequired,
  }).isRequired,
  filterControl: PropTypes.node,
  sortControl: PropTypes.node,
  tableProps: PropTypes.shape({
    ...Table.propTypes,
  }),
  onSelectOption: PropTypes.func,
  options: PropTypes.oneOf([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  renderCustomElement: PropTypes.node,
  renderCustomTableHeading: PropTypes.node,
  tabBarExtraContent: PropTypes.node
};

export default React.memo(ResourceTable);
export { default as Filter } from "./Filter";
export { default as Sorter } from "./Sorter";

/** @format */
export const GET_STAFF_REQUEST = "GET_STAFF_REQUEST";
export const GET_STAFF_REQUEST_SUCCESS = "GET_STAFF_REQUEST_SUCCESS";
export const GET_STAFF_REQUEST_FAILURE = "GET_STAFF_REQUEST_FAILURE";

export const UPDATE_STAFF_PROFILE = "UPDATE_STAFF_PROFILE";
export const UPDATE_STAFF_PROFILE_SUCCESS = "UPDATE_STAFF_PROFILE_SUCCESS";
export const UPDATE_STAFF_PROFILE_FAILURE = "UPDATE_STAFF_PROFILE_FAILURE";

export const GET_STAFF_ROLE_REQUEST = "GET_STAFF_ROLE_REQUEST";
export const GET_STAFF_ROLE_REQUEST_SUCCESS = "GET_STAFF_ROLE_REQUEST_SUCCESS";
export const GET_STAFF_ROLE_REQUEST_FAILURE = "GET_STAFF_ROLE_REQUEST_FAILURE";

export const ADD_STAFF_REQUEST = "GET_STAFF_INVITE_USER";
export const ADD_STAFF_REQUEST_SUCCESS = "ADD_STAFF_REQUEST_SUCCESS";
export const ADD_STAFF_REQUEST_FAILURE = "ADD_STAFF_REQUEST_FAILURE";

export const UPLOAD_STAFF_AVATAR_REQUEST = "UPLOAD_STAFF_AVATAR_REQUEST";
export const UPLOAD_STAFF_AVATAR_REQUEST_SUCCESS =
  "UPLOAD_STAFF_AVATAR_REQUEST_SUCCESS";
export const UPLOAD_STAFF_AVATAR_REQUEST_FAILURE =
  "UPLOAD_STAFF_AVATAR_REQUEST_FAILURE";

export const TOGGLE_STAFF_STATUS_REQUEST = "TOGGLE_STAFF_STATUS_REQUEST";
export const TOGGLE_STAFF_STATUS_REQUEST_SUCCESS =
  "TOGGLE_STAFF_STATUS_REQUEST_SUCCESS";
export const TOGGLE_STAFF_STATUS_REQUEST_FAILURE =
  "TOGGLE_STAFF_STATUS_REQUEST_FAILURE";

  export const RESET_STAFF_PASSWORD_REQUEST = "RESET_STAFF_PASSWORD_REQUEST";
  export const RESET_STAFF_PASSWORD_REQUEST_SUCCESS =
    "RESET_STAFF_PASSWORD_REQUEST_SUCCESS";
  export const RESET_STAFF_PASSWORD_REQUEST_FAILURE =
    "RESET_STAFF_PASSWORD_REQUEST_FAILURE";

    export const CHANGE_STAFF_ROLE_REQUEST = "CHANGE_STAFF_ROLE_REQUEST";
    export const CHANGE_STAFF_ROLE_REQUEST_SUCCESS = "CHANGE_STAFF_ROLE_REQUEST_SUCCESS";
    export const CHANGE_STAFF_ROLE_REQUEST_FAILURE = "CHANGE_STAFF_ROLE_REQUEST_FAILURE";
import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Row, notification, Spin, Select, message } from "antd";
import { makeStyles } from "@material-ui/styles";
import { useSelector, useDispatch } from "react-redux";

import Drawer from "../../atoms/Drawer";
import Banner from "../../atoms/Banner";
import Tags from "../../atoms/Tags";
import { fontWeight, fontSize, colors } from "../../Css";
import { Api } from "../../utils/Api";
import {
  getIsFetchingState,
  getErrorMessageState,
  getStatusState,
  getAssessmentReportDetailState,
  getUpdateActionStatusSuccessState,
  getUpdateActionStatusErrorState,
  getUpdateActionStatusMessageState,
  Status,
} from "../../redux/Assessment/assessmentReportDetailsReducer";
import { PrivatePaths } from "../../routes";
import history from "../../routes/history";
import { ACTION_STATUS, isValidNewActionStatus, getActionStatusIdForName } from "../../utils/Assessment";
import { RESET_UPDATE_ACTION_STATUS_STORE } from "../../redux/Assessment/actionTypes";

const ReportDetails = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isFetching = useSelector(getIsFetchingState);
  const status = useSelector(getStatusState);
  const errorMessage = useSelector(getErrorMessageState);
  const assessmentReportDetails = useSelector(getAssessmentReportDetailState);

  const updateActionStatusSuccess = useSelector(getUpdateActionStatusSuccessState)
  const updateActionStatusError = useSelector(getUpdateActionStatusErrorState)
  const updateActionStatusMessage = useSelector(getUpdateActionStatusMessageState)

  const dismissMessageRef = useRef(null);

  const { isVisible, onClose } = props;
  const reportDetails = !!props.reportDetails ? props.reportDetails : {};
  const {
    riskStatus,
    id,
    fullName,
    phoneNumber,
    state,
    source,
    reportDate,
    channel,
    gender,
  } = reportDetails;

  const reportType = !!riskStatus ? riskStatus.split(" ")[0].toLowerCase() : "";
  const { assessmentResponses, symptoms } = assessmentReportDetails;

  useEffect(() => {
    if (isVisible === true && !!id === true) {
      Api.AssessmentsRepository.getAssessmentReportDetail(id);
    }
  }, [id, isVisible]);

  useEffect(() => {
    if (status === Status.GET_ASSESSMENTS_REPORT_DETAILS_REQUEST_FAILURE) {
      notification["error"]({
        message: "Failed to load report",
        description: errorMessage,
      });
    }
  }, [status, errorMessage]);

  useEffect(() => {
    if (updateActionStatusSuccess) {
      notification['success']({
        message: 'Status successfully updated',
      });
    }

    if (updateActionStatusError) {
      notification['error']({
        message: 'There was an issue updating the status',
        description: updateActionStatusMessage,
      });
    }

    if (dismissMessageRef.current) {
      dismissMessageRef.current()
      dismissMessageRef.current = null
    }

    dispatch({
      type: RESET_UPDATE_ACTION_STATUS_STORE
    })
  }, [updateActionStatusSuccess, updateActionStatusError, updateActionStatusMessage, dispatch])

  const getBannerTitle = () => {
    switch (reportType) {
      case "high":
        return "High risk patient";
      case "medium":
        return "Medium risk patient";
      case "low":
      default:
        return "Low risk patient";
    }
  };

  const getBannerType = () => {
    switch (reportType) {
      case "high":
        return "error";
      case "confirmed":
        return "error";
      case "medium":
        return "warning";
      case "low":
        return "success";
      default:
        return "info";
    }
  };

  const updateStatus = (value) => {
    if (isFetching) return
    dismissMessageRef.current = message.loading({
      content: 'Updating status...'
    })
    Api.AssessmentsRepository.updateActionStatus(id, value)
  }

  const getBannerAction = () => {
    if (reportType === "high") {
      return [
        {
          content: "Create case",
          actionDisabled: isFetching,
          actionLoading: isFetching,
          onClick: () =>
            history.push({
              pathname: `${PrivatePaths.CASE_MANAGEMENT}/new-case`,
              state: {
                caseDetails: { ...reportDetails, ...assessmentReportDetails },
              },
            }),
        },
        <Select
          className={classes.statusSelect}
          value={getActionStatusIdForName(assessmentReportDetails.status)}
          disabled={isFetching}
          loading={isFetching}
          onChange={updateStatus}
        >
          {ACTION_STATUS
            .filter(actionStatus =>
              isValidNewActionStatus(assessmentReportDetails.status, actionStatus.label)
            )
            .map((actionStatus) => (
              <Select.Option value={actionStatus.id}>{actionStatus.label}</Select.Option>
            ))}
        </Select>
      ];
    } else {
      return [];
    }
  };

  const renderBanner = () => {
    return (
      <Banner
        type={getBannerType()}
        closable={false}
        title={getBannerTitle()}
        actions={getBannerAction()}
      >
        The Risk Analysis and determine if this is the way to go or leave it.
      </Banner>
    );
  };

  const renderDetails = () => {
    return (
      <div className={classes.detailsContainer}>
        {assessmentResponses && assessmentResponses.assessmentChannel ? (
          <div>
            <div className="detail">
              <h1 className="detailTitle">Assessment Date</h1>
              <p className="detailSubTitle">
                {moment(
                  assessmentResponses && assessmentResponses.assessmentDate
                ).format("LL")}
              </p>
            </div>
            <div className="detail">
              <h1 className="detailTitle">Channel</h1>
              <p className="detailSubTitle">
                {assessmentResponses && assessmentResponses.assessmentChannel}
              </p>
            </div>
            <div className="detail">
              <h1 className="detailTitle">Source</h1>
              <p className="detailSubTitle">
                {assessmentResponses && assessmentResponses.assessmentSource}
              </p>
            </div>
          </div>
        ) : (
          <Row justify="space-between" className={classes.detailsFirstSection}>
            <div>
              <p className="user-name">{fullName || `User ${id}`}</p>
              <div>
                <p className="smallText">No email</p>
                <p className="smallText">{phoneNumber || "No phone number"}</p>
                <p className="smallText">{gender}</p>
                <p className="smallText">{state}</p>
              </div>
            </div>
            <div>
              <div className="tag-container">
                <Tags label={channel} />
              </div>
              <div className="tag-container">
                <Tags label={reportDate || "unknown date"} />
              </div>
              <div className="tag-container">
                <Tags label={source || "Unknown Source"} />
              </div>
            </div>
          </Row>
        )}

        <Row className={classes.seperator} />
        {!isFetching && (
          <div className={classes.detailsSecondSection}>
            <p>Symptoms</p>

            <Row>
              {!!symptoms && symptoms.length > 0 ? (
                <>
                  {symptoms.map((symptom) =>
                    !!symptom ? (
                      <div key={symptom} className="tag-container">
                        <Tags label={symptom} type="error" />
                      </div>
                    ) : null
                  )}
                </>
              ) : (
                <p className={classes.noSymptom}>There are no symptoms</p>
              )}
            </Row>
          </div>
        )}
      </div>
    );
  };

  const renderQuestions = () => {
    return (
      <div className={classes.questionsContainer}>
        <div className="header">Questions</div>
        {!!assessmentResponses &&
          assessmentResponses.map((quest, i) => (
            <div key={i} className="question">
              <p className="question-title">Q: {quest.question}?</p>
              <p className="answer">A: {quest.response}</p>
            </div>
          ))}
        {isFetching && (
          <div className={classes.spinner}>
            <Spin />
          </div>
        )}
      </div>
    );
  };

  return (
    <Drawer
      visible={isVisible}
      onClose={onClose}
      title="Report Details"
      hideFooter={true}
    >
      {!!reportType === true && renderBanner()}
      {renderDetails()}
      {renderQuestions()}
    </Drawer>
  );
};

const useStyles = makeStyles({
  detailsContainer: {
    margin: "20px 0px",
    "& p": {
      margin: 0,
    },
    "& .detail": {
      marginTop: 20,
    },
    "& .detailTitle": {
      fontSize: 12,
      fontWeight: 600,
      color: colors.base,
    },
    "& .detailSubTitle": {
      fontSize: 14,
      color: colors.secondaryBase,
    },
  },
  detailsFirstSection: {
    width: "100%",
    "& .user-name": {
      fontWeight: fontWeight.medium,
      fontSize: fontSize.large,
      marginBottom: 8,
      color: colors.base,
    },
    "& .small-text": {
      color: colors.secondaryBase,
    },
    "& .tag-container": {
      marginBottom: 4,
    },
  },
  detailsSecondSection: {
    "& .title": {
      color: colors.base,
      textTransform: "uppercase",
      fontSize: fontSize.small,
      fontWeight: fontWeight.medium,
    },
    "& .tag-container": {
      marginTop: 10,
      marginRight: 10,
    },
  },
  seperator: {
    margin: "15px 0px",
    borderTop: `1px solid ${colors.seperator}`,
  },
  noSymptom: { fontSize: fontSize.small, marginTop: "8px !important" },
  questionsContainer: {
    marginTop: 20,
    padding: 20,
    borderRadius: 3,
    boxShadow: `0 0 0 1px rgba(63,63,68,0.07), 0 1px 3px 0 rgba(63,63,68,0.12)`,
    "& p": {
      margin: 0,
    },
    "& .header": {
      borderBottom: `1px solid ${colors.seperator}`,
      paddingBottom: 19,
      fontSize: fontSize.small,
      color: colors.secondaryBase,
      textTransform: "uppercase",
      fontWeight: fontWeight.medium,
    },
    "& .question": {
      marginTop: 19,
    },
    "& .question-title": {
      color: colors.base,
      fontSize: fontSize.medium,
    },
    "& .answer": {
      color: colors.secondaryBase,
      fontSize: fontSize.medium,
      marginTop: 3,
    },
  },
  spinner: {
    textAlign: "center",
    borderRadius: 4,
    padding: "30px 50px",
  },
  statusSelect: {
    width: 120
  }
});

ReportDetails.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  reportType: PropTypes.oneOf(["high", "medium", "low"]),
};

export default React.memo(ReportDetails);

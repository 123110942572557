import React, { useEffect, useState } from "react";
import { message, notification } from "antd";
import { useSelector } from "react-redux";

import {
  getErrorState,
  getFilesState,
  getStatusMessageState,
  getSuccessState,
  getUploadSuccessState
} from "../../redux/CaseManagement/newCaseReducer";
import { Api } from "../../utils/Api";
import { makeStyles } from "@material-ui/styles";
import HeadingWithBreadcrumb from "../../atoms/HeadingWithBreadcrumb";
import CaseForm from "../../components/CaseManagement/CaseForm";
import {
  getCaseDetailsState,
  getErrorMessageState,
  getIsFetchingState
} from "../../redux/CaseManagement/caseDetailsReducer";
import store from "../../redux/store";
import {
  GET_SINGLE_CASE_REQUEST_SUCCESS,
  RESET_NEW_CASE_FORM
} from "../../redux/CaseManagement/actionTypes";
import { useUpdateEffect } from "../../hooks/useUpdateEffect";

const EditCase = ({ match, history, location }) => {
  const classes = useStyles();
  //Fetch details states and effects
  const isFetching = useSelector(getIsFetchingState);
  const errorMessage = useSelector(getErrorMessageState);
  const caseDetails = useSelector(getCaseDetailsState);
  //Form success and error states and effects
  const success = useSelector(getSuccessState);
  const error = useSelector(getErrorState);
  const statusMessage = useSelector(getStatusMessageState);
  const fileUploadSuccess = useSelector(getUploadSuccessState);
  const fileURLs = useSelector(getFilesState);
  const [formValues, setFormValues] = useState({});
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const { caseId } = match.params;
    if (!location.state) {
      message["loading"]("Fetching case details");
      Api.CaseManagementRepository.getSingleCaseDetails(caseId);
    } else {
      store.dispatch({
        type: GET_SINGLE_CASE_REQUEST_SUCCESS,
        payload: location.state
      });
    }
    // eslint-disable-next-line
  }, [match]);

  useEffect(() => {
    if (errorMessage) {
      notification["error"]({
        message: "There was an issue fetching the case details",
        description: errorMessage
      });
    }
    message.destroy();
    // eslint-disable-next-line
  }, [isFetching]);

  useUpdateEffect(() => {
    if (success) {
      message.destroy();
      const { caseId } = match.params;
      notification["success"]({
        message: "Edited case successfully",
        description: statusMessage
      });
      store.dispatch({
        type: RESET_NEW_CASE_FORM
      });
      history.push(`/case-management/${caseId}`);
      setSubmitting(false);
    }

    if (fileUploadSuccess) {
      const newFormValues = {
        ...formValues,
        document: [...fileURLs, ...formValues.files],
        status: caseDetails.status
      };
      const { caseId } = match.params;
      newFormValues.id = Number(caseId);
      Api.CaseManagementRepository.editCase(newFormValues, caseId);
    }
  }, [success, fileUploadSuccess]);

  useEffect(() => {
    if (error) {
      message.destroy();
      notification["error"]({
        message: "Something went wrong",
        description: statusMessage
      });
      setSubmitting(false);
    }
    // eslint-disable-next-line
  }, [error]);

  const handleSubmit = ({ files, ...values }) => {
    setSubmitting(true);
    message["loading"]("Submitting the form", 0);
    //If the file has a url then it has been uploaded previously. If not then it hasn't
    const newFiles = files.filter(file => !file.url);
    const oldFiles = files.filter(file => file.url).map(({ url }) => url);
    setFormValues({ ...values, files: oldFiles });
    Api.CaseManagementRepository.uploadCaseFiles(newFiles);
  };

  return (
    <div className={classes.container}>
      <HeadingWithBreadcrumb title="Edit Case" page="Case Management" />
      {!isFetching && (
        <CaseForm
          handleSubmit={handleSubmit}
          caseDetails={{
            ...caseDetails,
            files: caseDetails.document.map((url, idx) => ({
              name: `File ${idx}`,
              url
            }))
          }}
          submitting={submitting}
        />
      )}
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    padding: 32
  }
});

export default EditCase;

import React, { useEffect } from "react";
import { Menu, notification, Checkbox } from "antd";
import { makeStyles } from "@material-ui/styles";

import {
  Status,
  getIsFetchingState,
  getErrorMessageState,
  getStatusState,
  getPartnerSourcesState,
} from "../../redux/Assessment/partnerSourcesReducer";
import { useSelector } from "react-redux";
import { Api } from "../../utils/Api";
import Loader from "../../atoms/Loader";

const SourceFilter = ({ selectedSource, onSelectSource }) => {
  const classes = useStyles();

  const isFetching = useSelector(getIsFetchingState);
  const errorMessage = useSelector(getErrorMessageState);
  const status = useSelector(getStatusState);
  const partnerSources = useSelector(getPartnerSourcesState);

  useEffect(() => {
    Api.AssessmentsRepository.getPartnerSources();
    return () => {
      Api.AssessmentsRepository.resetPartnerSourcesStore();
    };
  }, []);

  useEffect(() => {
    if (status === Status.GET_PARTNER_SOURCES_REQUEST_FAILURE)
      notification["error"]({
        message: "Failed to fetch partners",
        description: errorMessage,
      });
  }, [status, errorMessage]);

  return (
    <Menu className={classes.sourceFilter}>
      {isFetching ? (
        <Loader title="" />
      ) : (
        partnerSources.map(({ id, companyName }) => (
          <Menu.Item key={id}>
            <Checkbox
              checked={id === selectedSource}
              onChange={() => onSelectSource(id)}
            >
              {companyName}
            </Checkbox>
          </Menu.Item>
        ))
      )}
    </Menu>
  );
};

const useStyles = makeStyles({
  sourceFilter: {
    "&.ant-menu": {
      border: 0,
    },
    "& .ant-menu-item": {
      padding: 0,
      border: 0,
      lineHeight: 2.0,
      height: "fit-content",
      margin: "0 !important",
    },
    "& .ant-menu-item span:last-child": {
      paddingRight: 0,
    },
  },
});

export default SourceFilter;

import React from "react";
import { Spin } from "antd";
import { makeStyles } from "@material-ui/styles";
import { colors } from "../Css";

const Loader = () => {
  const classes = useStyles();
  return (
    <div className={classes.loader__container}>
      <Spin />
    </div>
  );
};

const useStyles = makeStyles({
  loader__container: {
    width: "100%",
    backgroundColor: colors.white,
    padding: 20,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  }
});

export default React.memo(Loader);

import moment from "moment";

export default class Auth {
  static isAuthenticated() {
    const token = localStorage.getItem("token");
    const expiryDate = localStorage.getItem("tokenExpiryDate");
    return token && moment() < moment(expiryDate);
  }

  static setToken(token, expiryDate) {
    localStorage.setItem("token", token);
    localStorage.setItem("tokenExpiryDate", expiryDate);
  }

  static setCurrentUser(currentUser) {
    localStorage.setItem("currentUser", JSON.stringify(currentUser));
  }

  static getToken() {
    return localStorage.getItem("token");
  }

  static getCurrentUser() {
    return JSON.parse(localStorage.getItem("currentUser"));
  }

  static getTokenExpiryDate() {
    return localStorage.getItem("tokenExpiryDate");
  }

  static removeToken() {
    localStorage.removeItem("token");
    localStorage.removeItem("tokenExpiryDate");
  }

  static removeCurrentUser() {
    localStorage.removeItem("currentUser");
  }
}

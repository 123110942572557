import axios from 'axios';
import {
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATIONS_REQUEST_SUCCESS,
  GET_NOTIFICATIONS_REQUEST_FAILURE,
  RESET_NOTIFICATIONS_STORE,
  GET_CONTACTS_REQUEST,
  GET_CONTACTS_REQUEST_SUCCESS,
  GET_CONTACTS_REQUEST_FAILURE,
  SEND_NOTIFICATION_REQUEST,
  SEND_NOTIFICATION_REQUEST_SUCCESS,
  SEND_NOTIFICATION_REQUEST_FAILURE,
  RESET_SEND_NOTIFICATION_STORE,
} from '../redux/Notification/actionTypes';
import store from '../redux/store';
import * as NotificationUtils from '../utils/Notifications';

function NotificationRepository(axiosInstance) {
  const CancelTokenForGetNotifications = axios.CancelToken;
  const CancelTokenForGetContacts = axios.CancelToken;

  let cancelForGetNotifications = null;
  let cancelForGetContacts = null;

  return {
    resetNotificationsStore: function () {
      store.dispatch({
        type: RESET_NOTIFICATIONS_STORE,
      });
    },

    getNotifications: function ({
      caseId,
      notificationCategory,
      pageNo,
      pageSize,
      dateRange,
    }) {
      store.dispatch({
        type: GET_NOTIFICATIONS_REQUEST,
        payload: {
          pageNo,
        },
      });
      cancelForGetNotifications && cancelForGetNotifications();
      axiosInstance
        .get(`/Notifications`, {
          cancelToken: new CancelTokenForGetNotifications(function executor(c) {
            cancelForGetNotifications = c;
          }),
          params: {
            referenceUserId: caseId,
            notificationCategory,
            startDate: !!dateRange.startDate
              ? dateRange.startDate.format('YYYY-MM-DD')
              : undefined,
            endDate: !!dateRange.endDate
              ? dateRange.endDate.format('YYYY-MM-DD')
              : undefined,
            pageNo,
            pageSize,
          },
        })
        .then((response) => {
          const { success, message, data, total } = response.data;
          if (success)
            store.dispatch({
              type: GET_NOTIFICATIONS_REQUEST_SUCCESS,
              payload: {
                data: NotificationUtils.transformBackendModelToFrontendModel(
                  data
                ),
                total,
                pageNo,
              },
            });
          else {
            store.dispatch({
              type: GET_NOTIFICATIONS_REQUEST_FAILURE,
              payload: message,
            });
          }
        })
        .catch((error) => {
          store.dispatch({
            type: GET_NOTIFICATIONS_REQUEST_FAILURE,
            payload: error.message,
          });
        });
    },

    resetSendNotificationStore: function () {
      store.dispatch({
        type: RESET_SEND_NOTIFICATION_STORE,
      });
    },

    getAllContacts: function (
      userId,
      variant = NotificationUtils.NOTIFICATION_VARIANTS.CaseManagement
    ) {
      store.dispatch({
        type: GET_CONTACTS_REQUEST,
      });
      if (cancelForGetContacts) cancelForGetContacts();
      let endpointURL = `/ContactTracing/GetContactTraceByGloEpidUserId/${userId}`;
      if (variant === NotificationUtils.NOTIFICATION_VARIANTS.CaseManagement)
        endpointURL = `/CaseManagement/GetCreatedCaseContact/${userId}`;
      axiosInstance
        .get(endpointURL, {
          cancelToken: new CancelTokenForGetContacts(function executor(c) {
            cancelForGetContacts = c;
          }),
          params: {
            pageNo: 1,
            pageSize: 100000,
          },
        })
        .then((response) => {
          const { success, message, data } = response.data;
          if (success)
            store.dispatch({
              type: GET_CONTACTS_REQUEST_SUCCESS,
              payload: data,
            });
          else
            store.dispatch({
              type: GET_CONTACTS_REQUEST_FAILURE,
              payload: message,
            });
        })
        .catch((error) => {
          if (axios.isCancel(error)) return;
          store.dispatch({
            type: GET_CONTACTS_REQUEST_FAILURE,
            payload: error.message,
          });
        });
    },

    sendNotification: function ({
      referenceUserId,
      createdCaseId,
      message,
      contactIds = [],
      caseContactIds = [],
      notificationType,
      notificationCategory,
    }) {
      store.dispatch({
        type: SEND_NOTIFICATION_REQUEST,
      });
      return axiosInstance
        .post('/Notifications', {
          gloEpidUserId: contactIds,
          createdCasesContactId: caseContactIds,
          message,
          notificationType,
          notificationCategory,
          referenceUserId: !!referenceUserId ? parseInt(referenceUserId) : null,
          createdCaseId: !!createdCaseId ? parseInt(createdCaseId) : null,
        })
        .then((response) => {
          const { success, message } = response.data;
          if (success)
            store.dispatch({
              type: SEND_NOTIFICATION_REQUEST_SUCCESS,
            });
          else {
            return Promise.reject(new Error(message));
          }
        })
        .catch((error) => {
          store.dispatch({
            type: SEND_NOTIFICATION_REQUEST_FAILURE,
            payload: error.message,
          });
          return Promise.reject(error);
        });
    },
  };
}

export default NotificationRepository;

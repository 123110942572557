import {
  GET_CREATED_CASE_CONTACT_REQUEST,
  GET_CREATED_CASE_CONTACT_REQUEST_SUCCESS,
  GET_CREATED_CASE_CONTACT_REQUEST_FAILURE,
  RESET_CREATED_CASE_CONTACT_STORE,
} from "./actionTypes";

const initialState = {
  isFetching: false,
  errorMessage: "",
  status: null,
  pageNo: 0,
  total: 0,
  allCreatedCaseContactByPageNumber: {},
  currentCreatedCaseContact: [],
};

export const Status = {
  GET_CREATED_CASE_CONTACT_REQUEST_SUCCESS: `get_created_case_contact_request_success`,
  GET_CREATED_CASE_CONTACT_REQUEST_FAILURE: `get_created_case_contact_request_failure`,
};

const createdCaseContactReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CREATED_CASE_CONTACT_REQUEST:
      return {
        ...state,
        isFetching: true,
        status: Status.GET_CREATED_CASE_CONTACT_REQUEST,
        currentCreatedCaseContact:
          state.allCreatedCaseContactByPageNumber[action.payload.pageNo] || [],
      };
    case GET_CREATED_CASE_CONTACT_REQUEST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        status: Status.GET_CREATED_CASE_CONTACT_REQUEST_SUCCESS,
        allCreatedCaseContactByPageNumber: {
          ...state.allCreatedCaseContactByPageNumber,
          [action.payload.pageNo]: action.payload.data,
        },
        currentCreatedCaseContact: action.payload.data,
        pageNo: action.payload.pageNo,
        total: action.payload.total,
      };
    case GET_CREATED_CASE_CONTACT_REQUEST_FAILURE:
      return {
        ...state,
        isFetching: false,
        status: Status.GET_CREATED_CASE_CONTACT_REQUEST_FAILURE,
        errorMessage: action.payload,
      };
    case RESET_CREATED_CASE_CONTACT_STORE:
      return initialState;
    default:
      return state;
  }
};

export const getIsFetchingState = (state) =>
  state.createdCaseContact.isFetching;
export const getErrorMessageState = (state) =>
  state.createdCaseContact.errorMessage;
export const getStatusState = (state) => state.createdCaseContact.status;
export const getTotalItemsCountState = (state) =>
  state.createdCaseContact.total;
export const getCurrentCreatedCaseContactState = (state) =>
  state.createdCaseContact.currentCreatedCaseContact;

export default createdCaseContactReducer;

/** @format */

import axios from 'axios';
import { AuthRepository } from '../repository/AuthRepository';
import { ContactTraceRepository } from '../repository/ContactTraceRepository';
import { CaseManagementRepository } from '../repository/CaseManagementRepository';
import { AssessmentsRepository } from '../repository/AssessmentsRepository';
import { PartnersRepository } from '../repository/PartnerRepository';
import { StaffRepository } from '../repository/StaffRepository';
import { OverviewRepository } from '../repository/OverviewRepository';

import Auth from './Auth';
import NotificationRepository from '../repository/NotificationRepository';

const config = {
  baseURL: process.env.REACT_APP_API_BASE_URL,
};

const instance = axios.create(config);

if (Auth.isAuthenticated() === true) {
  instance.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${Auth.getToken()}`;
}

export const Api = {
  AuthRepository: AuthRepository(instance),
  ContactTraceRepository: ContactTraceRepository(instance),
  AssessmentsRepository: AssessmentsRepository(instance),
  CaseManagementRepository: CaseManagementRepository(instance),
  PartnersRepository: PartnersRepository(instance),
  StaffRepository: StaffRepository(instance),
  OverviewRepository: OverviewRepository(instance),
  NotificationRepository: NotificationRepository(instance),
};

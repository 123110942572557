import React, { useState, useEffect, useRef } from "react";
import { Button, Card, Col, DatePicker, Form, Input, Row, Select } from "antd";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import moment from "moment";

import cancelIcon from "../../assets/images/cancel.svg";
import right from "../../assets/images/right.svg";
import UploadFileIcon from "../../assets/images/upload-file-icon.svg";
import { boxShadow, colors, fontSize, fontWeight } from "../../Css";
import store from "../../redux/store";
import {
  ADD_CONTACT,
  ADD_SYMPTOM,
  DELETE_SYMPTOM,
  EDIT_CONTACT_DETAILS,
  RESET_NEW_CASE_FORM
} from "../../redux/CaseManagement/actionTypes";
import Tags from "../../atoms/Tags";
import cancel from "../../assets/images/cancel.svg";
import NewContact from "./NewContact";
import {
  getContactsState,
  getSymptomsState,
  getSuccessState
} from "../../redux/CaseManagement/newCaseReducer";
import { getCodeFromGenderName, getGenderNameFromCode } from "../../utils";
import { getStates } from "../../utils/States";
import history from "../../routes/history";

const formFieldMappings = {
  fullName: "Full Name",
  phoneNumber: "Phone Number",
  emailAddress: "Email",
  location: "Address",
  address: "Address",
  dateOfBirth: "Date Of Birth",
  gender: "Gender",
  state: "State",
  doctorsNote: "Note",
  contactTime: "Date Tested Positive",
  statusAt: "Date Tested Positive",
  actionStatus: "Action Status"
};

const acceptedMimeTypes = process.env.REACT_APP_ACCEPTED_MIME_TYPES.split(",");
const maxFileSize = Number(process.env.REACT_APP_MAX_FILE_SIZE_CASE_FORM);
const getInitialValues = caseDetails => {
  const {
    phoneNumber,
    fullName,
    location,
    emailAddress,
    gender,
    state,
    dateOfBirth,
    doctorsNote,
    statusAt,
    actionStatus
  } = caseDetails;

  return {
    "Full Name": fullName,
    "Phone Number": phoneNumber,
    Email: emailAddress,
    Address: location,
    Gender:
      gender === "Unknown" || !gender
        ? null
        : typeof gender === "number"
        ? getGenderNameFromCode(gender)
        : gender,
    State: state === "Unknown" ? null : state,
    "Date Of Birth": dateOfBirth ? moment(dateOfBirth) : undefined,
    Note: doctorsNote,
    "Date Tested Positive": statusAt ? moment(statusAt) : undefined,
    "Action Status": actionStatus
  };
};

const validateMessages = {
  // eslint-disable-next-line
  required: "${name} is required!",
  // eslint-disable-next-line
  types: {
    // eslint-disable-next-line
    email: "${name} is not valid email!"
  },
  string: {
    // eslint-disable-next-line
    len: "${name} must be exactly ${len} characters"
  }
};

const CaseForm = props => {
  const { handleSubmit, submitting } = props;
  const caseDetails = props.caseDetails || {};
  const { symptoms: _symptoms, contacts: _contacts } = caseDetails;
  const classes = useStyles();
  const [form] = Form.useForm();

  /*
   * Couldn't think of a better name
   * It's basically for setting the data inside the drawer when you want to edit a contact
   * */
  const [editContactData, setEditContactData] = useState(null);
  const [newSymptom, setNewSymptom] = useState("");
  const [newCaseDrawerOpen, setNewCaseDrawerOpen] = useState(false);
  const [initialValues, setInitialValues] = useState(
    getInitialValues(caseDetails)
  );
  const [files, setFiles] = useState(caseDetails.files || []);
  const [invalidFile, setInvalidFile] = useState({
    invalid: false,
    //Size || Format
    type: ""
  });
  const [hasDraggedFile, setHasDraggedFile] = useState(false);
  const success = useSelector(getSuccessState);
  const contacts = useSelector(getContactsState);
  const symptoms = useSelector(getSymptomsState);
  const fileInputRef = useRef();

  useEffect(() => {
    if (!!_symptoms && Array.isArray(_symptoms)) {
      store.dispatch({
        type: ADD_SYMPTOM,
        payload: _symptoms
      });
    }

    if (!!_contacts && Array.isArray(_contacts)) {
      //Transform data to format needed by form
      const transformedContacts = _contacts.map(
        ({ contactTime, ...fields }) => ({
          ...fields,
          date: moment(contactTime),
          time: moment(contactTime)
        })
      );
      store.dispatch({
        type: ADD_CONTACT,
        payload: transformedContacts
      });
    }
  }, [_symptoms, _contacts]);

  useEffect(() => {
    // If a dropzone is present, prevent the browser from opening the file
    // in case a user accidentally drops the file outside the zone
    window.addEventListener("drop", overrideEventDefaults);
    window.addEventListener("dragover", overrideEventDefaults);
    return () => {
      window.removeEventListener("drop", overrideEventDefaults);
      window.removeEventListener("dragover", overrideEventDefaults);
    };
  }, []);

  useEffect(() => {
    return function() {
      if (success) {
        resetFormFields();
      }
    };
    // eslint-disable-next-line
  }, []);

  const flashInvalidFile = type => {
    setInvalidFile({
      invalid: true,
      type
    });

    setTimeout(() => {
      setInvalidFile({
        invalid: false,
        type: ""
      });
    }, 3000);
  };

  const verifyFile = file => {
    if (!acceptedMimeTypes.includes(file.type)) {
      return [false, "format"];
    }

    if (file.size > maxFileSize) {
      return [false, "size"];
    }

    return [true];
  };

  const addFile = ({ target }) => {
    const newFile = [...target.files].pop();
    if (newFile && files.every(file => file !== newFile)) {
      const [fileValid, error] = verifyFile(newFile);
      if (fileValid) {
        setFiles([...files, newFile]);
      } else {
        flashInvalidFile(error);
      }
    }
  };

  const deleteFile = index => {
    setFiles(files.filter((_, fileIndex) => fileIndex !== index));
  };

  const overrideEventDefaults = event => {
    event.preventDefault();
    event.stopPropagation();
  };

  const cancelCreateCase = () => {
    resetFormFields();
    history.goBack();
  };

  const resetFormFields = () => {
    form.resetFields();
    store.dispatch({
      type: RESET_NEW_CASE_FORM
    });
    setInitialValues(getInitialValues({}));
  };

  const transformFormData = values => {
    const transformedFields = Object.entries(formFieldMappings).reduce(
      (fields, [key, mapping]) => {
        return {
          ...fields,
          [key]: values[mapping]
        };
      },
      {}
    );
    transformedFields.contactTime = transformedFields.contactTime.toISOString();
    transformedFields.statusAt = transformedFields.statusAt.toISOString();
    transformedFields.dateOfBirth = transformedFields.dateOfBirth.toISOString();
    transformedFields.symptoms = symptoms;
    transformedFields.contacts = contacts.map(({ time, date, ...fields }) => {
      //The date picker automatically adds the current time you're in to the date. So I'm extracting just the day and then adding the time
      const newDate = moment(
        `${date.year()}-${date.months() + 1}-${date.date()}`
      );
      newDate.add({
        seconds: time.seconds(),
        minutes: time.minutes(),
        hours: time.hours()
      });

      //Remove created by field because it'll be add automatically on the backend
      delete fields.createdBy;
      return {
        ...fields,
        contactTime: newDate.toISOString()
      };
    });
    transformedFields.document = [];
    transformedFields.gender = getCodeFromGenderName(transformedFields.gender);
    transformedFields.files = files;
    return transformedFields;
  };

  const addSymptom = event => {
    const { key } = event;
    if (key === "Enter" && newSymptom.trim()) {
      event.preventDefault();
      store.dispatch({
        type: ADD_SYMPTOM,
        payload: newSymptom.trim()
      });
      setNewSymptom("");
    }
  };

  const newContactFormSubmit = (formData, hide = true) => {
    if (editContactData) {
      store.dispatch({
        type: EDIT_CONTACT_DETAILS,
        payload: {
          index: editContactData.index,
          contact: formData
        }
      });
    } else {
      store.dispatch({
        type: ADD_CONTACT,
        payload: formData
      });
    }
    setEditContactData(null);
    setNewCaseDrawerOpen(!hide);
  };

  const disableDateLaterThanCurrent = current => {
    return current > moment().endOf("day");
  };

  const disableDateLaterThanConfirmed = current => {
    /*
     * Disabled dates after the date tested positive
     * If undefined it'll disable dates after before the current day
     * */
    return current > moment(form.getFieldValue("Date Tested Positive"));
  };

  const handleDrop = event => {
    overrideEventDefaults(event);
    setHasDraggedFile(false);
    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      const droppedFiles = event.dataTransfer.files;
      const validFiles = [...droppedFiles].filter(file => {
        const [fileValid, error] = verifyFile(file);
        if (!fileValid) {
          flashInvalidFile(error);
          return false;
        }

        return true;
      });
      setFiles([...files, ...validFiles]);
    }
  };

  const handleDragEnter = event => {
    overrideEventDefaults(event);
    setHasDraggedFile(true);
  };

  const handleDragLeave = event => {
    overrideEventDefaults(event);
    setHasDraggedFile(false);
  };

  const renderBiodataInputsFields = () => {
    const { Option } = Select;
    return (
      <div className={classes.bioData} style={{ marginTop: 20 }}>
        <label>Full Name</label>
        <Form.Item
          className={classes.formItem}
          rules={[{ required: true }]}
          name="Full Name"
        >
          <Input
            autocomplete={"disabled"}
            className={classes.input}
            placeholder="Value"
          />
        </Form.Item>
        <Row gutter={20}>
          <Col className="gutter-row" span={12}>
            <label>Phone Number</label>
            <Form.Item
              className={classes.formItem}
              rules={[{ required: true, len: 11 }]}
              name="Phone Number"
              normalize={(value, prevValue) => {
                if (value) {
                  const isNum = /^\d+$/.test(value);
                  if (isNum) {
                    return value;
                  } else {
                    return prevValue || "";
                  }
                }
                return "";
              }}
            >
              <Input
                autocomplete={"disabled"}
                className={classes.input}
                placeholder="Value"
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <label>Email Address</label>
            <Form.Item
              className={classes.formItem}
              name="Email"
              rules={[{ required: false, type: "email" }]}
            >
              <Input
                autocomplete={"disabled"}
                className={classes.input}
                placeholder="Value"
              />
            </Form.Item>
          </Col>
        </Row>
        <label>Residential Address</label>
        <Form.Item
          className={classes.formItem}
          name="Address"
          rules={[{ required: true }]}
        >
          <Input
            autocomplete={"disabled"}
            className={classes.input}
            placeholder="Value"
          />
        </Form.Item>
        <Row gutter={20}>
          <Col className="gutter-row" span={8}>
            <label>Date of Birth</label>
            <Form.Item
              name="Date Of Birth"
              rules={[
                {
                  required: true
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (
                      moment(value).isBefore(
                        moment(getFieldValue("Date Tested Positive"))
                      )
                    ) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      "Date of Birth is before the Date Tested Positive"
                    );
                  }
                })
              ]}
            >
              <DatePicker
                style={{ width: "100%" }}
                placeholder="Date picker"
                className={classes.input}
                disabledDate={disableDateLaterThanConfirmed}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
            <label>Gender</label>
            <Form.Item name="Gender" rules={[{ required: true }]}>
              <Select
                showSearch
                placeholder="Select gender"
                optionFilterProp="children"
                className={classes.input}
              >
                <Option value="male">Male</Option>
                <Option value="female">Female</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
            <label>State</label>
            <Form.Item name="State" rules={[{ required: true }]}>
              <Select
                showSearch
                placeholder="Select state"
                optionFilterProp="children"
                className={classes.input}
              >
                {getStates().map(state => (
                  <Option key={state.id} value={state.stateName}>
                    {state.stateName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </div>
    );
  };

  const renderAssessmentInputFields = () => {
    const { TextArea } = Input;
    return (
      <div style={{ marginTop: 20 }}>
        <p>Symptoms</p>
        <Input
          autocomplete={"disabled"}
          className={`${classes.input} ${classes.formItem}`}
          placeholder="Write here"
          rows={10}
          value={newSymptom}
          onChange={({ target }) => setNewSymptom(target.value)}
          onKeyPress={addSymptom}
        />
        <div className={classes.filterTags}>
          {symptoms.map(symptom => {
            return (
              <Tags
                key={symptom}
                label={symptom}
                icon={<img src={cancel} alt="" />}
                btnAction={() =>
                  store.dispatch({
                    type: DELETE_SYMPTOM,
                    payload: symptom
                  })
                }
                rounded={false}
              />
            );
          })}
        </div>
        <Form.Item
          className={classes.formItem}
          style={{ marginTop: 20, marginBottom: 0 }}
        >
          <label>Note</label>
          <Form.Item rules={[{ required: true }]} name="Note" noStyle>
            <TextArea
              className={classes.textArea}
              placeholder="Write here"
              rows={10}
            />
          </Form.Item>
        </Form.Item>
      </div>
    );
  };

  return (
    <>
      <Form
        form={form}
        className={classes.newCaseForm}
        name="New case"
        onFinish={values => handleSubmit(transformFormData(values))}
        initialValues={initialValues}
        validateMessages={validateMessages}
        scrollToFirstError={true}
      >
        <Row gutter={24}>
          <Col className="gutter-row" span={16}>
            <Card className={classes.card}>
              <Row justify={'space-between'}>
                <h4 className={classes.heading}>Biodata</h4>
                <Form.Item
                  name={'Action Status'}
                  rules={[
                    {
                      required: true,
                      message: "Action Status is required"
                    }
                  ]}

                >
                  <Select placeholder={'Action Status'}>
                    <Select.Option value={0}>
                      Pending
                    </Select.Option>
                    <Select.Option value={1}>
                      In Progress
                    </Select.Option>
                    <Select.Option value={2}>
                      Escalated
                    </Select.Option>
                    <Select.Option value={3}>
                      Closed
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Row>
              {renderBiodataInputsFields()}
            </Card>
            <Card className={classes.card}>
              <h4 className={classes.heading}>Assessment</h4>
              {renderAssessmentInputFields()}
            </Card>
            <Card className={classes.card}>
              <h4 className={classes.heading}>Contact Tracing</h4>
              <Row style={{ marginTop: 24 }} gutter={12}>
                {contacts.map((data, index) => (
                  <Col key={data.fullName} className="gutter-row" span={8}>
                    <Card
                      bodyStyle={{ padding: 16 }}
                      className={classes.contactTracing}
                      aria-roledescription="button"
                      onClick={() => {
                        setEditContactData({
                          index: index,
                          data
                        });
                        setNewCaseDrawerOpen(true);
                      }}
                    >
                      <div className={classes.contactTracingContent}>
                        <p>{data.fullName}</p>
                        <img src={right} alt="Right Icon" />
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
              <Button
                style={{ marginTop: 12 }}
                className={classes.button}
                onClick={() => setNewCaseDrawerOpen(true)}
              >
                New Contact
              </Button>
            </Card>
          </Col>
          <Col className="gutter-row" span={8}>
            <Card className={classes.card}>
              <h4 className={classes.heading}>Upload Document</h4>
              <div
                onDrag={overrideEventDefaults}
                onDragStart={overrideEventDefaults}
                onDragEnd={overrideEventDefaults}
                onDragOver={overrideEventDefaults}
                onDrop={handleDrop}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                className={`${classes.uploadDocument} ${
                  hasDraggedFile ? "active" : ""
                }`}
              >
                <img src={UploadFileIcon} alt="Upload file icon" />
                <Button
                  className={`${classes.uploadFileBtn} ${classes.button}`}
                  onClick={_ => fileInputRef.current.click()}
                >
                  upload files here
                </Button>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onInput={addFile}
                />
                <p>
                  {hasDraggedFile
                    ? "Drop here to upload"
                    : "or drop files to upload"}
                </p>
                {invalidFile.invalid && (
                  <p className={"invalidFileAlert"}>
                    Invalid File{" "}
                    {invalidFile.type === "format"
                      ? "Format"
                      : `Size. Max Size: ${maxFileSize / 1000000}mb`}
                  </p>
                )}
              </div>
              <div>
                {files.length !== 0 ? <h4>Current Files</h4> : null}
                {files.map(({ name, url = null }, index) => (
                  <p>
                    {name}
                    <Button
                      className={classes.fileButton}
                      onClick={() => deleteFile(index)}
                    >
                      <img src={cancelIcon} alt="" />
                    </Button>
                    {url && (
                      <a href={url} target="_blank" rel="noopener noreferrer">
                        View
                      </a>
                    )}
                  </p>
                ))}
              </div>
            </Card>
            <Card
              bodyStyle={{ padding: 0 }}
              style={{ minHeight: 180, marginTop: 24 }}
              className={classes.card}
            >
              <div className={classes.publish}>
                <h4 className={classes.heading}>Publish</h4>
                <Card className={classes.publishBody}>
                  <p className={"header"}>Confirmed Date</p>

                  <Form.Item
                    style={{ marginBottom: 0 }}
                    name="Date Tested Positive"
                    rules={[
                      { required: true, message: "Please input the date" }
                    ]}
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      placeholder="Date Picker"
                      className={classes.input}
                      disabledDate={disableDateLaterThanCurrent}
                    />
                  </Form.Item>
                </Card>
                <div className={classes.formButtons}>
                  <Form.Item>
                    <Button onClick={cancelCreateCase} danger>
                      Cancel
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button
                      className={classes.savePatientBtn}
                      type="primary"
                      htmlType="submit"
                      disabled={submitting}
                    >
                      Save
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </Form>
      <NewContact
        title={"Case contact"}
        visible={newCaseDrawerOpen}
        onClose={() => {
          setNewCaseDrawerOpen(false);
          setEditContactData(null);
        }}
        onSave={formData => newContactFormSubmit(formData)}
        onSaveAndAddNew={formData => newContactFormSubmit(formData, false)}
        initialValues={editContactData ? editContactData.data : null}
      />
    </>
  );
};

const useStyles = makeStyles({
  heading: {
    color: colors.base,
    fontSize: fontSize.large,
    fontWeight: fontWeight.medium,
    margin: 0
  },
  newCaseForm: {
    "& label": {
      fontSize: fontSize.medium,
      paddingBottom: 3,
      display: "inline-block"
    }
  },
  input: {
    borderColor: "#C4CDD5",
    borderRadius: 3,
    boxShadow: boxShadow.input,
    "& > div": {
      borderColor: "#C4CDD5 !important"
    }
  },
  textArea: {
    border: `1px solid ${colors.infoWeak}`,
    borderRadius: 3,
    backgroundColor: "#F9FAFB",
    borderColor: "#DFE3E8",
    paddingTop: 8,
    resize: "none"
  },
  uploadDocument: {
    border: `2px dashed ${colors.seperator}`,
    borderRadius: 2,
    height: 220,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "20px 0",
    "& img": { marginTop: 10 },
    "& p": {
      color: colors.grayishBlue,
      fontSize: fontSize.medium,
      marginBottom: "0.4em"
    },
    "&.active": {
      borderColor: colors.primary
    },
    "& .invalidFileAlert": {
      color: colors.error,
      fontSize: 14,
      marginBottom: "0.6em"
    }
  },
  fileButton: {
    border: "none",
    backgroundColor: "transparent",
    marginLeft: 8
  },
  publish: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "& h4": { padding: 20 },
    "& > div": {
      "& p": {
        fontSize: fontSize.medium,
        fontWeight: fontWeight.medium
      }
    }
  },
  confirmedDate: { backgroundColor: "#f4f6f8", marginBottom: 20 },
  contactTracing: {
    backgroundColor: "#F4F6F8",
    marginBottom: 12,
    boxShadow: boxShadow.card,
    cursor: "pointer",
    "& p": {
      margin: 0,
      padding: 0,
      fontSize: fontSize.small,
      textTransform: "uppercase",
      fontWeight: fontWeight.medium
    },
    "& div": { margin: 0, padding: 0 },
    "& img": { width: 16 }
  },
  contractTracingIcon: {
    color: colors.grayishBlue,
    fontSize: fontSize.small,
    marginRight: 4,
    width: 15
  },
  contactTracingContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  button: {
    border: "1px solid #C4CDD5",
    borderRadius: "3px",
    background: "linear-gradient(180deg, #FFFFFF 0%, #F9FAFB 100%)",
    boxShadow: "0 1px 0 0 rgba(22,29,37,0.05)"
  },
  uploadFileBtn: {
    marginTop: 15,
    marginBottom: 15
  },
  formItem: {
    marginBottom: 20
  },
  card: {
    boxShadow: boxShadow.card,
    borderRadius: 3,
    "&:first-child": {
      minHeight: 300
    },
    "&:nth-child(2)": {
      marginTop: 24
    },
    "&:nth-child(3)": {
      minHeight: 120,
      marginTop: 24
    }
  },
  filterTags: {
    marginBottom: 10
  },
  publishBody: {
    border: "none",
    backgroundColor: "#F9FAFB",
    padding: "15px 0 23px",
    "& .header": {
      fontWeight: 600,
      color: "#212B36"
    }
  },
  formButtons: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 20,

    "& .ant-btn": {
      borderRadius: 3,
      width: 72,
      textAlign: "center"
    },
    "&  .ant-btn:first-of-type": {
      marginRight: 8
    }
  }
});

export default CaseForm;

/** @format */

import React, { useEffect, useState } from "react";
import { useUpdateEffect } from "../../hooks/useUpdateEffect";
import { useSelector } from "react-redux";
import {
  Row,
  Col,
  Button,
  DatePicker,
  notification,
  message,
  Form
} from "antd";
import { makeStyles } from "@material-ui/styles";
import debounce from "lodash.debounce";
import * as Constants from "../../utils/Constants";
import { colors, fontSize, fontWeight } from "../../Css";
import StatisticCards from "../../atoms/StatisticCards";
import ResourceTable, { Filter } from "../../atoms/ResourceTable";
import Tags from "../../atoms/Tags";
import { Api } from "../../utils/Api";
import AddOrEditPartner from "../../components/Partners/AddOrEditPartner";
import {
  getPartnersDataErrorMessageState,
  getPartnersDataIsFetchingState,
  getPartnersDataState,
  getPartnersDataStatusState,
  getTotalItemsCountState,
  PartnersDataStatus
} from "../../redux/Partners/partnersDataReducer";
import {
  getPartnersStatsErrorMessageState,
  getPartnersStatsIsFetchingState,
  getPartnersStatsState,
  getPartnersStatsStatusState,
  PartnerStatsStatus
} from "../../redux/Partners/partnersStatsReducer";
import {
  getPartnersFormActionErrorMessageState,
  getPartnersFormActionIsFetchingState,
  getPartnersFormActionStatusState,
  getPartnerTokenState,
  FormActionStatus
} from "../../redux/Partners/partnersFormActionsReducer";
import {
  getPartnersFormErrorMessageState,
  getPartnersFormIsFetchingState,
  getPartnersFormStatusState,
  PartnersFormStatus
} from "../../redux/Partners/partnersFormReducer";
import RevokeModal from "../../components/Partners/RevokeModal";
import { fromEntries } from "../../utils";

const Partners = props => {
  const [revokeForm] = Form.useForm();
  const classes = useStyles();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(Constants.DATA_LIMIT);

  const [resourceTableData, setResourceTableData] = useState([]);
  const [showRevokeModal, setShowRevokeModal] = useState(false);

  const isFetchingPartners = useSelector(getPartnersDataIsFetchingState);
  const partnersStatus = useSelector(getPartnersDataStatusState);
  const partnersErrorMessage = useSelector(getPartnersDataErrorMessageState);
  const partnersData = useSelector(getPartnersDataState);
  const totalPartners = useSelector(getTotalItemsCountState);

  const isFetchingStats = useSelector(getPartnersStatsIsFetchingState);
  const statsStatus = useSelector(getPartnersStatsStatusState);
  const statsErrorMessage = useSelector(getPartnersStatsErrorMessageState);
  const statsData = useSelector(getPartnersStatsState);
  const [statisticCardContent, setStatisticCardContent] = useState([]);

  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [currentTab, setCurrentTab] = useState("all");
  const [token, setToken] = useState("");
  const [queryValue, setQueryValue] = useState("");
  const [drawerType, setDrawerType] = useState("");
  const [partnerId, setPartnerId] = useState("");
  const [selectedPartner, setSelectedPartner] = useState({});

  const [partnerDateRangeLabel, setPartnerDateRangeLabel] = useState("Date");

  const formActionIsFetching = useSelector(
    getPartnersFormActionIsFetchingState
  );
  const formActionErrorMessage = useSelector(
    getPartnersFormActionErrorMessageState
  );
  const formActionStatus = useSelector(getPartnersFormActionStatusState);
  const partnerToken = useSelector(getPartnerTokenState);

  const formSubmitting = useSelector(getPartnersFormIsFetchingState);
  const formErrorMessage = useSelector(getPartnersFormErrorMessageState);
  const formStatus = useSelector(getPartnersFormStatusState);

  useEffect(() => {
    if (statsStatus === PartnerStatsStatus.GET_PARTNERS_STATS_REQUEST_FAILURE) {
      notification["error"]({
        message: "Something went wrong with fetching partners statistics",
        description: statsErrorMessage
      });
    }
    // eslint-disable-next-line
  }, [statsStatus]);

  useEffect(() => {
    if (partnersStatus === PartnersDataStatus.GET_PARTNER_REQUEST_FAILURE) {
      notification["error"]({
        message: "Something went wrong with fetching partners data",
        description: partnersErrorMessage
      });
    }
    // eslint-disable-next-line
  }, [partnersStatus]);

  useEffect(() => {
    setResourceTableData(partnersData);
  }, [partnersData]);

  useEffect(() => {
    if (
      formActionStatus === FormActionStatus.REGENERATE_TOKEN_REQUEST_FAILURE ||
      formActionStatus === FormActionStatus.TOGGLE_TOKEN_STATUS_REQUEST_ERROR
    ) {
      notification["error"]({
        message: "Something went wrong",
        description: formActionErrorMessage
      });
    }
    // eslint-disable-next-line
  }, [formActionStatus]);

  useEffect(() => {
    if (formSubmitting) {
      message.loading({ content: "Submitting form", duration: 0 });
    } else {
      message.destroy();
    }
  }, [formSubmitting]);

  useUpdateEffect(() => {
    const {
      ADD_PARTNER_REQUEST_FAILURE,
      EDIT_PARTNER_REQUEST_FAILURE,
      ADD_PARTNER_REQUEST_SUCCESS,
      EDIT_PARTNER_REQUEST_SUCCESS
    } = PartnersFormStatus;
    const addPartnerFail = formStatus === ADD_PARTNER_REQUEST_FAILURE;
    const updatePartnerFail = formStatus === EDIT_PARTNER_REQUEST_FAILURE;
    const addPartnerSuccessful = formStatus === ADD_PARTNER_REQUEST_SUCCESS;
    const updatePartnerSuccessfull =
      formStatus === EDIT_PARTNER_REQUEST_SUCCESS;

    if (addPartnerFail || updatePartnerFail) {
      notification["error"]({
        message: "Something went wrong",
        description: formErrorMessage
      });
    } else if (addPartnerSuccessful || updatePartnerSuccessfull) {
      notification["success"]({
        message: "Success",
        description: addPartnerSuccessful
          ? "Partner successfully added"
          : updatePartnerSuccessfull
          ? "Partner successfully updated"
          : null
      });
    }
  }, [formStatus]);

  useEffect(() => {
    fetchPartnersData({});
    Api.PartnersRepository.getPartnerStat();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setToken(partnerToken);
  }, [partnerToken]);

  useEffect(() => {
    fetchPartnersData({});
    // eslint-disable-next-line
  }, [pageSize, currentTab]);

  useEffect(() => {
    let _partnerStats = statsData;
    let total = _partnerStats[Object.keys(_partnerStats)[0]];
    let active = _partnerStats[Object.keys(_partnerStats)[2]];
    let deactivated = _partnerStats[Object.keys(_partnerStats)[1]];

    let _statisticCardContent = [
      {
        title: "Total Partners",
        cases: total,
        type: "info"
      },
      {
        title: "Active Tokens",
        cases: active,
        type: "success"
      },
      {
        title: "Deactivated Tokens",
        cases: deactivated,
        type: "error"
      }
    ];
    setStatisticCardContent(_statisticCardContent);
  }, [statsData]);

  useEffect(() => {
    let selectedData;
    if (!!currentTab) {
      let _currentTab = currentTab === "active";
      selectedData = partnersData.filter(
        value => value.isActive === _currentTab
      );
    }
    if (currentTab.toLocaleLowerCase() === "all") selectedData = partnersData;
    setResourceTableData(selectedData);
  }, [currentTab, partnersData]);

  const columns = () => [
    {
      title: "Name",
      dataIndex: "companyName",
      key: "companyName",
      render: (companyName, data) => {
        return (
          <div className={classes.partnerTableNameColumn}>
            <p>{companyName}</p>
            <p>{data.emailAddress}</p>
          </div>
        );
      }
    },
    {
      title: "Phone",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: phoneNumber => <>{phoneNumber}</>
    },
    {
      title: "Token Status",
      dataIndex: "isActive",
      key: "isActive",
      render: isActive => {
        const label = isActive ? "Active" : "Revoked";
        const type = isActive ? "success" : "error";
        return <Tags label={label} type={type} />;
      }
    },
    {
      title: "Token",
      dataIndex: "token",
      key: "token"
    }
  ];

  const openDrawer = () => {
    setDrawerType("add");
    setIsDrawerVisible(true);
  };

  const closeDrawer = () => {
    clearFormValues();
    setIsDrawerVisible(false);
  };

  const openEditPartnerDrawer = record => {
    setSelectedPartner({
      companyName: record.companyName,
      emailAddress: record.emailAddress,
      phone: record.phoneNumber,
      status: record.isActive,
      channel: record.channel
    });
    setIsDrawerVisible(true);
  };

  const clearFormValues = () => {
    setSelectedPartner(null);
    setToken("");
  };

  const handleSubmit = values => {
    const addPartner = {
      companyName: values.companyName,
      emailAddress: values.emailAddress,
      channel: values.channel,
      phoneNumber: values.phone
    };
    Api.PartnersRepository.addPartner({ addPartner, cb: closeDrawer });
  };

  const handleUpdateSubmit = values => {
    const updatePartner = {
      id: partnerId,
      companyName: values.companyName,
      emailAddress: values.emailAddress,
      channel: values.channel,
      phoneNumber: values.phone
    };

    Api.PartnersRepository.updatePartner({ updatePartner, cb: closeDrawer });
  };

  const handleGeneratePartnerToken = () => {
    Api.PartnersRepository.generatePartnerToken(partnerId);
  };

  const handleToggleActivatePartner = () => {
    Api.PartnersRepository.toggleActivatePartner(partnerId);
  };

  const handleUpdateDateRange = dateRangeValue => {
    let startDate, endDate;
    let dateRange = {};

    if (dateRangeValue && dateRangeValue.length === 2) {
      let startDateObj = dateRangeValue[0].startOf("day");
      let endDateObj = dateRangeValue[1].startOf("day");

      startDate = startDateObj.format("YYYY-MM-DD");
      endDate = endDateObj.format("YYYY-MM-DD");

      dateRange.startDate = startDate;
      dateRange.endDate = endDate;
      setPartnerDateRangeLabel(`${startDate} to ${endDate}`);
    }

    let pageRequest = {
      ...dateRange
    };

    fetchPartnersData(pageRequest);
  };

  const renderFilterDateControl = () => {
    return (
      <div onClick={e => e.stopPropagation()}>
        <DatePicker.RangePicker onChange={handleUpdateDateRange} />
      </div>
    );
  };

  const fetchPartnersData = ({ newPageNo, ...params }) => {
    const finalParams = {
      pageNo: newPageNo || 1,
      pageSize: pageSize,
      keyword: queryValue,
      currentData: partnersData,
      ...params
    };
    const paramsWithoutNullOrUndefined = fromEntries(
      Object.entries(finalParams).filter(
        ([_, value]) =>
          value !== null &&
          value !== undefined &&
          (Boolean(value) || value === 0)
      )
    );
    Api.PartnersRepository.getPartners(paramsWithoutNullOrUndefined);
  };

  const onChangeSearchQuery = e => {
    setQueryValue(e.target.value);
    fetchOnChangeSearchQuery(e.target.value);
  };

  const handleClearSearchQuery = () => {
    setQueryValue("");
    fetchOnChangeSearchQuery();
  };

  const fetchOnChangeSearchQuery = debounce(searchQuery => {
    fetchPartnersData({ searchQuery });
  }, 500);

  const handleSubmitForm = (values, mode) => {
    if (mode === "edit") return handleUpdateSubmit(values);
    handleSubmit(values);
  };

  const handleShowRevokeModal = () => {
    revokeForm.resetFields();
    setShowRevokeModal(!showRevokeModal);
  };

  const handleSubmitRevoke = () => {
    revokeForm.submit();
  };

  const handleRevokeFormFinish = values => {
    if (values.revoke.toLowerCase() === "revoke account") {
      Api.PartnersRepository.toggleActivatePartner(partnerId);
      handleShowRevokeModal();
      closeDrawer();
    }
  };

  const handleLoadMore = page => {
    setCurrentPage(page);
    fetchPartnersData({
      pageNo: page
    });
  };

  return (
    <div spinning={false}>
      <RevokeModal
        revokeForm={revokeForm}
        showRevokeModal={showRevokeModal}
        handleShowRevokeModal={handleShowRevokeModal}
        handleRevokeFormFinish={handleRevokeFormFinish}
        handleSubmitRevoke={handleSubmitRevoke}
      />
      <div className={classes.container}>
        <div className={classes.pageMetaData}>
          <div>
            <p className="header">Partners</p>
          </div>
          <Button onClick={openDrawer} className={classes.btn}>
            Add New Partner
          </Button>
        </div>
        <Row gutter={23} className={classes.statisticCardContainer}>
          {statisticCardContent.map(el => (
            <Col key={el.title} className="gutter-row column" span={6}>
              <StatisticCards
                isLoading={!!isFetchingStats && !el.cases}
                title={el.title}
                value={el.cases}
                hideCaption={true}
                type={el.type}
              />
            </Col>
          ))}
        </Row>
        <div className="assessment-table">
          <ResourceTable
            tableProps={{
              columns: columns(),
              loading: isFetchingPartners,
              dataSource: resourceTableData,
              onRow: record => ({
                onClick: () => {
                  setDrawerType("edit");
                  setPartnerId(record.id);
                  setToken(record.token);
                  openEditPartnerDrawer(record);
                }
              }),
              pagination: {
                total: totalPartners,
                current: currentPage,
                pageSize,
                onChange: handleLoadMore,
                onShowSizeChange: (current, pageSize) => setPageSize(pageSize)
              }
            }}
            resourceName={{ singular: "name", plural: "names" }}
            filterControl={
              <Filter
                filters={[
                  {
                    key: "Date",
                    label: partnerDateRangeLabel,
                    filter: renderFilterDateControl()
                  }
                ]}
                appliedFilters={[]}
                onQueryChange={onChangeSearchQuery}
                onPressEnter={onChangeSearchQuery}
                queryValue={queryValue}
                queryClear={() => {}}
                onQueryClear={handleClearSearchQuery}
                onClearAll={() => {}}
              />
            }
            tabList={[
              { tab: "All", key: "all" },
              { tab: "Active", key: "active" },
              { tab: "Deactivated", key: "revoked" }
            ]}
            onChangeTab={value => setCurrentTab(value)}
            onSelectOption={() => null}
          />
        </div>
        <AddOrEditPartner
          mode={drawerType}
          visible={isDrawerVisible}
          onClose={closeDrawer}
          isFetchingToken={formActionIsFetching}
          onRequestTokenGeneration={handleGeneratePartnerToken}
          onTogglePartnerActiveState={handleToggleActivatePartner}
          token={token}
          handleSubmit={handleSubmitForm}
          partnerDetails={selectedPartner}
          formSubmitting={formSubmitting}
          handleShowRevokeModal={handleShowRevokeModal}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  finishedItems: {
    backgroundColor: "yellow",
    borderTop: "1px solid blue",
    marginTop: "100px",
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 20
  },

  container: {
    padding: 32,
    "& .demograph-container": {
      marginTop: 24
    },
    "& .assessment-table": {
      marginTop: 24
    },
    "& .barchart-container": {
      overflowY: "scroll"
    }
  },
  statisticCardContainer: {
    "& .column": {
      cursor: "pointer",
      margin: 0
    }
  },

  pageMetaData: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
    "& .header": {
      margin: 0,
      color: colors.base,
      fontWeight: fontWeight.medium,
      fontSize: fontSize.xxlarge
    },
    "& .sub-header": {
      margin: 0,
      color: colors.secondaryBase,
      fontSize: fontSize.xxlarge
    }
  },

  btn: {
    backgroundColor: colors.primary,
    color: colors.white
  },

  partnerTableNameColumn: {
    "& p": {
      margin: 0
    },
    "& p:first-child": {
      fontWeight: fontWeight.medium,
      color: colors.base
    }
  },

  partnerModal: {
    width: "50% !important",

    "& .ant-modal-body": {
      padding: 0
    },

    "& .ant-form-item": {
      margin: 0
    },

    "& .anticon": {
      display: "none"
    },

    "& .header": {
      backgroundColor: "#DE3618",
      padding: "40px 63px",
      display: "flex",
      flexDirection: "column"
    },

    "& .header-title": {
      fontSize: 42,
      color: "#fff",
      fontWeight: fontWeight.medium,
      lineHeight: "44px"
    },

    "& .header-subtitle": {
      fontSize: 20,
      color: "#fff",
      fontWeight: fontWeight.regular
    },

    "& .modal-body": {
      padding: "40px 63px"
    },

    "& .body-label": {
      margin: "0",
      fontSize: "26px",
      color: "#637381",
      lineHeight: "32px"
    },

    "& .body-input": {
      height: "70px",
      width: "100%",
      borderRadius: "5px",
      border: "1px solid #C4CDD5",
      background: "#F4F6F8",
      marginTop: "5px",
      paddingLeft: "15px",
      fontSize: "20px",
      fontWeight: fontWeight.bold
    },

    "& .button-wrap": {
      marginTop: "40px",
      display: "flex",
      justifyContent: "flex-end"
    },

    "& .ant-btn": {
      height: "50px",
      borderRadius: "3px",

      "&.btn-cancel": {
        marginRight: "12px",
        background: "#F9FAFB"
      },
      "&.btn-submit": {
        background: "#BF0711",
        color: "#fff"
      }
    }
  }
});

export default React.memo(Partners);

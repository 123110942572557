import {
  GET_INDEX_CASES_REQUEST,
  GET_INDEX_CASES_REQUEST_SUCCESS,
  GET_INDEX_CASES_REQUEST_FAILURE,
  RESET_INDEX_CASES_STORE,
} from "./actionTypes";

const initialState = {
  isFetching: false,
  errorMessage: "",
  status: null,
  pageNo: 0,
  total: 0,
  allIndexCasesByPageNumber: {},
  currentIndexCases: [],
};

export const Status = {
  GET_INDEX_CASES_REQUEST: `get_index_cases_request`,
  GET_INDEX_CASES_REQUEST_SUCCESS: `get_index_cases_request_success`,
  GET_INDEX_CASES_REQUEST_FAILURE: `get_index_cases_request_failure`,
};

const indexCasesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_INDEX_CASES_REQUEST:
      return {
        ...state,
        isFetching: true,
        status: Status.GET_INDEX_CASES_REQUEST,
        currentIndexCases: action.payload.currentData,
      };
    case GET_INDEX_CASES_REQUEST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        status: Status.GET_INDEX_CASES_REQUEST_SUCCESS,
        allIndexCasesByPageNumber: {
          ...state.allIndexCasesByPageNumber,
          [action.payload.pageNo]: action.payload.data,
        },
        currentIndexCases: action.payload.data,
        pageNo: action.payload.pageNo,
        total: action.payload.total,
      };
    case GET_INDEX_CASES_REQUEST_FAILURE:
      return {
        ...state,
        isFetching: false,
        status: Status.GET_INDEX_CASES_REQUEST_FAILURE,
        errorMessage: action.payload,
      };
    case RESET_INDEX_CASES_STORE:
      return initialState;
    default:
      return state;
  }
};

export const getIsFetchingState = (state) => state.indexCases.isFetching;
export const getErrorMessageState = (state) => state.indexCases.errorMessage;
export const getStatusState = (state) => state.indexCases.status;
export const getTotalItemsCountState = (state) => state.indexCases.total;
export const getCurrentIndexCasesState = (state) =>
  state.indexCases.currentIndexCases;

export default indexCasesReducer;

/**
 * Upsert is a create | edit operation, so this reducer should contain create/edit staff
 * if it still follows that convention
 */
import {
  UPDATE_STAFF_PROFILE,
  UPDATE_STAFF_PROFILE_SUCCESS,
  UPDATE_STAFF_PROFILE_FAILURE,
} from "./actionTypes";

const initialState = {
  status: null,
  isFetching: false,
  errorMessage: "",
};

export const Status = {
  UPDATE_STAFF_PROFILE: `update_staff_profile`,
  UPDATE_STAFF_PROFILE_SUCCESS: `update_staff_profile_success`,
  UPDATE_STAFF_PROFILE_FAILURE: `update_staff_profile_failure`,
};

const upsertStaffReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_STAFF_PROFILE:
      return {
        ...state,
        status: Status.UPDATE_STAFF_PROFILE,
        isFetching: true,
        errorMessage: "",
      };
    case UPDATE_STAFF_PROFILE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        errorMessage: "",
        status: Status.UPDATE_STAFF_PROFILE_SUCCESS,
      };
    case UPDATE_STAFF_PROFILE_FAILURE:
      return {
        ...state,
        isFetching: false,
        status: Status.UPDATE_STAFF_PROFILE_FAILURE,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export const getStatusState = (state) => state.upsertStaff.status;
export const getErrorMessageState = (state) => state.upsertStaff.errorMessage;
export const getIsFetchingState = (state) => state.upsertStaff.isFetching;

export default upsertStaffReducer;

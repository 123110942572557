import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Card, Row, Col, Select, Button, notification, message } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";

import CaseManagementProfileDetailsTable from "../../components/CaseManagement/CaseManagementProfileDetailsTable";
import HeadingWithBreadcrumb from "../../atoms/HeadingWithBreadcrumb";
import Loader from "../../atoms/Loader";
import { colors, fontSize, fontWeight, boxShadow } from "../../Css";
import { Api } from "../../utils/Api";
import {
  Status,
  getIsFetchingState,
  getStatusState,
  getErrorMessageState,
  getCaseDetailsState
} from "../../redux/CaseManagement/caseDetailsReducer";
import { getAllCaseStatusWithCode, getCaseStatusNameByCode } from "../../utils";
import {
  getUpdateStatusError,
  getUpdateStatusMessage,
  getUpdateStatusSuccess
} from "../../redux/CaseManagement/caseManagementReducer";
import store from "../../redux/store";
import { RESET_UPDATE_STATUS_STATE } from "../../redux/CaseManagement/actionTypes";

const { Option } = Select;

const GENDERS = {
  0: "Unknown",
  1: "Male",
  2: "Female"
};
const NOTE_PREVIEW_LENGTH = 180;

const formatGender = id => GENDERS[id];

const formatConfirmationDate = date => moment(date).format("DD - MMM - YYYY");
const getNumDaysBetweenDateAndNow = date => moment().diff(date, "days");
const getAgeFromDateOfBirth = date => moment().diff(date, "years");

const CaseDetails = ({ match }) => {
  const { params } = match;
  const { caseId } = params;

  const isFetching = useSelector(getIsFetchingState);
  const errorMessage = useSelector(getErrorMessageState);
  const status = useSelector(getStatusState);
  const caseDetails = useSelector(getCaseDetailsState);
  const updateStatusSuccess = useSelector(getUpdateStatusSuccess);
  const updateStatusError = useSelector(getUpdateStatusError);
  const updateStatusMessage = useSelector(getUpdateStatusMessage);
  const [isNoteInPreview, setIsNoteInPreview] = useState(true);
  const classes = useStyles();

  const pageTitle = isFetching
    ? "Loading"
    : status === Status.GET_SINGLE_CASE_REQUEST_FAILURE
    ? "Error"
    : caseDetails.fullName;

  useEffect(() => {
    if (caseDetails) {
      if (!!caseDetails.doctorsNote === true) {
        setIsNoteInPreview(
          caseDetails.doctorsNote.length <= NOTE_PREVIEW_LENGTH ? false : true
        );
      } else {
        setIsNoteInPreview(false);
      }
    }
  }, [caseDetails]);

  useEffect(() => {
    if (isNaN(parseInt(caseId))) {
      notification["error"]({
        message: "This case is not valid"
      });
    } else {
      Api.CaseManagementRepository.getSingleCaseDetails(caseId);
    }
  }, [caseId]);

  useEffect(() => {
    if (status === Status.GET_SINGLE_CASE_REQUEST_FAILURE) {
      notification["error"]({
        message: "Failed to load case",
        description: errorMessage
      });
    }
  }, [status, errorMessage]);

  useEffect(() => {
    if (updateStatusSuccess) {
      notification["success"]({
        message: "Status Successfully Updated"
      });
    }

    if (updateStatusError) {
      notification["error"]({
        message: "There was an issue updating the status",
        description: updateStatusMessage
      });
    }

    message.destroy();
    store.dispatch({
      type: RESET_UPDATE_STATUS_STATE
    });
    // eslint-disable-next-line
  }, [updateStatusSuccess, updateStatusError]);

  const handleStatusChange = value => {
    Api.CaseManagementRepository.updateCaseStatus(caseId, value);
    message["loading"]("Updating status");
  };

  const handleViewMoreNote = () => {
    setIsNoteInPreview(prevState => !prevState);
  };

  const viewAttachment = ([attachment]) => {
    window.open(attachment, "_blank");
  };

  const renderUserDetailsHeader = () => (
    <div className={classes.detailsHeader}>
      <div className={classes.details}>
        <div>
          <h6 className="smFont">Age</h6>
          <p className="mdFont">
            {getAgeFromDateOfBirth(caseDetails.dateOfBirth)}
          </p>
        </div>
        <div className="rightSeperator leftSeperator bioSeperator">
          <h6 className="smFont">Gender</h6>
          <p className="mdFont">{formatGender(caseDetails.gender)}</p>
        </div>
        <div>
          <h6 className="smFont">State</h6>
          <p className="mdFont">{caseDetails.state}</p>
        </div>
        <Link
          className="editIcon"
          to={{
            pathname: `/case-management/edit-case/${caseId}`,
            state: caseDetails
          }}
        >
          <EditOutlined style={{ fontSize: 14, cursor: "pointer" }} />
        </Link>
      </div>
      <div className={classes.detailsSelect}>
        <Select
          defaultValue={
            caseDetails.status
              ? `Status: ${getCaseStatusNameByCode(caseDetails.status)}`
              : "Status: Active"
          }
          style={{ width: 164, height: 36, border: `1` }}
          onChange={handleStatusChange}
          id="status"
        >
          {getAllCaseStatusWithCode()
            .filter(({ Key }) => Key !== "")
            .map(({ Key, Value }) => (
              <Option value={Key}>Status: {Value}</Option>
            ))}
        </Select>
      </div>
    </div>
  );

  const confirmationDate = caseDetails.statusAt;

  const daysSinceDiagnosis = getNumDaysBetweenDateAndNow(confirmationDate);

  const renderNotePreviewButton = () => {
    let note = caseDetails.doctorsNote || "";
    return note.length > NOTE_PREVIEW_LENGTH ? (
      <span className="viewMore" onClick={handleViewMoreNote}>
        {isNoteInPreview === true ? "view more" : "show less"}
      </span>
    ) : null;
  };

  const renderUserDetailsBody = () => (
    <div className={classes.detailsBody}>
      <div className={classes.detailBodyListItem}>
        <h6 className="smFont">Confirmed Date</h6>
        <h6 className="mdFont">{formatConfirmationDate(daysSinceDiagnosis)}</h6>
        <h6 className="setApartText">
          {daysSinceDiagnosis > 0
            ? `DAY ${daysSinceDiagnosis} since diagnosis`
            : `Diagnosed today`}
        </h6>
      </div>
      <div className={classes.detailBodyListItem}>
        <div className="mt15">
          <h6 className="smFont">Email Address</h6>
          <h6 className="mdFont">{caseDetails.emailAddress}</h6>
        </div>
        <div className="mt15">
          <h6 className="smFont">Phone Number</h6>
          <h6 className="mdFont">+234{caseDetails.phoneNumber}</h6>
        </div>
        <div className="mt15">
          <h6 className="smFont">Address</h6>
          <h6 className="mdFont">{caseDetails.address}</h6>
        </div>
      </div>
      <div className={classes.detailBodyListItem}>
        <h6 className="smFont mt15">Symptoms</h6>
        <div className="detailBodySymptoms">
          {!!caseDetails &&
            !!caseDetails.symptoms &&
            caseDetails.symptoms.map(symptom => {
              return <div className="detailBodyTag">{symptom}</div>;
            })}
        </div>
      </div>
      <div className={classes.detailBodyListItem}>
        <h6 className="smFont mt15">Note</h6>
        <h6 className="mdFont">
          {isNoteInPreview
            ? `${caseDetails.doctorsNote.substring(0, NOTE_PREVIEW_LENGTH)}...`
            : caseDetails.doctorsNote === "null"
            ? "No notes taken"
            : caseDetails.doctorsNote}{" "}
          {renderNotePreviewButton()}
        </h6>
      </div>
      <div>
        <Button
          className="detailBodyBtn"
          onClick={() => viewAttachment(caseDetails.document)}
        >
          View Attachment
        </Button>
      </div>
    </div>
  );
  return (
    <div className={classes.container}>
      <HeadingWithBreadcrumb title={pageTitle} page="Case Management" />
      <div className="">
        <Row gutter={24}>
          <Col span={8}>
            <Card className={classes.userDetails}>
              {isFetching ? (
                <Loader />
              ) : (
                <React.Fragment>
                  {renderUserDetailsHeader()}
                  {renderUserDetailsBody()}
                </React.Fragment>
              )}
            </Card>
          </Col>
          <Col span={16}>
            <CaseManagementProfileDetailsTable
              isFetchingCase={isFetching}
              gloEpidUserId={caseDetails && caseDetails.gloEpidUserId}
              createdCaseId={params.caseId}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    padding: 32
  },
  userDetails: {
    backgroundColor: colors.white,
    boxShadow: boxShadow.card,
    padding: `0 !important`,
    "& .ant-card-body": {
      padding: `0 !important`
    }
  },
  heading: {
    color: colors.base,
    fontSize: fontSize.large,
    fontWeight: fontWeight.medium,
    margin: 0
  },
  detailsHeader: {
    borderBottom: `1px solid ${colors.seperator}`,
    paddingBottom: 14.5
  },
  diagnosisDays: {
    color: colors.error,
    fontSize: fontSize.large,
    margin: 0
  },
  details: {
    display: "flex",
    alignItems: "center",
    marginTop: 20,
    marginLeft: 19,
    marginRight: 19,
    paddingBottom: 10,
    borderBottom: `1px dashed ${colors.seperator}`,
    position: "relative",
    "& .smFont": {
      fontSize: fontSize.small,
      color: colors.secondaryBase,
      fontWeight: "bold",
      marginBottom: 0
    },
    "& .mdFont": {
      fontSize: 13,
      color: colors.base,
      marginBottom: 0
    },
    "& .rightSeperator": {
      borderRight: `1px solid ${colors.seperator}`
    },
    "& .leftSeperator": {
      borderLeft: `1px solid ${colors.seperator}`
    },
    "& .editIcon": {
      position: "absolute",
      top: 0,
      right: 13,
      backgroundColor: "transparent"
    },
    "& .bioSeperator": {
      marginLeft: 20,
      marginRight: 20,
      padding: `0px 20px`
    }
  },
  detailsSelect: {
    marginLeft: 20,
    marginTop: 15,
    "& .detailsSelectLabel": {
      marginRight: 15
    }
  },
  detailsBody: {
    backgroundColor: colors.skyLighter,
    paddingTop: 26.5,
    paddingLeft: 20.5,
    paddingRight: 20.5,
    paddingBottom: 15.5,
    "& .detailBodyBtn": {
      fontSize: 14,
      color: `${colors.base} !important`,
      textAlign: "center",
      marginTop: 20
    }
  },
  detailBodyListItem: {
    borderBottom: `1px solid ${colors.seperator}`,
    // marginBottom: 20,
    paddingBottom: 10,
    "& .smFont": {
      fontSize: fontSize.small,
      color: colors.secondaryBase,
      fontWeight: "bold",
      marginBottom: 0
    },
    "& .mdFont": {
      fontSize: fontSize.medium,
      color: colors.base,
      // fontWeight: "bold",
      marginBottom: 0
    },
    "& .setApartText": {
      color: colors.error,
      fontSize: 16,
      marginTop: 5
    },
    "& .mt15": {
      marginTop: 15
    },
    "& .detailBodySymptoms": {
      display: "flex",
      flexWrap: "wrap",
      marginTop: 15
    },
    "& .detailBodyTag": {
      padding: `4px 10px`,
      backgroundColor: colors.seperator,
      color: colors.grayishBlue,
      fontSize: 13,
      marginRight: 10,
      marginBottom: 5,
      borderRadius: 100,
      border: `2px solid ${colors.white}`
    },
    "& .viewMore": {
      fontSize: 14,
      color: colors.primary,
      cursor: "pointer"
    }
  }
});

export default React.memo(CaseDetails);

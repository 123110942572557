import {
  GET_ASSESSMENT_SOURCES_REQUEST,
  GET_ASSESSMENT_SOURCES_REQUEST_SUCCESS,
  GET_ASSESSMENT_SOURCES_REQUEST_FAILURE,
} from "./actionTypes";

const initialState = {
  isFetching: false,
  errorMessage: "",
  data: null,
  status: null,
};

export const Status = {
  GET_ASSESSMENT_SOURCES_REQUEST_SUCCESS: `get_assessment_sources_request_success`,
  GET_ASSESSMENT_SOURCES_REQUEST_FAILURE: `get_assessment_sources_request_failure`,
};

const assessmentSourcesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ASSESSMENT_SOURCES_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_ASSESSMENT_SOURCES_REQUEST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload.data,
        Status: Status.LOGIN_REQUEST_SUCCESS,
      };
    case GET_ASSESSMENT_SOURCES_REQUEST_FAILURE:
      return {
        ...state,
        isFetching: false,
        Status: Status.LOGIN_REQUEST_FAILURE,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export const getIsFetchingState = (state) => state.assessmentSources.isFetching;
export const getErrorMessageState = (state) =>
  state.assessmentSources.errorMessage;
export const getAssessmentSourcesState = (state) =>
  state.assessmentSources.data;
export const getStatusState = (state) => state.assessmentSources.status;

export default assessmentSourcesReducer;

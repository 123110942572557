import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { notification, message } from "antd";
import { useSelector } from "react-redux";

import HeadingWithBreadcrumb from "../../atoms/HeadingWithBreadcrumb";
import {
  getErrorState,
  getStatusMessageState,
  getSuccessState,
  getUploadSuccessState
} from "../../redux/CaseManagement/newCaseReducer";
import { Api } from "../../utils/Api";
import CaseForm from "../../components/CaseManagement/CaseForm";
import store from "../../redux/store";
import { RESET_NEW_CASE_FORM } from "../../redux/CaseManagement/actionTypes";
import { useUpdateEffect } from "../../hooks/useUpdateEffect";

const NewCase = ({ history, location }) => {
  const classes = useStyles();
  const caseDetails = location.state ? location.state.caseDetails : {};

  const success = useSelector(getSuccessState);
  const error = useSelector(getErrorState);
  const statusMessage = useSelector(getStatusMessageState);
  const fileUploadSuccess = useSelector(getUploadSuccessState);
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async values => {
    message["loading"]("Submitting the form", 0);
    setSubmitting(true);
    Api.CaseManagementRepository.uploadFileAndCreateCase(values);
  };

  useUpdateEffect(() => {
    if (success) {
      message.destroy();
      setSubmitting(false);
      notification["success"]({
        message: "Form submitted successfully. Redirecting...",
        description: statusMessage
      });
      store.dispatch({
        type: RESET_NEW_CASE_FORM
      });
      history.push(`/case-management`);
    }

    if (error || fileUploadSuccess === false) {
      setSubmitting(false);
      message.destroy();
      notification["error"]({
        message: "Something went wrong",
        description: statusMessage
      });
    }
  }, [success, error, fileUploadSuccess]);

  return (
    <div className={classes.container}>
      <HeadingWithBreadcrumb title="New Case" page="Case Management" />
      <CaseForm
        history={history}
        handleSubmit={handleSubmit}
        caseDetails={caseDetails}
        submitting={submitting}
      />
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    padding: 32
  }
});

export default React.memo(NewCase);

import {
  GET_ASSESSMENT_LOGS_REQUEST,
  GET_ASSESSMENT_LOGS_REQUEST_SUCCESS,
  GET_ASSESSMENT_LOGS_REQUEST_FAILURE,
  RESET_ASSESSMENT_LOGS_STORE,
} from "./actionTypes";

const initialState = {
  isFetching: false,
  errorMessage: "",
  status: null,
  pageNo: 0,
  total: 0,
  allAssessmentLogsByPageNumber: {},
  currentAssessmentLogs: [],
};

export const Status = {
  GET_ASSESSMENT_LOGS_REQUEST: `get_assessment_logs_request`,
  GET_ASSESSMENT_LOGS_REQUEST_SUCCESS: `get_assessment_logs_request_success`,
  GET_ASSESSMENT_LOGS_REQUEST_FAILURE: `get_assessment_logs_request_failure`,
};

const assessmentLogsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ASSESSMENT_LOGS_REQUEST:
      return {
        ...state,
        isFetching: true,
        status: Status.GET_ASSESSMENT_LOGS_REQUEST,
        currentAssessmentLogs:
          state.allAssessmentLogsByPageNumber[action.payload.pageNo] || [],
      };
    case GET_ASSESSMENT_LOGS_REQUEST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        status: Status.GET_ASSESSMENT_LOGS_REQUEST_SUCCESS,
        allAssessmentLogsByPageNumber: {
          ...state.allAssessmentLogsByPageNumber,
          [action.payload.pageNo]: action.payload.data,
        },
        currentAssessmentLogs: action.payload.data,
        pageNo: action.payload.pageNo,
        total: action.payload.total,
      };
    case GET_ASSESSMENT_LOGS_REQUEST_FAILURE:
      return {
        ...state,
        isFetching: false,
        status: Status.GET_ASSESSMENT_LOGS_REQUEST_FAILURE,
        errorMessage: action.payload,
      };
    case RESET_ASSESSMENT_LOGS_STORE:
      return initialState;
    default:
      return state;
  }
};

export const getIsFetchingState = (state) => state.assessmentLogs.isFetching;
export const getErrorMessageState = (state) =>
  state.assessmentLogs.errorMessage;
export const getStatusState = (state) => state.assessmentLogs.status;
export const getCurrentAssessmentsState = (state) =>
  state.assessmentLogs.currentAssessmentLogs;
export const getTotalItemsCountState = (state) => state.assessmentLogs.total;

export default assessmentLogsReducer;

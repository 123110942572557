import React, { useEffect } from "react";
import { Row, Button } from "antd";
import PropTypes from "prop-types";
import {
  FlagOutlined,
  CheckCircleOutlined,
  StopOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { makeStyles } from "@material-ui/styles";
import { colors, fontWeight, fontSize, boxShadow } from "../Css";

const Banner = (props) => {
  const classes = useStyles(props);
  const {
    type,
    children,
    title,
    closable,
    onClose,
    actions,
    withoutContent,
  } = props;
  const _type = type || "default";

  useEffect(() => {
    return () => {
      onClose && onClose();
    };
    // eslint-disable-next-line
  }, []);

  const getIcon = () => {
    switch (_type) {
      case "success":
        return <CheckCircleOutlined />;
      case "error":
        return <StopOutlined />;
      case "warning":
        return <ExclamationCircleOutlined />;
      case "info":
        return <InfoCircleOutlined />;
      default:
        return <FlagOutlined />;
    }
  };

  const isLastAction = index => index === actions.length - 1

  return (
    <Row className={`${classes.container} ${_type}`}>
      {closable === true && (
        <Button className={classes.closeButton}>
          <CloseOutlined />
        </Button>
      )}
      <div className={`${classes.bannerRibbon} ${_type}`}>{getIcon()}</div>
      <div>
        <div className={classes.bannerHeading}>
          <p>{title}</p>
        </div>
        {!withoutContent && (
          <div className={classes.bannerContent}>
            {children}
            <div className={classes.bannerActions}>
              {actions.map((action, i) => (
                React.isValidElement(action) ? React.cloneElement(action, {
                  style: { marginRight: isLastAction(i)  ? 0 : 16 },
                  key: i
                }) :
                <Button
                  key={i}
                  disabled={action.actionLoading || false}
                  loading={action.actionLoading || false}
                  onClick={action.onClick}
                  style={{ marginRight: isLastAction(i) ? 0 : 16 }}
                >
                  {action.content}
                </Button>
              ))}
            </div>
          </div>
        )}
      </div>
    </Row>
  );
};

const useStyles = makeStyles({
  container: (props) => ({
    boxShadow: boxShadow.card,
    padding: 16,
    paddingRight: 56,
    paddingBottom: props?.withoutContent ? 16 : 34,
    position: "relative",
    flexWrap: "nowrap",
    "& .anticon-close": {
      color: colors.secondaryBase,
    },
    "&.info": {
      border: 0,
      borderTop: `3px solid ${colors.teal}`,
      background: colors.tealLighter,
    },
    "&.error": {
      border: 0,
      borderTop: `3px solid ${colors.error}`,
      background: "#FEF2F0",
    },
    "&.success": {
      border: 0,
      borderTop: `3px solid ${colors.success}`,
      background: "#EFF7ED",
    },
    "&.warning": {
      border: 0,
      borderTop: `3px solid ${colors.warning}`,
      background: "#FDF7E3",
    },
    "&.default": {
      border: 0,
      borderTop: `3px solid ${colors.darkGrey}`,
      background: colors.lightGrey,
    },
  }),
  closeButton: {
    position: "absolute",
    right: 16,
    top: 20,
    padding: 0,
    background: "transparent",
    height: "fit-content",
    border: 0,
    "& .anticon": {
      fontSize: fontSize.small,
    },
  },
  bannerRibbon: {
    marginRight: 16,
    borderRadius: "50%",
    height: 28,
    width: 28,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&.info": {
      background: colors.tealLight,
    },
    "&.error": {
      background: "#FEAC9A",
    },
    "&.success": {
      background: "#BCE4B3",
    },
    "&.warning": {
      background: "#FFEA8A",
    },
    "&.default": {
      background: colors.seperator,
    },
    "& .anticon": {
      position: "relative",
      display: "flex",
      alignItems: "center",
      margin: 4,
      fontSize: 20,
    },
    "& .anticon.info": {
      background: colors.teal,
    },
    "& .anticon.error": {
      background: colors.error,
    },
    "& .anticon.success": {
      background: colors.success,
    },
    "& .anticon.warning": {
      background: colors.warning,
    },
    "& .anticon.default": {
      background: colors.darkGrey,
    },
  },
  bannerHeading: {
    wordBreak: "break-word",
    "& p": {
      margin: 0,
      fontWeight: fontWeight.medium,
      fontSize: fontSize.large,
    },
  },
  bannerContent: {
    wordWrap: "break-word",
    wordBreak: "break-word",
    overflowWrap: "break-word",
    padding: "2px 0",
    "& p": {
      margin: 0,
    },
  },
  bannerActions: {
    paddingTop: 16,
    "& button": {
      background: "transparent",
      border: "1px solid rgba(33,43,54,0.4)",
      color: colors.base,
    },
    "& button:active": {
      background: "transparent",
      border: "1px solid rgba(33,43,54,0.4)",
      color: colors.base,
    },
    "& button:hover": {
      background: "transparent",
      border: "1px solid rgba(33,43,54,0.4)",
      color: colors.base,
    },
  },
});

Banner.propTypes = {
  type: PropTypes.oneOf(["success", "info", "error", "warning"]),
  children: PropTypes.node,
  title: PropTypes.string,
  closable: PropTypes.bool,
  onClose: PropTypes.func,
  withoutContent: PropTypes.bool,
  actions: PropTypes.arrayOf(
    PropTypes.oneOfType(
      [
        PropTypes.shape({
          content: PropTypes.string.isRequired,
          actionLoading: PropTypes.bool,
          actionDisabled: PropTypes.bool,
          onClick: PropTypes.func,
        }),
        PropTypes.element,
      ]
    )
  ),
};

Banner.defaultProps = {
  closable: true,
  actions: [],
  withoutContent: false,
};

export default React.memo(Banner);

import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Row, notification, Menu, Checkbox, Button } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import moment from "moment";

import { colors, fontWeight, fontSize, boxShadow } from "../../Css";
import ResourceTable, { Filter, Sorter } from "../../atoms/ResourceTable";
import { Api } from "../../utils/Api";
import * as Constants from "../../utils/Constants";
import {
  Status,
  getIsFetchingState,
  getStatusState,
  getErrorMessageState,
  getCurrentCaseManagementState,
  getTotalItemsCountState
} from "../../redux/CaseManagement/caseManagementReducer";
import { getStates } from "../../utils/States";
import { useCallbackState } from "../../hooks/useCallbackState";
import { PrivatePaths } from "../../routes";
import HeadingWithBreadcrumb from "../../atoms/HeadingWithBreadcrumb";

const TAB_VIEWS = {
  cluster: "Cluster Map",
  list: "List view"
};

const SORT_ORDER = {
  latest: { name: "Latest data first", value: "-createdAt" },
  oldest: { name: "Oldest data first", value: "+createdAt" }
};

const CaseList = props => {
  const [currentView] = useState(TAB_VIEWS.list);
  const [pageSize, setPageSize] = useState(Constants.DATA_LIMIT);
  const [currentPage, setCurrentPage] = useState(1);
  const [indexState, setIndexState] = useCallbackState(null);
  const [queryValue, setQueryValue] = useCallbackState("");
  const [sortOrder, setSortOrder] = useCallbackState(SORT_ORDER.latest.value);
  const isFetching = useSelector(getIsFetchingState);
  const status = useSelector(getStatusState);
  const errorMessage = useSelector(getErrorMessageState);
  const currentCaseManagement = useSelector(getCurrentCaseManagementState);
  const totalItems = useSelector(getTotalItemsCountState);
  const classes = useStyles();
  const { history } = props;

  useEffect(() => {
    if (status === Status.GET_CASE_MANAGEMENT_REQUEST_FAILURE) {
      notification["error"]({
        message: "Failed to load cases",
        description: errorMessage
      });
    }
  }, [status, errorMessage]);

  useEffect(() => {
    if (currentView === TAB_VIEWS.list) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [currentView, pageSize, sortOrder, indexState, queryValue, currentPage]);

  const columns = () => [
    {
      title: "Name",
      dataIndex: "fullName",
      key: "fullName",
      render: renderTableName
    },
    {
      title: "Location",
      key: "Location",
      dataIndex: "location"
    },
    {
      title: "Epidemologist",
      dataIndex: "createdBy",
      key: "createdBy"
    },
    {
      title: "Date Confirmed",
      dataIndex: "statusAt",
      key: "statusAt",
      render: date => {
        return moment(date).format("DD - MMM - YYYY / HH:mm");
      }
    }
  ];

  useEffect(() => {
    if (status === Status.GET_CASE_MANAGEMENT_REQUEST_FAILURE) {
      notification["error"]({
        message: "Failed to load cases",
        description: errorMessage
      });
    }
  }, [status, errorMessage]);

  const queryParams = {
    pageNo: 1,
    pageSize,
    keyword: queryValue,
    stateId: indexState,
    sortBy: sortOrder
  };

  const fetchData = () => {
    Api.CaseManagementRepository.getAllCaseManagement({
      ...queryParams,
      currentData: currentCaseManagement
    });
  };

  const onChangeSearchQuery = e => {
    setQueryValue(e.target.value);
    setCurrentPage(1);
  };

  const handleClearSearchQuery = () => {
    setQueryValue("");
    setCurrentPage(1);
  };

  const handleLoadMore = page => {
    setCurrentPage(page);
  };

  const handleIndexStateChange = value => e => {
    setIndexState(value);
  };

  const handleIndexStateRemove = () => {
    setIndexState(null);
  };

  const handleChangeSortOrder = value => {
    setSortOrder(value);
  };

  const disambiguateLabel = (key, value) => {
    const state = getStates().find(el => el.id === value);
    if (key === "state") {
      return `State: ${state.stateName}`;
    }
  };

  const renderAvatar = record => {
    const { avatar, fullName } = record;
    return (
      <div className={classes.avatarContainer}>
        {!!avatar === true ? (
          <img src={avatar} alt={fullName} />
        ) : (
          <span>
            <UserOutlined />
          </span>
        )}
      </div>
    );
  };

  const renderTableName = (text, record) => {
    return (
      <Row className={classes.userNameContainer}>
        {renderAvatar(record)}
        <div>
          <p>{record.fullName}</p>
          <p>{record.phoneNumber}</p>
        </div>
      </Row>
    );
  };

  const renderCluster = () => {
    return <div className={classes.clutterView} />;
  };

  const renderPageMetaData = () => {
    return (
      <HeadingWithBreadcrumb
        title="Case Management"
        showBreadCrumb={false}
        renderHeaderRight={() => (
          <Button
            onClick={() =>
              history.push(`${PrivatePaths.CASE_MANAGEMENT}/new-case`)
            }
            type="primary"
          >
            Create Case
          </Button>
        )}
      />
    );
  };

  const renderFilterStateControl = () => {
    return (
      <Menu className={classes.stateList}>
        {getStates().map(state => (
          <Menu.Item key={state.id}>
            <Checkbox
              checked={state.id === indexState}
              onChange={handleIndexStateChange(state.id)}
            >
              {state.stateName}
            </Checkbox>
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  const appliedFilters = [];
  if (!!indexState === true) {
    const key = "state";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, indexState),
      onRemove: handleIndexStateRemove
    });
  }

  const renderCasesInformation = () => {
    return (
      <ResourceTable
        resourceName={{ singular: "case", plural: "cases" }}
        filterControl={
          <Filter
            filters={[
              {
                key: "state",
                label: "State",
                filter: renderFilterStateControl(),
                resetFilter: handleIndexStateChange(null)
              }
            ]}
            appliedFilters={appliedFilters}
            queryValue={queryValue}
            onQueryChange={onChangeSearchQuery}
            onQueryClear={handleClearSearchQuery}
            onPressEnter={onChangeSearchQuery}
          />
        }
        sortControl={
          currentView === TAB_VIEWS.cluster ? null : (
            <Sorter
              numberOfSortedData={totalItems}
              currentSort={SORT_ORDER.latest.name}
              sorters={[
                {
                  name: SORT_ORDER.latest.name,
                  value: SORT_ORDER.latest.value
                },
                {
                  name: SORT_ORDER.oldest.name,
                  value: SORT_ORDER.oldest.value
                }
              ]}
              onChange={handleChangeSortOrder}
            />
          )
        }
        tableProps={{
          columns: columns(),
          dataSource: currentCaseManagement,
          loading: isFetching,
          rowKey: "id",
          onRow: record => ({
            onClick: () =>
              history.push(`${PrivatePaths.CASE_MANAGEMENT}/${record.id}`)
          }),
          pagination: {
            total: totalItems,
            current: currentPage,
            onChange: handleLoadMore,
            onShowSizeChange: (current, pageSize) => setPageSize(pageSize)
          }
        }}
        renderCustomElement={
          currentView === TAB_VIEWS.cluster ? renderCluster() : null
        }
      />
    );
  };

  return (
    <div style={{ padding: 32 }}>
      {renderPageMetaData()}
      {renderCasesInformation()}
    </div>
  );
};

const useStyles = makeStyles({
  pageMetaData: {
    marginBottom: 21,
    "& p:first-child": {
      margin: 0,
      color: colors.base,
      fontWeight: fontWeight.medium,
      fontSize: fontSize.title
    }
  },
  actionHeader: {
    marginBottom: 20,
    "& span:nth-child(2)": {
      marginLeft: 18
    },
    "& span.gray-text": {
      color: colors.secondaryBase
    }
  },
  avatarContainer: {
    height: 40,
    width: 40,
    background: "#006FBB",
    marginRight: 9,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: colors.white,
    fontWeight: fontWeight.medium,
    fontSize: fontSize.medium,
    "& img": {
      width: "inherit",
      height: "inherit",
      borderRadius: "50%"
    }
  },
  userNameContainer: {
    "& p": {
      fontSize: fontSize.medium,
      color: colors.base,
      margin: 0
    },
    "& p:first-child": {
      fontWeight: fontWeight.medium
    }
  },
  clutterView: {
    width: "100%",
    height: "700px",
    padding: "0px 20px",
    paddingBottom: 20,
    "&>div": {
      boxShadow: boxShadow.card,
      background: colors.white,
      minHeight: 654
    }
  },
  stateList: {
    "&.ant-menu": {
      border: 0
    },
    "& .ant-menu-item": {
      padding: 0,
      border: 0,
      lineHeight: 2.0,
      height: "fit-content",
      margin: "0 !important"
    },
    "& .ant-menu-item span:last-child": {
      paddingRight: 0
    }
  },
  visualizationContainer: {
    width: "100%",
    height: "100%"
  }
});

export default React.memo(CaseList);

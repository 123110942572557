export const NOTIFICATION_VARIANTS = Object.freeze({
  ContactTrace: 'notification-variant/contact-trace',
  CaseManagement: 'notification-variant/case-management',
});

const ContactStatus = Object.freeze({
  Pending: 'queued',
  Successful: 'sent',
  Failed: 'failed',
});

const getSuccessfulContacts = (contacts) =>
  contacts.filter(
    (contact) => contact.status.toLowerCase() === ContactStatus.Successful
  );

const getPendingContacts = (contacts) =>
  contacts.filter(
    (contact) => contact.status.toLowerCase() === ContactStatus.Pending
  );

const getFailedContacts = (contacts) =>
  contacts.filter(
    (contact) => contact.status.toLowerCase() === ContactStatus.Failed
  );

export const transformBackendModelToFrontendModel = (data) => {
  return data.map(({ Key: date, Value: contacts }) => {
    const successful = getSuccessfulContacts(contacts);
    const pending = getPendingContacts(contacts);
    const failed = getFailedContacts(contacts);
    const result = {
      message: contacts[0].message,
      recepients: contacts,
      date,
      byStatus: {
        successful,
        pending,
        failed,
      },
      statusCount: {
        successful: successful.length,
        pending: pending.length,
        failed: failed.length,
      },
    };
    return result;
  });
};

const NOTIFICATION_TYPES = [
  {
    id: 1,
    name: 'SMS',
  },
  {
    id: 2,
    name: 'Whatsapp',
  },
  {
    id: 3,
    name: 'Email',
  },
  {
    id: 4,
    name: 'Push Notification',
  },
  {
    id: 5,
    name: 'Twitter',
  },
  {
    id: 6,
    name: 'Facebook',
  },
  {
    id: 7,
    name: 'Whatsapp',
  },
];

const ENABLED_CONTACT_TRACING_NOTIFICATION_TYPES = NOTIFICATION_TYPES.filter(
  (notificationType) => [1, 3, 4].includes(notificationType.id)
);

const ENABLED_CASE_MANAGEMENT_NOTIFICATION_TYPES = NOTIFICATION_TYPES.filter(
  (notificationType) => [1, 3].includes(notificationType.id)
);

export const DEFAULT_NOTIFICATION_TYPE = NOTIFICATION_TYPES[0];

export const getAllContactTraceNotificationTypes = () =>ENABLED_CONTACT_TRACING_NOTIFICATION_TYPES;
export const getAllCaseManagementNotificationTypes = () => ENABLED_CASE_MANAGEMENT_NOTIFICATION_TYPES;

export const getNotificationTypeId = (notificationType) => notificationType.id;
export const getNotificationTypeName = (notificationType) =>
  notificationType.name;

export const NOTIFICATION_CATEGORIES = {
  contact: 1,
  case: 2,
  review: 3,
};

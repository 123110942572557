export const SET_FORM_FIELD = "SET_FORM_FIELD";
export const RESET_FORM_FIELDS = "RESET_FORM_FIELDS";
export const SET_INITIAL_FIELD_VALUES = "SET_INITIAL_FIELD_VALUES";

export const EDIT_CONTACT_DETAILS = "SET_CONTACT_DETAILS";
export const ADD_CONTACT = "ADD_CONTACT";

export const ADD_SYMPTOM = "ADD_SYMPTOM";
export const DELETE_SYMPTOM = "DELETE_SYMPTOM";

export const GET_CASE_MANAGEMENT_REQUEST = "GET_CASE_MANAGEMENT_REQUEST";
export const GET_CASE_MANAGEMENT_REQUEST_SUCCESS =
  "GET_CASE_MANAGEMENT_REQUEST_SUCCESS";
export const GET_CASE_MANAGEMENT_REQUEST_FAILURE =
  "GET_CASE_MANAGEMENT_REQUEST_FAILURE";
export const RESET_CASE_MANAGEMENT_STORE = "RESET_CASE_MANAGEMENT_STORE";

export const ADD_NEW_CASE_REQUEST_SUCCESS = "ADD_NEW_CASE_REQUEST_SUCCESS";
export const ADD_NEW_CASE_REQUEST_ERROR = "ADD_NEW_CASE_REQUEST_ERROR";
export const EDIT_CASE_REQUEST_SUCCESS = "EDIT_CASE_REQUEST_SUCCESS";
export const EDIT_CASE_REQUEST_ERROR = "EDIT_CASE_REQUEST_ERROR";
export const SET_INITIAL_CASE_VALUES = "SET_INITIAL_CASE_VALUES";
export const UPLOAD_FILES_SUCCESS = "UPLOAD_FILES_SUCCESS";
export const UPLOAD_FILES_ERROR = "UPLOAD_FILES_ERROR";

export const GET_SINGLE_CASE_REQUEST = "GET_SINGLE_CASE_REQUEST";
export const GET_SINGLE_CASE_REQUEST_SUCCESS =
  "GET_SINGLE_CASE_REQUEST_SUCCESS";
export const GET_SINGLE_CASE_REQUEST_FAILURE =
  "GET_SINGLE_CASE_REQUEST_FAILURE";
export const RESET_SINGLE_CASE_STORE = "RESET_SINGLE_CASE_STORE";

export const GET_CREATED_CASE_CONTACT_REQUEST =
  "GET_CREATED_CASE_CONTACT_REQUEST";
export const GET_CREATED_CASE_CONTACT_REQUEST_SUCCESS =
  "GET_CREATED_CASE_CONTACT_REQUEST_SUCCESS";
export const GET_CREATED_CASE_CONTACT_REQUEST_FAILURE =
  "GET_CREATED_CASE_CONTACT_REQUEST_FAILURE";
export const RESET_CREATED_CASE_CONTACT_STORE =
  "RESET_CREATED_CASE_CONTACT_STORE";

export const RESET_NEW_CASE_FORM = "RESET_NEW_CASE_FORM";

export const UPDATE_STATUS_SUCCESS = "UPDATE_STATUS_SUCCESS";
export const UPDATE_STATUS_ERROR = "UPDATE_STATUS_ERROR";
export const RESET_UPDATE_STATUS_STATE = "RESET_UPDATE_STATUS";

import {
  GET_ASSESSMENTS_REQUEST,
  GET_ASSESSMENTS_REQUEST_SUCCESS,
  GET_ASSESSMENTS_REQUEST_FAILURE,
  RESET_ASSESSMENTS_STORE,
  UPDATE_ACTION_STATUS_SUCCESS,
} from "./actionTypes";
import { getActionStatusNameForId } from "../../utils/Assessment";

const initialState = {
  isFetching: false,
  errorMessage: "",
  status: null,
  pageNo: 0,
  total: 0,
  allAssessmentsByPageNumber: {},
  currentAssessments: [],
};

export const Status = {
  GET_ASSESSMENTS_REQUEST: `get_assessments_request`,
  GET_ASSESSMENTS_REQUEST_SUCCESS: `get_assessments_request_success`,
  GET_ASSESSMENTS_REQUEST_FAILURE: `get_assessments_request_failure`,
};

const assessmentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ASSESSMENTS_REQUEST:
      return {
        ...state,
        isFetching: true,
        status: Status.GET_ASSESSMENTS_REQUEST,
        currentAssessments: action.payload.currentData,
      };
    case GET_ASSESSMENTS_REQUEST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        status: Status.GET_ASSESSMENTS_REQUEST_SUCCESS,
        allAssessmentsByPageNumber: {
          ...state.allAssessmentsByPageNumber,
          [action.payload.pageNo]: action.payload.data,
        },
        currentAssessments: action.payload.data,
        pageNo: action.payload.pageNo,
        total: action.payload.total,
      };
    case GET_ASSESSMENTS_REQUEST_FAILURE:
      return {
        ...state,
        isFetching: false,
        status: Status.GET_ASSESSMENTS_REQUEST_FAILURE,
        errorMessage: action.payload,
      };
    case RESET_ASSESSMENTS_STORE:
      return initialState;
    case UPDATE_ACTION_STATUS_SUCCESS:
      // Also update the data on the table
      const newState = { ...state }
      // check currentAssessments
      let assessment = null;
      assessment = newState.currentAssessments.find(currentAssessment => currentAssessment.id === action.payload.assessmentId)
      if (assessment) {
        assessment.status = getActionStatusNameForId(action.payload.statusId)
      }
      // check all pages
      // assessment = Object.values(newState.allAssessmentsByPageNumber).reduce((acc, page) => [...acc, ...page])
      return newState;
    default:
      return state;
  }
};

export const getIsFetchingState = (state) => state.assessments.isFetching;
export const getErrorMessageState = (state) => state.assessments.errorMessage;
export const getStatusState = (state) => state.assessments.status;
export const getTotalItemsCountState = (state) => state.assessments.total;
export const getCurrentAssessmentState = (state) =>
  state.assessments.currentAssessments;

export default assessmentsReducer;

import {
  GET_PATIENT_INFO_REQUEST,
  GET_PATIENT_INFO_REQUEST_SUCCESS,
  GET_PATIENT_INFO_REQUEST_FAILURE
} from "./actionTypes";

const initialState = {
  isFetching: false,
  errorMessage: "",
  status: null,
  patientData: {}
};

export const Status = {
  GET_PATIENT_INFO_REQUEST: `get_patient_info_request`,
  GET_PATIENT_INFO_REQUEST_SUCCESS: `get_patient_info_request_success`,
  GET_PATIENT_INFO_REQUEST_FAILURE: `get_patient_info_request_failure`
};

const indexCasesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PATIENT_INFO_REQUEST:
      return {
        ...state,
        isFetching: true,
        status: Status.GET_PATIENT_INFO_REQUEST,
        patientData: {}
      };
    case GET_PATIENT_INFO_REQUEST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        status: Status.GET_PATIENT_INFO_REQUEST_SUCCESS,
        patientData: action.payload
      };
    case GET_PATIENT_INFO_REQUEST_FAILURE:
      return {
        ...state,
        isFetching: false,
        status: Status.GET_PATIENT_INFO_REQUEST_FAILURE,
        errorMessage: action.payload
      };
    default:
      return state;
  }
};

export const getIsFetchingState = state => state.patient.isFetching;
export const getErrorMessageState = state => state.patient.errorMessage;
export const getStatusState = state => state.patient.status;
export const getPatientDataState = state => state.patient.patientData;

export default indexCasesReducer;

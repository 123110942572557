/** @format */

import { combineReducers } from 'redux';
import loginReducer from './Authentication/loginReducer';
import resetPasswordReducer from './Authentication/resetPasswordReducer';
import forgotPasswordReducer from './Authentication/forgotPasswordReducer';

import assessmentsByDemographyReducer from './Assessment/assessmentsByDemographyReducer';
import assessmentsReducer from './Assessment/assessmentsReducer';
import assessmentsSummaryReducer from './Assessment/assessmentsSummaryReducer';
import assessmentSourcesReducer from './Assessment/assessmentSourcesReducer';
import assessmentsReportDetailsReducer from './Assessment/assessmentReportDetailsReducer';
import assessmentsByPeriodReducer from './Assessment/assessmentsByPeriodReducer';
import heatmapReducer from './Assessment/heatmapReducer';
import partnerSourcesReducer from './Assessment/partnerSourcesReducer';

import indexCasesReducer from './ContactTrace/indexCasesReducer';
import activityLogsReducer from './ContactTrace/activityLogsReducer';
import assessmentLogsReducer from './ContactTrace/assessmentLogsReducer';
import PatientInfoReducer from './ContactTrace/PatientInfoReducer';
import exportContactReducer from './ContactTrace/exportContactReducer';

import newContactFormReducer from './CaseManagement/newContactFormReducer';
import newCaseReducer from './CaseManagement/newCaseReducer';
import caseManagementReducer from './CaseManagement/caseManagementReducer';
import caseDetailsReducer from './CaseManagement/caseDetailsReducer';
import createdCaseContactReducer from './CaseManagement/createdCaseContactReducer';

import staffRolesReducer from './Staffs/rolesReducer';
import staffDataReducer from './Staffs/staffDataReducer';
import staffAvatarReducer from './Staffs/staffAvatarReducer';
import upsertStaffReducer from './Staffs/upsertStaffReducer';
import resetStaffPasswordReducer from './Staffs/resetStaffPasswordReducer';
import updateStaffRoleReducer from './Staffs/updateStaffRoleReducer';

import OverviewSummaryReducer from './Overview/overviewSummaryReducer';
import CasesByRegionReducer from './Overview/casesByRegionReducer';
import CasesByRiskFactorReducer from './Overview/casesByRiskFactorReducer'

import partnerChannelsReducer from './Partners/partnerChannelsReducer';
import partnersDataReducer from './Partners/partnersDataReducer';
import partnersStatsReducer from './Partners/partnersStatsReducer';
import partnersFormReducer from './Partners/partnersFormReducer';
import partnersFormActionsReducer from './Partners/partnersFormActionsReducer';

import notificationsReducer from './Notification/notificationsReducer';
import sendNotificationReducer from './Notification/sendNotificationReducer';
import {assessmentsCSVUrlReducer} from "./Assessment/assessmentsCSVUrlReducer";


export default combineReducers({
  login: loginReducer,
  resetPassword: resetPasswordReducer,
  forgotPassword: forgotPasswordReducer,

  indexCases: indexCasesReducer,
  activityLogs: activityLogsReducer,
  assessmentLogs: assessmentLogsReducer,

  demographicChart: assessmentsByDemographyReducer,
  patient: PatientInfoReducer,
  exportContact: exportContactReducer,

  notifications: notificationsReducer,
  sendNotification: sendNotificationReducer,

  assessments: assessmentsReducer,
  assessmentReportDetails: assessmentsReportDetailsReducer,
  assessmentSources: assessmentSourcesReducer,
  assessmentsByPeriod: assessmentsByPeriodReducer,
  assessmentsSummary: assessmentsSummaryReducer,
  assessmentsCSVUrl: assessmentsCSVUrlReducer,

  partnerSources: partnerSourcesReducer,
  heatmapData: heatmapReducer,

  caseManagement: caseManagementReducer,
  caseDetails: caseDetailsReducer,
  createdCaseContact: createdCaseContactReducer,
  newContactForm: newContactFormReducer,
  newCase: newCaseReducer,

  staffRoles: staffRolesReducer,
  staffData: staffDataReducer,
  staffAvatar: staffAvatarReducer,
  upsertStaff: upsertStaffReducer,
  resetStaffPassword: resetStaffPasswordReducer,
  updateStaffRole: updateStaffRoleReducer,

  partnerChannels: partnerChannelsReducer,
  partnersData: partnersDataReducer,
  partnersStats: partnersStatsReducer,
  partnersForm: partnersFormReducer,
  partnersFormActive: partnersFormActionsReducer,

  overviewSummary: OverviewSummaryReducer,
  casesByRegion: CasesByRegionReducer,
  casesByRiskFactor: CasesByRiskFactorReducer
});

export const GET_NOTIFICATIONS_REQUEST = 'GET_NOTIFICATIONS_REQUEST';
export const GET_NOTIFICATIONS_REQUEST_SUCCESS =
  'GET_NOTIFICATIONS_REQUEST_SUCCESS';
export const GET_NOTIFICATIONS_REQUEST_FAILURE =
  'GET_NOTIFICATIONS_REQUEST_FAILURE';
export const RESET_NOTIFICATIONS_STORE = 'RESET_NOTIFICATIONS_STORE';

export const GET_CONTACTS_REQUEST = 'GET_CONTACTS_REQUEST';
export const GET_CONTACTS_REQUEST_SUCCESS = 'GET_CONTACTS_REQUEST_SUCCESS';
export const GET_CONTACTS_REQUEST_FAILURE = 'GET_CONTACTS_REQUEST_FAILURE';

export const SEND_NOTIFICATION_REQUEST = 'SEND_NOTIFICATION_REQUEST';
export const SEND_NOTIFICATION_REQUEST_SUCCESS =
  'SEND_NOTIFICATION_REQUEST_SUCCESS';
export const SEND_NOTIFICATION_REQUEST_FAILURE =
  'SEND_NOTIFICATION_REQUEST_FAILURE';

export const SELECT_CONTACT = 'SELECT_CONTACT';
export const DESELECT_CONTACT = 'DESELECT_CONTACT';
export const SELECT_ALL_CONTACTS = 'SELECT_ALL_CONTACTS';
export const DESELECT_ALL_CONTACTS = 'DESELECT_ALL_CONTACTS';

export const RESET_SEND_NOTIFICATION_STORE = 'RESET_SEND_NOTIFICATION_STORE';

import React from "react";
import { Switch } from "react-router-dom";

import PrivateRoute from "../../routes/PrivateRoute";
import NewCase from "./NewCase";
import CaseDetails from "./CaseDetails";
import CaseList from "./CaseList";
import EditCase from "./EditCase";

const CaseManagement = (props) => {
  let {
    match: { path },
  } = props;

  return (
    <Switch>
      <PrivateRoute path={path} exact={true} component={CaseList} />
      <PrivateRoute
        path={`${path}/new-case`}
        exact={true}
        component={NewCase}
      />
      <PrivateRoute
        path={`${path}/edit-case/:caseId`}
        exact={true}
        component={EditCase}
      />
      <PrivateRoute
        path={`${path}/:caseId`}
        exact={true}
        component={CaseDetails}
      />
    </Switch>
  );
};

export default React.memo(CaseManagement);

export const ACTION_STATUS = [
  {
    id: 0,
    label: 'Pending',
    type: 'warning'
  },
  {
    id: 1,
    label: 'In progress',
    type: 'info'
  },
  {
    id: 2,
    label: 'Escalated',
    type: 'success'
  },
  {
    id: 3,
    label: 'Closed',
    type: 'default'
  }
]

const indexOf = statusName => ACTION_STATUS.findIndex(actionStatus => actionStatus.label === statusName)

export const getActionStatusById = (statusId) => ACTION_STATUS.find(actionStatus => actionStatus.id === statusId)
export const getActionStatusByName = (statusName) => ACTION_STATUS.find(actionStatus => actionStatus.label === statusName)
export const getActionStatusIdForName = (statusName) => ACTION_STATUS.find(actionStatus => actionStatus.label === statusName)?.id
export const getActionStatusNameForId = (statusId) => ACTION_STATUS.find(actionStatus => actionStatus.id === statusId)?.label

// The user cannot go back to a previous status after moving forward
export const isValidNewActionStatus = (currentStatusName, newStatusName) => indexOf(newStatusName) >= indexOf(currentStatusName)
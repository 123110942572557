import React, { useEffect, useState } from "react";
import PropTypes, { string, node } from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Input, Button } from "antd";
import { SearchOutlined, CloseOutlined } from "@ant-design/icons";

import { colors, fontSize } from "../../Css";
import ScrollBarImg from "../../assets/images/scrollbar-track.png";
import { getStates } from "../../utils/States";

const states = getStates();

const States = ({ selectedStateId, onChangeSelectedState }) => {
  const classes = useStyles();
  const [stateSearch, setStateSearch] = useState("");
  const [filteredStates, setFilteredStates] = useState(states);

  useEffect(() => {
    if (!stateSearch.trim() && !stateSearch) {
      setFilteredStates(states);
    } else {
      setFilteredStates(
        states.filter(({ stateName }) =>
          stateName.toLowerCase().includes(stateSearch.trim().toLowerCase())
        )
      );
    }
  }, [stateSearch]);

  const handleChange = e => {
    const { value } = e.target;
    setStateSearch(value);
  };

  const handleClearClick = e => {
    e.stopPropagation();
    onChangeSelectedState(null);
  };

  return (
    <div>
      <Input
        size="large"
        placeholder="Search state"
        allowClear={false}
        prefix={<SearchOutlined />}
        className={classes.searchBox}
        value={stateSearch}
        onChange={handleChange}
      />
      <div className={classes.statesContainer}>
        {filteredStates.map(el => (
          <div
            className={`${classes.statesWrapper} ${selectedStateId === el.id &&
              "activeSelection"}`}
            onClick={() => onChangeSelectedState(el)}
            key={el.id}
          >
            <p className={classes.statesName}>{el.stateName}</p>
            {selectedStateId === el.id ? (
              // An empty string is used here instead of null to avoid errors
              // in getAssessmentsByPeriod and getAssessmentSources
              <Button
                type="link"
                style={{ color: colors.primary }}
                onClick={handleClearClick}
              >
                <CloseOutlined />
              </Button>
            ) : null}
          </div>
        ))}
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  searchBox: {
    width: "100%",
    borderRadius: "3px",
    height: 36,
    "& input": {
      fontSize: fontSize.medium
    },
    "& .ant-input-prefix": {
      marginTop: -2
    }
  },
  statesWrapper: {
    color: colors.secondaryBase,
    cursor: "pointer",
    padding: "5px 10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "&.activeSelection": {
      color: colors.primary,
      backgroundColor: colors.primaryWeak
    },
    "& button": {
      padding: 0,
      margin: 0,
      lineHeight: 1,
      height: "unset"
    }
  },
  statesCase: {
    color: colors.base,
    width: 30
  },
  statesName: {
    paddingLeft: 0
  },
  statesContainer: {
    fontSize: fontSize.medium,
    flex: 1,
    height: "550px",
    "overflow-y": "scroll",
    marginTop: 20,
    "& > div": {
      marginTop: 7,
      marginRight: 10
    },
    "& p": {
      display: "inline-block",
      margin: 0
    },
    [`&::-webkit-scrollbar-button, 
      &::-webkit-scrollbar-track-piece, 
      &::-webkit-scrollbar-corner,
      &::-webkit-resizer`]: {
      display: "none"
    },
    [`&::-webkit-scrollbar,&::-webkit-scrollbar-thumb,&::-webkit-scrollbar-track`]: {
      width: 15,
      border: "none",
      background: "transparent"
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: 3,
      backgroundColor: "#637381",
      width: 15
    },
    "&::-webkit-scrollbar-track": {
      backgroundImage: `url(${ScrollBarImg})`,
      backgroundRepeat: "repeat-y",
      backgroundSize: "contain"
    },
    "& .activeSelection": {}
  }
});

States.propTypes = {
  label: PropTypes.oneOfType([string, node]),
  type: PropTypes.oneOf(["error", "success", "warning", "info"])
};

export default React.memo(States);

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Button, Dropdown, Card, DatePicker, Table, notification } from "antd";
import { ReactComponent as NoDataImage } from "../../assets/images/no-data-image.svg";
import { fontWeight, fontSize, colors } from "../../Css";
import { CaretDownOutlined, RightOutlined } from "@ant-design/icons";
import moment from "moment";
import { DATA_LIMIT } from "../../utils/Constants";
import {
  getIsFetchingState,
  getStatusState,
  getErrorMessageState,
  getTotalItemsCountState,
  getCurrentNotificationsState,
  Status,
  getIsFirstFetchState
} from "../../redux/Notification/notificationsReducer";
import { Api } from "../../utils/Api";
import Loader from "../../atoms/Loader";
import ViewNotification from "./ViewNotification";
import SendNotification from "./SendNotification";
import {
  NOTIFICATION_VARIANTS,
  NOTIFICATION_CATEGORIES
} from "../../utils/Notifications";

const formatDate = date => moment(date).format("DD - MMM - YYYY / HH:mm");

const Notification = ({
  caseId,
  variant = NOTIFICATION_VARIANTS.ContactTrace,
  isFetchingCase,
  gloEpidUserId
}) => {
  const classes = useStyles();

  const isFetching = useSelector(getIsFetchingState);
  const isFirstFetch = useSelector(getIsFirstFetchState);
  const status = useSelector(getStatusState);
  const errorMessage = useSelector(getErrorMessageState);
  const totalItems = useSelector(getTotalItemsCountState);
  const currentNotifications = useSelector(getCurrentNotificationsState);
  const [pageSize] = useState(DATA_LIMIT);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [showSendNotificaton, setShowSendNotification] = useState(false);

  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null
  });

  const resetTableState = () => {
    setCurrentPage(1);
    setDateRange({
      startDate: null,
      endDate: null
    });
  };

  const renderNoNotificationsMessage = () => (
    <div className={classes.emptyState}>
      <NoDataImage className="image" />
      <h5 className="title">No data</h5>
      <p className="subtitle">
        You have not sent any message, click{" "}
        <Button
          type="link"
          onClick={() => setShowSendNotification(true)}
          style={{ padding: 0, lineHeight: 1 }}
        >
          here
        </Button>{" "}
        to send
      </p>
    </div>
  );

  const handleDateRangeChange = dates => {
    if (dates === null || dates === undefined) {
      setDateRange({
        startDate: null,
        endDate: null
      });
    } else {
      const [startDate, endDate] = dates;
      setDateRange({
        startDate,
        endDate
      });
    }
  };

  const renderDateRangeOverlay = () => (
    <Card bodyStyle={{ padding: 16 }}>
      <div onClick={e => e.stopPropagation()}>
        <DatePicker.RangePicker
          value={[dateRange.startDate, dateRange.endDate]}
          onChange={handleDateRangeChange}
        />
      </div>
    </Card>
  );

  const renderDateRangeFilter = () => (
    <Dropdown
      placement="bottomRight"
      overlay={renderDateRangeOverlay()}
      trigger={["click"]}
    >
      <Button>
        Date <CaretDownOutlined />
      </Button>
    </Dropdown>
  );

  const columns = () => [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: formatDate
    },
    {
      title: "Total Recepients",
      dataIndex: "recepients",
      key: "recepients",
      render: recepients => recepients.length
    },
    {
      title: "Status",
      dataIndex: "statusCount",
      key: "statusCount",
      className: "rightAlignText",
      render: statusCount => (
        <div className={classes.statusWrapper}>
          <div className={classes.status}>
            <p className="successfulCountText">
              {statusCount.successful} Successes
            </p>
            <p className="failedCountText">{statusCount.failed} Fails</p>
            <p className="pendingCountText">{statusCount.pending} Pending</p>
          </div>
          <RightOutlined />
        </div>
      )
    }
  ];

  const renderNotificationsTable = () => (
    <Table
      columns={columns()}
      loading={isFetching}
      dataSource={currentNotifications}
      onRow={record => ({
        onClick: () => setSelectedNotification(record)
      })}
      pagination={{
        total: totalItems,
        current: currentPage,
        pageSize,
        onChange: page => setCurrentPage(page)
      }}
    />
  );

  const handleCloseSendNotification = (refreshData = false) => {
    if (refreshData) {
      resetTableState();
    }
    setShowSendNotification(false);
  };

  useEffect(() => {
    if (status === Status.GET_NOTIFICATIONS_REQUEST_FAILURE)
      notification["error"]({
        message: "Failed to load notifications",
        description: errorMessage
      });
  }, [status, errorMessage]);

  useEffect(() => {
    let notificationCategory = NOTIFICATION_CATEGORIES.contact;
    if (variant === NOTIFICATION_VARIANTS.CaseManagement)
      notificationCategory = NOTIFICATION_CATEGORIES.case;
    Api.NotificationRepository.getNotifications({
      // A case that's a gloepid user will have it's notifications fetched by
      // their gloEpidUserId, otherwise their caseId is used
      caseId: gloEpidUserId || caseId,
      notificationCategory,
      pageNo: currentPage,
      pageSize,
      dateRange
    });
  }, [caseId, currentPage, pageSize, dateRange, variant, gloEpidUserId]);

  const parentOrSelfIsFetching = isFetching || isFetchingCase;

  return (
    <div className={classes.notification}>
      <div className={classes.topBar}>
        {renderDateRangeFilter()}
        <Button
          disabled={isFetchingCase}
          onClick={() => setShowSendNotification(true)}
        >
          Add New
        </Button>
      </div>
      {parentOrSelfIsFetching && isFirstFetch && <Loader title="" />}
      {!parentOrSelfIsFetching &&
        isFirstFetch &&
        totalItems === 0 &&
        renderNoNotificationsMessage()}
      {(totalItems !== 0 || !isFirstFetch) && renderNotificationsTable()}
      <ViewNotification
        visible={!!selectedNotification}
        notification={selectedNotification}
        onClose={() => setSelectedNotification(null)}
      />
      <SendNotification
        caseId={caseId}
        caseGloEpidUserId={gloEpidUserId}
        variant={variant}
        visible={showSendNotificaton}
        onClose={handleCloseSendNotification}
      />
    </div>
  );
};

const useStyles = makeStyles({
  notification: {
    padding: "0 0 10px",
    "& .ant-table-thead > tr > th": {
      borderBottom: "1px solid #C4CDD4",
      background: colors.white,
      padding: "0px 20px",
      height: 53,
      fontWeight: "600"
    },
    "& .ant-table-tbody > tr > td": {
      borderBottom: `1px solid ${colors.seperator}`,
      padding: "0px 20px",
      height: 53,
      cursor: "pointer"
    },
    "& .ant-pagination": {
      paddingRight: 24
    },
    "& .rightAlignText": {
      textAlign: "right"
    }
  },
  topBar: {
    padding: "12px 17px",
    display: "flex"
  },
  emptyState: {
    width: "100%",
    height: 500,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& .image": {
      width: 130,
      height: 122,
      marginBottom: 18
    },
    "& .title": {
      fontWeight: fontWeight.medium,
      fontSize: fontSize.large,
      color: colors.base,
      marginBottom: 15,
      lineHeight: 1.5
    },
    "& .subtitle": {
      fontSize: fontSize.medium,
      color: colors.secondaryBase,
      lineHeight: 1.43
    }
  },
  statusWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  status: {
    display: "flex",
    alignItems: "flex-end",
    flexDirection: "column",
    marginRight: 22,
    "& p": {
      margin: 0,
      fontSize: fontSize.small,
      lineHeight: 1.33
    },
    "& .successfulCountText": {
      color: colors.success
    },
    "& .failedCountText": {
      color: colors.error
    },
    "& .pendingCountText": {
      color: colors.info
    }
  }
});

export default Notification;
